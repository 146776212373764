import { Box, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton } from "@mui/material";
import { useContext, useState, useEffect } from "react";
import PaginationGeneric from "../../../components/Pagination/PaginationGeneric";
import keycloakConf from "../../../api/keycloakConf";
import texto from '../../../assets/Glosario';
import Acciones from "../../../components/Acciones/Acciones";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useLocation, useNavigate } from "react-router-dom";
import { fetchNotificacionesAcuerdo } from "../Asignado/Tabs/Acuerdo/AcuerdoService";
import { obtenerAcuerdoCargado } from "../BandejaService";
import RefreshButton from "../../../components/Button/RefreshButton";

const NotificacionesAcuerdoTable = ({ general, isConsulta }) => {
  const [pageable, setPageable] = useState([]);
  const [page, setPage] = useState(0);
  const { getToken } = useContext(keycloakConf);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const navigate = useNavigate();
  const location = useLocation();
  const { item, acuerdo } = location.state || {};

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const handleCancel = () => {
    navigate('/api/bandeja/expediente', {
      state: {
        item: item,
        tabnav: 2
      }
    })
  };

  const handleShowAcuerdo = async (item) => {
    try {
      const token = getToken();
      let documento = await obtenerAcuerdoCargado(token, item, item.tamanioPapel);

      let blob = new Blob([documento], { type: "application/pdf" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.target = "acuerdo.pdf";
      link.click();

    } catch (error) {
      console.error("Error al generar el documento:", error);
    }
  };

  const fetchNotificaciones = async () => {
    const token = await getToken();
    const idNotificacion = acuerdo ? acuerdo.numeroAcuerdo : null;
    if (idNotificacion) {
      try {
        const data = await fetchNotificacionesAcuerdo(token, idNotificacion, page, rowsPerPage);
        setPageable(data);
      } catch (error) {
        console.error("Error al obtener las notificaciones:", error);
      }
    }
  };

  useEffect(() => {
    fetchNotificaciones();
  }, [acuerdo, page, rowsPerPage, getToken]);

  const capitalize = (str) => {
    return str
      .replace(/_/g, ' ')
      .toLowerCase()
      .replace(/^\w/, (char) => char.toUpperCase())
  };

  return <>
    <Acciones accion2={handleCancel} isDisabled={isConsulta} isVisibleBtn1={false} />

    <Box mb={2} mt={2} display="flex" justifyContent="space-between" alignItems="center">
      <Box display="flex" alignItems="center">
        <RefreshButton onClick={fetchNotificaciones} />
      </Box>
    </Box>

    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer sx={{ maxHeight: 600, overflowY: 'auto' }}>
          <Table stickyHeader sx={{ minWidth: 750 }}>
            <TableHead>
              <TableRow>
                <TableCell className='table-header'>{texto.nombreDestinatario}</TableCell>
                <TableCell className='table-header'>{texto.metodoNotificacion}</TableCell>
                <TableCell className='table-header'>{texto.Estatus}</TableCell>
                <TableCell className='table-header'>{texto.comentarios}</TableCell>
                <TableCell className='table-header'>{texto.AccionesRapidas}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pageable.content && pageable.content.length > 0 ? (
                pageable.content.map((notificacion) => (
                  <StyledTableRow key={notificacion.numAcuerdo}>
                    <TableCell>{notificacion.nombreDestinatario}</TableCell>
                    <TableCell>{capitalize(notificacion.metodoNotificacion)}</TableCell>
                    <TableCell>{capitalize(notificacion.estatus)}</TableCell>
                    <TableCell>{notificacion.comentarios}</TableCell>
                    <TableCell sx={{ display: 'flex', justifyContent: 'center' }}>
                      <IconButton className="icon-color" onClick={(event) => {
                        event.stopPropagation();
                        handleShowAcuerdo(acuerdo.numeroAcuerdo);

                      }}>
                        <PictureAsPdfIcon />
                      </IconButton>
                    </TableCell>
                  </StyledTableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    No hay registros disponibles.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {pageable && pageable.totalElements !== undefined && (
          <PaginationGeneric
            pageable={pageable}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        )}
      </Paper>
    </Box>
  </>
}

export default NotificacionesAcuerdoTable;
