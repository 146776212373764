import axios from "axios";

export const fetchTransferencia = async (token) =>{
    try {
        const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/transferencias/registro`, { 
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });
    
        return response.data;
      } catch (error) {
        console.error('Error al obtener los documentos asignados:', error);
        throw error;
      }
};

export const createTransferencia = async (token, data) =>{
    try {
        const response = await axios.post(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/transferencias/`, data, { 
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });
    
        return response.data;
      } catch (error) {
        console.error('Error al obtener los documentos asignados:', error);
        throw error;
      }
};

export const updateTransferencia = async (token, data, id) =>{
    try {
        const response = await axios.put(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/transferencias/${id}`, data, { 
          headers: {
            Authorization: `Bearer ${token}`,
          }
        });
    
        return response.data;
      } catch (error) {
        console.error('Error al obtener los documentos asignados:', error);
        throw error;
      }
};

export const downloadTransferencia = async (token, uuid) =>{
    try {
        const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/transferencias/download/${uuid}`, { 
          headers: {
            responseType: 'arraybuffer',
            Authorization: `Bearer ${token}`,
          }
        });
    
        return response.blob;
      } catch (error) {
        console.error('Error al obtener los documentos asignados:', error);
        throw error;
      }
};