import React, { forwardRef } from "react";
import { Box, Typography, Grid, TextField, IconButton, Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const AnexosExhorto = ({
  formData,
  handleAnexoChange,
  handleRemoveAnexo,
  handleAddAnexo,
  anexosErrors,
  anexoRefs,
}) => {
  return (
    <Grid item xs={10} mb={2} ml={7}>
      
     
        {formData.anexos.map((anexo, index) => (
          <Grid container key={index} sx={{ mb: 1 }}>
            <Grid item xs={11}>
              <TextField
                label={`Anexo ${index + 1}`}
                fullWidth
                value={anexo}
                onChange={(e) => handleAnexoChange(index, e.target.value)}
                inputRef={(el) => (anexoRefs.current[index] = el)}
                helperText={
                  anexosErrors[index] && "El anexo debe tener al menos 3 caracteres"
                }
                FormHelperTextProps={{
                  style: {
                    color: anexosErrors[index] !== "" ? "#d32f2f" : "inherit",
                  },
                }}
              />
            </Grid>
            <Grid item>
              <IconButton onClick={() => handleRemoveAnexo(index)} color="error">
                <DeleteIcon className='icon-color' />
              </IconButton>
            </Grid>
          </Grid>
        ))}

        {/* Botón para agregar anexos */}
        <Grid item xs={12}>
          <Button
            className='button-validation'
            startIcon={<AddCircleOutlineIcon />}
            variant="contained"
            onClick={handleAddAnexo}
          >
            Agregar Anexo
          </Button>
        </Grid>
  
    </Grid>
  );
};

export default AnexosExhorto;
