import { useContext, useEffect, useState } from "react";
import keycloakConf from "../../api/keycloakConf";
import TableBandejaOficios from "./Grid/TableBandejaOficios";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import { useToastAlert } from '../../components/Alert/ToastAlertSave/ToastAlertSave';
import { useToastAlertValidation } from '../../components/Alert/ToastAlertValidation/ToastAlertValidation';
import { fetchOficios, cancelOficio } from "../../shared/services/DocumentoService";
import { Grid } from "@mui/material";

const Bandeja = () => {
  const [bandejas, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const { getToken } = useContext(keycloakConf);
  const [loading, setLoading] = useState(true);
  const [pageable, setPageable] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [modalOpen, setModalOpen] = useState(false);
  const { showSnackbar } = useToastAlert();
  const { showSnackbar: showAlertValidate } = useToastAlertValidation();
  const [idDoc, setIdDoc] = useState({id: 0, folio: ''});

  useEffect(() => {
    loadBandeja();
  }, [getToken, page, rowsPerPage]);

  const loadBandeja = async () => {
    setLoading(true);
    const token = getToken();
    try {
      const bandejaData = await fetchOficios(
        token,
        page,
        rowsPerPage,
        "",
        searchQuery
      );
      setData(bandejaData.content);
      setPageable(bandejaData.page);
    } catch (error) {
      console.error("Error cargando registros:", error);
    } finally {
      setLoading(false);
    }
  };

  //Cancela Oficio
  const handleConfirm = async () => {
    const token = getToken();
    try {
      await cancelOficio(token, idDoc.id);
      loadBandeja();
      showSnackbar("Oficio "+ idDoc.folio +" cancelado con éxito", "success");
    } catch (error) {
      showSnackbar("Error al cancelar el oficio: " + idDoc.folio, "error");
    }
    setIdDoc({id: 0, folio: ''});
  }

  return (
    <>
    <Grid className="box-sombra">
      <TableBandejaOficios
        bandejas={bandejas}
        pageable={pageable}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        loadBandeja={loadBandeja}
        loading={loading}
        setModalOpen={setModalOpen}
        setIdDoc={setIdDoc}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />
      </Grid>

      <ConfirmationModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={handleConfirm}
        title="¿Cancelar oficio?"
        message={<>
          Se cancelará el oficio con folio <strong>{idDoc.folio}</strong> de manera permanente.
        </>}
        confirmationButton="Sí, cancelar oficio"
        closeButton="No, salir"
      />
    </>
  );
};

export default Bandeja;
