import React, { useEffect, useState, useContext, useRef } from "react";
import { Grid, Typography, Box, Divider, Select, FormControl, InputLabel, MenuItem, TextField, Button,  } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import keycloakConf from "../../../api/keycloakConf";
import FormExpedienteJuzgado from "../../../layouts/FormExpedienteJuzgado/FormExpedienteJuzgado";
import { useToastAlert } from "../../../components/Alert/ToastAlertSave/ToastAlertSave";
import { useToastAlertUpdate } from '../../../components/Alert/ToastAlertUpdate/ToastAlertUpdate';
import ConfirmationModal from "../../../components/Modal/ConfirmationModal";
import { updateAnexos, fetchInfoPromocion } from '../../../shared/services/DocumentoService';
import { useLocation, useNavigate } from "react-router-dom";
import {
  onlyAlphaNumericSpace,
} from "../../../components/Caracteres/caracteresEspeciales";
import { getSello } from "../../Demanda/DemandaService";

export default function EditPromocion() {
  const { getToken } = useContext(keycloakConf);
  const location = useLocation();
  const navigate = useNavigate();
  const { documentoId } = location.state || {};
  const { showSnackbar } = useToastAlert();
  const { showSnackbar: showUpdateSnackbar } = useToastAlertUpdate();
  const [errorMessages, setErrorMessages] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [anexos, setAnexos] = useState([]);
  const [anexosErrors, setAnexosErrors] = useState([]);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(true);
  const anexoRefs = useRef([]);
  const irAruta = '/api/bandeja/entrada';

  const [formData, setFormData] = useState({
    expediente: "",
    year: "",
    juzgado: null,
    razonEdicion: ""
  });

  useEffect(() => {
    loadInfoPromocion();
  }, []);

  const loadInfoPromocion = async () => {
      const token = getToken();
      try {
        const response = await fetchInfoPromocion(token, documentoId);
        const updatedResponse = {
          ...response,
          year: response.year
        };
        setFormData(prevFormData => ({
          ...prevFormData, 
          ...updatedResponse
        }));
        setAnexos(response.anexos || []);
        setLoading(false);
      } catch (error) {
          console.error('Error cargando información de promocion');
      }
  };

  const handleSubmitFEJ = async () => {
    if (!formData.razonEdicion.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        razonEdicion: "Este campo es obligatorio.",
      }));
      return;
    }
  
    setModalOpen(true);
  };

  const handleConfirm = async () => {
    setModalOpen(false);
    const token = getToken();
    if (documentoId && token) {
      try {
        await updateAnexos(token, documentoId, anexos, formData?.razonEdicion);
        showUpdateSnackbar('Registro actualizado con éxito', 'success');
        
        const sello = await getSello(token, documentoId);
        var blob = new Blob([sello], { type: "application/pdf" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.target = "sello.pdf";
        link.click();

        navigate("/api/bandeja/entrada");
      } catch (error) {
        showSnackbar('¡Algo salió mal!', 'error');
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleRemoveAnexo = (index) => {
    const newAnexos = anexos.filter((_, i) => i !== index);
    setAnexos(newAnexos);
  };

  const handleAddAnexo = () => {
    setAnexos([...anexos, ""]);

    setTimeout(() => {
      const lastAnexoRef = anexoRefs.current[anexos.length];
      if (lastAnexoRef) lastAnexoRef.focus();
    }, 0);
  };

  const handleAnexoChange = (index, value) => {
    const newValue = onlyAlphaNumericSpace(value);
    const newAnexos = [...anexos];
    newAnexos[index] = newValue;
    setAnexos(newAnexos);

    const newAnexosErrors = [...anexosErrors];
    newAnexosErrors[index] =
      value.length >= 3
        ? ""
        : `El anexo ${index + 1} debe tener al menos 3 caracteres.`;
    setAnexosErrors(newAnexosErrors);
  };

  return (
    <>
    {loading ? (
          <p>Cargando ... </p>
    ) : (
      <>
      <ConfirmationModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={handleConfirm}
        title="¿Actualizar anexos?"
        message="Se actualizarán los anexos con el motivo de la edición en el sistema."
        confirmationButton="Sí, actualizar"
        closeButton="No, cancelar"
      />

      <Grid item xs={12} sm={6} md={6}>
        <FormExpedienteJuzgado
          handleSubmit={handleSubmitFEJ}
          formData={formData}
          setFormData={setFormData}
          errorMessages={errorMessages}
          isDisabled={true}
          irAruta={irAruta}
        />
      </Grid>

      <Grid container spacing={1} sx={{ marginTop: 2 }}>
        <Grid item xs={12} sm={6} md={2}>
          <Typography>Actor: </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={10}>
          <Typography style={{ color: "gray" }}>{formData.actor}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Typography>Demandado: </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={10}>
          <Typography style={{ color: "gray" }}>
            {formData.demandado || ""}
          </Typography>
        </Grid>
      </Grid>

      <Box sx={{ marginTop: 2, marginBottom: 2 }}>
        <Divider />
      </Box>

      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        <Grid item xs={12} sm={6} md={4}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Typography sx={{ minWidth: "150px" }}>Tipo promoción</Typography>
            <FormControl
              fullWidth
              variant="outlined"
            >
              <InputLabel id="select-tipo-promocion-label"></InputLabel>
              <Select
                labelId="select-tipo-promocion-label"
                name="tipoPromocion"
                value={formData.tipoPromocion}
                label=""
                displayEmpty
                renderValue={(selected) => {
                  if (!selected) {
                    return (
                      <span style={{ color: "gray" }}>Selecciona uno</span>
                    );
                  }
                  return selected === "ESCRITO" ? "Escrito" : "Oficio";
                }}
                disabled
              >
                <MenuItem value="ESCRITO">Escrito</MenuItem>
                <MenuItem value="OFICIO">Oficio</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Divider sx={{ my: 5 }} />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Typography sx={{ minWidth: "150px", paddingBottom: "1.5rem" }}>Motivo edición:</Typography>
            <Box sx={{ flexGrow: 2 }}>
              <TextField
                name={"razonEdicion"}
                multiline
                placeholder="Motivo edición"
                fullWidth
                variant="outlined"
                value={formData?.razonEdicion}
                onChange={handleChange}
                error={!!errors.razonEdicion} 
                helperText={
                  <Box display="flex" justifyContent="space-between">
                    <span>{errors.razonEdicion}</span>
                    <span>{`${formData?.razonEdicion.length}/150 caracteres`}</span>
                  </Box>
                }
                inputProps={{ maxLength: 150 }}
                required={true} 
              />
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ marginTop: 2, marginBottom: 2 }}>
        <Divider />
      </Box>

      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Typography sx={{ minWidth: "150px" }}>Anexos</Typography>
            <Box sx={{ flexGrow: 2 }}>
              {anexos.map((anexo, index) => (
                <Grid container key={index} sx={{ mb: 1 }} alignItems="center">
                  <Grid item xs={11}>
                    <TextField
                      label={`Anexo ${index + 1}`}
                      fullWidth
                      value={anexo}
                      onChange={(e) => handleAnexoChange(index, e.target.value)}
                      inputRef={(el) => (anexoRefs.current[index] = el)}
                      helperText={
                        anexosErrors[index] &&
                        "El anexo debe tener al menos 3 caracteres"
                      }
                      FormHelperTextProps={{
                        style: {
                          color: anexosErrors[index] ? "#d32f2f" : "inherit",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <IconButton
                      onClick={() => handleRemoveAnexo(index)}
                    >
                      <DeleteIcon className='icon-color' />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}

              {/* Button anexos */}
              <Grid item xs={12}>
                <Button
                  className="button-validation"
                  startIcon={<AddCircleOutlineIcon />}
                  variant="contained"
                  onClick={handleAddAnexo}
                >
                  Agregar Anexo
                </Button>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
      </>
    )}
    </>
  );
}
