import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { KeycloakProvider } from './api/keycloakConf';
import { ToastAlertSaveProvider } from './components/Alert/ToastAlertSave/ToastAlertSave';
import { ToastAlertUpdateProvider } from './components/Alert/ToastAlertUpdate/ToastAlertUpdate';
import { ToastAlertValidation } from './components/Alert/ToastAlertValidation/ToastAlertValidation';
import { JuicioProvider } from './components/Breadcrumb/JuicioContext';
import { ExpedienteProvider } from './components/Breadcrumb/ExpedienteContext';
import { TooltipBProvider } from './components/Breadcrumb/TooltipContext';
import { ItemProvider } from './components/ItemProvider/ItemContext';
import { NumAcuerdoProvider } from './components/Breadcrumb/NumAcuerdoContext'; 

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <KeycloakProvider>
    <ToastAlertSaveProvider>
      <ToastAlertUpdateProvider>
        <ToastAlertValidation>
          <JuicioProvider>
            <ExpedienteProvider>
              <TooltipBProvider>
                <ItemProvider>
                  <NumAcuerdoProvider>
                    <App />
                  </NumAcuerdoProvider>
                </ItemProvider>
              </TooltipBProvider>
            </ExpedienteProvider>
          </JuicioProvider>
        </ToastAlertValidation>
      </ToastAlertUpdateProvider>
    </ToastAlertSaveProvider>
  </KeycloakProvider>
);


reportWebVitals();
