import React from 'react';
import Acciones from '../../../components/Acciones/Acciones';

const GenerarDemanda = ({ handleButtonClick, handleClose }) => {
  return (
    <Acciones accion1={handleButtonClick} accion2={handleClose}/>
  );
};

export default GenerarDemanda;
