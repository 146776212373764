import axios from "../../api/axiosConfig";

export const fetchDesahogoAudiencias = async (token) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_TRIALS_API_URL}/api/core/desahogoaudiencia`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error al obtener desahogos de audiencia:', error);
      throw error;
    }
  };