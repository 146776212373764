import { useState } from "react";
import {
  Box,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Tooltip,
  Button
} from "@mui/material";
import text from "../../../../assets/Glosario";
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ModalRegistrarAsistencia from "../../../../components/Modal/ModalRegistrarAsistencia";
import ModalListaParticipaciones from "../../../../components/Modal/ModalListaParticipaciones";

const TablaActores = ({ data, tipo, item, filtrarAsistencia }) => {

  const [modalOpen, setModalOpen] = useState(false);
  const [modalListaOpen, setModalListaOpen] = useState(false);
  const [selectedParticipante, setSelectedParticipante] = useState(null);

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const handleOpenModal = (participante) => {
    setSelectedParticipante(participante);
    setModalOpen(true);
  };
  
  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedParticipante(null);
  };

  const handleOpenModalLista = (participante) => {
    setSelectedParticipante(participante);
    setModalListaOpen(true);
  };
  
  const handleCloseModalLista = () => {
    setModalListaOpen(false);
    setSelectedParticipante(null);
  };

  const participantesFiltrados = filtrarAsistencia
    ? data.filter(participante => participante.asistencia === "SI")
    : data;

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer sx={{ maxHeight: 600, overflowY: "auto" }}>
            <Table stickyHeader sx={{ minWidth: 750 }}>
              <TableHead>
                <TableRow>
                  <TableCell className="table-header">{text.Nombre}</TableCell>
                  <TableCell className="table-header">{text.rol}</TableCell>
                  <TableCell className="table-header">{text.asistencia}</TableCell>
                  <TableCell className="table-header">{text.documentoIdenficacion}</TableCell>
                  <TableCell className="table-header" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{text.Acciones}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {participantesFiltrados.length > 0 ? (
                  participantesFiltrados.map((participante) => (
                    <StyledTableRow key={participante.id} hover>
                      <TableCell>{participante.nombre} {participante.apellidoPaterno} {participante.apellidoMaterno}</TableCell>
                      <TableCell>{participante.tipoParte}</TableCell>
                      <TableCell>{participante.asistencia? participante.asistencia.charAt(0).toUpperCase() + participante.asistencia.slice(1).toLowerCase() : "No"}</TableCell>
                      <TableCell>{participante.documentoIdentificacion}</TableCell>
                      <TableCell sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {participante.asistencia === null && (
                          <Tooltip title="Registrar asistencia" arrow>
                            <IconButton className="icon-color" onClick={() => handleOpenModal(participante)} >
                              <EmojiPeopleIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        {participante.asistencia === "SI" && (
                          <>
                            <Tooltip title="Vista previa" arrow>
                              <IconButton className="icon-color" onClick={() => { }}>
                                <ContactPageIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Pruebas" arrow>
                              <IconButton className="icon-color" onClick={() => { }}>
                                <ChatBubbleIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Lista de Participaciones" arrow>
                              <IconButton className="icon-color" onClick={() => handleOpenModalLista(participante)}>
                                <FormatListBulletedIcon />
                              </IconButton>
                            </Tooltip>
                          </>
                        )}
                      </TableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      No hay participantes.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        <ModalRegistrarAsistencia
          open={modalOpen}
          onClose={handleCloseModal}
          participante={selectedParticipante}
          item={item}
        />

        <ModalListaParticipaciones
          open={modalListaOpen}
          onClose={handleCloseModalLista}
          participante={selectedParticipante}
        />
      </Box>
    </>
  );
};

export default TablaActores;
