import React, { useState, useContext } from 'react';
import {
    Box,
    Paper,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Checkbox
} from '@mui/material';
import PaginationGeneric from '../../../components/Pagination/PaginationGeneric';
import text from '../../../assets/Glosario';
import NoteIcon from '@mui/icons-material/Note';
import DescriptionIcon from '@mui/icons-material/Description';
import AgregarNota from '../ModalesGrid/AgregarNota';
import { fetchGetAcuerdo } from '../../Bandeja/Asignado/Tabs/Acuerdo/AcuerdoService';
import keycloakConf from '../../../api/keycloakConf';
import { useNavigate } from 'react-router-dom';
import ResumenNotificacionModal from '../ModalesGrid/ResumenNotificacionModal';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import ConfirmationModal from "../../../components/Modal/ConfirmationModal";
import { updateEstadoNotificaciones, fetchDocumentoActa } from '../../../shared/services/NotificacionService';
import ModalCrearActa from '../../../components/Modal/ActaMinimaModal';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('es-ES', {
        day: '2-digit',
        month: 'long',
        year: 'numeric',
        timeZone: 'UTC'
    }).format(date);
};


const NotificacionTable = ({
    estado,
    data,
    pageable,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    onSelectionChange,
    fetchData,
    sendRutaModal,
    setSendRutaModal,
    setIsModalDescargarListaOpen,
    tipo
}) => {

    const { getToken } = useContext(keycloakConf);
    const [selected, setSelected] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSummaryOpen, setIsSummaryOpen] = useState(false);
    const [ModalActaOpen, setIsModalActaOpen] = useState(false);
    const [selectedExpediente, setSelectedExpediente] = useState(null);
    const [selectedConcepto, setSelectedConcepto] = useState('');
    const [selectedId, setSelectedId] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const navigate = useNavigate();
    const openModal = () => setIsModalActaOpen(true);
    const closeModal = () => setIsModalActaOpen(false);
    const token = getToken();
    const handleSelectAll = (event) => {
        if (event.target.checked) {
            const allIds = data.map((item) => item.id);
            setSelected(allIds);
            onSelectionChange(allIds);
        } else {
            setSelected([]);
            onSelectionChange([]); // evniar los ids seleccionados
        }
    };

    const handleSelect = (id) => {
        const updatedSelected = selected.includes(id)
            ? selected.filter((selectedId) => selectedId !== id)
            : [...selected, id];
        setSelected(updatedSelected);
        onSelectionChange(updatedSelected); // Notificar al padre
    };

    const handleFile = async (item) => {
    try {
      const doc = await fetchDocumentoActa(token, item.id);
      let blob = new Blob([doc], { type: "application/pdf" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.target = item.ruta;
      link.click();
    } catch (error) {
      console.error("Error al generar el documento:", error);
    }
    };


    const sortedData = [...data].sort((a, b) => new Date(b.fechaPublicacion) - new Date(a.fechaPublicacion));

    const handleOpenModal = (id, expediente, concepto) => {
        setSelectedId(id);
        setSelectedExpediente(expediente);
        setSelectedConcepto(concepto);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedExpediente(null);
        setSelectedConcepto('');
    };

    const handleOpenModalDetalle = (item) => {
        setSelectedItem(item);
        setIsSummaryOpen(true);
    };

    const handleOpenModalActa = (item) => {
        setSelectedItem(item);
        setIsModalActaOpen(true);
    };

    const handleSelectionChange = (selected) => {
        setSelected(selected);
        onSelectionChange(selected.length); // Pasa la cantidad seleccionada 
    };

    const refreshData = async () => {
        if (fetchData) {
            await fetchData(); // Llama a la función para recargar los datos
        }
    };

    //Actualiza status a EN_RUTA para los items seleccionados
    const handleConfirmEnRuta = async () => {
        try {
            const token = getToken();
            await updateEstadoNotificaciones(token, selected, "EN_RUTA");
            refreshData();

            setIsModalDescargarListaOpen(true);
        } catch (error) {
            console.error('Error al actualizar estado a EN_RUTA', error);
        }
    };

    const handleShowRegister = async (registro) => {

        let isSentencia = registro.tipoDocumento === "SENTENCIA";
        let data = await fetchGetAcuerdo(getToken(), registro.documentoId);
        let item = { carpetaId: data.carpetaId, routeNotificaciones:true, tipo:tipo, estado:estado  }

        navigate('/api/bandeja/expediente/agregarAcuerdo', {
            state: { item: item, data: data, isSentencia: isSentencia, isDisabled: true }
        });
    }

    return (
        <Box sx={{ width: '100%' }}>

            <Paper sx={{ width: '100%', mb: 2 }}>
                <PaginationGeneric
                    pageable={pageable}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                />
                <TableContainer sx={{ maxHeight: 600, overflowY: 'auto' }}>
                    {estado === "POR_NOTIFICAR" && (
                        <Table stickyHeader sx={{ minWidth: 750 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell className='table-header' padding="checkbox">
                                        <Checkbox
                                            indeterminate={selected.length > 0 && selected.length < data.length}
                                            checked={data.length > 0 && selected.length === data.length}
                                            onChange={handleSelectAll}
                                        />
                                    </TableCell>
                                    <TableCell className='table-header'>{text.NoExpediente}</TableCell>
                                    <TableCell className='table-header'>{text.Concepto}</TableCell>
                                    <TableCell className='table-header'>{text.Notas}</TableCell>
                                    <TableCell className='table-header'>{text.domicilio}</TableCell>
                                    <TableCell className='table-header'>{text.FechaPublicacion}</TableCell>
                                    <TableCell className='table-header'>{text.FechaResolucion}</TableCell>
                                    <TableCell className='table-header'>{text.Acciones}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedData.map((item, index) => (
                                    <StyledTableRow
                                        key={item.id}
                                        hover
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={selected.includes(item.id)}
                                                onChange={() => handleSelect(item.id)}
                                                onClick={(e) => e.stopPropagation()}
                                            />
                                        </TableCell>
                                        <TableCell>{item.expediente}</TableCell>
                                        <TableCell>{item.concepto}</TableCell>
                                        <TableCell>{item.notas}</TableCell>
                                        <TableCell>{item.domicilio}</TableCell>
                                        <TableCell align="center">
                                            {item.documentoDetalleRecord?.fechaPublicacion
                                                ? formatDate(item.documentoDetalleRecord.fechaPublicacion)
                                                : '-'}
                                        </TableCell>
                                        <TableCell align="center">
                                            {item.documentoDetalleRecord?.fechaResolucion
                                                ? formatDate(item.documentoDetalleRecord.fechaResolucion)
                                                : '-'}
                                        </TableCell>

                                        <TableCell align="left">
                                            <Tooltip title="Agregar nota">
                                                <NoteIcon
                                                    className="icon-color"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleOpenModal(item.id, item.expediente, item.concepto)
                                                    }}
                                                    style={{ cursor: 'pointer', marginRight: '10px'}}
                                                />
                                            </Tooltip>
                                            <Tooltip title="Ver resumen">
                                                <LibraryBooksIcon
                                                    className="icon-color"
                                                    onClick={() => handleOpenModalDetalle(item)}
                                                    style={{ cursor: 'pointer', marginRight: '10px'}}
                                                />
                                            </Tooltip>
                                            <Tooltip title="Crear acta">
                                                <NoteAddIcon
                                                    className="icon-color"
                                                    onClick={() => handleOpenModalActa(item)}
                                                    style={{ cursor: 'pointer', marginRight: '10px'}}
                                                />
                                            </Tooltip>
                                            <Tooltip title="Notificar">
                                                <PictureAsPdfIcon
                                                    className="icon-color"
                                                    onClick={() => handleFile(item)}
                                                    style={{ cursor: 'pointer', marginRight: '10px'}}
                                                />
                                            </Tooltip>
                                        </TableCell>
                                    </StyledTableRow>
                                ))}
                                {sortedData.length === 0 && (
                                    <TableRow>
                                        <TableCell colSpan={7} align="center">
                                            No hay registros disponibles.
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    )}
                    {estado === "EN_RUTA" && (
                        <Table stickyHeader sx={{ minWidth: 750 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell className='table-header'>{text.fechaSalida}</TableCell>
                                    <TableCell className='table-header'>{text.Acciones}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedData.map((item, index) => (
                                    <StyledTableRow
                                        key={item.id}
                                        hover
                                    >
                                        <TableCell>
                                            {item.fechaSalida
                                                ? formatDate(item.fechaSalida)
                                                : '-'}
                                        </TableCell>
                                        <TableCell align="left">
                                            <Tooltip title="Crear acta de notificación">
                                                <DescriptionIcon
                                                    className="icon-color"
                                                    onClick={null}
                                                    style={{ cursor: 'pointer', marginRight: '10px'}}
                                                />
                                            </Tooltip>
                                        </TableCell>
                                    </StyledTableRow>
                                ))}
                                {sortedData.length === 0 && (
                                    <TableRow>
                                        <TableCell colSpan={7} align="center">
                                            No hay registros disponibles.
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    )}
                    {estado === "NOTIFICADOS" && (
                        <Table stickyHeader sx={{ minWidth: 750 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell className='table-header'>{text.fechaSalida}</TableCell>
                                    <TableCell className='table-header'>{text.fechaNotificado}</TableCell>
                                    <TableCell className='table-header'>{text.Acciones}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedData.map((item, index) => (
                                    <StyledTableRow
                                        key={item.id}
                                        hover
                                    >
                                        <TableCell>
                                            {item.fechaSalida
                                                ? formatDate(item.fechaSalida)
                                                : '-'}
                                        </TableCell>
                                        <TableCell>
                                            {item.fechaNotificado
                                                ? formatDate(item.fechaNotificado)
                                                : '-'}
                                        </TableCell>

                                        <TableCell align="left">
                                            <Tooltip title="Ver acta de notificación">
                                                <DescriptionIcon
                                                    className="icon-color"
                                                    onClick={null}
                                                    style={{ cursor: 'pointer', marginRight: '10px'}}
                                                />
                                            </Tooltip>
                                        </TableCell>
                                    </StyledTableRow>
                                ))}
                                {sortedData.length === 0 && (
                                    <TableRow>
                                        <TableCell colSpan={7} align="center">
                                            No hay registros disponibles.
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    )}
                    {estado !== "POR_NOTIFICAR" && estado !== "EN_RUTA" && estado !== "NOTIFICADOS" && (
                    <Table stickyHeader sx={{ minWidth: 750 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell className='table-header' padding="checkbox">
                                    <Checkbox
                                        indeterminate={selected.length > 0 && selected.length < data.length}
                                        checked={data.length > 0 && selected.length === data.length}
                                        onChange={handleSelectAll}
                                    />
                                </TableCell>
                                <TableCell className='table-header'>{text.NoExpediente}</TableCell>
                                <TableCell className='table-header'>{text.Concepto}</TableCell>
                                {tipo !== "CORREO_ELECTRONICO" && (<>
                                    <TableCell className='table-header'>{text.Notas}</TableCell>
                                    <TableCell className='table-header'>{text.Tipo}</TableCell>
                                </>)}

                                <TableCell className='table-header'>{text.FechaPublicacion}</TableCell>
                                <TableCell className='table-header'>{text.FechaResolucion}</TableCell>
                                {tipo !== "CORREO_ELECTRONICO" && (<>
                                    <TableCell className='table-header'>{text.Acciones}</TableCell>
                                </>)}

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedData.map((item, index) => (
                                <StyledTableRow
                                    key={item.id}
                                    hover
                                    onClick={ () => { handleShowRegister(item) } }
                                >
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            checked={selected.includes(item.id)}
                                            onChange={() => handleSelect(item.id)}
                                            onClick={(e) => e.stopPropagation()}
                                        />
                                    </TableCell>
                                    <TableCell>{item.expediente}</TableCell>
                                    <TableCell align="center">{item.concepto && item.concepto.length > 0 ? item.concepto : '-'}</TableCell>
                                    {tipo !== "CORREO_ELECTRONICO" && (<>
                                        <TableCell>{item.notas}</TableCell>
                                        <TableCell>
                                            {item.tipo
                                                .charAt(0)
                                                .toUpperCase() + item.tipo.slice(1).toLowerCase()
                                                    .replace(/_/g, ' ')}
                                        </TableCell>
                                    </>)}

                                    <TableCell align="center">
                                        {item.documentoDetalleRecord?.fechaPublicacion
                                            ? formatDate(item.documentoDetalleRecord.fechaPublicacion)
                                            : '-'}
                                    </TableCell>
                                    <TableCell align="center">
                                        {item.documentoDetalleRecord?.fechaResolucion
                                            ? formatDate(item.documentoDetalleRecord.fechaResolucion)
                                            : '-'}
                                    </TableCell>
                                    {tipo !== "CORREO_ELECTRONICO" && (<>
                                        <TableCell align="left">
                                            <Tooltip title="Agregar nota">
                                                <NoteIcon
                                                    className="icon-color"
                                                    onClick={() => handleOpenModal(item.id, item.expediente, item.concepto)}
                                                    style={{ cursor: 'pointer', marginRight: '10px' }}
                                                />
                                            </Tooltip>
                                        </TableCell>
                                    </>)}

                                </StyledTableRow>
                            ))}
                            {sortedData.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={7} align="center">
                                        No hay registros disponibles.
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    )}
                </TableContainer>
                <PaginationGeneric
                    pageable={pageable}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                />
            </Paper>
            {isModalOpen && (
                <AgregarNota
                    isOpen={isModalOpen}
                    handleClose={handleCloseModal}
                    expediente={selectedExpediente}
                    concepto={selectedConcepto}
                    id={selectedId}
                    handleSave={refreshData}
                />
            )}
            {isSummaryOpen && (
                <ResumenNotificacionModal
                    isModalOpen = {isSummaryOpen}
                    setModalOpen = {setIsSummaryOpen}
                    expedienteData = {selectedItem}
                    formatDate= {formatDate}
                />
            )}
            <ConfirmationModal
                open={sendRutaModal}
                onClose={() => setSendRutaModal(false)}
                onConfirm={handleConfirmEnRuta}
                title={"En ruta"}
                message={"Se marcarán "+ selected.length +" expedientes en ruta"}
                confirmationButton={"Sí, continuar"}
                closeButton="No, cancelar"
            />
        <ModalCrearActa
            isModalOpen={ModalActaOpen}
            setModalOpen={setIsModalActaOpen} // Control modal open state
            onClose={closeModal} // Control modal close state
            item={selectedItem}
            token={token}
          />
        </Box>
    );
};

export default NotificacionTable;
