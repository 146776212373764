import { useContext, useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { Box, Grid, Typography, Button, Select, MenuItem, Checkbox, FormControlLabel, TextField, Autocomplete } from "@mui/material";
import keycloakConf from "../../../api/keycloakConf";
import { fetchTipoAudienciasAutocomplete } from "../../../shared/services/TipoAudienciasService";
import { fetchSalasbyJuzgado } from "../../Salas/SalasService";
import { fetchAudienciasMotivos, fetchEstatusAudiencias, setHoraAudiencia, createActaMinima ,audienciaTabGeneral, getActaMinima } from "../../../shared/AudienciasService";
import ModalDiferirReprogramar from "../../../components/Acciones/ModalDiferirReprogramar";
import { fetchDesahogoAudiencias } from "../../../shared/services/DesahogoAudienciaService";
import { useToastAlert } from "../../../components/Alert/ToastAlertSave/ToastAlertSave";
import { useNavigate } from 'react-router-dom';
import ActaMinimaModal from "./ActaMinimaModal";

const General = forwardRef(({ item, isConsulta, isTabF , itemToUse }, ref) => {
  const { getToken } = useContext(keycloakConf);
  const [tiposAudiencia, setTiposAudiencia] = useState([]);
  const [salas, setSalas] = useState([]);
  const [motivos, setMotivos] = useState([]);
  const [estatusAudiencias, setEstatusAudiencias] = useState([]);
  const [selectedEstatus, setSelectedEstatus] = useState("");
  const [horaInicio, setHoraInicio] = useState(null);
  const [horaFin, setHoraFin] = useState(null);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  const [selectedId, setSelectedId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedSala, setSelectedSala] = useState(null);
  const [selectedTipoAudiencia, setSelectedTipoAudiencia] = useState(null);
  const fechaAudienciaPasada = item?.fechaHora ? new Date(item.fechaHora) < new Date() : false;
  const [desahogos, setDesahogos] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedMotivoRetraso, setSelectedMotivoRetraso] = useState("");
  const [selectedParteNoPresentada, setSelectedParteNoPresentada] = useState("");
  const { showSnackbar } = useToastAlert();
  const [selectedItem, setSelectedItem] = useState(null);
  const isTab = false;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
  file: "", 
  });

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const navigate = useNavigate();

  const handleOpenModal = (id, item) => {
    setSelectedId(id);
    setSelectedItem(item);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedId(null);
  };

  const handleFileUploaded = (file) => {
    console.log('File uploaded successfully:', file);
    setFormData({ ...formData, file });
  };

  const handleFileGet = async () => {
    const token = getToken();
    try {
      const documento = await getActaMinima(token, item.id);
      let blob = new Blob([documento], { type: "application/pdf" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.target = "_blank"; 
      
      link.click();
    } catch (error) {
      console.error("Error al generar el documento:", error);
      showSnackbar("¡Algo salió mal!", "error");
    }
  };
  
  
  const handleActualizarAudiencia = async () => {
    const token = getToken();

    const audienciaData = {
      idAudiencia: item?.id,
      idTipoAudiencia: selectedTipoAudiencia?.id || undefined,
      idSala: selectedSala?.id || undefined,
      motivoRetraso: selectedMotivoRetraso || undefined,
      resultadoDesahogo: [
        ...Object.entries(selectedCheckboxes)
          .filter(([key, value]) => value)
          .map(([key, value]) => (key === "Otro" ? value : key)),
      ]
        .filter(Boolean)
        .join(",") || undefined,
      actores: selectedParteNoPresentada || undefined,
      estatusAudiencia: selectedEstatus || undefined
    };

    let dataSend = new FormData();
    
    dataSend.append("file", formData.file);
    try {
      if(formData.file !== ""){
        await createActaMinima(token, dataSend, audienciaData.idAudiencia);
      }
      await audienciaTabGeneral(token, audienciaData);
      showSnackbar('Audiencia actualizada con éxito', 'success');
      isTabF ? navigate('/api/bandeja/expediente', {
        state: {
            item: itemToUse,
            tabnav: 3
        }
      }) : navigate('/api/bandeja/audiencias');;
    } catch (error) {
      showSnackbar('Error al actualizar la audiencia', 'error');
    }
  };

  useImperativeHandle(ref, () => ({
    handleActualizarAudiencia,
  }));

  const handleIniciar = async () => {
    const now = new Date();
    setHoraInicio(now);
  
    const token = getToken();
    try {
      await setHoraAudiencia(token, item.id, now.toISOString(), true);
    } catch (error) {
    }
  };
  
  const handleTerminar = async () => {
    const now = new Date();
    setHoraFin(now);
  
    const token = getToken();
    try {
      await setHoraAudiencia(token, item.id, now.toISOString(), false);
    } catch (error) {
    }
  };
  

  useEffect(() => {
    const loadTiposAudiencia = async () => {
      const token = getToken();
      try {
        const data = await fetchTipoAudienciasAutocomplete(token);
        setTiposAudiencia(data);
      } catch (error) {
      }
    };
    loadTiposAudiencia();
  }, [getToken]);

  useEffect(() => {
    const loadSalas = async () => {
      const token = getToken();
      try {
        const data = await fetchSalasbyJuzgado(token, item.id);
        setSalas(data);
      } catch (error) {
      }
    };
    loadSalas();
  }, [getToken]);

  useEffect(() => {
    const loadMotivos = async () => {
      const token = getToken();
      try {
        const data = await fetchAudienciasMotivos(token);
        setMotivos(data);
      } catch (error) {
      }
    };

    loadMotivos();
  }, [getToken]);

  useEffect(() => {
    const loadEstatusAudiencias = async () => {
      const token = getToken();
      try {
        const data = await fetchEstatusAudiencias(token);
        setEstatusAudiencias(data);
      } catch (error) {
      }
    };

    loadEstatusAudiencias();
  }, [getToken]);

  useEffect(() => {
    const loadDesahogos = async () => {
      const token = getToken();
      try {
        const data = await fetchDesahogoAudiencias(token);
        setDesahogos(data);
      } catch (error) {
      }
    };

    loadDesahogos();
  }, [getToken]);

  useEffect(() => {
    if (item) {
      setSelectedEstatus(item.estatus || "");
    }
  }, [item]);

  useEffect(() => {
    if (selectedEstatus !== "DESAHOGADA") {
      setSelectedCheckboxes({});
    }
  }, [selectedEstatus]);

  useEffect(() => {
    if (item && Array.isArray(tiposAudiencia)) {
      setSelectedTipoAudiencia(
        tiposAudiencia.find(tipo => tipo.nombre === item.tipoAudiencia) || null
      );
    }
  }, [item, tiposAudiencia]);
  
  useEffect(() => {
    if (item && Array.isArray(salas)) {
      setSelectedSala(
        salas.find(sala => sala.nombre === item.lugar) || null
      );
    }
  }, [item, salas]);

  const handleChange = (event) => {
    setSelectedEstatus(event.target.value);
  };

  const handleRefresh = () => {
  };

  const handleCheckboxChange = (id) => {
    setSelectedValues((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((value) => value !== id)
        : [...prevSelected, id]
    );
  };

  const handleMotivoRetrasoChange = (event) => {
    setSelectedMotivoRetraso(event.target.value);
  };
  
  const handleParteNoPresentadaChange = (event) => {
    setSelectedParteNoPresentada(event.target.value);
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Grid container spacing={2}>
        <Grid container spacing={2} sx={{ marginBottom: 5 }}>
          <Grid item xs={4}>
            <Typography variant="subtitle1">Tipo de audiencia</Typography>
            <Autocomplete
              fullWidth
              options={tiposAudiencia}
              getOptionLabel={(option) => option.nombre || ""}
              value={selectedTipoAudiencia}
              onChange={(event, newValue) => {
                setSelectedTipoAudiencia(newValue);
              }}
              renderInput={(params) => <TextField {...params}
              disabled={isConsulta} />}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1">Sala</Typography>
            <Autocomplete
              fullWidth
              options={salas}
              getOptionLabel={(option) => option.nombre || ""}
              value={selectedSala}
              onChange={(event, newValue) => {
                setSelectedSala(newValue);
              }}
              renderInput={(params) => <TextField {...params} 
              disabled={isConsulta} />}
            />
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1">
              Fecha y hora programada para la audiencia
            </Typography>
            <Typography>{item.fechaHora ? new Date(item.fechaHora).toLocaleString() : null}</Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ marginBottom: 5 }}>
          <Grid item xs={4}>
            <Typography variant="subtitle1">Motivo del retraso</Typography>
            <Select
              fullWidth
              value={selectedMotivoRetraso}
              onChange={handleMotivoRetrasoChange}
              displayEmpty
              disabled={!fechaAudienciaPasada || isConsulta}
              renderValue={(selected) => {
                const motivoSeleccionado = motivos.find((motivo) => motivo.clave === selected);
                return motivoSeleccionado ? motivoSeleccionado.etiqueta : "Seleccione uno";
              }}
            >
              {motivos.length > 0 ? (
                motivos.map((motivo) => (
                  <MenuItem key={motivo.clave} value={motivo.clave}>
                    {motivo.etiqueta}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>Cargando motivos...</MenuItem>
              )}
            </Select>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1">Hora de inicio de la audiencia</Typography>
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-start" }}>
              <Button
                variant="contained"
                className="button-save"
                onClick={handleIniciar}
                disabled={isConsulta}
              >
                Iniciar
              </Button>
              {horaInicio && (
                <Typography sx={{ marginLeft: 2 }}>
                  {horaInicio.toLocaleTimeString()}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="subtitle1">Hora de fin de la audiencia</Typography>
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-start" }}>
              <Button
                variant="contained"
                className="button-close"
                onClick={handleTerminar}
                disabled={isConsulta}
              >
                Terminar
              </Button>
              {horaFin && (
                <Typography sx={{ marginLeft: 2 }}>
                  {horaFin.toLocaleTimeString()}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ marginBottom: 5 }}>
          <Grid item xs={4}>
            <Button fullWidth variant="contained" onClick={openModal} className="button-save" disabled={isConsulta} >
              Generar Acta Mínima
            </Button>
            <ActaMinimaModal
            isModalOpen={isModalOpen}
            setModalOpen={setIsModalOpen} // Control modal open state
            onClose={closeModal} // Control modal close state
            handleAceptar={handleFileUploaded} // Handle file upload
          />
          </Grid>
          <Grid item xs={4}>
            <Button fullWidth variant="contained" className="button-save" disabled={isConsulta} >
              Crear Acuerdo
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button fullWidth variant="contained" className="button-save" disabled={isConsulta} >
              Programar nueva audiencia
            </Button>
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ marginBottom: 5 }}>
          <Typography variant="subtitle1">Resultados del Desahogo:</Typography>
          <Grid container spacing={1}>
            {desahogos.map((desahogo) => (
              <Grid item xs={12} sm={6} md={4} key={desahogo.id}>
                {desahogo.desahogoAudiencia === "Otro" ? (
                  <TextField
                    fullWidth
                    label="Otro"
                    value={selectedCheckboxes["Otro"] || ""}
                    onChange={(e) => {
                      setSelectedCheckboxes((prev) => ({
                        ...prev,
                        Otro: e.target.value,
                      }));
                    }}
                    disabled={selectedEstatus !== "DESAHOGADA" || isConsulta}
                  />
                ) : (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={!!selectedCheckboxes[desahogo.desahogoAudiencia]}
                        onChange={(e) => {
                          setSelectedCheckboxes((prev) => ({
                            ...prev,
                            [desahogo.desahogoAudiencia]: e.target.checked,
                          }));
                        }}
                        disabled={selectedEstatus !== "DESAHOGADA" || isConsulta}
                      />
                    }
                    label={desahogo.desahogoAudiencia}
                  />
                )}
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
            Estatus de la audiencia
          </Typography>
        <Grid container spacing={2} sx={{ marginBottom: 5 }}>
          <Grid item xs={8} sx={{ display: "flex", flexDirection: "center" }}>
            <Select
              labelId="estatus-audiencia-label"
              id="estatus-audiencia"
              value={selectedEstatus}
              onChange={handleChange}
              sx={{ width: "100%" }}
              disabled={!fechaAudienciaPasada || isConsulta}
            >
              {estatusAudiencias.map((estatus) => (
                <MenuItem key={estatus} value={estatus}>
                  {estatus.charAt(0).toUpperCase() + estatus.slice(1).toLowerCase()}
                </MenuItem>
              ))}
            </Select>
            {selectedEstatus === "DIFERIDA" && (
              <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
                <Button
                  variant="contained"
                  className="button-save"
                  onClick={() => handleOpenModal(item.id, item)}
                  sx={{ marginLeft: 2, nwhiteSpace: "nowrap", padding: "6px 16px" }}
                  disabled={isConsulta}
                >
                  Reprogramar audiencia
                </Button>
              </Grid>
            )}
            {selectedEstatus === "INCOMPARECENCIA" && (
              <Select
                fullWidth
                value={selectedParteNoPresentada}
                onChange={handleParteNoPresentadaChange}
                displayEmpty
                renderValue={(selected) => {
                  return selected
                    ? selected.charAt(0).toUpperCase() + selected.slice(1).toLowerCase()
                    : "Parte que no se presentó";
                }}
                sx={{ marginLeft: 2, width: "300px" }}
                disabled={isConsulta}
              >
                <MenuItem value="ACTOR">Actor</MenuItem>
                <MenuItem value="DEMANDADO">Demandado</MenuItem>
                <MenuItem value="AMBOS">Ambos</MenuItem>
              </Select>
            )}
          </Grid>
        </Grid>
      </Grid>
      <ModalDiferirReprogramar
        open={modalOpen}
        onClose={handleCloseModal}
        id={selectedId}
        item={selectedItem}
        handleRefresh={handleRefresh}
        token={getToken()}
      />
    </Box>
  );
});

export default General;
