import axios from "../../../../../api/axiosConfig";

export const fetchRubrosAutoComplete = async (token, page = 0, size = 10, carpetaId, nombre = '') => {
  
    try {
      const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/core/acuerdorubros/autocomplete/${carpetaId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page,
          size,
          nombre
        },
      });
  
      return response.data;
    } catch (error) {
      console.error('Error al listar Instituciones:', error);
      throw error;
    }
  };

  export const fetchEtapasProcesaes = async(token, idTipoJuicio, idProcedimiento=0) =>{
 
    try {
      const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/core/etapaprocesal?IdTipoJuicio=${idTipoJuicio}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      
      return response.data;
  
    } catch (error) {
      console.log('Error al descargar el documento: ', error);
      
      
    }
  
  }

  
  export const fetchTipoAcuerdos = async(token, carpetaId) =>{
 
    try {
      const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/core/tipoacuerdo/${carpetaId}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      
      return response.data;
  
    } catch (error) {
      console.log('Error al descargar el documento: ', error);
      
      
    }
  
  }

  export const fetchPromocionesCarpeta = async(token, idCarpeta, documentoId, tipoDocumento) =>{

    try {
      const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/documentos/obtenerPromociones/${idCarpeta}/${documentoId}/${tipoDocumento}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      return response.data;
  
    } catch (error) {
      console.log('Error al descargar el documento: ', error);
      
      
    }
  
  }

  export const fetchGetAcuerdo = async(token, documentoId) =>{
 
    try {
      const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/documentos/obtenerAcuerdo/${documentoId}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      
      return response.data;
  
    } catch (error) {
      console.log('Error al obtener el documento: ', error);
      
      
    }
  
  }

export const crearAcuerdo = async (token, acuerdoData) => {
  try {
      const response = await axios.post(process.env.REACT_APP_TRIALS_API_URL +'/api/workflow/documentos/crearAcuerdo', acuerdoData, {
          headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
          }
      });
      return response.data;
  } catch (error) {
      console.error('Error al crear el acuerdo:', error);
      throw error;
  }
};

//cambiarURL
export const crearSentencia = async (token, sentenciaData) => {
  try {
      const response = await axios.post(process.env.REACT_APP_TRIALS_API_URL +'/api/workflow/documentos/crearSentencia', sentenciaData, {
          headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
          }
      });
      return response.data;
  } catch (error) {
      console.error('Error al crear la sentencia:', error);
      throw error;
  }
};



export const fetchActualizarAcuerdo = async (token, data) => {
  try {
      const response = await axios.put(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/documentos/actualizarAcuerdo`, data, {
          headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
          },

      });
      return response.data;
  } catch (error) {
      console.error('Error al actualizar el registro:', error);
      throw error;
  }
};


export const fetchActualizarSentencia = async (token, data) => {
  try {
      const response = await axios.put(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/documentos/actualizarSentencia`, data, {
          headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
          },

      });
      return response.data;
  } catch (error) {
      console.error('Error al actualizar el registro:', error);
      throw error;
  }
};



export const fetchPublicarAcuerdo = async (token, acuerdoData) => {
  try {
      const response = await axios.post(process.env.REACT_APP_TRIALS_API_URL +'/api/workflow/documentos/publicarAcuerdo', acuerdoData, {
          headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
          }
      });
      return response.data;
  } catch (error) {
      console.error('Error al publicar el acuerdo:', error);
      throw error;
  }
};

export const fetchPublicarSentencia = async (token, acuerdoData) => {
  try {
      const response = await axios.post(process.env.REACT_APP_TRIALS_API_URL +'/api/workflow/documentos/publicarSentencia', acuerdoData, {
          headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
          }
      });
      return response.data;
  } catch (error) {
      console.error('Error al publicar el acuerdo:', error);
      throw error;
  }
};


export const fetchGetAcuerdos = async (token, page = 0, size = 10, carpetaId, key) => {
  
  try {
    const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/documentos/obtenerAcuerdos/${carpetaId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page,
        size,
        key
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error al obtener los Acuerdos:', error);
    throw error;
  }
};

export const fetchTipoPartesAcuerdo = async(token, carpetaId, tipoParte) =>{
 
  try {
    const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/documentos/obtenerTipoPartesAcuerdo/${carpetaId}/${tipoParte}`,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    
    return response.data;

  } catch (error) {
    console.log('Error al obtener el tipo parte: ', error);
    
    
  }

}

export const fetchPromocionesAcuerdo = async(token, acuerdoId) =>{
 
  try {
    const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/documentos/acuerdos/${acuerdoId}/promociones`,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    
    return response.data;

  } catch (error) {
    console.log('Error al descargar el documento: ', error);
    
    
  }

}

export const enviarNotificaciones = async (token, data) => {
  try {
      const response = await axios.post(process.env.REACT_APP_TRIALS_API_URL +'/api/workflow/documentos/enviarNotificacion', data, {
          headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
          }
      });
      return response.data;
  } catch (error) {
      console.error('Error al  el acuerdo:', error);
      throw error;
  }
};

export const fetchNotificacionesAcuerdo = async (token, idNotificacion, page = 0, size = 10) => {
  try {
      const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/acuerdo/notificaciones/${idNotificacion}`, {
          headers: {
              Authorization: `Bearer ${token}`,
          },
          params: {
              page,
              size
          },
      });

      return response.data;
  } catch (error) {
      console.error('Error al obtener las notificaciones de acuerdo:', error);
      throw error;
  }
};


export const fetchTempletes = async (token) => {
  try {
      const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/core/templates/`, {
          headers: {
              Authorization: `Bearer ${token}`,
          }
      });

      return response.data;
  } catch (error) {
      console.error('Error al obtener los Templetes:', error);
      throw error;
  }
};


export const fetchGuardarTempletes = async (token, newTemplateData) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_TRIALS_API_URL + `/api/core/templates/create`,
      newTemplateData,  // Enviar los datos como cuerpo de la solicitud
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',  // Asegura que los datos se envíen en formato JSON
        }
      }
    );
    
    return response.data; 
  } catch (error) {
    console.error('Error al guardar los Templetes:', error);
    throw error; 
  }
};

