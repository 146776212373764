import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Checkbox
} from '@mui/material';
import { create, fetchById, update } from '../SedeService';
import { validateFields } from '../../../components/Alert/ValidationRequired/validationRequired';
import { useNavigate } from 'react-router-dom';
import { fetchEstados, fetchMunicipiosByEstadoId } from '../../../shared/services/EstadosService';
import { fetchDistritos } from '../../../shared/services/DistritosService';
import { useToastAlert } from '../../../components/Alert/ToastAlertSave/ToastAlertSave';
import { useToastAlertUpdate } from '../../../components/Alert/ToastAlertUpdate/ToastAlertUpdate';
import { useToastAlertValidation } from '../../../components/Alert/ToastAlertValidation/ToastAlertValidation';
import AccionesOnlyButtons from '../../../components/Acciones/AccionesOnlyButtons';
import Breadcrumbs from '../../../components/Breadcrumb/breadcrumbs';

const FormSede = ({ token, onAdd, onEdit }) => {
    const navigate = useNavigate();
    const { showSnackbar, showConfirmDialog } = useToastAlert();
    const { showSnackbar: showUpdateSnackbar } = useToastAlertUpdate();
    const { showSnackbar: showAlertValidate } = useToastAlertValidation();
    const [formData, setFormData] = useState({
        id: '',
        nombre: '',
        estado: '',
        telefono: '',
        extension: '',
        tipo: '',
        domicilio: {
            id: '',
            calle: '',
            interior: '',
            exterior: '',
            localidad: '',
            colonia: '',
            codigoPostal: '',
            municipio: '',
            idEstado: '',
            estadoRepublica: '',
            referencia: '',
        },
        distrito: {
            id: 0
        }
    });

    const [estados, setEstados] = useState([]);
    const [municipios, setMunicipios] = useState([]);
    const [distritos, setDistritos] = useState([]);
    const [errorMessages, setErrorMessages] = useState({});

    useEffect(() => {
        loadDistritos();
        if (onEdit) {
            getById();
        } else {
            loadEstados();
            resetForm();
            setFormData((prevData) => ({
                ...prevData,
                estado: 'ACTIVE',
                domicilio: {
                    ...prevData.domicilio,
                    estadoRepublica: prevData.domicilio.estadoRepublica || 'Puebla'
                }
            }))
        }
    }, [onEdit, token]);

    const loadEstados = async () => {
        try {
            const estadosData = await fetchEstados(token);
            setEstados(estadosData);
            try {
                const estadoData = estadosData.filter(estado =>
                    estado.name.toLowerCase() === 'Puebla'.toLowerCase()
                );

                const municipiosData = await fetchMunicipiosByEstadoId(token, estadoData[0].id);
                setMunicipios(municipiosData);
            } catch (error) {
                console.error('Error al obtener municipios:', error);
            }
        } catch (error) {
            console.error('Error al obtener estados:', error);
        }
    };

    const loadDistritos = async () => {
        try {
            const distritosData = await fetchDistritos(token);
            setDistritos(distritosData);
        } catch (error) {
            console.error('Error al obtener distritos:', error);
        }
    };

    const resetForm = () => {
        setFormData({
            id: '',
            nombre: '',
            estado: 'ACTIVE',
            domicilio: {
                calle: '',
                interior: '',
                exterior: '',
                colonia: '',
                localidad: '',
                codigoPostal: '',
                municipio: '',
                estadoRepublica: '',
                referencia: ''
            },
            distrito: {
                id: ''
            },
            telefono: '',
            extension: ''
        });
        setErrorMessages({});
    };

    const getById = function (id) {
        const loadbyId = async () => {
            try {
                const sede = await fetchById(token, onEdit.id);
                sede.domicilio = { ...sede.domicilio, audit: {} }
                setFormData(sede);
                try {
                    const estadosData = await fetchEstados(token);
                    setEstados(estadosData);
                    try {
                        const estadoData = estadosData.filter(estado =>
                            estado.name.toLowerCase() === sede.domicilio.estadoRepublica.toLowerCase()
                        );

                        const municipiosData = await fetchMunicipiosByEstadoId(token, estadoData[0].id);
                        setMunicipios(municipiosData);
                    } catch (error) {
                        console.error('Error al obtener municipios:', error);
                    }
                } catch (error) {
                    console.error('Error al obtener estados:', error);
                }
            } catch (error) {
                console.error('Error al obtener la sede:', error);
            }
        };
        loadbyId();
    };

    const formatPhoneCelNumbers = (value) => {
        const numericValue = value.replace(/\D/g, '');

        if (numericValue.length === 0) {
            return '';
        } else if (numericValue.length <= 3) {
            return `(${numericValue}`;
        } else if (numericValue.length <= 6) {
            return `(${numericValue.slice(0, 3)}) ${numericValue.slice(3)}`;
        } else {
            return `(${numericValue.slice(0, 3)}) ${numericValue.slice(3, 6)} ${numericValue.slice(6, 10)}`;
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'telefono') {
            const formattedValue = formatPhoneCelNumbers(value);
            const numericValue = value.replace(/\D/g, '');

            setFormData((prevData) => ({
                ...prevData,
                telefono: numericValue
            }));
            e.target.value = formattedValue;
        } else if (name.startsWith('domicilio.')) {
            const fieldName = name.split('.')[1];
            setFormData((prevData) => ({
                ...prevData,
                domicilio: {
                    ...prevData.domicilio,
                    [fieldName]: value
                }
            }));
        } else if (name.startsWith('distrito.')) {
            const fieldName = name.split('.')[1];
            setFormData((prevData) => ({
                ...prevData,
                distrito: {
                    ...prevData.distrito,
                    [fieldName]: value
                }
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value
            }));
        }

        setErrorMessages({
            ...errorMessages,
            [name]: '',
        });
    };

    const handleSubmit = async (e) => {
        const rules = {
            nombre: { required: true },
            estado: { required: true },
            tipo: { required: true },
            telefono: { pattern: /^\(\d{3}\) \d{3} \d{4}$/ },
            'domicilio.calle': { required: true },
            'distrito.id': { required: true },
            'domicilio.municipio': { required: true }
        };

        const errors = validateFields(formData, rules, showAlertValidate)

        if (Object.keys(errors).length > 0) {
            setErrorMessages(errors);
            return;
        }

        if (formData.telefono.replace(/\D/g, '').length !== 10 && formData.telefono.replace(/\D/g, '').length > 0) {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                telefono: 'El número de telefono debe tener 10 dígitos.'
            }));
            showSnackbar('El número de telefono debe tener 10 dígitos.', 'error');
            return;
        }

        let formDataToSubmit = {
            ...formData,
            audit: {}
        };

        try {
            if (formData.id) {
                await update(token, formDataToSubmit);
                showUpdateSnackbar('Registro actualizado con éxito', 'success');
            } else {
                await create(token, formDataToSubmit);
                showSnackbar('Registro guardado con éxito', 'success');
            }

            if (onAdd) {
                onAdd();
            }

            resetForm();
        } catch (error) {
            console.error('Error al guardar el registro:', error);
            if (error.response && error.response.status === 409) {
                showSnackbar("No pueden existir 2 sedes con el mismo nombre", 'error');
                setErrorMessages({ ...errorMessages, nombre: 'Nombre de la sede ya existe' });
            } else {
                showSnackbar('¡Algo salió mal!', 'error');
            }
        }
    };

    const handleCancel = () => {
        navigate('/api/core/sedes');
    };

    return (
        <form>
            <AccionesOnlyButtons accion1={handleSubmit} accion2={handleCancel} />

            <Grid className='box-sombra'>
                <Breadcrumbs istitulo="true" className="title2"/>
                <Grid  container spacing={2} sx={{ marginTop: 1 }}>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        name="nombre"
                        inputProps={{ minLength: 3, maxLength: 100 }}
                        label="Nombre de la sede"
                        variant="outlined"
                        fullWidth
                        value={formData.nombre}
                        onChange={handleChange}
                        error={!!errorMessages.nombre}
                    />
                    {errorMessages.nombre && <FormHelperText error>{errorMessages.nombre}</FormHelperText>}
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        name="domicilio.calle"
                        inputProps={{ minLength: 3, maxLength: 100 }}
                        label="Calle"
                        variant="outlined"
                        fullWidth
                        value={formData.domicilio.calle}
                        onChange={handleChange}
                        error={!!errorMessages['domicilio.calle']}
                    />
                    {errorMessages['domicilio.calle'] &&
                        <FormHelperText error>{errorMessages['domicilio.calle']}</FormHelperText>}
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        name="domicilio.exterior"
                        inputProps={{ maxLength: 20 }}
                        label="Número exterior"
                        variant="outlined"
                        fullWidth
                        value={formData.domicilio.exterior}
                        onChange={handleChange}
                        error={!!errorMessages.exterior}
                    />
                    {errorMessages.exterior && <FormHelperText error>{errorMessages.exterior}</FormHelperText>}
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        name="domicilio.interior"
                        inputProps={{ maxLength: 20 }}
                        label="Número interior (Opcional)"
                        variant="outlined"
                        fullWidth
                        value={formData.domicilio.interior}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth variant="outlined" error={!!errorMessages['domicilio.estadoRepublica']}>
                        <InputLabel id="estado-label">Estado</InputLabel>
                        <Select
                            labelId="estado-label"
                            name="domicilio.estadoRepublica"
                            value={formData.domicilio.estadoRepublica || 'Puebla'}
                            label="Estado"
                            error={!!errorMessages['domicilio.estadoRepublica']}
                            disabled
                        >
                            {estados.map((estado) => (
                                <MenuItem key={estado.id} value={estado.name}>{estado.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>


                <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth variant="outlined" error={!!errorMessages['domicilio.municipio']}>
                        <InputLabel id="municipio-label">Delegación o municipio</InputLabel>
                        <Select
                            labelId="municipio-label"
                            name="domicilio.municipio"
                            value={formData.domicilio.municipio}
                            onChange={handleChange}
                            label="Delegación o municipio"
                            error={!!errorMessages['domicilio.municipio']}
                        >
                            {municipios.map((municipio) => (
                                <MenuItem key={municipio.id} value={municipio.name}>{municipio.name}</MenuItem>
                            ))}
                        </Select>
                        {errorMessages['domicilio.municipio'] &&
                            <FormHelperText>{errorMessages['domicilio.municipio']}</FormHelperText>}
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        name="domicilio.localidad"
                        inputProps={{ maxLength: 100 }}
                        label="Localidad (Opcional)"
                        variant="outlined"
                        fullWidth
                        value={formData.domicilio.localidad}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        name="domicilio.colonia"
                        inputProps={{ maxLength: 100 }}
                        label="Colonia (Opcional)"
                        variant="outlined"
                        fullWidth
                        value={formData.domicilio.colonia}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        name="domicilio.codigoPostal"
                        inputProps={{ maxLength: 5 }}
                        label="Código postal (Opcional) "
                        variant="outlined"
                        fullWidth
                        value={formData.domicilio.codigoPostal}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth variant="outlined" error={!!errorMessages['distrito.id']}>
                        <InputLabel id="distrito-label">Distrito</InputLabel>
                        <Select
                            labelId="distrito-label"
                            name="distrito.id"
                            value={formData.distrito.id}
                            onChange={handleChange}
                            label="Distrito"
                            error={!!errorMessages['distrito.id']}
                        >
                            {distritos.map((distrito) => (
                                <MenuItem key={distrito.id} value={distrito.id}>{distrito.nombre}</MenuItem>
                            ))}
                        </Select>
                        {errorMessages['distrito.id'] &&
                            <FormHelperText>{errorMessages['distrito.id']}</FormHelperText>}

                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth variant="outlined" error={!!errorMessages.tipo}>
                        <InputLabel id="estado-label">Tipo</InputLabel>
                        <Select
                            labelId="estado-label"
                            name="tipo"
                            value={formData.tipo}
                            onChange={handleChange}
                            label="Tipo"
                        >
                            <MenuItem value="EXTERNO" defaultChecked={true}>Externo</MenuItem>
                            <MenuItem value="INTERNO">Interno</MenuItem>
                        </Select>
                        {errorMessages.tipo && <FormHelperText>{errorMessages.tipo}</FormHelperText>}
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        name="telefono"
                        inputProps={{ maxLength: 14 }}
                        label="Teléfono (Opcional)"
                        variant="outlined"
                        fullWidth
                        value={formatPhoneCelNumbers(formData.telefono)}
                        onChange={handleChange}
                        error={!!errorMessages.telefono}
                    />
                    {errorMessages.telefono && <FormHelperText error>{errorMessages.telefono}</FormHelperText>}
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        name="extension"
                        inputProps={{ maxLength: 6 }}
                        label="Extensión (Opcional)"
                        variant="outlined"
                        fullWidth
                        value={formData.extension}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        name="domicilio.referencia"
                        inputProps={{ maxLength: 100 }}
                        label="Referencia (Opcional)"
                        variant="outlined"
                        fullWidth
                        value={formData.domicilio.referencia}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth variant="outlined">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formData.estado === "ACTIVE"}
                                    onChange={(event) => handleChange({
                                        target: {
                                            name: 'estado',
                                            value: event.target.checked ? 'ACTIVE' : 'INACTIVE'
                                        }
                                    })}
                                    color="primary"
                                />
                            }
                            label="Activar sede"
                        />
                        {errorMessages.estado && <FormHelperText>{errorMessages.estado}</FormHelperText>}
                    </FormControl>
                </Grid>
                </Grid>
            </Grid>
        </form>
    );
}
    ;

export default FormSede; 
