import React from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  Tooltip,
  styled,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PaginationGeneric from '../../../components/Pagination/PaginationGeneric';
import text from '../../../assets/Glosario';
import CheckIcon from '@mui/icons-material/Check';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const JuzgadosTable = ({
  juzgados,
  onDelete,
  onUpdateStatus,
  onEdit,
  pageable,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage
}) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <PaginationGeneric
          pageable={pageable}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
        <TableContainer sx={{ maxHeight: 600, overflowY: 'auto' }}>
          <Table stickyHeader sx={{ minWidth: 750 }}>
            <TableHead>
              <TableRow>
                <TableCell className='table-header'>{text.Nombre}</TableCell>
                <TableCell className='table-header'>{text.Mate}</TableCell>
                <TableCell className='table-header'>{text.Estatus}</TableCell>
                <TableCell className='table-header'>{text.Acciones}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {juzgados
                .map((juzgado) => (
                  <StyledTableRow
                    key={juzgado.id}
                    onClick={() => onEdit(juzgado)} // Acción al hacer click en la fila
                    hover // Agrega un efecto de hover
                    style={{ cursor: 'pointer' }} // Cambio del cursor al pasar por la fila
                  >
                    <TableCell>{juzgado.nombre}</TableCell>
                    <TableCell>{juzgado.materia}</TableCell>
                    <TableCell>
                      {juzgado.estado === "ACTIVE" ? <span>Activo</span> : <span>Inactivo</span>}
                    </TableCell>
                    <TableCell align="center">
                      <Stack direction="row">
                        <Tooltip title="Eliminar">
                            <DeleteIcon 
                            style={{ marginRight: '10px' }} 
                            className='icon-color' onClick={(event) => {
                            event.stopPropagation();
                            onDelete(juzgado.id, juzgado.nombre); // Llama a la función de eliminar
                          }}>
                          </DeleteIcon>
                        </Tooltip>

                        <Tooltip title={juzgado.estado === 'ACTIVE' ? 'Desactivar' : 'Activar'}>
                          <CheckIcon  style={{ marginRight: '10px' }} className='icon-color' onClick={(event) => {
                            event.stopPropagation();
                            onUpdateStatus(juzgado); // Llama a la función de eliminar
                          }}>
                          </CheckIcon>
                        </Tooltip>
                      </Stack>
                    </TableCell>
                  </StyledTableRow>
                ))}
              {juzgados.length === 0 && (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No hay juzgados disponibles.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <PaginationGeneric
          pageable={pageable}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default JuzgadosTable;
