import axios from "../../../../../api/axiosConfig";

const urlParticipante = process.env.REACT_APP_TRIALS_API_URL + "/api/workflow/personaDetalle/create";

export const addNewParticipant = async (token, formData) => {
    
    try {
    const response = await axios.post(urlParticipante, formData,{
      headers: {
        Authorization: `Bearer ${token}`, 
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error al enviar los datos del nuevo participante:", error);
    throw error; 
  }
}

const urlGetParticipanteById = process.env.REACT_APP_TRIALS_API_URL + "/api/workflow/personaDetalle";

export const getParticipanteById = async (token, id) => {
    try {
    const response = await axios.get(`${urlGetParticipanteById}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`, 
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error al obtener los datos del participante:", error);
    throw error; 
  }
}

const urlUpdateParticipante = process.env.REACT_APP_TRIALS_API_URL + "/api/workflow/personaDetalle";

export const updateParticipante = async (token, id, formData) => {
    try {
    const response = await axios.put(`${urlUpdateParticipante}/${id}`, formData, {
      headers: {
        Authorization: `Bearer ${token}`, 
        'Content-Type': 'application/json'
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error al actualizar participante:", error);
    throw error; 
  }
}

