import axios from "../../api/axiosConfig";

export const findAll = async (token, page = 0, size = 25, sort = '', tipo = '', estado = '') => {
    try {
        const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/bandeja/notificaciones`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                page,
                size,
                sort,
                tipo,
                estado
            },
        });
        // console.log(response.data)
        return response.data;
    } catch (error) {
        console.error('Error al listar los registros:', error);
        throw error;
    }
};

export const addNota = async (token, notaResponse) => {

    try {
        const response = await axios.post(
            process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/bandeja/notificaciones/createNota`,
            notaResponse,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            }
        );

        return response.data;
    } catch (error) {
        console.error('Error al agregar la nota:', error);
        throw error;
    }
};

export const createLista = async (token, listaResponse) => {
    try {
        const response = await axios.post(
            process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/bandeja/notificaciones/createLista`,
            listaResponse,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            }
        );

        return response.data;
    } catch (error) {
        console.error('Error al agregar la lista:', error);
        throw error;
    }
};

export const getNotificacionesTurnado = async (token, carpetaIds) => {
    console.log(carpetaIds);
    try {
        const response = await axios.get(
            process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/bandeja/notificaciones/detalle/turnado`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                params: {
                    carpetaIds: carpetaIds.join(",")
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error al obtener las notificaciones turnado:', error);
        throw error;
    }
};
