import React, { useState, useEffect } from 'react';
import {
  FormControl, FormLabel, RadioGroup, FormControlLabel, Radio,
  Select, MenuItem, InputLabel, Grid, Button, Divider,
  Box, TextField,
  FormHelperText,
  Checkbox,
  Autocomplete
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { fetchSalasById, findAllBloques, findAllJueces, findAllJuzgados } from '../../../Salas/SalasService';
import { validateFields } from '../../../../components/Alert/ValidationRequired/validationRequired';
import { create, update } from '../../SalasService';
import { useToastAlert } from '../../../../components/Alert/ToastAlertSave/ToastAlertSave';
import { useToastAlertUpdate } from '../../../../components/Alert/ToastAlertUpdate/ToastAlertUpdate';
import { useToastAlertValidation } from '../../../../components/Alert/ToastAlertValidation/ToastAlertValidation';
import { fetchAllJueces } from '../../../Personas/PersonasService';
import { fetchJuzgados, fetchJuzgadosAutocomplete } from '../../../../shared/services/JuzgadosService';
import AccionesOnlyButtons from '../../../../components/Acciones/AccionesOnlyButtons';
import Breadcrumbs from '../../../../components/Breadcrumb/breadcrumbs';
import ConfirmationExit from '../../../../components/Alert/ConfirmationExit';

const FormSala = ({ token, onAdd, onEdit }) => {
  const navigate = useNavigate();
  const { showSnackbar, showConfirmDialog } = useToastAlert();
  const { showSnackbar: showUpdateSnackbar } = useToastAlertUpdate();
  const { showSnackbar: showAlertValidate } = useToastAlertValidation();
  const [ isEdit, setJuzEdit] = useState({
    isEdit: 0,
    idJuez: null,
    nombre: ''
  });
  const [formData, setFormData] = useState({
    nombre: '',
    estado: '',
    juez: null,
    bloque: null,
    juzgado: null,
    version: null
  });
  const [formDataEdit, setFormDataEdit] = useState({
    nombre: '',
    estado: '',
    juez: null,
    bloque: null,
    juzgado: null,
    version: null
  });

  const [bloques, setBloques] = useState([]);
  const [juzgados, setJuzgados] = useState([]);
  const [jueces, setJueces] = useState([]);
  const [errorMessages, setErrorMessages] = useState({});
  const [dialogMessage, setDialogMessage] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
    
  

  useEffect(() => {
    loadBloques();
    loadJuzgados();

    if (onEdit) {
      getById();
    } else {
      resetForm();
      setFormData((prevData) => ({
        ...prevData,
        estado: 'ACTIVE',
      }));
      setFormDataEdit((prevData) =>({
        ...prevData,
        estado: 'ACTIVE',
      }));
    }
  }, [onEdit, token]);

  const getById = async () => {
    try {
      const sala = await fetchSalasById(token, onEdit.id);

      setFormData({
        id: sala.id,
        nombre: sala.nombre,
        estado: sala.estado,
        juez: sala.juez ? { id: sala.juez.id } : null,
        bloque: sala.bloque ? { id: sala.bloque.id } : null,
        juzgado: sala.juzgado ? sala.juzgado : null,
        version: sala.version
      });
      setFormDataEdit({
        id: sala.id,
        nombre: sala.nombre,
        estado: sala.estado,
        juez: sala.juez ? { id: sala.juez.id } : null,
        bloque: sala.bloque ? { id: sala.bloque.id } : null,
        juzgado: sala.juzgado ? sala.juzgado : null,
        version: sala.version
      });
      setJuzEdit({
        isEdit: 1,
        idJuez: sala.juez.id,
        nombre: sala.juez.nombreCompleto,
      });
      loadJueces(sala.juzgado.id);

    } catch (error) {
      console.error('Error al obtener la sala:', error);
    }
  };

  const resetForm = () => {
    setFormData({
      nombre: '',
      estado: 'ACTIVE',
      juez: null,
      bloque: null,
      juzgado: null
    });
    setErrorMessages({});
  };

  const loadBloques = async () => {
    try {
      const bloques = await findAllBloques(token);
      setBloques(bloques || []);
    } catch (error) {
      console.log("Error al obtener los bloques");
    }
  };

  const loadJuzgados = async () => {
    try {
      const juzgados = await fetchJuzgadosAutocomplete(token, 0, 10, '', '');

      setJuzgados(juzgados || []);
    } catch (error) {
      console.log("Error al obtener los Juzgados");
    }
  };

  const handleSubmit = async (e) => {

    // Definir las reglas de validación
    const rules = {
      estado: { required: true },
      juez: { required: true },
      bloque: { required: true },
      juzgado: { required: true },
    };

    // Validar los campos del formulario
    const errors = validateFields(formData, rules, showAlertValidate);

    if (Object.keys(errors).length > 0) {
      setErrorMessages(errors);
      return;
    }

    let formSend = formData;

    formSend.juzgado = { id: formData.juzgado.id };

    let formDataToSubmit = {
      ...formData,
      audit: {}

    };

    try {
      if (formData.id) {
        await update(token, formDataToSubmit);
        showUpdateSnackbar('Registro actualizado con éxito', 'success');
      } else {
        await create(token, formDataToSubmit);
        showSnackbar('Registro guardado con éxito', 'success');
      }

      if (onAdd) {
        onAdd();
      }

      resetForm();
    } catch (error) {
      console.error('Error al guardar el registro:', error);
      showSnackbar('¡Algo salió mal!', 'error');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleBloqueChange = (event) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      bloque: { id: event.target.value }
    }));
  };

  const handleJuzgadoChange = (event, item) => {
    formData.juez = null;
    if(item !== null){
      loadJueces(item.id)
    }else{
      loadJueces(0);
      //Quitar Juez MenuItem
      setJuzEdit({
        isEdit: 0
      })
    }
    setFormData(prevFormData => ({
      ...prevFormData,
      juzgado: item
    }));
  };

  const loadJueces = async (item) => {
    try {
      const jueces = await fetchAllJueces(token, item);
      setJueces(jueces);
    } catch (error) {
      console.error('Error al obtener listado de jueces:', error);
    }
  };


  const handleJuecesChange = (event) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      juez: { id: event.target.value }
    }));
  };

  const handleEstadoChange = (event) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      estado: event.target.value
    }));
  };

const handleCancel = () => {
  // Verificar si alguno de los campos de juez, juzgado o bloque están modificados

  if (
    (isEdit.isEdit === 0 && formData.juez !== null) || 
    (isEdit.isEdit === 0 && formData.juzgado !== null ) || 
    (isEdit.isEdit === 0 && formData.bloque !== null ) ||
    (isEdit.isEdit === 1 && formData.bloque.id !== formDataEdit.bloque.id) ||
    (isEdit.isEdit === 1 && formData.juzgado.id !== formDataEdit.juzgado.id) ||
    (isEdit.isEdit === 1 && formData.juez.id !== formDataEdit.juez.id)
  ) {
    setDialogMessage('¿Estás seguro que deseas salir sin guardar los cambios?');
    setDialogOpen(true);
  } else {
    navigate('/api/core/salas');
  }
};


  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleConfirmDelete = async () => {
    navigate('/api/core/salas');
  }

  return (
    <form>
      <AccionesOnlyButtons accion1={handleSubmit} accion2={handleCancel} />

      <Grid className='box-sombra'>
        <Breadcrumbs istitulo="true" className="title2"/>
        <Grid container spacing={2} sx={{ marginTop: 1 }}>

        {formData.nombre !== '' && (
          <Grid item xs={12} sm={6} md={4}>
            <FormControl fullWidth variant="outlined">
              <TextField
                name="nombre"
                label="Nombre"
                variant="outlined"
                fullWidth
                value={formData.nombre}
                onChange={handleChange}
                error={!!errorMessages.nombre}
                helperText={errorMessages.nombre}
                disabled={true}
              />
            </FormControl>
          </Grid>
        )}


        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth variant="outlined" error={!!errorMessages.juzgado}>

            <Autocomplete
              options={juzgados.sort((a, b) => a.materia.localeCompare(b.materia))}
              getOptionLabel={(option) => (option.nombre)}
              groupBy={(option) => option.materia}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              value={formData?.juzgado}
              onChange={handleJuzgadoChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Juzgado"
                  variant="outlined"
                  error={!!errorMessages.juzgado}
                  helperText={errorMessages.juzgado}
                />
              )}
            />
            {errorMessages.juzgado && <FormHelperText>{errorMessages.juzgado}</FormHelperText>}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth variant="outlined" error={!!errorMessages.juez}>
            <InputLabel id="select-jueces-label">Juez</InputLabel>
            <Select
              labelId="select-jueces-label"
              value={formData.juez?.id || ''}
              onChange={handleJuecesChange}
              required
              label="Juez"
            >
              {jueces.map((juez) => (
                <MenuItem key={juez.id} value={juez.id}>
                  {juez.nombreCompleto}
                </MenuItem>
              ))}
                 {isEdit.isEdit === 1 && (
                <MenuItem key={isEdit.idJuez} value={isEdit.idJuez}>
                  {isEdit.nombre}
                </MenuItem>         
              )}

            </Select>
            {errorMessages.juez && <FormHelperText>{errorMessages.juez}</FormHelperText>}
          </FormControl>
        </Grid>
        {formData.nombre === '' && (
          <Grid item xs={12} sm={6} md={4}>
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth component="fieldset" margin="normal" error={!!errorMessages.bloque}>
            <FormLabel component="legend">Horario</FormLabel>
            <RadioGroup value={formData.bloque?.id || ''} onChange={handleBloqueChange}>
              {bloques.map((bloque) => (
                <FormControlLabel
                  key={bloque.id}
                  value={bloque.id.toString()}
                  control={<Radio />}
                  label={`${bloque.horaInicial} - ${bloque.horaFinal}`}
                />
              ))}
            </RadioGroup>
            {errorMessages.bloque && <FormHelperText>{errorMessages.bloque}</FormHelperText>}
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth variant="outlined">
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.estado === "ACTIVE"}
                  onChange={(event) => handleChange({
                    target: {
                      name: 'estado',
                      value: event.target.checked ? 'ACTIVE' : 'INACTIVE'
                    }
                  })}
                  color="primary"
                />
              }
              label="Activar sala"
            />
            {errorMessages.estado && <FormHelperText>{errorMessages.estado}</FormHelperText>}
          </FormControl>
        </Grid>
        </Grid>
      </Grid>
      
      <ConfirmationExit
        open={dialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDelete}
        message={dialogMessage}
      />
    </form>
  );
};

export default FormSala;
