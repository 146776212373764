import React from 'react';
import { Grid, Divider, Typography, TextField, Box } from '@mui/material';

const MotivoEdicion = ({ razonEdicion, setRazonEdicion, errors, setErrors, isEditRoute }) => {
    const handleChange = (e) => {
        const value = e.target.value;
        setRazonEdicion(value);

        if (errors.razonEdicion) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                razonEdicion: "",
            }));
        }
    };

    return (
        <Grid container spacing={2} >

            <Grid item xs={12}>
                <Divider sx={{ my: 5 }} />
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={1}>
                        <Typography>Motivo edición: </Typography>
                    </Grid>
                    <Grid item xs={10} mb={2} ml={7}>
                        <TextField
                            multiline
                            placeholder="Motivo edición"
                            fullWidth
                            variant="outlined"
                            value={razonEdicion}
                            onChange={handleChange}
                            error={!!errors.razonEdicion}
                            helperText={
                                <Box display="flex" justifyContent="space-between">
                                    <span>{errors.razonEdicion}</span>
                                    <span>{`${razonEdicion.length}/150 caracteres`}</span>
                                </Box>
                            }
                            inputProps={{ maxLength: 150 }}
                            required={isEditRoute}
                        />
                    </Grid>
                </Grid>
                <Divider sx={{ my: 5 }} />
            </Grid> 
           
        </Grid>



    );
};

export default MotivoEdicion;
