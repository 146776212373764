import axios from 'axios';

async function fetchLenguasIndigenas(token) {
    try {
        const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + '/api/core/lenguasindigenas', {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error al obtener lenguas indigenas:', error);
    }
}

export default fetchLenguasIndigenas;