import {
    Tooltip
} from "@mui/material";

import DevolverIcon from '@mui/icons-material/Replay';
import SendIcon from '@mui/icons-material/Send';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import { useState } from "react";
import ReturnTurnadoModal from "../../../components/Modal/ReturnTurnadoModal";
import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';

const ButtonsAccionRapida = ({
    item,
    handleSingleTurnar,
    handleOpenModal,
    setMessageDialogPieza,
    setTitleDialogConfirm,
    setButtonCancelLabel,
    setButtonOkLabel,
    handleRefresh,
    isCarpetaAsignadaPromocion
}) => {

    const [selectedExpediente, setSelectedExpediente] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const devolverExpediente = (id, expediente) => {
        setSelectedExpediente({ expediente, documentoId: id});
        setIsModalOpen(true);
    };

    const handleCloseModalInterno = () => {
        setIsModalOpen(false);
        setSelectedExpediente(null);
    };

    const handleSave = (data) => {
        handleCloseModalInterno();
    };
    const publicarAcuerdo = (id) => {
        console.log(id);
    };

    const handleAdjuntarPieza = (item) => {
        const data = {
            carpetaId: item.carpetaId,
            expediente: item.expediente,
            tipo: item.tipoEntrada,
            carpetaAsignada: isCarpetaAsignadaPromocion(item.carpetaId),
            documentos: [{ id: item.id, expediente: item.expediente },]
        };
        handleOpenModal(data, "pieza");
    };

    const handleAcoplarPieza = (item, accion) => {
        const data = {
            piezaId: item.carpetaId,
            estatus: accion === "CANCELAR" ? "CANCELADO" : accion === "INTEGRAR" ? "INTEGRADO" : ""
        }

        if (accion === "CANCELAR") {
            setMessageDialogPieza(`Se cancelará la pieza ${item.expediente} de manera permanente`);
            setTitleDialogConfirm("¿Cancelar pieza?");
            setButtonOkLabel("Si, cancelar");
            setButtonCancelLabel("No, salir");
        }

        if (accion === "INTEGRAR") {
            setTitleDialogConfirm("¿Integrar pieza?");
            setMessageDialogPieza(`Se integrará la pieza ${item.expediente} al expediente principal`);
            setButtonOkLabel("Si, integrar");
            setButtonCancelLabel("No, cancelar");
        }

        handleOpenModal(data, "confirm");

    };

    return (
        <>
            {item.estatus === "Turnado" &&
                <Tooltip title="Cancelar turnado">
                    <DevolverIcon
                      style={{ marginRight: '10px' }}
                        className="icon-color"
                        onClick={(e) => {
                            e.stopPropagation();
                            devolverExpediente(item.carpetaId, item.expediente);
                        }}
                    />
                </Tooltip>
            }
            {item.observaciones === ''}
            {item.estatus !== "Turnado" && item.tipoEntrada !== 'Pieza' && 
                <Tooltip title={item.observaciones == "" ? "Normal" : item.observaciones}>
                    <FeedbackOutlinedIcon
                      style={{ marginRight: '10px', color: 'red'}}
                        className={item.observaciones == 'Urgente' ? '' : 'icon-color'}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    />
                </Tooltip>
            }
            
            {/*
            {item.estatus !== "Turnado" && item.tipoEntrada !== "Promocion" &&

                <Tooltip title="Publicar">
                    <PublishIcon
                      style={{ marginRight: '10px' }}
                        className="icon-color"
                        onClick={(e) => {
                            e.stopPropagation();
                            publicarAcuerdo(item.id);
                        }}
                    />
                </Tooltip>
            }
            */}
            {/*
            {item.estatus !== "Turnado" && item.tipoEntrada !== "Promocion" &&
                <Tooltip title="Notificaciones">
                    <NotificationsIcon
                      style={{ marginRight: '10px' }}
                        className="icon-color"
                        onClick={(e) => {
                            e.stopPropagation();
                            verNotificaciones(item.id);
                        }}
                    />
                </Tooltip>
            }
            */}
            {item.estatus !== "Turnado" && item.tipoEntrada !== "Promocion" &&
                <Tooltip title="Turnar">
                    <SendIcon
                      style={{ marginRight: '10px' }}
                        className="icon-color"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleSingleTurnar(item.carpetaId)
                        }}
                    />
                </Tooltip>
            }

            {item.estatus !== "Turnado" && item.tipoEntrada === "Promocion" &&
                <Tooltip title="Adjuntar">
                    <DriveFolderUploadIcon
                      style={{ marginRight: '10px' }}
                        className="icon-color"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleAdjuntarPieza(item);
                        }}
                    />
                </Tooltip>
            }

            {item.tipoEntrada === "Pieza" &&
                <Tooltip title="Integrar pieza">
                    <DriveFolderUploadIcon
                    
                        className="icon-color"
                        style={{ transform: 'rotate(180deg)', marginRight: '10px' }}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleAcoplarPieza(item, "INTEGRAR");
                        }}
                    />
                </Tooltip>
            }
            {item.tipoEntrada === "Pieza" &&
                <Tooltip title="Cancelar pieza">
                    <DoNotDisturbAltIcon
                      style={{ marginRight: '10px' }}
                        className="icon-color"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleAcoplarPieza(item, "CANCELAR");
                        }}
                    />
                </Tooltip>
            }

            <ReturnTurnadoModal
                isOpen={isModalOpen}
                handleClose={handleCloseModalInterno}
                expediente={selectedExpediente}
                handleSave={handleSave}
                handleRefresh={handleRefresh}
            />
        </>
    );
};

export default ButtonsAccionRapida;