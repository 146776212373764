import { useContext, useState, useEffect, useRef } from "react";
import { Modal, Typography, Box, IconButton, Divider, Card, CardContent, TextField, CircularProgress, Button } from "@mui/material";
import keycloakConf from "../../api/keycloakConf";
import { useToastAlert } from "../Alert/ToastAlertSave/ToastAlertSave";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import EditIcon from "@mui/icons-material/Edit";
import { fetchComentariosByPersonaDocumento, createComentario, updateComentario } from "../../shared/services/ComentariosAsistentesService";

const ModalListaParticipaciones = ({ open, onClose, participante }) => {
  const { getToken } = useContext(keycloakConf);
  const token = getToken();
  const { showSnackbar } = useToastAlert();

  const [comentarios, setComentarios] = useState([]);
  const [nuevoComentario, setNuevoComentario] = useState("");
  const [comentarioEditado, setComentarioEditado] = useState(null);
  const [comentarioEnEdicion, setComentarioEnEdicion] = useState("");
  const comentariosRef = useRef(null);
  const [loadingComentarios, setLoadingComentarios] = useState(false);
  const comentariosRefs = useRef({});
  const [scrollToEnd, setScrollToEnd] = useState(false);

  useEffect(() => {
    if (open && participante) {
      const obtenerComentarios = async () => {
        setLoadingComentarios(true);
        try {
          const data = await fetchComentariosByPersonaDocumento(token, participante.id);
          setComentarios(data.content);
          setScrollToEnd(true);
        } catch (error) {
          showSnackbar("Error al cargar los comentarios", "error");
        } finally {
          setLoadingComentarios(false);
        }
      };
      obtenerComentarios();
    }
  }, [open, participante, token, showSnackbar]);

  useEffect(() => {
    if (comentariosRef.current) {
      if (scrollToEnd) {
        comentariosRef.current.scrollTop = comentariosRef.current.scrollHeight;
        setScrollToEnd(false);
      }
    }
  }, [comentarios, scrollToEnd]);

  useEffect(() => {
    if (comentarioEditado && comentariosRefs.current[comentarioEditado.id]) {
      comentariosRefs.current[comentarioEditado.id].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [comentarioEditado]);

  const handleEnviarComentario = async () => {
    if (nuevoComentario.trim() === "") {
      showSnackbar("El comentario no puede estar vacío", "error");
      return;
    }

    const comentarioData = {
      idPersonaDocumento: participante.id,
      comentario: nuevoComentario,
    };

    try {
      await createComentario(token, comentarioData);
      setNuevoComentario("");
      const data = await fetchComentariosByPersonaDocumento(token, participante.id);
      setComentarios(data.content);
      setScrollToEnd(true);
    } catch (error) {
      showSnackbar("Error al enviar el comentario", "error");
    }
  };

  const handleEditarComentario = (comentario) => {
    setComentarioEditado(comentario);
    setComentarioEnEdicion(comentario.comentario);
  };

  const handleActualizarComentario = async () => {
    if (comentarioEnEdicion.trim() === "") {
      showSnackbar("El comentario no puede estar vacío", "error");
      return;
    }

    const comentarioData = {
      idPersonaDocumento: participante.id,
      comentario: comentarioEnEdicion,
    };

    try {
      await updateComentario(token, comentarioEditado.id, comentarioData);
      setComentarioEditado(null);
      setComentarioEnEdicion("");
      const data = await fetchComentariosByPersonaDocumento(token, participante.id);
      setComentarios(data.content);
    } catch (error) {
      showSnackbar("Error al actualizar el comentario", "error");
    }
  };

  const handleCancelarEdicion = () => {
    setComentarioEditado(null);
    setComentarioEnEdicion("");
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          width: "500px",
        }}
      >
        <Box sx={{ position: "absolute", top: 0, right: 0, p: 1 }}>
          <IconButton onClick={onClose} sx={{ color: "black" }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography variant="h6" gutterBottom>
          Participaciones
        </Typography>
        <Divider sx={{ marginBottom: 2, borderColor: "black" }} />

        {loadingComentarios ? (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "300px" }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box
            ref={comentariosRef}
            sx={{
              marginTop: 2,
              maxHeight: "300px",
              overflowY: "auto",
              "&::-webkit-scrollbar": { width: "8px" },
              "&::-webkit-scrollbar-thumb": { backgroundColor: "rgba(0, 0, 0, 0.2)", borderRadius: "10px" },
              "&::-webkit-scrollbar-track": { background: "transparent" },
            }}
          >
             {comentarios.length > 0 ? (
              comentarios.map((comentario) => (
                <Box
                  key={comentario.id}
                  ref={(el) => (comentariosRefs.current[comentario.id] = el)}
                  sx={{ marginBottom: 2 }}
                >
                  <Typography
                    variant="caption"
                    sx={{ display: "block", textAlign: "center", marginBottom: 1 }}
                  >
                    {new Date(comentario.horaRegistro).toLocaleString("es-MX", {
                      timeZone: "America/Mexico_City",
                    })}
                  </Typography>
                  <Card sx={{ border: "1px solid black", display: "flex", justifyContent: "space-between" }}>
                    <CardContent sx={{ flexGrow: 1 }}>
                      {comentarioEditado?.id === comentario.id ? (
                        <TextField
                          fullWidth
                          variant="outlined"
                          value={comentarioEnEdicion}
                          onChange={(e) => setComentarioEnEdicion(e.target.value)}
                          multiline
                          rows={2}
                        />
                      ) : (
                        <Typography variant="body2">{comentario.comentario}</Typography>
                      )}
                    </CardContent>
                    <Box sx={{ display: "flex", alignItems: "center", padding: 1 }}>
                      {comentarioEditado?.id !== comentario.id && (
                        <IconButton onClick={() => handleEditarComentario(comentario)} className="icon-color">
                          <EditIcon />
                        </IconButton>
                      )}
                    </Box>
                  </Card>

                  {comentarioEditado?.id === comentario.id && (
                    <Box sx={{ marginTop: 2, display: "flex", justifyContent: "flex-end" }}>
                      <Button onClick={handleActualizarComentario} type="button" variant="contained" className="button-save">
                        Actualizar
                      </Button>
                      <Button onClick={handleCancelarEdicion} type="button" variant="contained" className="button-close" style={{ marginLeft: "10px" }}>
                        Cancelar
                      </Button>
                    </Box>
                  )}
                </Box>
              ))
            ) : (
              <Typography variant="body2" sx={{ textAlign: "center", marginTop: 1 }}>
                No hay comentarios disponibles
              </Typography>
            )}
          </Box>
        )}

        <Box sx={{ marginTop: 7, display: "flex", justifyContent: "space-between" }}>
          <TextField
            fullWidth
            variant="outlined"
            label="Escribe un comentario"
            value={nuevoComentario}
            onChange={(e) => setNuevoComentario(e.target.value)}
            multiline
            rows={2}
            sx={{ marginRight: 2 }}
          />
          <IconButton onClick={handleEnviarComentario} className="icon-color">
            <SendIcon />
          </IconButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalListaParticipaciones;
