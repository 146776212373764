import React, { createContext, useContext, useState } from 'react';

const TooltipBContext = createContext();

export const useTooltipB = () => useContext(TooltipBContext);

export const TooltipBProvider = ({ children }) => {
  const [tooltipBTitle, setTooltipBTitle] = useState(() => {
    const savedTooltipB = localStorage.getItem('tooltipBTitle');
    return savedTooltipB ? JSON.parse(savedTooltipB) : null;
  });

  const clearTooltipBTitle = () => {
    setTooltipBTitle(null);
    localStorage.removeItem('tooltipBTitle');
  };

  return (
    <TooltipBContext.Provider value={{ tooltipBTitle, setTooltipBTitle, clearTooltipBTitle }}>
      {children}
    </TooltipBContext.Provider>
  );
};
