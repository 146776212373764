import { useNavigate, useLocation } from "react-router-dom";
import Acciones from "../../../../../components/Acciones/Acciones";
import { Box, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useState, useContext, useEffect } from "react";
import { enviarNotificaciones, fetchTipoPartesAcuerdo } from "./AcuerdoService";
import keycloakConf from "../../../../../api/keycloakConf";
import texto from '../../../../../assets/Glosario';
import { useToastAlertValidation } from "../../../../../components/Alert/ToastAlertValidation/ToastAlertValidation";

const AcuerdoNotificacion = () => {
    const location = useLocation();
    const { item, data } = location.state || {};
    const { showSnackbar: showAlertValidate } = useToastAlertValidation();
    const [notificarA, setNotificarA] = useState("");
    const [especificar, setEspecificar] = useState(false);
    const [partes, setPartes] = useState([]);
    const [notificados, setNotificados] = useState([]);
    const navigate = useNavigate();
    const { getToken } = useContext(keycloakConf);
    const [btnGuardar, setBtnGuardar] = useState(false);

    
    const handleEnviarNotificaciones = async () => {
        let personasNotificacion = [];
        console.log(data);
        
        if (especificar && notificados.length > 0) {
            personasNotificacion = notificados;

        } else {
            personasNotificacion = partes.map(item => item.id);
        }

        if (personasNotificacion.length === 0) {
            showAlertValidate('Es necesario seleccionar algun registro', 'error', 'Selección no valida');
            return;
        }

        let dataSend = {
            documentoId: data.acuerdoId ? data.acuerdoId : data.sentenciaId,
            notas: "",
            personasDocumentosId: personasNotificacion
        }


        const response = await enviarNotificaciones(getToken(), dataSend);
        if (response.estatus === 200) {
            showAlertValidate('Se han enviado las notificaciones a las partes seleccionadas.', 'success', 'Notificaciones enviadas');
            accion2();
        } else {
            showAlertValidate('No ha sido posible enviar las notificaciones.', 'error', 'Ha ocurrido un problema');
        }

    }
    
    useEffect(() => {
        let desactivarBtn = false;
    
        if (!especificar) {
            // Si no se especifica, desactivar el botón si alguna parte carece de 'metodo'
            desactivarBtn = partes.some((parte) => !parte.metodo);
        } else {
            // Si se especifica pero no hay seleccionados, desactivar el botón
            if (notificados.length === 0) {
                desactivarBtn = true;
            } else {
                // Desactivar el botón si algún seleccionado carece de 'metodo'
                desactivarBtn = notificados.some((id) => {
                    const parte = partes.find((p) => p.id === id);
                    return parte && !parte.metodo;
                });
            }
        }
        if(notificarA === ""){
            setBtnGuardar(false);
        }else{
            setBtnGuardar(!desactivarBtn);
        }
       
    }, [partes, notificados, especificar]);
    


    const accion2 = () => {
        navigate('/api/bandeja/expediente', {
            state: { item: item, tabnav: 2 }
        })
    }

    const handleNotificarA = async (e) => {
        setNotificarA(e.target.value);
        const response = await fetchTipoPartesAcuerdo(getToken(), data.carpetaId, e.target.value);
        setPartes(response);
    }

    const handleEspecificar = (e) => {
        setEspecificar(e.target.checked)
        setNotificados([]);
    }

    const handleCheckParte = (parte, checked) => {

        setNotificados(prevState => {
            if (checked) {

                return [...prevState, parte.id];
            } else {

                return prevState.filter(id => id !== parte.id);
            }
        });
    };

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    return <>
        <Acciones accion1={handleEnviarNotificaciones} accion2={accion2} textoAccion2="CERRAR" isVisibleBtn1={btnGuardar} />

        <Box display="flex" ml={2} mr={2} justifyContent="space-between" alignItems="center">
            <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <InputLabel id="select-tamanio-papel-label">Notificar a: </InputLabel>
                        <Select
                            name="notificarAID"
                            labelId="select-notificarA-label"
                            value={notificarA || ""}
                            onChange={handleNotificarA}
                            label="Tamaño de papel"
                        >
                            <MenuItem value="actor">Parte actora</MenuItem>
                            <MenuItem value="demandado">Parte demandada</MenuItem>
                            <MenuItem value="ambos">Ambos</MenuItem>
                            <MenuItem value="otros">Otros</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>


                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={especificar}
                                onChange={(e) => { handleEspecificar(e) }}

                            />
                        }
                        label="Especificar"
                    />

                    <TableContainer sx={{ maxHeight: 600, overflowY: 'auto' }}>
                        <Table stickyHeader sx={{ minWidth: 750 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell className='table-header'></TableCell>
                                    <TableCell className='table-header'>{texto.Nombre}</TableCell>
                                    <TableCell className='table-header'>{texto.TipoAcuerdo}</TableCell>
                                    <TableCell className='table-header'>{texto.Metodo}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {partes.map((parte) => (
                                    <StyledTableRow
                                        key={parte.id}
                                        hover
                                        style={{ cursor: 'pointer' }}>
                                        <TableCell>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={notificados.includes(parte.id)}
                                                        onChange={(e) => handleCheckParte(parte, e.target.checked)}
                                                        disabled={!especificar}
                                                    />
                                                }
                                            />
                                        </TableCell>
                                        <TableCell>{parte.nombre}</TableCell>
                                        <TableCell>{parte.tipo}</TableCell>
                                        <TableCell>{parte.metodo ? parte.metodo : 'Sin especificar'}</TableCell>
                                    </StyledTableRow>
                                ))}
                                {partes.length === 0 && (
                                    <TableRow>
                                        <TableCell colSpan={6} align="center">
                                            No hay registros disponibles.
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>

            </Grid>
        </Box>
    </>
}

export default AcuerdoNotificacion;