import React, { useEffect, useState, useContext } from 'react';
import { Modal, Box, Typography, TextField, Select, MenuItem, Checkbox, FormControlLabel, Button, FormControl, FormHelperText, Autocomplete, CircularProgress } from "@mui/material";
import { getAllConceptos } from '../../shared/services/ConceptosService';
import { fetchPersonalTurnado } from '../../shared/services/PersonasService';
import { turnadoPersonalJuzgado } from '../../shared/services/DocumentoService';
import keycloakConf from '../../api/keycloakConf';
import { useToastAlert } from '../Alert/ToastAlertSave/ToastAlertSave'

const ModalTurnado = ({ open, onClose, data, handleRefresh, subTitle }) => {
  const [selectedConcept, setSelectedConcept] = useState("");
  const [selectedPersonal, setSelectedPersonal] = useState("");
  const [specifyTime, setSpecifyTime] = useState(false);
  const [urgent, setUrgent] = useState("NORMAL");
  const [time, setTime] = useState('');
  const [conceptos, setConceptos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorPara, setErrorPara] = useState(null);
  const [errorConcepto, setErrorConcepto] = useState(null);
  const [errorHoras, setErrorHoras] = useState(null);
  const { getToken } = useContext(keycloakConf);
  const [personalTurnadoOptions, setPersonalTurnadoOptions] = useState([]);
  const { showSnackbar, showConfirmDialog } = useToastAlert();

  const token = getToken();

  useEffect(() => {
    if (open) {

      const fetchConceptos = async () => {
        setLoading(true);
        try {
          const response = await getAllConceptos(token, data[0].carpetaId);
          setConceptos(response);
        } catch (err) {
          setErrorConcepto('Error al cargar conceptos');
        } finally {
          setLoading(false);
        }
      };

      const fetchPersonalTurnadoOptions = async () => {
        try {
          const data = await fetchPersonalTurnado(token);
          setPersonalTurnadoOptions(data);
        } catch (err) {
          setErrorPara('Error al cargar personal turnado');
        }
      };

      fetchConceptos();
      fetchPersonalTurnadoOptions();
    } else {
      setSelectedConcept("");
      setSelectedPersonal("");
      setTime('');
      setSpecifyTime(false);
      setUrgent("NORMAL");
    }
  }, [open, token]);

  const handleChangeConcept = (event, newValue) => {
  
    const selectedConceptId = newValue ? newValue.id : "";
    setSelectedConcept(selectedConceptId);
    const selectedConceptData = conceptos.find(concepto => concepto.id === selectedConceptId);
    if (selectedConceptData) {
      setTime(selectedConceptData.dias);
    }
    setErrorConcepto(null);
  };

  const handleSpecifyTimeChange = (checked) => {
    setSpecifyTime(checked);
    if (!checked) {
      const selectedConceptData = conceptos.find(concepto => concepto.id === selectedConcept);
      if (selectedConceptData) {
        setTime(selectedConceptData.dias);
      }
    } else {
      setTime('');
    }
  };

  const handleTimeChange = (e) => {
    const newValue = e.target.value;
    if (newValue >= 0 || newValue === '') {
      setTime(newValue);
    }
  };

  const handleUrgentChange = (e) => {
    setUrgent(e.target.checked ? "URGENTE" : "NORMAL");
  };

  const handleTurnado = async () => {
    const validateForm = () => {
      let isValid = true;
      if (selectedPersonal === "") {
        setErrorPara('Este campo es obligatorio');
        isValid = false;
      } else {
        setErrorPara(null);
      }
      if (selectedConcept === "") {
        setErrorConcepto('Este campo es obligatorio');
        isValid = false;
      } else {
        setErrorConcepto(null);
      }
      if (specifyTime && time <= 0) {
        setErrorHoras('Este campo es obligatorio');
        isValid = false;
      } else {
        setErrorHoras(null);
      }
      return isValid;
    };
    if (!validateForm()) return;

    const payload = data.map((item) => ({
      idDocumentoAsignado: item.carpetaId,
      idPersonalJuzgado: selectedPersonal,
      idConcepto: selectedConcept,
      horas: specifyTime || time ? time : null,
      prioridad: urgent,
    }));

    try {
      await turnadoPersonalJuzgado(token, payload);
      showSnackbar('Documento turnado con éxito', 'success');
      handleRefresh();
      onClose();
    } catch (err) {
      console.error('Error al enviar el turnado:', err);
      showSnackbar('Error al enviar el turnado', 'error');
    }
  };

  const clearErrors = () => {
    setErrorPara(null);
    setErrorConcepto(null);
    setErrorHoras(null);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 2,
      }}>
        <Typography variant="h7" align="left" sx={{ fontWeight: 'bold', mb: 2 }}>Turnado</Typography>
        {subTitle && ( <Typography variant="h7" align="left" sx={{ mb: 2 }}> {subTitle} </Typography> )}
        <Box display="flex" alignItems="center" gap={2} mt={2}>
          <Typography sx={{ minWidth: 70 }}>Para:</Typography>
          <FormControl fullWidth size="small" error={!!errorPara}>
            <Select
              value={selectedPersonal}
              onChange={(event) => {
                setSelectedPersonal(event.target.value);
                setErrorPara("");
              }}
              displayEmpty
              sx={{ width: '100%' }}
              renderValue={(selected) => {
                return selected ? personalTurnadoOptions.find(persona => persona.id === selected)?.nombre : <span style={{ color: "gray" }}>Selecciona uno</span>;
              }}
            >
              {loading ? (
                <MenuItem disabled>Cargando...</MenuItem>
              ) : (
                personalTurnadoOptions.map(persona => (
                  <MenuItem key={persona.id} value={persona.id}>
                    {persona.nombre}
                  </MenuItem>
                ))
              )}
            </Select>
            {errorPara && <FormHelperText>{errorPara}</FormHelperText>}
          </FormControl>
        </Box>

        <Box display="flex" alignItems="center" gap={2} mt={2}>
          <Typography sx={{ minWidth: 70 }}>Concepto:</Typography>
          <FormControl fullWidth size="small" error={!!errorConcepto}>
            <Autocomplete
              value={conceptos.find((concepto) => concepto.id === selectedConcept) || null}
              onChange={handleChangeConcept}
              options={conceptos}
              getOptionLabel={(option) => option.nombre}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              loading={loading}
              noOptionsText="Sin opciones"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Selecciona un concepto"
                  error={!!errorConcepto}
                  helperText={errorConcepto}
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
            {errorConcepto && <FormHelperText>{errorConcepto}</FormHelperText>}
          </FormControl>
        </Box>

        <Box mt={2} display="flex" alignItems="center">
          <Typography sx={{ minWidth: 120 }}>Tiempo de término:</Typography>
          <TextField
            type="number"
            value={time}
            onChange={handleTimeChange}
            disabled={!specifyTime}
            size="small"
            error={!!errorHoras}
            sx={{ width: 100, mx: 1 }}
          />
          {errorHoras && <FormHelperText>{errorHoras}</FormHelperText>}
          <Typography sx={{ ml: 1 }}>hrs.</Typography>
          <FormControlLabel
            control={<Checkbox checked={specifyTime} onChange={(e) => handleSpecifyTimeChange(e.target.checked)} />}
            label="Especificar tiempo"
            sx={{ ml: 'auto' }}
          />
        </Box>

        <FormControlLabel
          control={<Checkbox checked={urgent === "URGENTE"} onChange={handleUrgentChange} />}
          label="Marcar como urgente"
          sx={{ mt: 3 }}
        />

        <Typography mt={3}>Total de elementos seleccionados a turnar: {data.length}</Typography>

        <Box display="flex" justifyContent="flex-end" mt={3} gap={2}>
          <Button id="btnClosePieza"
            variant="outlined"
            className="button-close"
            onClick={() => {
              clearErrors();
              onClose();
            }}
          >
            Cerrar
          </Button>
          <Button variant="contained" className="button-save" onClick={handleTurnado}>
            Turnar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalTurnado;
