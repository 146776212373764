import axios from 'axios';

export const fetchMateriaPericial = async (token) => {
    try {
        const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + '/api/core/materiapericial', {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error al obtener materia pericial:', error);
    }
};