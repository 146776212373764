
import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Box,
  Grid,
  FormHelperText,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material';
import GenericModal from '../../../../components/Modal/Modal';
import { addMateria, updateMateria } from '../../MateriasService';

const FormMateria = ({ token, onAddMateria, materiaToEdit, onEdit }) => {
  const [materia, setMateria] = useState({
    id: '',
    nombre: '',
    estado: '',
    audit: {
      fechaAlta: new Date().toISOString(),
      usuarioAlta: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      fechaEdita: new Date().toISOString(),
      usuarioEdita: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
    },
  });

  const [isOpen, setIsOpen] = useState(false);
  const [errorMessages, setErrorMessages] = useState({});

  useEffect(() => {
    if (materiaToEdit) {
      setMateria(materiaToEdit);
      setIsOpen(true);
    } else {
      resetForm();
    }
  }, [materiaToEdit]);

  const resetForm = () => {
    setMateria({
      id: '',
      nombre: '',
      estado: '',
      audit: {
        fechaAlta: new Date().toISOString(),
        usuarioAlta: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        fechaEdita: new Date().toISOString(),
        usuarioEdita: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
      },
    });
    setErrorMessages({});
    setIsOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMateria({
      ...materia,
      [name]: value,
    });

    setErrorMessages({
      ...errorMessages,
      [name]: '',
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let errors = {};
    if (!materia.nombre) {
      errors.nombre = 'Este campo es obligatorio';
    }
    if (!materia.estado) {
      errors.estado = 'Este campo es obligatorio';
    }

    if (Object.keys(errors).length > 0) {
      setErrorMessages(errors);
      return;
    }

    const materiaData = {
      version: materia.version,
      nombre: materia.nombre,
      estado: materia.estado,
      audit: {
        fechaAlta: materia.audit.fechaAlta,
        usuarioAlta: materia.audit.usuarioAlta,
        fechaEdita: new Date().toISOString(),
        usuarioEdita: materia.audit.usuarioEdita,
      },
    };

    try {
      if (materia.id) {
        await updateMateria(token, { ...materiaData, id: materia.id });
        if (onEdit) {
          onEdit(); // Llama a la función para actualizar la lista (fuerza la recarga)
        }
      } else {
        await addMateria(token, materiaData);
        if (onAddMateria) {
          onAddMateria(); // Actualiza la lista en caso de una nueva materia
        }
      }
      resetForm(); // Cierra el modal y resetea el formulario
    } catch (error) {
      console.error('Error al guardar materia:', error);
    }
  };

  return (
    <>
      <Button variant="contained" color="success" onClick={() => setIsOpen(true)}>
        Crear Nueva Materia
      </Button>
      <GenericModal isOpen={isOpen} handleClose={resetForm} title={materia.id ? "Editar Materia" : "Crear Nueva Materia"}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                name="nombre"
                label="Nombre de la materia"
                variant="outlined"
                fullWidth
                value={materia.nombre}
                onChange={handleChange}
                error={!!errorMessages.nombre}
              />
              {errorMessages.nombre && <FormHelperText error>{errorMessages.nombre}</FormHelperText>}
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth variant="outlined" error={!!errorMessages.estado}>
                <InputLabel id="estado-label">Estado *</InputLabel>
                <Select
                  labelId="estado-label"
                  name="estado"
                  value={materia.estado}
                  onChange={handleChange}
                >
                  <MenuItem value="A">A</MenuItem>
                  <MenuItem value="I">I</MenuItem>
                  <MenuItem value="D">D</MenuItem>
                </Select>
                {errorMessages.estado && <FormHelperText error>{errorMessages.estado}</FormHelperText>}
              </FormControl>
            </Grid>
          </Grid>
          <Box sx={{ marginTop: 2 }}>
            <Button type="submit" variant="contained" color="success">
              {materia.id ? "Actualizar Materia" : "Crear Materia"}
            </Button>
          </Box>
        </form>
      </GenericModal>
    </>
  );
};

export default FormMateria;
