import React, { useState, useRef } from "react";
import {
  TextField,
  Divider,
  IconButton,
  Typography,
  Grid,
  Button,
  Box,
  Card,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Checkbox,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import { onlyAlphaNumericSpace, onlyNames } from "../../../components/Caracteres/caracteresEspeciales";

export default function FormApelacion({
  carpeta,
  personas,
  selectedActorCheckboxes,
  setSelectedActorCheckboxes,
  selectedDemandadoCheckboxes,
  setSelectedDemandadoCheckboxes,
  otroNombreActor,
  setOtroNombreActor,
  otroNombreDemandado,
  setOtroNombreDemandado,
  anexos,
  setAnexos
}) {

  const anexoRefs = useRef([]);
  const [anexosErrors, setAnexosErrors] = useState([]);

  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const handleAddAnexo = () => {
    setAnexos([...anexos, ""]);
    setIsButtonClicked(true);

    setTimeout(() => {
      const lastAnexoRef = anexoRefs.current[anexos.length];
      if (lastAnexoRef) lastAnexoRef.focus();
    }, 0);
  };

  const handleAnexoChange = (index, value) => {
    const newValue = onlyAlphaNumericSpace(value);
    const newAnexos = [...anexos];
    newAnexos[index] = newValue;
    setAnexos(newAnexos);

    const newAnexosErrors = [...anexosErrors];
    newAnexosErrors[index] = newValue.length < 3 ? `El anexo debe tener al menos 3 caracteres.` : "";
    setAnexosErrors(newAnexosErrors);
  };

  const handleRemoveAnexo = (index) => {
    const newAnexos = anexos.filter((_, i) => i !== index);
    setAnexos(newAnexos);

    if (newAnexos.length === 0) {
      setIsButtonClicked(false);
    }
  };

  const handleActorCheckboxChange = (id) => {
    setSelectedActorCheckboxes((prev) => 
      prev.includes(id) ? prev.filter((checkboxId) => checkboxId !== id) : [id]
    );
    setSelectedDemandadoCheckboxes([]);
    setOtroNombreDemandado("");
  };

  const handleDemandadoCheckboxChange = (id) => {
    setSelectedDemandadoCheckboxes((prev) => 
      prev.includes(id) ? prev.filter((checkboxId) => checkboxId !== id) : [id]
    );
    setSelectedActorCheckboxes([]);
    setOtroNombreActor("");
  };

  const handleOtroNombreActorChange = (e) => {
    const value = onlyNames(e.target.value);
    setOtroNombreActor(value);
    if (value) {
      setOtroNombreDemandado("");
    }
  };

  const handleOtroNombreDemandadoChange = (e) => {
    const value = onlyNames(e.target.value);
    setOtroNombreDemandado(value);
    if (value) {
      setOtroNombreActor("");
    }
  };

  const actores = personas.filter(persona => persona.tipoPartesNombre === "Actor");
  const demandados = personas.filter(persona => persona.tipoPartesNombre === "Demandado");

  

  const isActorSelected = selectedActorCheckboxes.length > 0;
  const isDemandadoSelected = selectedDemandadoCheckboxes.length > 0;

  return (
    <div>
      <form>
        <Grid container spacing={1} sx={{ marginTop: 2 }} className="box-sombra">
          <Grid item xs={12} sm={6} md={2}>
            <Typography>Actor: </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={10}>
            <Typography style={{ color: 'gray' }}>{carpeta.actor}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Typography >Demandado: </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={10}>
            <Typography style={{ color: 'gray' }}>{carpeta.demandado || ''}</Typography>
          </Grid>
        </Grid>

        <Box sx={{ marginTop: 2, marginBottom: 2 }}>
          <Divider />
        </Box>

        <Grid container spacing={2} sx={{ mt: 4 }}>
          <Grid item xs={6}>
            <Card>
              <Typography sx={{ padding: 2 }}>Actores</Typography>
              <List dense component="div" role="list">
                {actores.map((actor) => (
                  <ListItemButton key={actor.tiposPartesId}>
                    <ListItemIcon>
                      <Checkbox
                        checked={selectedActorCheckboxes.includes(actor.tiposPartesId)}
                        tabIndex={-1}
                        disableRipple
                        onChange={() => handleActorCheckboxChange(actor.tiposPartesId)}
                        disabled={isDemandadoSelected}
                      />
                    </ListItemIcon>
                    <ListItemText primary={`${actor.nombre} ${actor.apellidoPaterno || ''} ${actor.apellidoMaterno || ''}`.trim()} />
                  </ListItemButton>
                ))}
              </List>
            </Card>
          </Grid>
          
          <Grid item xs={6}>
            <Card>
              <Typography sx={{ padding: 2 }}>Demandados</Typography>
              <List dense component="div" role="list">
                {demandados.map((demandado) => (
                  <ListItemButton key={demandado.tiposPartesId}>
                    <ListItemIcon>
                      <Checkbox
                        checked={selectedDemandadoCheckboxes.includes(demandado.tiposPartesId)}
                        tabIndex={-1}
                        disableRipple
                        onChange={() => handleDemandadoCheckboxChange(demandado.tiposPartesId)}
                        disabled={isActorSelected}
                      />
                    </ListItemIcon>
                    <ListItemText primary={`${demandado.nombre} ${demandado.apellidoPaterno || ''} ${demandado.apellidoMaterno || ''}`.trim()} />
                  </ListItemButton>
                ))}
              </List>
            </Card>
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Otro nombre actor"
              fullWidth
              value={otroNombreActor}
              onChange={handleOtroNombreActorChange}
              disabled={isDemandadoSelected || otroNombreDemandado}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Otro nombre demandado"
              fullWidth
              value={otroNombreDemandado}
              onChange={handleOtroNombreDemandadoChange}
              disabled={isActorSelected || otroNombreActor}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Typography sx={{ minWidth: '150px' }}>Anexos</Typography>
              <Box sx={{ flexGrow: 2 }}>
                {anexos.map((anexo, index) => (
                  <Grid container key={index} sx={{ mb: 1 }} alignItems="center">
                    <Grid item xs={11}>
                      <TextField
                        label={`Anexo ${index + 1}`}
                        fullWidth
                        value={anexo}
                        onChange={(e) => handleAnexoChange(index, e.target.value)}
                        inputRef={(el) => (anexoRefs.current[index] = el)}
                        helperText={anexosErrors[index] && "El anexo debe tener al menos 3 caracteres"}
                        FormHelperTextProps={{
                          style: {
                            color: anexosErrors[index] ? "#d32f2f" : "inherit",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <IconButton onClick={() => handleRemoveAnexo(index)}>
                        <DeleteIcon className='icon-color' />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}

                <Grid item xs ={12}>
                <Button
                  className="button-validation"
                  startIcon={<AddCircleOutlineIcon />}
                  variant="contained"
                  onClick={handleAddAnexo}
                >
                  Agregar Anexo
                </Button>
                </Grid>
              </Box>
            </Box>
           
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
