import axios from 'axios';

export const getDocumento = async(token, documentoId) =>{
 
  try {
    const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/documentos/digitalizacion/${documentoId}`,{
      responseType: 'arraybuffer',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    
    return response.data;

  } catch (error) {
    console.log('Error al descargar el documento: ', error);
    
    
  }

}

export const UpdateEstatus = async(token, documentoId, status) => {
  try {
     const response = await axios.patch(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/bandeja/entrada/${documentoId}/status/${status}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }
     );

     return response.data;
  } catch (error) {
    console.log('Error al actualizar el estado del documento: ', error);
    
  }
}