import axios from 'axios';

const urlNotificaciones = process.env.REACT_APP_TRIALS_API_URL + '/api/workflow/bandeja/notificaciones';

export const getDetalleNotificacion = async (token, id) => {
    try {
        const response = await axios.get(`${urlNotificaciones}/detalle/${id}`, {
          headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
          },
        });
        return response.data;
    } catch (error) {
        console.error('Error en obtener detalle de Notificacion');
        throw error;
    }
};

export const updateEstadoNotificaciones = async (token, ids, estado) => {
    try {
      const response = await axios.patch(`${urlNotificaciones}/${estado}`, ids, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      return response;
    } catch (error) {
      console.error('Error al actualizar el estatus de las notificaciones:', estado, ids);
      throw error;
    }
  };

  export const updateEstadoDomicilio = async (token, crearActa, file) => {
    try {
      const formData = new FormData();
      formData.append('notificacionActaJson', JSON.stringify(crearActa));
  console.log(formData);
      if (file) {
        formData.append('file', file);
      } else {
        formData.append('file', null);
      }
  
      const response = await axios.post(
        `${urlNotificaciones}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      return response.data;
    } catch (error) {
      console.error('Error al crear la audiencia prueba', error);
      throw error;
    }
  };

  export const fetchDocumentoActa = async (token, notificacionId) => {
    try {
      const response = await axios.get(`${urlNotificaciones}/${notificacionId}/file`, {
        responseType: 'arraybuffer',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
  
    } catch (error) {
      console.error('Error al generar el Prueva:', error);
      throw error;
    }
  };