import { useState, useContext } from "react";
import {
  Box,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import text from "../../../../../assets/Glosario";
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteParticipante } from "../../../../BandejaAsignados/BandejaAsignadosService";
import keycloakConf from "../../../../../api/keycloakConf";
import ConfirmationDialog from "../../../../../components/Alert/ConfirmationDialog";
import ToastAlertDelete from "../../../../../components/Alert/ToastAlertDelete/ToastAlertDelete";
import { useNavigate } from 'react-router-dom';
import NotificationModal from "../../../../../components/Modal/TipoNotificacion";
import QuizIcon from '@mui/icons-material/Quiz';
import { ModalRegistroPrueba } from "../../../../../components/ModalPruebas/ModalPruebas";
const TablaActores = ({ data, tipo, onDelete, isPieza = false, hasNotEtapaProcesal = false, isDisabled, item }) => {
  const [open, setOpen] = useState(false);
  const [openTipoPrueba, setOpenTipoPrueba] = useState(false);
  const { getToken } = useContext(keycloakConf);
  const navigate = useNavigate();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastType, setToastType] = useState('success');
  const [actorToDelete, setActorToDelete] = useState(null);
  const [selectedPersonId, setSelectedPersonId] = useState(null); // Estado para almacenar el ID de la persona seleccionada

  console.log(isDisabled);
  let isEdit = false;

  const handleOpen = (personId) => {
    setSelectedPersonId(personId);
    setOpen(true);
  };

  const handleOpenTipoPrueba = (personId) => {
    setSelectedPersonId(personId);
    setOpenTipoPrueba(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenTipoPrueba(false); 
  };

  const handleDeleteClick = (id, tipo, rol) => {
    if (rol === 'PRINCIPAL') {
      setToastMessage("No es posible eliminar a este participante");
      setToastType("error");
      setToastOpen(true);
    } else {
      const message = `El "${tipo}" seleccionado será permanentemente eliminado del sistema.`;
      setDialogMessage(message);
      setActorToDelete(id);
      setDialogOpen(true);
    }
  };

  const handleConfirmDelete = async () => {
    const token = getToken();
    try {
      await deleteParticipante(token, actorToDelete);
      onDelete(actorToDelete);
      setToastMessage("Actor eliminado con éxito");
      setToastType("success");
    } catch (error) {
      setToastMessage("El actor no fue eliminado");
      setToastType("error");
    }
    setToastOpen(true);
    setDialogOpen(false);
  };


  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleCloseToast = () => {
    setToastOpen(false);
  };

  const handleRowEditClick = (personaId, isEdit) => {
    isEdit = true;
    navigate(`/api/agregar-participante/`, { state: { participanteId: personaId, isEdit, isDisabled, item } });
  };

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer sx={{ maxHeight: 600, overflowY: "auto" }}>
            <Table stickyHeader sx={{ minWidth: 750 }}>
              <TableHead>
                <TableRow>
                  <TableCell className="table-header">{text.Nombre}</TableCell>
                  <TableCell className="table-header">{text.Tipo}</TableCell>
                  {!(isPieza || hasNotEtapaProcesal) && (
                    <TableCell className="table-header">{text.Acciones}</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((persona) => (


                  <StyledTableRow key={persona.id} hover style={{ cursor: isPieza ? "" : "pointer" }}
                    onClick={() => !(isPieza || hasNotEtapaProcesal) && handleRowEditClick(persona.id, isEdit)}
                  >

                    <TableCell>{persona.nombre}</TableCell>
                    <TableCell>{tipo}</TableCell>
                    {!(isPieza || hasNotEtapaProcesal) && (
                      <TableCell>
                        <IconButton
                          onClick={(e) => {
                            handleOpen(persona.id);
                            e.stopPropagation();
                          }}
                        >
                          <NotificationsNoneIcon />
                        </IconButton>
                        <IconButton
                          onClick={(e) => {
                            handleOpenTipoPrueba(persona.id);
                            e.stopPropagation();
                          }}
                        >
                          <QuizIcon />
                        </IconButton>

                        {persona.rol !== "PRINCIPAL" && (
                          <IconButton onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteClick(persona.id, tipo, persona.rol);
                          }} disabled={isDisabled}>
                            <DeleteIcon className="icon-color" />
                          </IconButton>
                        )}


                      </TableCell>
                    )}
                  </StyledTableRow>
                ))}
                {data.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      No hay participantes.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        <NotificationModal
          open={open}
          handleClose={handleClose}
          personId={selectedPersonId}
          isDisabled={isDisabled}
        />
         <ModalRegistroPrueba
          open={openTipoPrueba}
          handleClose={handleClose}
          personId={selectedPersonId}
          item={item}
        />
      </Box>

      <ConfirmationDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDelete}
        message={dialogMessage}
      />

      <ToastAlertDelete
        open={toastOpen}
        onClose={handleCloseToast}
        message={toastMessage}
        type={toastType}
      />


    </>


  );
};

export default TablaActores;
