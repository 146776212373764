import axios from "../../api/axiosConfig";
// Función para obtener todas las salas
export const fetchSalas = async (token, page = 0, size = 10, sort = '', nombre = '') => {
  try {
    const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/core/salas`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page,
        size,
        sort,
        nombre
      },
    });


    return response.data;
  } catch (error) {
    console.error('Error al listar salas:', error);
    throw error;
  }
};

export const create = async (token, nuevaSala) => {
  try {

    const response = await axios.post(process.env.REACT_APP_TRIALS_API_URL + `/api/core/salas`, nuevaSala, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error al agregar sala:', error);
    throw error;
  }
};


export const update = async (token, sala) => {


  console.log(sala)
  try {
    const response = await axios.put(process.env.REACT_APP_TRIALS_API_URL + `/api/core/salas`, sala, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },

    });
    return response;
  } catch (error) {
    console.error('Error al actualizar sala:', error);
    throw error;
  }
};

// Función para eliminar un sala
export const deleteSalas = async (token, id) => {
  try {
    const response = await axios.delete(process.env.REACT_APP_TRIALS_API_URL + `/api/core/salas/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error al eliminar sala:', error);
    throw error;
  }
};

export const fetchSalasById = async (token, id) => {
  try {
    const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/core/salas/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error al obtener la sala:', error);
    throw error;
  }
};


export const findAllBloques = async (token) => {
  try {
    const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/core/bloques`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },

    });


    return response.data.content;
  } catch (error) {
    console.error('Error al listar los bloques:', error);
    throw error;
  }
};


export const fetchSalasbyJuzgado = async (token, idAudiencia, page = 0, size = 10, sort = '', nombre = '') => {
  try {
    const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/core/salas/juzgado/${idAudiencia}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page,
        size,
        sort,
        nombre
      },
    });


    return response.data;
  } catch (error) {
    console.error('Error al listar salas:', error);
    throw error;
  }
};