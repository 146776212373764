import axios from "../../api/axiosConfig";

const urlCarpeta = process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/documento/promocion`;
const urlDemanda = process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/demanda`;
const urlExhortoSalida = process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/exhorto/salida`;
const urlGetAmparo = process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/documentos/amparo`;
const urlUpdateAmparo = process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/documentos/amparo/update`;


// Función para añadir promocion
export const addPromocion = async (token, documentoPromocionRecord, file) => {
  const formData = new FormData();
  formData.append('documentoPromocionRecord', JSON.stringify(documentoPromocionRecord));
  formData.append('file', file);
    try {
        const response = await axios.post(urlCarpeta, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                },
        });
        return response.data;
    } catch (error) {
        console.error('Error al guardar promoción: ', error);
        throw error;
    }
};

// Función para obtener todas las Salidas
export const fetchSalidas = async (token, page = 0, size = 10, sort = '', key = '') => {
    try {
      const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/bandeja/salida`, { 
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page,
          size,
          sort,
          key
        },
      });
  
      return response.data;
    } catch (error) {
      console.error('Error al obtener Salidas:', error);
      throw error;
    }
  };

// Función para dar Salida a TURNADO
export const turnadoDocumento = async (token, body) => {
  try {
    const response = await axios.post(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/bandeja/salida`, body, { 
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error al cambiar estatus de items del carrito:', error);
    throw error;
  }
};


export const fetchIndicadores = async (token, isRecepcion) => {
  try {
      const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/documentos/indicadores`, {
          headers: {
              Authorization: `Bearer ${token}`,
          },
          params: {
            isRecepcion,
          },
      });
      
      return response.data;
  } catch (error) {
      console.error('Error al obtener indicadores:', error);
      throw error;
  }
};

// Cancelar Oficio
export const cancelOficio = async(token, documentoId) => {
  try {
     const response = await axios.patch(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/bandeja/oficio/${documentoId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }
     );

     return response.data;
  } catch (error) {
    console.log('Error al cancelar el oficio: ', error);
    throw error;
  }
}

// Función para obtener todos los oficios
export const fetchOficios = async (token, page = 0, size = 10, sort = '', key = '') => {
  try {
    const params = new URLSearchParams();
        params.append("sort", encodeURI('estatus'));
        params.append("sort", encodeURI('dd.fechaEmision,desc'));
        params.append("page", page);
        params.append("size", size);
        params.append("key", key);

    const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/bandeja/oficios`, { 
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: params,
    });

    return response.data;
  } catch (error) {
    console.error('Error al obtener los oficios:', error);
    throw error;
  }
};

export const movimientoPersonalJuzgado = async (token, record) => {
  try {
      const response = await axios.post(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/bandeja/recepcion/movimiento`, record, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });

      return response.data;
  } catch (error) {
    console.error('Error al realizar movimiento personal en juzgado: ', error);
    throw error;
  }
};

export const turnadoPersonalJuzgado = async (token, records) => {
  try {
      const response = await axios.post(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/bandeja/asignados/movimiento`, records, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });

      return response.data;
  } catch (error) {
    console.error('Error al realizar el turnado del personal juzgado: ', error);
    throw error;
  }
};

export const addAmparo = async (token, data) => {
  try {
      const response = await axios.post(`${process.env.REACT_APP_TRIALS_API_URL}/api/workflow/documentos/amparo`, data, {
              headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json'
              },
      });
      return response.data;
  } catch (error) {
      console.error('Error al guardar el amparo : ', error);
      throw error;
  }
};

export const getCaratulaPieza = async (token, documentoId) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_TRIALS_API_URL}/api/workflow/documentos/${documentoId}/caratula`, {
      responseType: 'arraybuffer',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;

  } catch (error) {
    console.error('Error al generar la caratula:', error);
    throw error;
  }
};
export const updateAnexos = async (token, id, anexos, motivoEdita) => {
  try {
    const data = {
      anexos,
      motivoEdita
    };
    const response = await axios.patch(`${urlDemanda}/${id}/anexos`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error al actualizar anexos:', error);
    throw error;
  }
};

export const fetchInfoPromocion = async (token, id) => {
  try {
      const response = await axios.get(`${urlCarpeta}/${id}`, {
          headers: {
              Authorization: `Bearer ${token}`,
          }
      });
      
      return response.data;
  } catch (error) {
      console.error('Error al obtener la informacion de la promocion:', error, " con id:", id);
      throw error;
  }
};

export const createExhortoSalida = async (token, documentoExhortoSalida, file) => {
  const formData = new FormData();
  formData.append('documentoExhortoSalida', JSON.stringify(documentoExhortoSalida));

  if (file) {
    formData.append('file', file);
  }

  try {
    const response = await axios.post(urlExhortoSalida, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error al crear el exhorto de salida:', error);
    throw error;
  }
};

export const createSentenciaPublica = async (token, idDocumento, file) => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    await axios.post(`${process.env.REACT_APP_TRIALS_API_URL}/api/workflow/documentos/sentencia/publica/${idDocumento}`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      }
    });
  } catch (error) {
    console.error('Error al crear la sentencia pública:', error);
    throw error;
  }
};

export const adjuntarPromocion = async (token, promocionId) => {
  const url = `${process.env.REACT_APP_TRIALS_API_URL}/api/workflow/documentos/promocion/${promocionId}/adjuntar`;
  
  try{
      const response = await axios.post(url, {}, {
          headers:{
              Authorization: `Bearer ${token}`
          }
      });

      return response.data;
  } catch (error) {
      console.error('Error al actualizar la pieza:', error);
      throw error;
  }
  
};

export const getAmparoById = async (token, id) => {
  try {
      const response = await axios.get(`${urlGetAmparo}/${id}`, {
          headers: {
              Authorization: `Bearer ${token}`,
          }
      });
      return response.data;
  } catch (error) {
      console.error('Error al obtener la informacion del amparo:', error, " con id:", id);
      throw error;
  }
};

export const updateAmparo = async (token, id, formData) => {
  try {
    const response = await axios.put(`${urlUpdateAmparo}/${id}`, formData, {
      headers: {
        Authorization: `Bearer ${token}`, 
        'Content-Type': 'application/json'
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error al actualizar amparo:", error);
    throw error; 
  }
}
