import axios from "../../api/axiosConfig";

export const fetchOficialias = async (token, page = 0, size = 25, sort = '', searchQuery = '') => { 
    try {
        const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/core/oficialias`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                page,       
                size,       
                sort,       
                searchQuery 
            },
        });

        return response.data;
    } catch (error) {
        console.error('Error al listar oficialias:', error);
        throw error;
    }
};


export const fetchOficialiasById = async (token, id) => {
    try {
        const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/core/oficialias/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        return response.data; 
    } catch (error) {
        console.error('Error al listar oficialias:', error);
        throw error;
    }
};

// Función para agregar una nueva oficialia
export const addOficialia = async (token, nuevaOficialia) => {
    try {

        const response = await axios.post(process.env.REACT_APP_TRIALS_API_URL + `/api/core/oficialias`, nuevaOficialia, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return response.data; // Devuelve la respuesta al agregar un juzgado
    } catch (error) {
        console.error('Error al agregar oficialia:', error);
        throw error;
    }
};

// Función para actualizar una oficialia
export const updateOficialia = async (token, oficialia) => {
    try {
        const response = await axios.put(process.env.REACT_APP_TRIALS_API_URL + `/api/core/oficialias`, oficialia, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            
        });
        return response.data; // Devuelve la respuesta de la API
    } catch (error) {
        console.error('Error al actualizar oficialia:', error);
        throw error;
    }
};

// Función para eliminar una oficialia
export const deleteOficialia = async (token, id) => {
    try {
        const response = await axios.delete(process.env.REACT_APP_TRIALS_API_URL + `/api/core/oficialias/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });

        return response.data; // Devuelve información sobre la eliminación
    } catch (error) {
        console.error('Error al eliminar oficialia:', error);
        throw error;
    }
};