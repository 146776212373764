import React, { useEffect, useState, useContext } from "react";
import { Grid } from "@mui/material";
import { validateFields } from '../../components/Alert/ValidationRequired/validationRequired';
import keycloakConf from "../../api/keycloakConf";
import FormPromocion from "./Form/FormPromocion";
import FormExpedienteJuzgado from "../../layouts/FormExpedienteJuzgado/FormExpedienteJuzgado";
import { useToastAlert } from '../../components/Alert/ToastAlertSave/ToastAlertSave';
import { useToastAlertValidation } from '../../components/Alert/ToastAlertValidation/ToastAlertValidation';
import { fetchCarpetaActorDemandado } from '../../shared/services/CarpetaService';
import { addPromocion } from '../../shared/services/DocumentoService';
import { getSello } from '../Demanda/DemandaService';
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import { hasRole } from "../../shared/Util";


export default function Promocion() {
  const { getToken } = useContext(keycloakConf);
  const [token, setToken] = useState({});
  const { showSnackbar } = useToastAlert();
  const { showSnackbar: showAlertValidate } = useToastAlertValidation();
  const [errorMessages, setErrorMessages] = useState({});
  const [tipoPromocion, setTipoPromocion] = useState({ tipoPromocion: '' });
  const [modalOpen, setModalOpen] = useState(false);
  const [bandValidate, setBandValidate] = useState(false);
  const [anexos, setAnexos] = useState([]);
  const [displayForm, setDisplayForm] = useState(false);
  const [anexosErrors, setAnexosErrors] = useState([]);

  const [file, setFile] = useState(null);
  const isOficialia = hasRole("CAPTURISTA,OFICIAL_MAYOR_OFICIALIA");
  const irAruta = isOficialia ? undefined : '/api/bandeja/asignados';
  const [formData, setFormData] = useState({
    expediente: '',
    year: '',
    juzgado: null
  });

  const [carpeta, setCarpeta] = useState({
    idCarpeta: 0,
    actor: '',
    demandado: ''
  });


  useEffect(() => {
    const obtenerDatos = async () => {
      try {
        setToken(getToken());
      } catch (error) {
        console.error("Error al obtener el token:", error);
      }
    };

    obtenerDatos();
  }, [getToken]);


  const handleSubmitFEJ = async () => {
    if (bandValidate) {
      const rules = {
        tipoPromocion: { required: true }
      };
      const errors = validateFields(tipoPromocion, rules, showAlertValidate);
      if (Object.keys(errors).length > 0) {
        setErrorMessages(errors);
        return;
      }

      const newAnexosErrors = anexos.map((anexo, index) => anexo.length < 3 ? index + 1 : "");
      setAnexosErrors(newAnexosErrors);

      const errorsAnexos = newAnexosErrors.filter((error) => error !== "");
      if (errorsAnexos.length === 1) {
        const errorMessage = `El anexo ${errorsAnexos.join(", ")} debe tener al menos 3 caracteres.`;
        showSnackbar(errorMessage, "error");
        return;
      } else if (errorsAnexos.length > 0) {
        const errorMessage = `Los anexos ${errorsAnexos.join(", ")} deben tener al menos 3 caracteres.`;
        showSnackbar(errorMessage, "error");
        return;
      }

      setErrorMessages({});

      setModalOpen(true)

    } else {
      const rules = {
        expediente: { required: true },
        year: { required: true },
        juzgado: { required: true },
      };
      const errors = validateFields(formData, rules, showAlertValidate);
      if (Object.keys(errors).length > 0) {
        setErrorMessages(errors);
        return;
      }
      setErrorMessages({});
      showSnackbar('Falta validar expediente, año y juzgado', 'error');
    }

  };

  const handleValidateFEJ = (e) => {
    const rules = {
      expediente: { required: true },
      year: { required: true },
      juzgado: { required: true },
    };
    const errors = validateFields(formData, rules, showAlertValidate);
    if (Object.keys(errors).length > 0) {
      setErrorMessages(errors);
      return;
    }
    searchCarpeta();
    setErrorMessages({});
  };


  const searchCarpeta = async () => {
    try {
      const data = {
        "numExpediente": formData.expediente,
        "year": Number(formData.year),
        "idJuzgado": formData.juzgado.id
      };
      const response = await fetchCarpetaActorDemandado(token, data);
      setCarpeta({
        idCarpeta: response.idCarpeta,
        actor: response.actor,
        demandado: response.demandado
      });

      setBandValidate(true);

    } catch (error) {
      setBandValidate(false);
      showSnackbar('No existe expediente relacionado con la información ingresada', 'error');
    }
  };

  const clearFields = () => {
    setFormData({
      expediente: '',
      year: '',
      juzgado: null
    });
    setAnexos([]);
    setCarpeta({
      idCarpeta: 0,
      actor: '',
      demandado: ''
    });
    setTipoPromocion({ tipoPromocion: '' });
    setBandValidate(false);
  };

  const handleFileSelect = (selectedFile) => {
    setFile(selectedFile);
  };
  const handleConfirm = async () => {
    const token = await getToken();
    const nuevoDocumento = {
      carpetaId: carpeta.idCarpeta,
      tipoPromocion: tipoPromocion.tipoPromocion,
      anexos: anexos
    };

    if (!file && !isOficialia) {
      showSnackbar("Por favor, carga el archivo antes de continuar", "error");
      return;
    }
    try {
      file = (isOficialia) ? null : file;
      let  documento = await addPromocion(token, nuevoDocumento, file);

      showSnackbar("Promoción generada con éxito", "success");

      const sello = await getSello(token, documento.id);
      var blob = new Blob([sello], { type: "application/pdf" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.target = "sello.pdf";
      link.click();

      clearFields();
    } catch (error) {
      console.error("Error al guardar promoción.");
      showSnackbar("¡Algo salió mal!", "error");
    }

    setModalOpen(false);
  };

  return (
    <>
      <ConfirmationModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={handleConfirm}
        title="¿Generar promoción?"
        message="Se generará una nueva promoción en el sistema con la información proporcionada."
        confirmationButton="Sí, generar"
        closeButton="No, cancelar"
      />

      <Grid item xs={12} sm={6} md={6}>
        <FormExpedienteJuzgado
          handleSubmit={handleSubmitFEJ}
          handleValidate={handleValidateFEJ}
          formData={formData}
          setFormData={setFormData}
          errorMessages={errorMessages}
          irAruta={irAruta}
          isDisabled={bandValidate}
        />
      </Grid>
      {bandValidate && (
        <FormPromocion
          carpeta={carpeta}
          tipoPromocion={tipoPromocion}
          setTipoPromocion={setTipoPromocion}
          errorMessages={errorMessages}
          anexos={anexos}
          setAnexos={setAnexos}
          onFileSelect={handleFileSelect}
          isOficialia={isOficialia}
        />
      )}
    </>
  );
}
