import React, { useState, useEffect, useContext } from 'react';
import { fetchAudienciasPruebas } from '../../../shared/AudienciasService';
import TablaDetallesPruebas from './Tables/TablaDetallesPruebas';
import keycloakConf from '../../../api/keycloakConf';

const DetallesPruebas = ({ item, isConsulta, rows }) => {
  const [audienciaPrueba, setAudienciaPrueba] = useState([]);
  const [page, setPage] = useState(0); // Página actual
  const [rowsPerPage, setRowsPerPage] = useState(25); // Filas por página
  const [pageable, setPageable] = useState([]);
  const { getToken } = useContext(keycloakConf);
  const token = getToken();

  useEffect(() => {
    loadTiposAudiencia();
  }, [token, page, rowsPerPage, item.id]); 

  const loadTiposAudiencia = async () => {
    try {
      const data = await fetchAudienciasPruebas(token, page, rowsPerPage, item.id);
      setAudienciaPrueba(data.content);
      setPageable(data.page)
    } catch (error) {
      console.error("Error al cargar los datos:", error);
    }
  };

  return (
    <>
      <TablaDetallesPruebas
        token={token}
        rows={audienciaPrueba} // Pasa los datos cargados a la tabla
        pageable={pageable}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        onEdit={(row) => console.log('Editar:', row)} // Tu lógica de edición
      />
    </>
  );
};

export default DetallesPruebas;
