import { Box, Typography, TextField, Button, RadioGroup, FormControlLabel, Radio, Divider, Grid } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import keycloakConf from "../../api/keycloakConf";
import { fetchLibroGobierno } from "../../shared/services/CarpetaService";
import TableLibroGobierno from "./Grid/TableLibroGobierno";
import SearchInput from "../../components/Search/SearchInput";
import { useNavigate } from "react-router-dom";
import { useItem } from "../../components/ItemProvider/ItemContext"; 

const LibroGobierno = () => {
  const [bandejas, setData] = useState([]);
  const [filteredBandejas, setFilteredBandejas] = useState([]);
  const [filter, setFilter] = useState("");
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState("");
  const { getToken } = useContext(keycloakConf);
  const [loading, setLoading] = useState(true);
  const [pageable, setPageable] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searchQuery, setSearchQuery] = useState('');
  const [numExpediente, setNumExpediente] = useState("");
  const [actorDemandado, setActorDemandado] = useState("");
  const [cujus, setCujus] = useState("");
  const navigate = useNavigate();
  const { item, setItem } = useItem();

  useEffect(() => {
    loadLibroGobierno();
  }, [getToken, page, rowsPerPage]);


  const loadLibroGobierno = async () => {
    setLoading(true);
    const token = getToken();
    try {
      const libroGobiernoData = await fetchLibroGobierno(token, page, rowsPerPage, searchQuery); 

      const data = libroGobiernoData.content.map((item)=>({
        ...item,
        carpetaId: item.id
      }));

      setData(data);
      setFilteredBandejas(data);
      setPageable(libroGobiernoData.page);
    } catch (error) {
      console.error('Error cargando registros:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchFecha = (filteredData) => {
    if (fechaInicio || fechaFin) {
      filteredData = filteredData.filter((bandeja) => {
        const fecha = new Date(bandeja.fechaHora);
    
        const inicio = fechaInicio ? new Date(fechaInicio) : null;
        const fin = fechaFin ? new Date(fechaFin) : null;
    
        const localFecha = new Date(fecha.getTime() - fecha.getTimezoneOffset() * 60000);
        const bandejaDate = localFecha.toISOString().split('T')[0];
        const inicioFormatted = inicio ? inicio.toISOString().split('T')[0] : null;
        const finFormatted = fin ? fin.toISOString().split('T')[0] : null;
   
        return (
          (!inicioFormatted || bandejaDate >= inicioFormatted) &&
          (!finFormatted || bandejaDate <= finFormatted)
        );
      });
    }

    return filteredData;
  };

  const handleSearchExpediente = (filteredData) => {
    if (numExpediente) {
      filteredData = filteredData.filter((bandeja) => {
        return bandeja.numExpediente.toLowerCase().includes(numExpediente.toLowerCase());
      });
    }
    return filteredData;
  };

  const handleSearchActorDemandado = (filteredData) => {
    if (actorDemandado) {
      filteredData = filteredData.filter((bandeja) => {
        return (
          bandeja.actor.toLowerCase().includes(actorDemandado.toLowerCase()) || 
          bandeja.demandado.toLowerCase().includes(actorDemandado.toLowerCase())
        );
      });
    }
    return filteredData;
  };

  const handleSearchCujus = (filteredData) => {
    if (cujus) {
      filteredData = filteredData.filter((bandeja) => {
        return bandeja.cujus.toLowerCase().includes(cujus.toLowerCase());
      });
    }
    return filteredData;
  };

  const handleSearch = () => {
    let filteredData = bandejas;
    filteredData = handleSearchFecha(filteredData);
    filteredData = handleSearchExpediente(filteredData);
    filteredData = handleSearchActorDemandado(filteredData);
    filteredData = handleSearchCujus(filteredData);
    setFilteredBandejas(filteredData);
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);

    if (newFilter !== "fechaExpediente") {
      setFechaInicio("");
      setFechaFin("");
    }
    if (newFilter !== "expediente") {
      setNumExpediente("");
    }
    if (newFilter !== "actorDemandado") {
      setActorDemandado("");
    }
    if (newFilter !== "cujus") {
      setCujus("");
    }
  };

  const verExpediente = (expediente) =>{
    if(expediente.tipoEntrada !== 'Promocion' && expediente.estatus !== 'Turnado'){
      setItem(expediente);
      navigate('/api/bandeja/expediente', { state: { item: expediente, tabnav: 0, isConsulta: !expediente.asignado  } });
    }
  }

  return (
    <>
      <Box mb={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Button variant="contained" className="button-save" onClick={handleSearch}>
            Buscar
          </Button>
          <Box flexBasis="300px" ml={2}>
            <SearchInput
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Buscar..."
                ariaLabel="Campo de búsqueda para libro de gobierno"
                tooltipTitle="Escriba para buscar"
                onKeyDown={(e) => e.key === 'Enter' && loadLibroGobierno()}
            />
          </Box>
        </Box>

        <Box sx={{ marginTop: 2, marginBottom: 2 }}>
            <Divider />
        </Box>
        <Grid className="box-sombra">
        <Box display="flex" alignItems="center" mt={2}>
        <Typography variant="subtitle1" mr={3}>Buscar por:</Typography>
          <RadioGroup row value={filter} onChange={(e) => handleFilterChange(e.target.value)}>
            <FormControlLabel value="fechaExpediente" control={<Radio />} label="Fecha" />
            <FormControlLabel value="expediente" control={<Radio />} label="Expediente" />
            <FormControlLabel value="actorDemandado" control={<Radio />} label="Actor o demandado" />
            <FormControlLabel value="cujus" control={<Radio />} label="CUJUS" /> 
          </RadioGroup>
        </Box>

        {filter === "fechaExpediente" && (
          <Box display="flex" alignItems="center" mt={2} gap={2}>
          <TextField
            label="Fecha inicio"
            type="date"
            value={fechaInicio}
            InputLabelProps={{ shrink: true }}
            size="small"
            onChange={(e) => setFechaInicio(e.target.value)}
          />
          <TextField
            label="Fecha fin"
            type="date"
            value={fechaFin}
            InputLabelProps={{ shrink: true }}
            size="small"
            onChange={(e) => setFechaFin(e.target.value)}
          />
        </Box>
        )}

        {filter === "expediente" && (
          <Box display="flex" alignItems="center" mt={2}>
            <TextField
              label="Expediente"
              value={numExpediente}
              onChange={(e) => setNumExpediente(e.target.value)}
              size="small"
              sx={{ mr: 2, width: '400px' }}
              onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
            />
          </Box>
        )}

        {filter === "actorDemandado" && (
          <Box display="flex" alignItems="center" mt={2}>
            <TextField
              label="Nombre del actor o demandado"
              value={actorDemandado}
              onChange={(e) => setActorDemandado(e.target.value)}
              size="small"
              sx={{ mr: 2, width: '400px' }}
              onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
            />
          </Box>
        )}

        {filter === "cujus" && (
          <Box display="flex" alignItems="center" mt={2}>
            <TextField
              label="CUJUS"
              value={cujus}
              onChange={(e) => setCujus(e.target.value)}
              size="small"
              sx={{ mr: 2, width: '400px' }}
              onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
            />
          </Box>
        )}
      <Box>
        {loading ? <p>Cargando ... </p> : (
          <TableLibroGobierno
            bandejas={filteredBandejas}
            token={getToken()}
            loadBandejas={loadLibroGobierno}
            pageable={pageable}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            verExpediente={verExpediente}
          />
        )}
      </Box>
      </Grid>
      </Box>

    </>
  );
};

export default LibroGobierno;
