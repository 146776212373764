import axios from "../api/axiosConfig"

export const fetchAudienciasGenerales = async (token, page = 0, size = 20, sort = '', key = '') => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_TRIALS_API_URL}/api/workflow/bandeja/audienciasgenerales`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page,
          size,
          sort,
          key,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error al obtener audiencias generales:', error);
    throw error;
  }
};


export const removeAudiencia = async (token, id) => {
  try {
      const response = await axios.delete(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/bandeja/audienciasgenerales/${id}`, {
          headers: {
              Authorization: `Bearer ${token}`,
          },
      });

      return response.data;
  } catch (error) {
      console.error('Error al eliminar el registro:', error);
      throw error;
  }
};

export const diferirAudiencia = async (token, id) => {
  try {
    const response = await axios.patch(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/bandeja/audienciasgenerales/diferir/${id}`, 
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
    });
      
    return response.data;
  } catch (error) {
    console.error('Error al diferir la audiencia:', error);
    throw error;
  }
};

export const fetchAudienciasMotivos = async (token) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_TRIALS_API_URL}/api/workflow/audiencias/motivos`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error al obtener los motivos de audiencias:', error);
    throw error;
  }
};

export const fetchEstatusAudiencias = async (token) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_TRIALS_API_URL}/api/workflow/audiencias/estatus`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error al obtener los estatus de audiencias:', error);
    throw error;
  }
};

export const setHoraAudiencia = async (token, idAudiencia, hora, inicio) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_TRIALS_API_URL}/api/workflow/audiencias/horaInicio`,
      {
        idAudiencia,
        hora,
        isInicio: inicio,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error al actualizar la hora de la audiencia:', error);
    throw error;
  }
};

export const audienciaTabGeneral = async (token, audienciaTabGeneralRecord) => {
  try {
    const response = await axios.patch(
      `${process.env.REACT_APP_TRIALS_API_URL}/api/workflow/audiencias/tabGeneral`,
      audienciaTabGeneralRecord,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error al actualizar la audiencia en la pestaña general:', error);
    throw error;
  }
};

export const createActaMinima = async (token, documentoNuevo, audienciaId) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_TRIALS_API_URL}/api/workflow/audiencias/tabGeneral/${audienciaId}`,
      documentoNuevo,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error al actualizar la audiencia en la pestaña general:', error);
    throw error;
  }
};

export const getActaMinima = async(token, audienciaId) =>{
 
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_TRIALS_API_URL}/api/workflow/audiencias/tabGeneral/${audienciaId}`,
      {
        responseType: 'arraybuffer',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;

  } catch (error) {
    console.log('Error al descargar el documento: ', error);
    
    
  }

}

export const fetchAudienciasPruebas = async (token, page = 0, size = 20, id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_TRIALS_API_URL}/api/workflow/audiencias-pruebas/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page,
          size,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error al obtener Detalles de Pruebas:', error);
    throw error;
  }
};

export const fetchDocumentoPrueba = async (token, documentoId) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_TRIALS_API_URL}/api/workflow/audiencias-pruebas/pruebaDoc/${documentoId}`, {
      responseType: 'arraybuffer',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;

  } catch (error) {
    console.error('Error al generar el Prueva:', error);
    throw error;
  }
};


export const fetchDesistimientoAdmision = async (token, id, desAdm) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_TRIALS_API_URL}/api/workflow/audiencias-pruebas/${id}`,
      desAdm, // Estatus 'desAdm' enviado en el cuerpo de la solicitud
      {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error al realizar la solicitud:', error);
    throw error;
  }
};