import { useContext, useEffect, useState } from "react";
import keycloakConf from "../../../../../api/keycloakConf";
import Acciones from "../../../../../components/Acciones/Acciones";
import { useToastAlertValidation } from "../../../../../components/Alert/ToastAlertValidation/ToastAlertValidation";
import { validateFields } from "../../../../../components/Alert/ValidationRequired/validationRequired";
import { onlyAlphaNumericSpace, onlyNumbers } from "../../../../../components/Caracteres/caracteresEspeciales";
import { Box, Grid, Tab, Tabs } from "@mui/material";
import General from "./Tabs/General";
import AcuerdoSentenciaTab from "./Tabs/AcuerdoSentenciaTab";
import { useNavigate, useLocation } from "react-router-dom";
import { crearAcuerdo, fetchActualizarAcuerdo, fetchActualizarSentencia, crearSentencia, fetchEtapasProcesaes, fetchPromocionesCarpeta, fetchPublicarAcuerdo, fetchPublicarSentencia, fetchRubrosAutoComplete, fetchTipoAcuerdos } from "./AcuerdoService";

const CrearAcuerdo = () => {

    const { getToken } = useContext(keycloakConf);
    const location = useLocation();
    const { item, isSentencia, data, isDisabled  } = location.state || {};
    
    const [errors, setErrors] = useState({});
    const tipoJuicio = 100; //TODO: debe de enviarse desde el grid, AUN NO SE HA VALIDADO COMO HACERLO.
    const { showSnackbar: showAlertValidate } = useToastAlertValidation();
    const [etapasProcesales, setEtapasProcesales] = useState([]);
    const [tiposAcuerdos, setTiposAcuerdos] = useState([]);
    const [promociones, setPromociones] = useState([]);
    const [rubros, setRubros] = useState([]);
    const navigate = useNavigate();
    const [tabvalue, setTabValue] = useState(0);
    const formatos = {
        'numeroExpediente': onlyNumbers,
        'anioExpediente': onlyNumbers,
        'asunto': onlyAlphaNumericSpace
    }
    const [formdata, setFormData] = useState({
        general: {
            tipoAcuerdo: data?.tipoAcuerdo || "",
            fechaResolucion: data?.fechaResolucion || "",
            etapaProcesal: data?.etapaProcesal || "",
            resumen: data?.resumen || "",
            rubros: data?.rubros || [],
            promocionesRelacionadas:  [],
            tipoSentencia: data?.tipoSentencia || "",
            tipoResolucion: data?.tipoResolucion || "",
            extractoSentencia: data?.extractoSentencia || ""

        },
        acuerdo: {
            sentenciaId: data?.sentenciaId || null,
            acuerdoId: data?.acuerdoId || null,
            tamanioPapel: data?.tamanioPapel || "",
            textoEditor: data?.textoEditor || "",
            acuerdoPublicado: ""
        }
    });

    useEffect(() => {
        // Cargar etapas procesales
        loadData({
            fetchFunction: () => fetchEtapasProcesaes(getToken(), tipoJuicio),
            setFieldState: setEtapasProcesales,
            typeData: "Etapas procesales"
        });
    
        if (!isSentencia) {
            // Cargar rubros
            loadData({
                fetchFunction: () => fetchRubrosAutoComplete(getToken(), 0, 200, item.carpetaId, ""),
                setFieldState: setRubros,
                typeData: "Rubros"
            });
    
            // Cargar tipos de acuerdos
            loadData({
                fetchFunction: () => fetchTipoAcuerdos(getToken(), item.carpetaId),
                setFieldState: setTiposAcuerdos,
                typeData: "Tipos de acuerdos"
            });
        }
        
        loadPromociones();
    }, []);

    const loadData = async ({ fetchFunction, setFieldState, typeData, setFieldPagination, onFinally }) => {
        try {
            const response = await fetchFunction();
    
            // Manejo del contenido principal
            const data = response?.content || response || [];
            await setFieldState(data);
    
            // Manejo de la paginación si la hay
            if (setFieldPagination && response?.page) {
                setFieldPagination(response.page);
            }
    
        } catch (error) {
            console.error(`Error al obtener los ${typeData}:`, error);
        } finally {
            onFinally?.();
        }
    };
    


    const loadPromociones = async () => {        
        const token = getToken();
        let documentoId = formdata.acuerdo.acuerdoId || formdata.acuerdo.sentenciaId ?  formdata.acuerdo.acuerdoId || formdata.acuerdo.sentenciaId : null;
        let tipoDocumento = isSentencia ? "SENTENCIA" : "ACUERDO";

        try {
            
            let promociones = formdata.general.promocionesRelacionadas.length > 0 ? data.promocionesRelacionadas : await fetchPromocionesCarpeta(token, item.carpetaId, documentoId, tipoDocumento);

            let promocionesSeleccionadas = promociones.filter(item => item.seleccionado === 1);

            setFormData(prevState => ({
                ...prevState,
                general: {
                    ...prevState.general,
                    promocionesRelacionadas: promocionesSeleccionadas
                }
            }));

            setPromociones(promociones);
        } catch (error) {
            console.log("Ha ocurrido un error: ", error);

        }
    }

    const handlerSave = async ({ tipo = 'acuerdo', publicar = false }) => {
        const isAcuerdo = tipo === 'acuerdo';

        try {

            const dataSend = {
                ...(isAcuerdo
                    ? { acuerdoId: formdata.acuerdo.acuerdoId }
                    : { sentenciaId: formdata.acuerdo.sentenciaId }),
                carpetaId: item.carpetaId,
                documentoId: item.id,
                tipoAcuerdo: isAcuerdo ? formdata.general.tipoAcuerdo : undefined,
                tipoSentencia: !isAcuerdo ? formdata.general.tipoSentencia : undefined,
                fechaResolucion: formdata.general.fechaResolucion,
                etapaProcesal: formdata.general.etapaProcesal,
                rubros: isAcuerdo ? formdata.general.rubros : undefined,
                promocionesRelacionadas:  formdata.general.promocionesRelacionadas,
                tipoResolucion: !isAcuerdo ? formdata.general.tipoResolucion : undefined,
                extractoSentencia: !isAcuerdo ? formdata.general.extractoSentencia : undefined,
                tamanioPapel: formdata.acuerdo.tamanioPapel,
                textoEditor: formdata.acuerdo.textoEditor,
                resumen: isAcuerdo ? formdata.general.resumen : undefined,
            };

            const handleSuccess = (message) => {
                showAlertValidate(message, 'success', '¡Excelente!');
            };

            let response;
            if (publicar) {
                response = await (isAcuerdo ? fetchPublicarAcuerdo : fetchPublicarSentencia)(getToken(), dataSend);
                navigate('/api/workflow/acuerdo/acuerdoNotificacion', { state: { item: item, data:response } });
                handleSuccess(`${isAcuerdo ? 'Acuerdo' : 'Sentencia'} publicada con éxito`);
            } else if (isAcuerdo ? formdata.acuerdo.acuerdoId : formdata.acuerdo.sentenciaId) {
                response = await (isAcuerdo ? fetchActualizarAcuerdo : fetchActualizarSentencia)(getToken(), dataSend);
                handleSuccess(`${isAcuerdo ? 'Acuerdo' : 'Sentencia'} actualizada con éxito`);
            } else {
                response = await (isAcuerdo ? crearAcuerdo : crearSentencia)(getToken(), dataSend);
                setFormData((prevState) => ({
                    ...prevState,
                    acuerdo: {
                        ...prevState.acuerdo,
                        ...(isAcuerdo ? { acuerdoId: response.id } : { sentenciaId: response.id }),
                    },
                }));
                loadPromociones();
                handleSuccess(`${isAcuerdo ? 'Acuerdo' : 'Sentencia'} generada con éxito`);
            }
        } catch (error) {
            console.error(`Error al guardar el ${isAcuerdo ? 'acuerdo' : 'sentencia'}`, error);
        }
    };



    const validacionCampos = (publicar) => {

        let rules = {
            "general.tipoAcuerdo": { required: !isSentencia },
            "general.fechaResolucion": { required: true },
            "general.etapaProcesal": { required: true },
            "general.rubros": { required: !isSentencia },
            "general.resumen": { required: !isSentencia },
            "general.tipoSentencia": { required: isSentencia },
            "general.tipoResolucion": { required: isSentencia },
            "general.extractoSentencia": { required: isSentencia },
            "acuerdo.tamanioPapel": { required: true },
            "acuerdo.textoEditor": { required: true }

        }

        let validationErrors = validateFields(formdata, rules, showAlertValidate);

        //valdiaciones de arreglos:
        if (formdata.general.rubros.length === 0 && !isSentencia) {
            validationErrors['general.rubros'] = "Es necesario elegir al menos un rubro";
        }

        const erroresGeneral = Object.keys(validationErrors).some(key => key.includes('general'));
        const erroresAcuerdoTab = Object.keys(validationErrors).some(key => key.includes('acuerdo'));

        if (erroresGeneral) { setTabValue(0) }
        else if (erroresAcuerdoTab) { setTabValue(1) }

        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            const tipo = isSentencia ? "sentencia" : "acuerdo";
            handlerSave({ tipo, publicar });
        }

    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;


        // Verificar si el campo es uno que necesita la función de limpieza
        const caracterValue = (formatos[name] || ((val) => val))(value);

        // Dividir el name para saber en qué parte del objeto anidado debe ir el valor
        const nameParts = name.split('.');  // Dividimos por el punto: ["general", "acuerdo"]

        setFormData((prevState) => {
            let newFormData = { ...prevState };  // Clonamos el estado actual

            // Navegamos hasta el nivel correcto del objeto anidado
            let currentLevel = newFormData;
            for (let i = 0; i < nameParts.length - 1; i++) {
                currentLevel = currentLevel[nameParts[i]];
            }

            // Actualizamos el campo correcto
            currentLevel[nameParts[nameParts.length - 1]] = caracterValue;

            return newFormData;
        });

    };

    const handleTabChange = (event, tabnewValue) => {
        setTabValue(tabnewValue);
    }

    const handleCancelButton = () => {
        if(item.routeNotificaciones){
            navigate('/api/bandeja/notificaciones', {
                state:{ estado:item.estado, tipo: item.tipo }
            })
        }else{
            navigate('/api/bandeja/expediente', {
                state: { item: item, tabnav: 2 }
            })
        }
    }

    return (
        <>
            <Acciones accion1={() => { validacionCampos(false) }} accion2={handleCancelButton} textoAccion2="CERRAR" isVisibleBtn1={ !isDisabled } />

            <Box display="flex" ml={2} mr={2} justifyContent="space-between" alignItems="center">
                <Grid container spacing={2} sx={{ mt: 2 }}>
                    <Grid item xs={8}>
                        <Tabs value={tabvalue} onChange={handleTabChange}>
                            <Tab label="General" />
                            <Tab label={isSentencia ? "Sentencia" : "Acuerdo"} />
                        </Tabs>
                    </Grid>
                    <Grid item xs={4}></Grid>

                    {tabvalue === 0 && (
                        <General
                            formData={formdata}
                            setFormData={setFormData}
                            handleInputChange={handleInputChange}
                            errors={errors}
                            etapasProcesales={etapasProcesales}
                            tiposAcuerdos={tiposAcuerdos}
                            promociones={promociones}
                            rubros={rubros}
                            isSentencia={isSentencia}
                            isDisabled={isDisabled}
                        />
                    )}

                    {tabvalue === 1 && (
                        <AcuerdoSentenciaTab
                            formData={formdata}
                            handleInputChange={handleInputChange}
                            setFormData={setFormData}
                            errors={errors}
                            validacionCampos={validacionCampos}
                            isSentencia={isSentencia}
                            isDisabled={isDisabled}
                        />
                    )}

                </Grid>
            </Box>



        </>
    )
}

export default CrearAcuerdo;