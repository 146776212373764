import React, { useState, useEffect, useContext, useRef } from "react";
import {
    Box,
    Grid,
    Typography
} from "@mui/material";

import { useLocation, useNavigate } from "react-router-dom";
import {
    useToastAlertValidation,
} from "../../../components/Alert/ToastAlertValidation/ToastAlertValidation.js";
import SweetAlertSave from "../../../components/Alert/SweetAlertSave/SweetAlertSave.js";
import { getExhortoById } from "../ExhortoService.js";
import KeycloakConf from "../../../api/keycloakConf";
import { getSello } from "../../Demanda/DemandaService.js";
import ConfirmationModal from "../../../components/Modal/ConfirmationModal";
import Acciones from "../../../components/Acciones/Acciones.js";
import CamposExhorto from "./RegistroExhorto/CamposExhorto.js";
import AnexosExhorto from "./RegistroExhorto/AnexosExhorto.js";
import MotivoEdicion from "./RegistroExhorto/MotivoEdicion.js";
import { updateAnexos } from "../../Demanda/DemandaService.js";
import { validateFields } from "../../../components/Alert/ValidationRequired/validationRequired.js";
function EditFormExhorto() {

    const { getToken } = useContext(KeycloakConf);
    const { showSnackbar } = useToastAlertValidation();
    const [demandaData, setDemandaData] = useState(null);
    const { showSnackbar: showAlertValidate } = useToastAlertValidation();
    const [razonEdicion, setRazonEdicion] = useState("");
    const [formData, setFormData] = useState({
        tipoExhorto: "",
        procedencia: "",
        observaciones: "",
        anexos: [],
    });


    const anexoRefs = useRef([]);
    const [anexosErrors, setAnexosErrors] = useState([]);
    const [isEditMode, setIsEditMode] = useState(false);
    const [errors, setErrors] = useState({});

    const [touched, setTouched] = useState({
        tipoExhorto: false,
        procedencia: true,
        observaciones: false,
    });

    const [isEditAnexos, setIsEditAnexos] = useState(false);
    const [isButtonClicked, setIsButtonClicked] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const [fechaHoraActual, setFechaHoraActual] = useState({
        fecha: "",
        hora: "",
    });

    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date();
            setFechaHoraActual({
                fecha: now.toLocaleDateString(),
                hora: now.toLocaleTimeString(),
            });
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    const location = useLocation();

    const navigate = useNavigate();

    const { state } = location;
    const { documentoId } = state || {};

    const tiposExhortos = [
        "Exhorto",
        "Carta rogatoria",
        "Despacho",
        "Cooperación Judicial e Internacional",
        "Oficio",
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });

        if (errors.procedencia) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                procedencia: "",
            }));
        }
    };


    const validateForm = () => {
        const rules = {
            razonEdicion: { required: true },
            procedencia: { required: true },
        };
        const dataToValidate = {
            razonEdicion: razonEdicion,
            procedencia: formData.procedencia,
        };
        const edicionErrors = validateFields(dataToValidate, rules, showAlertValidate);

        setErrors({
            ...edicionErrors,
        });

        return Object.keys(edicionErrors).length === 0;
    };


    const handleButtonClick = async (e) => {
        e.preventDefault();

        const isValid = validateForm();
        if (!isValid) return;

        const newAnexosErrors = formData.anexos.map((anexo, index) => anexo.length < 3 ? index + 1 : "");
        setAnexosErrors(newAnexosErrors);

        const errors = newAnexosErrors.filter((error) => error !== "");
        if (errors.length === 1) {
            const errorMessage = `El anexo ${errors.join(", ")} debe tener al menos 3 caracteres.`;
            showSnackbar(errorMessage, "error");
            return;
        } else if (errors.length > 0) {
            const errorMessage = `Los anexos ${errors.join(", ")} deben tener al menos 3 caracteres.`;
            showSnackbar(errorMessage, "error");
            return;
        }

        setModalOpen(true);
    };

    const handleConfirm = async () => {
        try {
            const token = getToken();
            
            await updateAnexos(token, documentoId, formData.anexos, razonEdicion, formData.procedencia);
            showSnackbar("Anexos actualizados con éxito", "success");
            const sello = await getSello(token, documentoId);
            var blob = new Blob([sello], { type: "application/pdf" });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.target = "sello.pdf";
            link.click();
            navigate("/api/bandeja/entrada");
        } catch (error) {
            if (error.response && error.response.data[0]) {
                showSnackbar(error.response.data[0].message, "error");
            } else {
                showSnackbar("¡Algo salió mal!", "error");
            }
        } finally {
            setModalOpen(false);
        }
    };

    const handleSubmit = async () => {
        SweetAlertSave.showSuccess();
    };

    const handleAnexoChange = (index, value) => {
        const newAnexos = [...formData.anexos];
        newAnexos[index] = value;
        setFormData({ ...formData, anexos: newAnexos });

        const newAnexosErrors = [...anexosErrors];
        newAnexosErrors[index] = value.length >= 3 ? "" : `El anexo ${index + 1} debe tener al menos 3 caracteres.`;
        setAnexosErrors(newAnexosErrors);
    };

    const handleAddAnexo = () => {
        setFormData({ ...formData, anexos: [...formData.anexos, ""] });
        setIsButtonClicked(true);

        setTimeout(() => {
            const lastAnexoRef = anexoRefs.current[formData.anexos.length];
            if (lastAnexoRef) lastAnexoRef.focus();
        }, 0);
    };

    const handleRemoveAnexo = (index) => {
        const newAnexos = formData.anexos.filter((_, i) => i !== index);
        setFormData({ ...formData, anexos: newAnexos });

        if (newAnexos.length === 0) {
            setIsButtonClicked(false);
        }
    };

    const isEditRoute = location.pathname === "/api/workflow/exhorto";

    const handleClose = async () => {
        navigate("/api/bandeja/entrada");
    };

    const handleTipoExhortoChange = (event) => {
        setFormData({
            ...formData,
            tipoExhorto: event.target.value,
        });
    };

    useEffect(() => {

        const fetchExhorto = async () => {
            const token = getToken();

            if (documentoId && token) {
                setIsEditMode(true);
                try {
                    const data = await getExhortoById(token, documentoId);
                    setFormData({
                        tipoExhorto: data.tipoJuicio || "",
                        procedencia: data.exhortoProcedencia || "",
                        observaciones: data.exhortoObservaciones || "",
                        anexos: data.anexos || [],
                    });

                } catch (error) {
                    console.error("Error al obtener el exhorto:", error);
                }
            }
        };

        fetchExhorto();
    }, [documentoId, getToken]);

    return (
        <form onSubmit={handleSubmit}>
            <Grid container>
                <Acciones accion1={handleButtonClick} accion2={handleClose} />

                <Grid>
                    <Box sx={{ p: 3, mx: "auto" }}>
                        <Grid container spacing={2}>

                            <CamposExhorto
                                formData={formData}
                                handleTipoExhortoChange={handleTipoExhortoChange}
                                handleChange={handleChange}
                                tiposExhortos={tiposExhortos}
                                errors={errors}
                                touched={touched}
                                isDisabled={isEditMode}
                            />

                            <MotivoEdicion
                                razonEdicion={razonEdicion}
                                setRazonEdicion={setRazonEdicion}
                                errors={errors}
                                setErrors={setErrors}
                                isEditRoute={isEditRoute}
                            />

                            <Grid container spacing={12}>
                                <Grid item xs={12} sm={1} mt={2}>
                                    <Typography>Anexos: </Typography>
                                </Grid>
                                <AnexosExhorto
                                    formData={formData}
                                    handleAnexoChange={handleAnexoChange}
                                    handleRemoveAnexo={handleRemoveAnexo}
                                    handleAddAnexo={handleAddAnexo}
                                    anexosErrors={anexosErrors}
                                    anexoRefs={anexoRefs}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>

            <ConfirmationModal
                open={modalOpen && !isEditAnexos}
                onClose={() => setModalOpen(false)}
                onConfirm={handleConfirm}
                title="¿Actualizar anexos?"
                message="Se actualizarán los anexos con el motivo de la edición en el sistema."
                confirmationButton="Sí, generar"
                closeButton="No, cancelar"
            />
        </form>
    );
}

export default EditFormExhorto;
