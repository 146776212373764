const validCombinations = {
  "Actor_fisica": [
    "nombre",
    "apellidoPaterno",
    "apellidoMaterno",
    "pseudonimo",
    "sexo",
    "fechaNacimiento",
    "nacionalidad",
    "edad",
    "rfc",
    "curp",
    "estadoCivil",
    "paisNacimiento",
    "entidadNacimiento",
    "municipioNacimiento",
    "escolaridad",
    "discapacidad",
    "condicionMigratoria",
    "hablaEspanol",
    "lenguaIndigena",
    "hablaLenguaExtranjera",
    "grupoVulnerable",
    "sabeLeer",
    "profesion",
    "recibePercepciones",
    "lugarTrabajo",
    "tieneDependientes",
    "bienes",
    "tieneReligion",
    "tieneSeniasParticulares",
    "grupoEtnico",
    "datosPrivados",
    "documentoIdentificacion"
  ], 
  "Actor_moral": [
    "razonSocial",
    "nacionalidad",
    "rfc",
    "curp",
    "condicionMigratoria",
    "profesion",
    "recibePercepciones",
    "lugarTrabajo"
  ],
  "Actor - Promovente_fisica": [
    "curp",
    "apellidoPaterno",
    "apellidoMaterno",
    "nombre",
    "sexo",
    "fechaNacimiento",
    "nacionalidad",
    "edad",
    "rfc",
    "curp",
    "estadoCivil",
    "paisNacimiento",
    "entidadNacimiento",
    "municipioNacimiento",
    "escolaridad",
    "discapacidad",
    "condicionMigratoria",
    "hablaEspanol",
    "lenguaIndigena",
    "lenguaExtranjera",
    "grupoVulnerable",
    "sabeLeerYEscribir",
    "profesion",
    "recibePercepciones",
    "lugarTrabajo",
    "tieneDependientes",
    "bienes",
    "tieneReligion",
    "tieneSeniasParticulares",
    "grupoEtnico",
    "datosPrivados",
    "documentoIdentificacion"
  ],

  "Actor - Promovente_moral":[
    "razonSocial",
    "nacionalidad",
    "rfc",
    "curp",
    "condicionMigratoria",
    "profesion",
    "recibePercepciones",
    "lugarTrabajo",
    "documentoIdentificacion"
  ],

  "Actor - Abogado patrono_fisica":[
    "nombre",
    "apellidoPaterno",
    "apellidoMaterno",
    "pseudonimo",
    "sexo",
    "fechaNacimiento",
    "nacionalidad",
    "edad",
    "rfc",
    "curp",
    "estadoCivil",
    "cedula",
    "tipoDefensor",
    "enRepresentacion",
    "paisNacimiento",
    "entidadNacimiento",
    "municipioNacimiento",
    "escolaridad",
    "discapacidad",
    "condicionMigratoria",
    "hablaEspanol",
    "lenguaIndigena",
    "lenguaExtranjera",
    "grupoVulnerable",
    "sabeLeerYEscribir",
    "profesion",
    "recibePercepciones",
    "lugarTrabajo",
    "tieneDependientes",
    "bienes",
    "tieneReligion",
    "tieneSeniasParticulares",
    "grupoEtnico",
    "datosPrivados",
    "documentoIdentificacion"
  ],

  "Actor - Abogado patrono_moral": "disableAll",

  "Demandado - Abogado patrono_moral": "disableAll",

  "Demandado - Abogado patrono_fisica": [
    "nombre",
    "apellidoPaterno",
    "apellidoMaterno",
    "pseudonimo",
    "sexo",
    "fechaNacimiento",
    "nacionalidad",
    "edad",
    "rfc",
    "curp",
    "estadoCivil",
    "cedula",
    "tipoDefensor",
    "enRepresentacion",
    "paisNacimiento",
    "entidadNacimiento",
    "municipioNacimiento",
    "escolaridad",
    "discapacidad",
    "condicionMigratoria",
    "hablaEspanol",
    "lenguaIndigena",
    "lenguaExtranjera",
    "grupoVulnerable",
    "sabeLeerYEscribir",
    "profesion",
    "recibePercepciones",
    "lugarTrabajo",
    "tieneDependientes",
    "bienes",
    "tieneReligion",
    "tieneSeniasParticulares",
    "grupoEtnico",
    "datosPrivados",
    "documentoIdentificacion"
  ],

    "Abogado autorizado, pero no patrono_fisica": [
        "nombre",
        "apellidoPaterno",
        "apellidoMaterno",
        "pseudonimo",
        "sexo",
        "fechaNacimiento",
        "nacionalidad",
        "edad",
        "rfc",
        "curp",
        "estadoCivil",
        "cedula",
        "tipoDefensor",
        "paisNacimiento",
        "entidadNacimiento",
        "municipioNacimiento",
        "escolaridad",
        "discapacidad",
        "condicionMigratoria",
        "hablaEspanol",
        "lenguaIndigena",
        "lenguaExtranjera",
        "grupoVulnerable",
        "sabeLeerYEscribir",
        "profesion",
        "recibePercepciones",
        "lugarTrabajo",
        "tieneDependientes",
        "bienes",
        "tieneReligion",
        "tieneSeniasParticulares",
        "grupoEtnico",
        "datosPrivados",
        "documentoIdentificacion"
    ],

    "Abogado autorizado, pero no patrono_moral": "disableAll",

    "Actor - Abogado autorizado, pero no patrono_fisica": [
        "nombre",
        "apellidoPaterno",
        "apellidoMaterno",
        "pseudonimo",
        "sexo",
        "fechaNacimiento",
        "nacionalidad",
        "edad",
        "rfc",
        "curp",
        "estadoCivil",
        "cedula",
        "tipoDefensor",
        "paisNacimiento",
        "entidadNacimiento",
        "municipioNacimiento",
        "escolaridad",
        "discapacidad",
        "condicionMigratoria",
        "hablaEspanol",
        "lenguaIndigena",
        "lenguaExtranjera",
        "grupoVulnerable",
        "sabeLeerYEscribir",
        "profesion",
        "recibePercepciones",
        "lugarTrabajo",
        "tieneDependientes",
        "bienes",
        "tieneReligion",
        "tieneSeniasParticulares",
        "grupoEtnico",
        "datosPrivados",
        "documentoIdentificacion"
    ],

    "Actor - Abogado autorizado, pero no patrono_moral": "disableAll",

    "Demandado - Abogado autorizado, pero no patrono_fisica": [
        "nombre",
        "apellidoPaterno",
        "apellidoMaterno",
        "pseudonimo",
        "sexo",
        "fechaNacimiento",
        "nacionalidad",
        "edad",
        "rfc",
        "curp",
        "estadoCivil",
        "cedula",
        "tipoDefensor",
        "paisNacimiento",
        "entidadNacimiento",
        "municipioNacimiento",
        "escolaridad",
        "discapacidad",
        "condicionMigratoria",
        "hablaEspanol",
        "lenguaIndigena",
        "lenguaExtranjera",
        "grupoVulnerable",
        "sabeLeerYEscribir",
        "profesion",
        "recibePercepciones",
        "lugarTrabajo",
        "tieneDependientes",
        "bienes",
        "tieneReligion",
        "tieneSeniasParticulares",
        "grupoEtnico",
        "datosPrivados",
        "documentoIdentificacion"
    ],

    "Demandado - Abogado autorizado, pero no patrono_moral": "disableAll",

    "Actor - Abogado litigante_fisica": [
        "nombre",
        "apellidoPaterno",
        "apellidoMaterno",
        "pseudonimo",
        "sexo",
        "fechaNacimiento",
        "nacionalidad",
        "edad",
        "rfc",
        "curp",
        "estadoCivil",
        "cedula",
        "tipoDefensor",
        "paisNacimiento",
        "entidadNacimiento",
        "municipioNacimiento",
        "escolaridad",
        "discapacidad",
        "condicionMigratoria",
        "hablaEspanol",
        "lenguaIndigena",
        "lenguaExtranjera",
        "grupoVulnerable",
        "sabeLeerYEscribir",
        "profesion",
        "recibePercepciones",
        "lugarTrabajo",
        "tieneDependientes",
        "bienes",
        "tieneReligion",
        "tieneSeniasParticulares",
        "grupoEtnico",
        "datosPrivados",
        "documentoIdentificacion"
    ],

    "Actor - Abogado litigante_moral": "disableAll",

    "Demandado - Abogado litigante_fisica": [
        "nombre",
        "apellidoPaterno",
        "apellidoMaterno",
        "pseudonimo",
        "sexo",
        "fechaNacimiento",
        "nacionalidad",
        "edad",
        "rfc",
        "curp",
        "estadoCivil",
        "cedula",
        "tipoDefensor",
        "paisNacimiento",
        "entidadNacimiento",
        "municipioNacimiento",
        "escolaridad",
        "discapacidad",
        "condicionMigratoria",
        "hablaEspanol",
        "lenguaIndigena",
        "lenguaExtranjera",
        "grupoVulnerable",
        "sabeLeerYEscribir",
        "profesion",
        "recibePercepciones",
        "lugarTrabajo",
        "tieneDependientes",
        "bienes",
        "tieneReligion",
        "tieneSeniasParticulares",
        "grupoEtnico",
        "datosPrivados",
        "documentoIdentificacion"
    ],

    "Demandado - Abogado litigante_moral": "disableAll",

    "Actor - Representante legal de la persona moral actora_fisica": [
        "nombre",
        "apellidoPaterno",
        "apellidoMaterno",
        "pseudonimo",
        "sexo",
        "fechaNacimiento",
        "nacionalidad",
        "edad",
        "rfc",
        "curp",
        "estadoCivil",
        "cedula",
        "tipoDefensor",
        "enRepresentacion",
        "paisNacimiento",
        "entidadNacimiento",
        "municipioNacimiento",
        "escolaridad",
        "discapacidad",
        "condicionMigratoria",
        "hablaEspanol",
        "lenguaIndigena",
        "lenguaExtranjera",
        "grupoVulnerable",
        "sabeLeerYEscribir",
        "profesion",
        "recibePercepciones",
        "lugarTrabajo",
        "tieneDependientes",
        "bienes",
        "tieneReligion",
        "tieneSeniasParticulares",
        "grupoEtnico",
        "datosPrivados",
        "documentoIdentificacion"
    ],

    "Actor - Representante legal de la persona moral actora_moral": "disableAll",

    "Actor - Representante legal_fisica": [
        "nombre",
        "apellidoPaterno",
        "apellidoMaterno",
        "pseudonimo",
        "sexo",
        "fechaNacimiento",
        "nacionalidad",
        "edad",
        "rfc",
        "curp",
        "estadoCivil",
        "cedula",
        "tipoDefensor",
        "enRepresentacion",
        "paisNacimiento",
        "entidadNacimiento",
        "municipioNacimiento",
        "escolaridad",
        "discapacidad",
        "condicionMigratoria",
        "hablaEspanol",
        "lenguaIndigena",
        "lenguaExtranjera",
        "grupoVulnerable",
        "sabeLeerYEscribir",
        "profesion",
        "recibePercepciones",
        "lugarTrabajo",
        "tieneDependientes",
        "bienes",
        "tieneReligion",
        "tieneSeniasParticulares",
        "grupoEtnico",
        "datosPrivados",
        "documentoIdentificacion"
    ],

    "Actor - Representante legal_moral": "disableAll",

    "Demandado - Representante legal_fisica": [
        "nombre",
        "apellidoPaterno",
        "apellidoMaterno",
        "pseudonimo",
        "sexo",
        "fechaNacimiento",
        "nacionalidad",
        "edad",
        "rfc",
        "curp",
        "estadoCivil",
        "cedula",
        "tipoDefensor",
        "enRepresentacion",
        "paisNacimiento",
        "entidadNacimiento",
        "municipioNacimiento",
        "escolaridad",
        "discapacidad",
        "condicionMigratoria",
        "hablaEspanol",
        "lenguaIndigena",
        "lenguaExtranjera",
        "grupoVulnerable",
        "sabeLeerYEscribir",
        "profesion",
        "recibePercepciones",
        "lugarTrabajo",
        "tieneDependientes",
        "bienes",
        "tieneReligion",
        "tieneSeniasParticulares",
        "grupoEtnico",
        "datosPrivados",
        "documentoIdentificacion"
    ],

    "Demandado - Representante legal_moral": "disableAll",

    "Demandado_fisica": [
        "nombre",
        "apellidoPaterno",
        "apellidoMaterno",
        "pseudonimo",
        "sexo",
        "fechaNacimiento",
        "nacionalidad",
        "edad",
        "rfc",
        "curp",
        "estadoCivil",
        "paisNacimiento",
        "entidadNacimiento",
        "municipioNacimiento",
        "escolaridad",
        "discapacidad",
        "condicionMigratoria",
        "hablaEspanol",
        "lenguaIndigena",
        "lenguaExtranjera",
        "grupoVulnerable",
        "sabeLeerYEscribir",
        "profesion",
        "recibePercepciones",
        "lugarTrabajo",
        "tieneDependientes",
        "bienes",
        "tieneReligion",
        "tieneSeniasParticulares",
        "grupoEtnico",
        "datosPrivados",
        "documentoIdentificacion"
    ],
    
    "Demandado_moral": [
        "razonSocial",
        "nacionalidad",
        "rfc",
        "curp",
        "condicionMigratoria",
        "profesion",
        "recibePercepciones",
        "lugarTrabajo",
        "documentoIdentificacion"
    ],

    "Demandado - Representante legal de la persona moral demandada_fisica": [
        "nombre",
        "apellidoPaterno",
        "apellidoMaterno",
        "pseudonimo",
        "sexo",
        "fechaNacimiento",
        "nacionalidad",
        "edad",
        "rfc",
        "curp",
        "estadoCivil",
        "cedula",
        "tipoDefensor",
        "enRepresentacion",
        "paisNacimiento",
        "entidadNacimiento",
        "municipioNacimiento",
        "escolaridad",
        "discapacidad",
        "condicionMigratoria",
        "hablaEspanol",
        "lenguaIndigena",
        "lenguaExtranjera",
        "grupoVulnerable",
        "sabeLeerYEscribir",
        "profesion",
        "recibePercepciones",
        "lugarTrabajo",
        "tieneDependientes",
        "bienes",
        "tieneReligion",
        "tieneSeniasParticulares",
        "grupoEtnico",
        "datosPrivados",
        "documentoIdentificacion"
    ],

    "Demandado - Representante legal de la persona moral demandada_moral": "disableAll",

    "Ministerio Publico_fisica": [
        "nombre",
        "apellidoPaterno",
        "apellidoMaterno",
        "pseudonimo",
        "genero",
        "fechaNacimiento",
        "nacionalidad",
        "edad",
        "rfc",
        "curp",
        "estadoCivil",
        "cedula",
        "adscripcion",
        "paisNacimiento",
        "entidadNacimiento",
        "municipioNacimiento",
        "escolaridad",
        "discapacidad",
        "condicionMigratoria",
        "hablaEspanol",
        "lenguaIndigena",
        "lenguaExtranjera",
        "grupoVulnerable",
        "sabeLeerYEscribir",
        "profesion",
        "recibePercepciones",
        "lugarTrabajo",
        "tieneDependientes",
        "bienes",
        "tieneReligion",
        "tieneSeniasParticulares",
        "grupoEtnico",
        "datosPrivados",
        "documentoIdentificacion"
    ],

    "Ministerio Publico_moral": "disableAll",

    "Tercero Interesado_fisica": [
        "nombre",
        "apellidoPaterno",
        "apellidoMaterno",
        "pseudonimo",
        "genero",
        "fechaNacimiento",
        "nacionalidad",
        "edad",
        "rfc",
        "curp",
        "estadoCivil",
        "paisNacimiento",
        "entidadNacimiento",
        "municipioNacimiento",
        "escolaridad",
        "discapacidad",
        "condicionMigratoria",
        "hablaEspanol",
        "lenguaIndigena",
        "lenguaExtranjera",
        "grupoVulnerable",
        "sabeLeerYEscribir",
        "profesion",
        "recibePercepciones",
        "lugarTrabajo",
        "tieneDependientes",
        "bienes",
        "tieneReligion",
        "tieneSeniasParticulares",
        "grupoEtnico",
        "datosPrivados",
        "documentoIdentificacion"
    ],

    "Tercero Interesado_moral": [
        "razonSocial",
        "nacionalidad",
        "rfc",
        "curp",
        "condicionMigratoria",
        "profesion",
        "recibePercepciones",
        "lugarTrabajo",
        "documentoIdentificacion"
    ],

    "Tercero Interesado - Representante del Sindicato_fisica": [
        "nombre",
        "apellidoPaterno",
        "apellidoMaterno",
        "pseudonimo",
        "genero",
        "fechaNacimiento",
        "nacionalidad",
        "edad",
        "rfc",
        "curp",
        "estadoCivil",
        "paisNacimiento",
        "entidadNacimiento",
        "municipioNacimiento",
        "escolaridad",
        "discapacidad",
        "condicionMigratoria",
        "hablaEspanol",
        "lenguaIndigena",
        "lenguaExtranjera",
        "grupoVulnerable",
        "sabeLeerYEscribir",
        "profesion",
        "recibePercepciones",
        "lugarTrabajo",
        "tieneDependientes",
        "bienes",
        "tieneReligion",
        "tieneSeniasParticulares",
        "grupoEtnico",
        "datosPrivados",
        "documentoIdentificacion"
    ],

    "Tercero Interesado - Representante del Sindicato_moral": [
        "razonSocial",
        "nacionalidad",
        "rfc",
        "curp",
        "condicionMigratoria",
        "profesion",
        "recibePercepciones",
        "lugarTrabajo",
        "documentoIdentificacion"
    ],

    "Tercero Interesado - IMSS_fisica": [
        "nombre",
        "apellidoPaterno",
        "apellidoMaterno",
        "pseudonimo",
        "genero",
        "fechaNacimiento",
        "nacionalidad",
        "edad",
        "rfc",
        "curp",
        "estadoCivil",
        "paisNacimiento",
        "entidadNacimiento",
        "municipioNacimiento",
        "escolaridad",
        "discapacidad",
        "condicionMigratoria",
        "hablaEspanol",
        "lenguaIndigena",
        "lenguaExtranjera",
        "grupoVulnerable",
        "sabeLeerYEscribir",
        "profesion",
        "recibePercepciones",
        "lugarTrabajo",
        "tieneDependientes",
        "bienes",
        "tieneReligion",
        "tieneSeniasParticulares",
        "grupoEtnico",
        "datosPrivados",
        "documentoIdentificacion"
    ],

    "Tercero Interesado - IMSS_moral": [
        "razonSocial",
        "nacionalidad",
        "rfc",
        "curp",
        "condicionMigratoria",
        "profesion",
        "recibePercepciones",
        "lugarTrabajo",
        "documentoIdentificacion"
    ],

    "Tercero Interesado - Infonavit_fisica": [
        "nombre",
        "apellidoPaterno",
        "apellidoMaterno",
        "pseudonimo",
        "genero",
        "fechaNacimiento",
        "nacionalidad",
        "edad",
        "rfc",
        "curp",
        "estadoCivil",
        "paisNacimiento",
        "entidadNacimiento",
        "municipioNacimiento",
        "escolaridad",
        "discapacidad",
        "condicionMigratoria",
        "hablaEspanol",
        "lenguaIndigena",
        "lenguaExtranjera",
        "grupoVulnerable",
        "sabeLeerYEscribir",
        "profesion",
        "recibePercepciones",
        "lugarTrabajo",
        "tieneDependientes",
        "bienes",
        "tieneReligion",
        "tieneSeniasParticulares",
        "grupoEtnico",
        "datosPrivados",
        "documentoIdentificacion"
    ],

    "Tercero Interesado - Infonavit_moral": [
        "razonSocial",
        "nacionalidad",
        "rfc",
        "curp",
        "condicionMigratoria",
        "profesion",
        "recibePercepciones",
        "lugarTrabajo",
        "documentoIdentificacion"
    ],
   
    "Tercero Interesado - Testigo_fisica": [
        "nombre",
        "apellidoPaterno",
        "apellidoMaterno",
        "pseudonimo",
        "genero",
        "fechaNacimiento",
        "nacionalidad",
        "edad",
        "rfc",
        "curp",
        "estadoCivil",
        "paisNacimiento",
        "entidadNacimiento",
        "municipioNacimiento",
        "escolaridad",
        "discapacidad",
        "condicionMigratoria",
        "hablaEspanol",
        "lenguaIndigena",
        "lenguaExtranjera",
        "grupoVulnerable",
        "sabeLeerYEscribir",
        "profesion",
        "recibePercepciones",
        "lugarTrabajo",
        "tieneDependientes",
        "bienes",
        "tieneReligion",
        "tieneSeniasParticulares",
        "grupoEtnico",
        "datosPrivados",
        "documentoIdentificacion"
    ],

    "Tercero Interesado - Testigo_moral": [
        "razonSocial",
        "nacionalidad",
        "rfc",
        "curp",
        "condicionMigratoria",
        "profesion",
        "recibePercepciones",
        "lugarTrabajo",
        "documentoIdentificacion"
    ]

};

export default validCombinations;