import React, { useState, useRef, useEffect } from "react";
import {
  Modal,
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
  Tooltip,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useToastAlert } from "../Alert/ToastAlertSave/ToastAlertSave";
import ConfirmationDialog from "../Alert/ConfirmationDialog";
import { updateEstadoDomicilio } from "../../shared/services/NotificacionService";

const ModalCrearActa = ({
  isModalOpen,
  setModalOpen,
  handleAceptar,
  onClose,
  token,
  item,
}) => {
  const [reason, setReason] = useState("");
  const [note, setNote] = useState("");
  const [notificationStatus, setNotificationStatus] = useState("");
  const [file, setFile] = useState(null);
  const { showSnackbar } = useToastAlert();
  const fileInputRef = useRef(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const reasonsList = [
    { value: "RAZON_1", label: "Razón 1" },
    { value: "RAZON_2", label: "Razón 2" },
    { value: "RAZON_3", label: "Razón 3" },
  ];

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) return;

    if (selectedFile.size > 50 * 1024 * 1024) {
      showSnackbar("El archivo debe ser menor a 50 MB", "error");
      return;
    }
    if (selectedFile.type !== "application/pdf") {
      showSnackbar("Solo se permiten archivos PDF.", "error");
      return;
    }

    setFile(selectedFile);
  };

  const handleRadioChange = (event) => {
    setNotificationStatus(event.target.value);
  };

  const handleConfirmSave = async () => {
    if (!file) {
      showSnackbar("Por favor, cargue un archivo PDF antes de guardar.", "error");
      return;
    }
    if (!notificationStatus) {
      showSnackbar("Por favor, seleccione el estatus de notificación.", "error");
      return;
    }

    // Crear el objeto JSON para "notificacionActaJson"
    const notificacionActa = {
      id: item?.id,
      estadoNotificacion: notificationStatus,
      nota: note,
      razon: reason,
      urlDocumento: file.name, // Solo el nombre del archivo aquí
    };


    try {
      // Hacer la solicitud con el FormData
      const response = await updateEstadoDomicilio(token, notificacionActa, file);
      showSnackbar('Datos guardados con éxito', 'success');
      handleAceptar?.(notificacionActa);
      setModalOpen(false);
    } catch (error) {
      console.error("Error al guardar:", error);
      showSnackbar("¡Algo salió mal al guardar los datos!", "error");
    }
  };

  const handleDownloadPdf = () => {
    const blob = new Blob([file], { type: "application/pdf" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = file.name || "documento.pdf";
    link.click();
  };

  const handleConfirmDelete = () => {
    setFile(null);
    if (fileInputRef.current) fileInputRef.current.value = null;
  };

  const handleCloseDialog = () => setModalOpen(false);;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "white",
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal open={isModalOpen} onClose={onClose} aria-labelledby="modal-notificacion-expediente">
      <Box sx={style}>
        <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
          Notificando Expediente: {item?.expediente || "N/A"}
        </Typography>
        <Grid container spacing={2}>
          {/* Información de la persona */}
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              Parte: {item?.parte || "N/A"}
            </Typography>
            <Typography variant="body1">
              Nombre: {item?.nombrePersonaNotificado || "N/A"}
            </Typography>
          </Grid>

          {/* Estatus de la notificación */}
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ fontWeight: "bold", mb: 1 }}>
              Estatus
            </Typography>
            <FormControl>
              <RadioGroup row value={notificationStatus} onChange={handleRadioChange}>
                <FormControlLabel value="NOTIFICADOs" control={<Radio />} label="Sí notificado" />
                <FormControlLabel value="NO_NOTIFICACION" control={<Radio />} label="No notificado" />
              </RadioGroup>
            </FormControl>
          </Grid>

          {/* Razón y nota (si no notificado) */}
          {notificationStatus === "NO_NOTIFICACION" && (
            <>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="razon-label">Razón</InputLabel>
                  <Select
                    labelId="razon-label"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                  >
                    {reasonsList.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Nota (opcional)"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  multiline
                  rows={3}
                />
              </Grid>
            </>
          )}

          {/* Carga de archivo */}
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Button variant="contained" component="label" startIcon={<CloudUploadIcon />}>
              Adjuntar acta
              <input
                type="file"
                accept="application/pdf"
                hidden
                onChange={handleFileChange}
                ref={fileInputRef}
              />
            </Button>
          </Grid>
          {file && (
            <Card sx={{ mt: 2, position: "relative" }}>
              <Typography
                sx={{ position: "absolute", top: 5, right: 5, cursor: "pointer", color: "red" }}
                onClick={() => setDialogOpen(true)}
              >
                X
              </Typography>
              <CardContent>
                <Tooltip title={file.name}>
                  <Typography
                    variant="body2"
                    sx={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={handleDownloadPdf}
                  >
                    {file.name}
                  </Typography>
                </Tooltip>
              </CardContent>
            </Card>
          )}
        </Grid>

        {/* Botones de acción */}
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2, mt: 3 }}>
          <Button variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
          <Button variant="contained" onClick={handleConfirmSave}>
            Guardar
          </Button>
        </Box>

        <ConfirmationDialog
          open={dialogOpen}
          onClose={handleCloseDialog}
          onConfirm={handleCloseDialog}
          message="¿Está seguro de eliminar este archivo?"
        />
      </Box>
    </Modal>
  );
};

export default ModalCrearActa;
