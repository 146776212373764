import React, { useEffect, useState, useContext } from 'react';
import KeycloakConf from '../../api/keycloakConf';
import { Box, Grid } from '@mui/material';
import ButtonAdd from '../../components/Button/ButtonAdd';
import RefreshButton from '../../components/Button/RefreshButton';
import { Link, useNavigate } from 'react-router-dom';
import Breadcrumbs from '../../components/Breadcrumb/breadcrumbs';
import ConceptoTable from './Grid/ConceptoTable';
import SearchInput from '../../components/Search/SearchInput';
import { getConceptos } from './ConceptoService';
import ConfirmationDialog from '../../components/Alert/ConfirmationDialog';
import ToastAlertDelete from '../../components/Alert/ToastAlertDelete/ToastAlertDelete';
import { updateStatusConcepto } from './ConceptoService';
import { deleteConcepto } from './ConceptoService';

export default function Concepto() {
    const [conceptos, setConceptos] = useState([]);
    const [pageable, setPageable] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [loading, setLoading] = useState(true);
    const { getToken } = useContext(KeycloakConf);
    const [searchQuery, setSearchQuery] = useState('');

    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [itemToDelete, setItemToDelete] = useState(null);
    const [toastOpen, setToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastType, setToastType] = useState('success');

    const navigate = useNavigate();

    const loadConceptos = async () => {
        setLoading(true);
        const token = getToken();
        try {
            const conceptosData = await getConceptos(token, page, rowsPerPage, '', searchQuery); 
            setConceptos(conceptosData.content);
            setPageable(conceptosData.page);
        } catch (error) {
            console.error('Error cargando conceptos:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadConceptos();
    }, [getToken, page, rowsPerPage]);

    const handleRefresh = () => {
        setSearchQuery('');
        loadConceptos();
    };

    const handleSearchKeyDown = (event) => {
        if (event.key === 'Enter') {
            loadConceptos();
        }
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleDelete = (id) => {
        setDialogMessage(`El concepto seleccionado será permanentemente eliminado.`);
        setItemToDelete(id);
        setDialogOpen(true);
    };

    const handleConfirmDelete = async () => {
        const token = getToken();
        try {
            await deleteConcepto(token, itemToDelete);
            setConceptos(conceptos.filter(concepto => concepto.id !== itemToDelete));
            setToastMessage('Registro eliminado con éxito');
            setToastType('success');
          } catch (error) {
            const errorMessage = error.response?.data?.[0]?.message || 'Error al eliminar el registro'; 
            setToastMessage(errorMessage);
            setToastType('error');
          }
          setToastOpen(true);
          setDialogOpen(false);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const handleCloseToast = () => {
        setToastOpen(false);
    };

    const handleUpdateStatus = async (item) => {
        const nuevoEstado = item.estado === 'ACTIVE' ? 1 : 0;
        const mensajeAccion = nuevoEstado === 'ACTIVE' ? 'activado' : 'desactivado';
    
        try {
          await updateStatusConcepto(getToken(), item.id, nuevoEstado);
    
          const toastMessage = `Registro ${mensajeAccion} con éxito`;
          setToastMessage(toastMessage, 'success');
          loadConceptos();
        } catch (error) {
          setToastMessage(`Error al ${mensajeAccion} el registro`);
          setToastType('error');
          setToastOpen(true);
        }
    
      };

      const handleEditConcepto = (concepto) => {
        navigate('/api/core/conceptos/editar_concepto', { state: { concepto } });
      };

    return (
        <>
            <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" alignItems="center">
                    <Link to="/api/core/conceptos/crear_conceptos">
                        <ButtonAdd ariaLabel="añadir nuevo concepto" />
                    </Link>
                    &nbsp;
                    <RefreshButton onClick={handleRefresh} />
                </Box>

                <Box flexBasis="300px" ml={2}>
                    <SearchInput
                        value={searchQuery}
                        onChange={handleSearchChange}
                        placeholder="Buscar..."
                        ariaLabel="Campo de búsqueda para conceptos"
                        tooltipTitle="Escriba para buscar"
                        onKeyDown={handleSearchKeyDown}
                    />
                </Box>
            </Box>
            <Grid className="box-sombra">
            <Breadcrumbs istitulo="true" className="title2" />
                <Box>
                    {loading ? (
                        <p>Cargando ...</p>
                    ) : (
                        <ConceptoTable
                            conceptos={conceptos}
                            onDelete={handleDelete}
                            pageable={pageable}
                            page={page}
                            setPage={setPage}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={setRowsPerPage}
                            onUpdateStatus={handleUpdateStatus}
                            onEdit={handleEditConcepto}

                        />
                    )}
                </Box>
            </Grid>

            <ConfirmationDialog
                open={dialogOpen}
                onClose={handleCloseDialog}
                onConfirm={handleConfirmDelete}
                message={dialogMessage}
            />

            <ToastAlertDelete
                open={toastOpen}
                onClose={handleCloseToast}
                message={toastMessage}
                type={toastType}
            />
        </>
    );
}