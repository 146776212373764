import axios from "../../api/axiosConfig";

const urlGetCorreo = process.env.REACT_APP_TRIALS_API_URL + "/api/workflow/personasdocumentos/correo";
export const getCorreoByPersonaDocumentoId = async (token, id) => {
    try {
    const response = await axios.get(`${urlGetCorreo}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`, 
      },
    });

    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 404) {
      const errorMessage = error.response.data.detail || "Error desconocido";
      if (errorMessage === "No hay correo disponible") {
        throw new Error("No se encontró un correo valido, ingresa uno nuevo");
      }
    }
    throw error;
  }
}

const urlGetNotificacion = process.env.REACT_APP_TRIALS_API_URL + "/api/workflow/personaDetalle/tipo-notificacion";
export const getTipoNotificacion = async (token) => {
    try {
    const response = await axios.get(`${urlGetNotificacion}`, {
      headers: {
        Authorization: `Bearer ${token}`, 
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error al obtener los tipos de Notificación", error);
    throw error; 
  }
}

const urlGetDomicilio = process.env.REACT_APP_TRIALS_API_URL + "/api/workflow/personasdocumentos/domicilio";

export const getDomicilioByPersonaDocumentoId = async (token, id) => {
    try {
      const response = await axios.get(`${urlGetDomicilio}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 404) {
        const errorMessage = error.response.data.detail || 'Error desconocido';
        if (errorMessage === "No hay domicilio disponible para la persona") {
          throw new Error('No se encontró un domicilio para esa persona. Por favor, ingrese uno nuevo.');
        }
      }
      throw error;
    }
};

const urlCreateNotificacion = process.env.REACT_APP_TRIALS_API_URL + "/api/workflow/notificaciones/create";

export const createNotificacion = async (token, formData) => {
  try {
    const response = await axios.post(`${urlCreateNotificacion}`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error al crear la notificación: ", error);
    throw error;
  }
};