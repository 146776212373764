import { React, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import breadcrumbConfig from "./breadcrumbConfig";
import { Breadcrumbs as MUIBreadcrumbs, Typography, Box, Tooltip } from "@mui/material";
import useMenuItems from "../../hooks/Menu/useMenuItems";
import { useJuicio } from "./JuicioContext";
import { useExpediente } from "./ExpedienteContext";
import { useNumAcuerdoContext } from "./NumAcuerdoContext";
import { useTooltipB } from "./TooltipContext";
import InfoIcon from '@mui/icons-material/Info';

const Breadcrumbs = ({ istitulo = false, type ="true" }) => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const { items } = useMenuItems();
  const { juicioSeleccionado } = useJuicio();
  const { expedienteSeleccionado } = useExpediente();
  const { numAcuerdo } = useNumAcuerdoContext();
  const { tooltipBTitle } = useTooltipB();
  const [bandTooltip, setBandTooltip] = useState(false);
  
  useEffect(() => {
    setBandTooltip(false);
  }, [location.pathname]);

  const breadcrumbs = pathnames.map((_, index, arr) => {
    const path = `/${arr.slice(0, index + 1).join("/")}`;
    return {
      path,
      label: breadcrumbConfig[path] || path,
    };
  });
  

  if (location.pathname !== "/home") {
    breadcrumbs.unshift({
      path: "/home",
      label: breadcrumbConfig["/home"] || "Inicio",
    });
  }

  const filteredBreadcrumbs = breadcrumbs.filter(
    (breadcrumb) => breadcrumbConfig[breadcrumb.path]
  );

  let currentModule = null;
  items.forEach((item) => {
    
    const foundItem = item.items.find(
      (subItem) => subItem.path === location.pathname
    );

    

    if (foundItem) {
      currentModule = item;
      filteredBreadcrumbs.splice(1, 0, {
        path: item.path,
        label: item.displayName,
      });
    } else {
      const basePath = item.items.find((subItem) =>
        location.pathname.includes(subItem.path)
      );
      
      if (basePath) {
        currentModule = item;
        filteredBreadcrumbs.splice(1, 0, {
          path: item.path,
          label: item.displayName,
        });
      }
    }
  });

  const validPaths = [
    '/api/workflow/demanda/add',
    '/api/workflow/demanda/familiar'
  ];
  
  if (juicioSeleccionado && validPaths.includes(location.pathname)) {
    filteredBreadcrumbs.push({
      path: `/api/workflow/demanda/${juicioSeleccionado.id}`,
      label: juicioSeleccionado.nombre,
    });
  }

  if (location.pathname === '/api/bandeja/expediente' || location.pathname === '/api/bandeja/expediente/') {
    filteredBreadcrumbs.length = 0;
    filteredBreadcrumbs.push({ path: '/', label: 'Inicio' });
    filteredBreadcrumbs.push({ path: '/api/bandeja', label: 'Bandeja' });
    filteredBreadcrumbs.push({ path: '/api/bandeja/asignados', label: 'Asignado' });

    filteredBreadcrumbs.push({
      path: `/api/bandeja/expediente/${expedienteSeleccionado}`,
      label: expedienteSeleccionado,
    });
  }

  if (location.pathname === '/api/bandeja/expediente/notificacionesAcuerdo') {
    filteredBreadcrumbs.length = 0;
    filteredBreadcrumbs.push({ path: '/', label: 'Inicio' });
    filteredBreadcrumbs.push({ path: '/api/bandeja', label: 'Bandeja' });
    filteredBreadcrumbs.push({ path: '/api/bandeja/asignados', label: 'Asignado' });

    filteredBreadcrumbs.push({
      path: `/api/bandeja/expediente/${expedienteSeleccionado}`,
      label: expedienteSeleccionado,
    });

    filteredBreadcrumbs.push({
      path: `/api/bandeja/expediente/${expedienteSeleccionado}/${numAcuerdo}`,
      label: 'Acuerdo' + ' ' + numAcuerdo,
    });
  }
  
  if (location.pathname === '/api/bandeja/expediente/detalle') {
    filteredBreadcrumbs.length = 0;
    filteredBreadcrumbs.push({ path: '/api/bandeja/expediente/detalle', label: 'Detalle' });
    filteredBreadcrumbs.push({
      path: `/api/bandeja/expediente/detalle/${expedienteSeleccionado}`,
      label: expedienteSeleccionado,
    });
  }

  if (location.pathname === '/api/agregar-participante') {
    filteredBreadcrumbs.push({
      path: `/expediente/${expedienteSeleccionado}/api/agregar-participante`, 
      label: `${expedienteSeleccionado} / Agregar participante`,  
    });
  }

  if(location.pathname === '/api/bandeja/expediente/agregarAcuerdo'){
    let titulo = location?.state?.item?.acuerdoId ? 'Editar acuerdo' : 'Agregar acuerdo'
    
    filteredBreadcrumbs.push({
      path: `/api/bandeja/expediente/${expedienteSeleccionado}`,
      label: expedienteSeleccionado + " / " + titulo,
    });
  }

  if(location.pathname === '/api/workflow/acuerdo/acuerdoNotificacion'){
    filteredBreadcrumbs.push({
      path: `/api/bandeja/expediente/${expedienteSeleccionado}`,
      label: expedienteSeleccionado + " /  Notificaciones",
    });
  }

  if (location.pathname === '/api/workflow/exhorto/salida') {
    filteredBreadcrumbs.push({
      path: `/api/workflow/exhorto/salida/${expedienteSeleccionado}/${' / Agregar exhorto salida'}`,
      label: expedienteSeleccionado,
    });
  }

  if (location.pathname === '/api/core/personal/edit') {
    filteredBreadcrumbs.splice(1, 0, { path: 'menuItem', label: 'Catálogo' });
    filteredBreadcrumbs.splice(2, 0, { path: '/api/core/personas', label: 'Personal' });

    const titulo = location?.state?.item?.id ? 'Editar personal' : 'Agregar personal';
    filteredBreadcrumbs.push({
      path: '/api/core/personal/edit',
      label: `${titulo}`,
    });
  }

  if (location.pathname === '/api/workflow/demanda/edit') {
    filteredBreadcrumbs.length = 0; // Esto limpia breadcrumbs previos
  
    //elementos en el orden correcto:
    filteredBreadcrumbs.push({ path: '/', label: 'Inicio' });
    filteredBreadcrumbs.push({ path: '/api/bandeja/entrada', label: 'Bandeja' });
    filteredBreadcrumbs.push({ path: '/api/bandeja/entrada', label: 'Entrada' });
    filteredBreadcrumbs.push({ path: '/api/workflow/demanda/edit', label: 'Demanda' });
    filteredBreadcrumbs.push({ path: '/api/core/personal/edit', label: 'Corrección anexos' });
  }

  if (location.pathname === '/api/workflow/exhorto/edit') {
    filteredBreadcrumbs.length = 0;
    filteredBreadcrumbs.push({ path: '/', label: 'Inicio' });
    filteredBreadcrumbs.push({ path: '/api/bandeja/entrada', label: 'Bandeja' });
    filteredBreadcrumbs.push({ path: '/api/bandeja/entrada', label: 'Entrada' });
    filteredBreadcrumbs.push({ path: '/api/workflow/exhorto/edit', label: 'Exhorto' });
    filteredBreadcrumbs.push({ path: '/api/core/personal/edit', label: 'Corrección anexos' });
  }

  if (location.pathname === '/api/bandeja/listaestrado') {
    filteredBreadcrumbs.length = 0;
    filteredBreadcrumbs.push({ path: '/', label: 'Inicio' });
    filteredBreadcrumbs.push({ path: '/api/bandeja/listaestrado', label: 'Bandeja' });
    filteredBreadcrumbs.push({ path: '/api/bandeja/listaestrado', label: 'Lista Estrados' });
  }

  if (location.pathname === '/api/workflow/amparo') {
    filteredBreadcrumbs.length = 0;
    filteredBreadcrumbs.push({ path: '/', label: 'Inicio' });
    filteredBreadcrumbs.push({ path: '/api/workflow/amparo', label: 'Registro' });
    filteredBreadcrumbs.push({ path: '/api/workflow/amparo', label: 'Amparo' });
  }

  if (location.pathname === '/api/workflow/documentoContenido') {
    filteredBreadcrumbs.length = 0;
    filteredBreadcrumbs.push({ path: '/', label: 'Inicio' });
    filteredBreadcrumbs.push({ path: '/api/workflow/documentoContenido', label: 'Bandeja' });
    filteredBreadcrumbs.push({ path: '/api/workflow/documentoContenido', label: 'Oficios' });
    filteredBreadcrumbs.push({ path: '/api/workflow/documentoContenido', label: 'Editar oficio' });
  }

  if(location.pathname == "/api/workflow/oficio"){
    const expediente = location?.state?.item?.expediente
    if(expediente){
      filteredBreadcrumbs.splice(1, 2);
      filteredBreadcrumbs.push({
        path: `/api/workflow/oficio`,
        label: expedienteSeleccionado ,
      });
    }
  }

  const validateFields = (item, breadcrumb) => {
    let validate = item.path === breadcrumb.path;
    //Revisa si es la ruta dada para no agregarle link
    if(breadcrumb.path === '/api/bandeja/entrada/correccion') return true;
    if(breadcrumb.path === '/api/bandeja') return true;
    if(breadcrumb.path === '/api/bandeja/expediente/detalle') return true;

    return validate;
  };

  if (location.pathname === '/api/bandeja/audiencias') {
    filteredBreadcrumbs.length = 0;
    filteredBreadcrumbs.push({ path: '/', label: 'Inicio' });
    filteredBreadcrumbs.push({ path: '/api/bandeja', label: 'Bandeja' });
    filteredBreadcrumbs.push({ path: '/api/bandeja/audiencias', label: 'Audiencias' });
    if(!bandTooltip) setBandTooltip(true);
  }
  
  if (type) {
    const lastBreadcrumbLabel = filteredBreadcrumbs[filteredBreadcrumbs.length - 1]?.label;
  }

      // Si istitulo es verdadero, mostramos solo el título de la última sección
      if (istitulo) {
        const lastBreadcrumbLabel = filteredBreadcrumbs[filteredBreadcrumbs.length - 1]?.label;
        return (
          <Box>
            <h3>{lastBreadcrumbLabel}</h3>
          </Box>
        );
      }

  return (
    <Box sx={{ mb: 1.5, borderBottom: "1px solid #e0e0e0", pb: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <MUIBreadcrumbs aria-label="breadcrumb" separator="/">
        {filteredBreadcrumbs.map((breadcrumb, index) => {
          const isMenuItem = items.some(
            (item) => validateFields(item, breadcrumb)
          );

          if (breadcrumb.path === 'menuItem') {
            return (<Typography key={breadcrumb.path}>{breadcrumb.label}</Typography>);
          }

          return index === filteredBreadcrumbs.length - 1 ? (
            <Typography key={breadcrumb.path}>{breadcrumb.label}</Typography>
          ) : isMenuItem ? (
            <Typography key={breadcrumb.path}>{breadcrumb.label}</Typography>
          ) : (
            <Link
              key={breadcrumb.path}
              style={{ textDecoration: "none", color: "#636569" }}
              to={breadcrumb.path}
            >
              {breadcrumb.label}
            </Link>
          );
        })}
      </MUIBreadcrumbs>

      {bandTooltip && tooltipBTitle && (
        <Tooltip title={tooltipBTitle}>
          <InfoIcon color="action" />
        </Tooltip>
      )}
    </Box>
  );
};

export default Breadcrumbs;
