import { useContext, useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Typography, Box, Button, TextField, Select, MenuItem, InputLabel, FormControl, FormHelperText } from "@mui/material";
import keycloakConf from "../../api/keycloakConf";
import fetchListTipoPartes from "../../pages/TipoPartes/TipoPartesService";
import fetchIdentificaciones from "../../shared/services/IdentificacionService";
import { registrarAsistencia } from "../../shared/services/AsistenciaAudienciaService";
import { useToastAlert } from "../Alert/ToastAlertSave/ToastAlertSave";

const ModalRegistrarAsistencia = ({ open, onClose, participante, item }) => {
  const { getToken } = useContext(keycloakConf);
  const token = getToken();
  const [tipos, setTipos] = useState([]);
  const [selectedTipo, setSelectedTipo] = useState("");
  const [documento, setDocumento] = useState([]);
  const [selectedDocumento, setSelectedDocumento] = useState("");
  const [errors, setErrors] = useState({ tipo: false, documento: false });
  const { showSnackbar } = useToastAlert();
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile) return;
  
    if (selectedFile.size > 50 * 1024 * 1024) {
      showSnackbar('El archivo debe ser menor a 50 MB', 'error');
      return;
    }
  
    if (selectedFile.type !== "application/pdf") {
      showSnackbar('Solo se permiten archivos PDF.', 'error');
      return;
    }
  
    setFile(selectedFile);
  };

  const loadTipos = async () => {
    if (item) {
      try {
        const tipos = await fetchListTipoPartes(token, item.idCarpeta);
        setTipos(tipos);
      } catch (error) {
        console.error('Error al cargar los tipos de partes:', error);
      }
    }
  };

  const loadIdentificaciones = async () => {
    try {
      const documentosData = await fetchIdentificaciones(token);
      setDocumento(documentosData);
    } catch (error) {
      console.error('Error al obtener los documentos de identificación:', error);
    }
  };

  const validateFields = () => {
    const newErrors = {
      tipo: !selectedTipo,
      documento: !selectedDocumento,
    };
    setErrors(newErrors);
    return !newErrors.tipo && !newErrors.documento;
  };

  const handleRegistrarAsistencia = async () => {
    if (!validateFields()) return;
  
    if (!file) {
      showSnackbar('Por favor, carga un archivo PDF para registrar la asistencia.', 'error');
      return;
    }
  
    const asistenciaAudienciaRecord = {
      idPersonaDocumento: participante?.id,
      idAudiencia: item?.id,
      idDocumentoIdentificacion: selectedDocumento,
    };
  
    try {
      await registrarAsistencia(token, asistenciaAudienciaRecord, file);
      navigate(`/api/bandeja/audiencias`);
      showSnackbar(`Asistencia de ${participante.nombre} ${participante.apellidoPaterno} ${participante.apellidoMaterno} registrada con éxito`, 'success');
      onClose();
    } catch (error) {
      console.error('Error al registrar asistencia:', error);
      showSnackbar(`Hubo un error al registrar la asistencia de ${participante.nombre} ${participante.apellidoPaterno} ${participante.apellidoMaterno}`, 'error');
    }
  };
  

  useEffect(() => {
    loadTipos();
    loadIdentificaciones();
  },[item, token]);

  useEffect(() => {
    if (open) {
      setSelectedTipo(participante?.tipoParte ? tipos.find((tipo) => tipo.nombre === participante.tipoParte)?.id || "" : "");
      setSelectedDocumento("");
      setErrors({ tipo: false, documento: false });
      setFile(null);
    }
  }, [open, participante, tipos]);

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedTipo(value);
  
    setErrors((prevErrors) => ({
      ...prevErrors,
      tipo: !value,
    }));
  };
  
  const handleSelectDocumentoChange = (event) => {
    const value = event.target.value;
    setSelectedDocumento(value);
  
    setErrors((prevErrors) => ({
      ...prevErrors,
      documento: !value,
    }));
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          width: "500px",
        }}
      >
        {participante ? (
          <>
            <Typography variant="h6" gutterBottom>
              Registro de asistencia
            </Typography>
            <TextField
              label="Nombre"
              value={`${participante.nombre} ${participante.apellidoPaterno} ${participante.apellidoMaterno}`}
              fullWidth
              margin="normal"
              size="small"
              InputProps={{
                readOnly: true,
              }}
              sx={{ mb: 2 }}
            />
            <FormControl fullWidth margin="normal" size="small" sx={{ mb: 2 }} error={errors.tipo}>
              <InputLabel>Tipo</InputLabel>
              <Select
                value={selectedTipo}
                onChange={handleSelectChange}
                label="Tipo"
                disabled={!!participante?.tipoParte}
              >
                {tipos.map((tipo) => (
                  <MenuItem key={tipo.id} value={tipo.id}>
                    {tipo.nombre}
                  </MenuItem>
                ))}
              </Select>
              {errors.tipo && (
                <FormHelperText>Por favor, selecciona un tipo.</FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth margin="normal" size="small" sx={{ mb: 2 }} error={errors.documento}>
              <InputLabel>Documento con el cuál se identifica</InputLabel>
              <Select
                value={selectedDocumento}
                onChange={handleSelectDocumentoChange}
                label="Documento con el cual se identifica"
              >
                {documento.map((doc) => (
                  <MenuItem key={doc.id} value={doc.id}>
                    {doc.identificacion}
                  </MenuItem>
                ))}
              </Select>
              {errors.documento && (
                <FormHelperText>Por favor, selecciona un documento.</FormHelperText>
              )}
            </FormControl>
            <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
              <Button
                variant="contained"
                component="label"
                sx={{ mr: 2 }}
              >
                Cargar archivo
                <input type="file" accept="application/pdf" hidden onChange={handleFileChange} ref={fileInputRef} />
              </Button>
              {file && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    backgroundColor: "#f5f5f5",
                    padding: 2,
                    borderRadius: 1,
                    boxShadow: 1,
                    maxWidth: "300px", // Tamaño máximo para la carta
                    overflow: "hidden",
                    flexGrow: 1,
                  }}
                >
                  <Typography variant="body2" sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "80%" }}>
                    {file.name}
                  </Typography>
                  <Button
                    variant="text"
                    color="error"
                    onClick={() => {
                      setFile(null);
                      if (fileInputRef.current) fileInputRef.current.value = null;
                    }}
                    sx={{
                      minWidth: "auto",
                      padding: 0,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "1.5rem",
                        lineHeight: "1",
                        color: "red",
                      }}
                    >
                      ✖
                    </Typography>
                  </Button>
                </Box>
              )}
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
              <Button
                variant="contained"
                className="button-save"
                onClick={handleRegistrarAsistencia}
                sx={{ mr: 1 }}
              >
                Aceptar
              </Button>
              <Button
                variant="outlined"
                className="button-close"
                onClick={onClose}
              >
                Cancelar
              </Button>
            </Box>
          </>
        ) : (
          <Typography>No hay datos disponibles.</Typography>
        )}
      </Box>
    </Modal>
  );
};

export default ModalRegistrarAsistencia;