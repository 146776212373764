import { Box, Tab, Paper, Tabs, Button, Card, CardContent, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, Typography, Autocomplete, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import keycloakConf from "../../../../../../api/keycloakConf";
import { useState, useEffect, useContext } from "react";
import { Editor } from "@tinymce/tinymce-react";
import ConfirmationModal from "../../../../../../components/Modal/ConfirmationModal";
import { fetchTempletes, fetchGuardarTempletes } from "../AcuerdoService";
import { useToastAlert } from "../../../../../../components/Alert/ToastAlertSave/ToastAlertSave";


const AcuerdoSentenciaTab = ({
    formData,
    handleInputChange,
    setFormData,
    errors,
    validacionCampos,
    isSentencia,
    isDisabled
}) => {
    const { getToken } = useContext(keycloakConf);
    const [templates, setTemplates] = useState([]);
    const [tabTemplete, setTabTemplete] = useState(0);
    const { showSnackbar } = useToastAlert();
    const [modalOpenPublicarOficio, setModalOpenPublicarOficio] = useState(false);
    const [modalCreateTemplateOpen, setModalCreateTemplateOpen] = useState(false);
    const [newTemplateData, setNewTemplateData] = useState({
        nombre: "",
        tipo: "ACUERDO",
        contenido: formData.acuerdo.textoEditor,
        juzgado: {}
    });


    useEffect(() => {
        loadTemplete();
    }, []);

    const loadTemplete = async () => {
        const token = getToken();
        try {
            const templateData = await fetchTempletes(token);
            setTemplates(templateData);
        } catch (error) {
            console.error('Error cargando registros:', error);
        }
    };

    const handleTabTempleteChange = (event, tabnewValue) => {
        setTabTemplete(tabnewValue);
    };

    const handleCreateTemplate = async () => {
        const token = getToken();
        try {
            let formDataToSubmit = {
                ...newTemplateData,
                contenido: formData.acuerdo.textoEditor,
                audit: {}
            };
            const data = await fetchGuardarTempletes(token, formDataToSubmit);  // Pasa los datos a la función
            resetForm();
            setModalCreateTemplateOpen(false);
            loadTemplete();
            showSnackbar('Template creado: ' + formDataToSubmit.nombre, 'success');

        } catch (error) {
            resetForm();
            showSnackbar('Error al crear el template', 'error');
        }
    };


    const handleNewTemplateChange = (field, value) => {
        setNewTemplateData((prevState) => ({
            ...prevState,
            [field]: value
        }));
    };

    const getTemplateOptions = () => {
        const templatesList = templates.filter(item => item.templete);
        const placeholdersList = templates.filter(item => !item.templete);
        return { templatesList, placeholdersList };
    };


    const resetForm = () => {
        setNewTemplateData({
            nombre: "",
            tipo: "ACUERDO",
            contenido: formData.acuerdo.textoEditor,
            juzgado: {}
        });
    };

    const { templatesList, placeholdersList } = getTemplateOptions();

    return (
        <>
            <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item sm={4}>
                    <FormControl fullWidth>
                        <InputLabel id="select-tamanio-papel-label">Tamaño de papel</InputLabel>
                        <Select
                            name="acuerdo.tamanioPapel"
                            labelId="select-tamanio-papel-label"
                            value={formData.acuerdo.tamanioPapel || ""}
                            onChange={handleInputChange}
                            disabled={isDisabled}
                            label="Tamaño de papel"
                            error={!!errors["acuerdo.tamanioPapel"]}
                        >
                            <MenuItem value="c">Carta</MenuItem>
                            <MenuItem value="o">Oficio</MenuItem>
                        </Select>
                        <FormHelperText>{errors["acuerdo.tamanioPapel"] || ''}</FormHelperText>
                    </FormControl>
                </Grid>

                <Grid item sm={4}>
                    <InputLabel id="select-acuerdo-label">{isSentencia ? "Sentencia" : "Acuerdo"}</InputLabel>

                    {formData.acuerdo.acuerdoPublicado === "S" && (
                        <Card sx={{ maxWidth: 130, margin: "0 auto", position: "relative" }}>
                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: 80 }}>
                                <PictureAsPdfIcon sx={{ fontSize: 75, color: "black" }} />
                            </Box>
                            <CardContent>
                                <a href="#" onClick={() => { }}>acuerdo.pdf</a>
                            </CardContent>
                        </Card>
                    )}
                </Grid>

                <Grid item sm={4}>
                    <FormControl fullWidth>
                        <Autocomplete
                            id="select-template"
                            options={[...templatesList, ...placeholdersList]}
                            groupBy={(option) => option.templete ? 'Plantillas' : 'Placeholders'}
                            getOptionLabel={(option) => option.nombre}
                            renderInput={(params) => <TextField {...params} label="Cargar Template" />}
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    setFormData(prevState => ({
                                        ...prevState,
                                        acuerdo: {
                                            ...prevState.acuerdo,
                                            textoEditor: prevState.acuerdo.textoEditor + "\n\n" + newValue.contenido
                                        }
                                    }));
                                }
                            }}
                            disabled={isDisabled}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            renderOption={(props, option) => (
                                <li {...props}>
                                    {option.nombre}
                                </li>
                            )}
                        />

                    </FormControl>
                </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item sm={12}>
                    <Box display="flex" ml={2} mr={2} justifyContent="space-between" alignItems="center">
                        <Grid container spacing={2} sx={{ mt: 2 }}>
                            <Grid item xs={12}>
                                <Tabs value={tabTemplete} onChange={handleTabTempleteChange} fullWidth>
                                    <Tab label="Editar" />
                                    <Tab label="Código" />
                                    <Tab label="Vista previa" />
                                </Tabs>
                            </Grid>

                            {tabTemplete === 0 && (
                                <Box item sx={{ width: "95%", marginTop: 3 }}>
                                    <Button disabled={isDisabled} sx={{ mb: 2 }} variant="contained" className="button-save" onClick={() => setModalOpenPublicarOficio(true)}>
                                        <Typography variant="button">Publicar</Typography>
                                    </Button>

                                    <Editor
                                        apiKey='zb00d05qfmbbu6nyr7rvemq1siihfac4ehlonnhfjajbe589'
                                        value={formData.acuerdo.textoEditor}
                                        disabled={isDisabled}
                                        onEditorChange={(newValue) =>
                                            setFormData((prevState) => ({
                                                ...prevState,
                                                acuerdo: {
                                                    ...prevState.acuerdo,
                                                    textoEditor: newValue
                                                }
                                            }))
                                        }
                                        init={{
                                            height: 400,
                                            width: "100%",
                                            menubar: 'edit insert view format table',
                                            language: 'es',
                                            plugins: ['advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen', 'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount', 'table', 'anchor'],
                                            toolbar: 'undo redo | blocks | bold italic forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat',
                                            statusbar: false,
                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                        }}
                                    />
                                    <Grid item sm={12}>
                                        <FormControl fullWidth>
                                            <FormHelperText>{errors["acuerdo.textoEditor"] || ''}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                </Box>
                            )}

                            {tabTemplete === 1 && (
                                <Grid item sx={{ width: "100%" }}>
                                    <Paper square sx={{ pb: '50px' }}>
                                        <Box sx={{ p: 2, backgroundColor: "#f4f4f4", borderRadius: 2 }}>
                                            <Typography variant="h6" sx={{ mb: 2 }}>
                                                Código HTML:
                                            </Typography>
                                            <pre
                                                style={{
                                                    backgroundColor: "#2d2d2d",
                                                    color: "#f8f8f2",
                                                    padding: "15px",
                                                    borderRadius: "5px",
                                                    whiteSpace: "pre-wrap",
                                                    wordWrap: "break-word",
                                                    fontSize: "14px",
                                                    fontFamily: "'Courier New', Courier, monospace",
                                                    maxHeight: "300px",
                                                    overflowY: "auto",
                                                    marginBottom: "10px",
                                                }}
                                            >
                                                <code>{formData.acuerdo.textoEditor}</code>
                                            </pre>
                                        </Box>
                                    </Paper>
                                </Grid>
                            )}

                            {tabTemplete === 2 && (
                                <Grid item sx={{ width: "100%" }}>
                                    <Box display="flex" justifyContent="space-between" sx={{ mb: 2 }}>
                                        <Typography variant="h6">
                                            Vista previa:
                                        </Typography>

                                        <Button variant="contained" className="button-save" onClick={() => setModalCreateTemplateOpen(true)}>
                                            Crear Template
                                        </Button>
                                    </Box>

                                    <Paper square elevation={3}>
                                        <Box sx={{ p: 2 }}>
                                            <Box
                                                dangerouslySetInnerHTML={{
                                                    __html: formData.acuerdo.textoEditor || ""
                                                }}
                                            />
                                        </Box>
                                    </Paper>
                                </Grid>

                            )}
                        </Grid>
                    </Box>
                </Grid>
            </Grid>

            <Dialog open={modalCreateTemplateOpen} onClose={() => setModalCreateTemplateOpen(false)}>
                <DialogTitle>Crear Nuevo Template</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Nombre del Template"
                        type="text"
                        fullWidth
                        value={newTemplateData.nombre}
                        onChange={(e) => handleNewTemplateChange("nombre", e.target.value)}
                        required
                        error={newTemplateData.nombre.length > 0 && newTemplateData.nombre.length < 3}
                        helperText={newTemplateData.nombre.length > 0 && newTemplateData.nombre.length < 3 ? "El nombre debe tener al menos 3 caracteres." : ""}
                    />
                </DialogContent>
                <DialogActions>
                    <Button className="button-close" onClick={() => { setModalCreateTemplateOpen(false); resetForm() }}>Cancelar</Button>
                    <Button
                        className="button-save"
                        variant="contained"
                        onClick={handleCreateTemplate}
                        disabled={newTemplateData.nombre.length < 3}
                    >
                        Guardar
                    </Button>
                </DialogActions>
            </Dialog>


            <ConfirmationModal
                open={modalOpenPublicarOficio}
                onClose={() => setModalOpenPublicarOficio(false)}
                onConfirm={validacionCampos}
                title={isSentencia ? "Publicar sentencia" : "Publicar acuerdo"}
                message={isSentencia ? "Se publicará la sentencia." : "Se publicará el acuerdo."}
                confirmationButton="Sí, publicar"
                closeButton="No, salir"
            />
        </>
    );
};

export default AcuerdoSentenciaTab;
