import React, { useState, useEffect, useContext } from 'react';
import {Modal as MuiModal, Box, Typography,
  Divider,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  TextField,
  Button,
  IconButton,
  Grid,
  InputLabel,
  FormControl
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import keycloakConf from '../../api/keycloakConf';
import { getCorreoByPersonaDocumentoId } from '../../pages/BandejaAsignados/NotificacionesService';
import { getTipoNotificacion } from '../../pages/BandejaAsignados/NotificacionesService';
import { getDomicilioByPersonaDocumentoId } from '../../pages/BandejaAsignados/NotificacionesService';
import { useToastAlertValidation } from '../Alert/ToastAlertValidation/ToastAlertValidation';
import { fetchEstados } from '../../shared/services/EstadosService';
import { fetchMunicipiosByEstadoId } from '../../shared/services/EstadosService';
import { createNotificacion } from '../../pages/BandejaAsignados/NotificacionesService';
import { useNavigate } from 'react-router-dom'; 

const NotificationModal = ({ open, handleClose, personId, isDisabled, item}) => {
  const [metodo, setMetodo] = useState('');
  const [correo, setCorreo] = useState('');
  const [tiposNotificacion, setTiposNotificacion] = useState([]);
  const [usarCorreoRegistrado, setUsarCorreoRegistrado] = useState(false);
  const [usarMismaDireccion, setUsarMismaDireccion] = useState(false);
  const [domicilio, setDomicilio] = useState({});
  const [estados, setEstados] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [selectedEstado, setSelectedEstado] = useState('');
  const [selectedMunicipio, setSelectedMunicipio] = useState('');
  const navigate = useNavigate();
  
  const notificacion = {
    personId,
    metodo: '',
    correo:'',
    estadoRepublica: selectedEstado,
    ciudad:'',
    calle:'',
    latitud:'',
    longitud:'',
    codigoPostal:'',
    municipio: selectedMunicipio,
    colonia:'',
    exterior:'',
    interior:'',
    idDomicilio: null,
    usarCorreoRegistrado: false,
  }

  const [ciudad, setCiudad] = useState('');
  const [calle, setCalle] = useState('');
  const [codigoPostal, setCodigoPostal] = useState('');
  const [colonia, setColonia] = useState('');
  const [exterior, setExterior] = useState('');
  const [interior, setInterior] = useState('');
  const [latitud, setLatitud] = useState('');
  const [longitud, setLongitud] = useState('');
  const [idDomicilio, setIdDomicilio] = useState('');


  const { showSnackbar } = useToastAlertValidation(); 
  const { getToken } = useContext(keycloakConf);
  const token = getToken(); 

  useEffect(() => {
    const loadEstados = async () => {
      try {
        const estadosData = await fetchEstados(token);
        setEstados(estadosData);
      } catch (error) {
        console.error('Error al obtener los estados:', error);
        showSnackbar('Error al cargar los estados.', 'error');
      }
    };

    if (open) {
      loadEstados();
    }
  }, [open, token, showSnackbar]);

  const handleMethodChange = (e) => {
    setMetodo(e.target.value);
    if (e.target.value !== '1') {
      setUsarCorreoRegistrado(false);
      setCorreo('');
    }
  };

  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    setUsarCorreoRegistrado(checked);
    if (!checked) {
      setCorreo('');
    }
  };

  const handleCheckboxChangeDireccion = (e) => {
    const checked = e.target.checked;
    setUsarMismaDireccion(checked);
    if (checked) {
      getDomicilio();
    } else {
      setDomicilio(null);
      setSelectedEstado('');
      setSelectedMunicipio('');
      setCalle('');
      setCodigoPostal('');
      setColonia('');
      setExterior('');
      setInterior('');
    }
  };

  const handleSave = async() => {
  notificacion.metodo = metodo;
  notificacion.correo = correo;
  notificacion.estadoRepublica = selectedEstado;
  notificacion.ciudad = ciudad;
  notificacion.calle = calle;
  notificacion.latitud = latitud;
  notificacion.longitud = longitud;
  notificacion.codigoPostal = codigoPostal;
  notificacion.municipio = selectedMunicipio;
  notificacion.colonia = colonia;
  notificacion.exterior = exterior;
  notificacion.interior = interior;
  notificacion.idDomicilio = idDomicilio;
  notificacion.usarCorreoRegistrado = usarCorreoRegistrado;

  try {
    await createNotificacion(token, notificacion);
    showSnackbar('Notificación creada exitosamente.', 'success');
    handleClose();
    navigate("/api/bandeja/expediente/", { state: { item } });
  } catch (error) {
    console.error('Error al guardar la notificación:', error);
    showSnackbar('Error al crear la notificación.', 'error');
  }
    handleClose();
  };

  const handleCancelClick = () => {
    handleClose();
  };

  const resetForm = () => {
    setMetodo('');
    setCorreo('');
    setUsarCorreoRegistrado(false);
    setDomicilio(null);
    setSelectedEstado('');
    setSelectedMunicipio('');
    setDomicilio(null);
    setLatitud('');
    setLongitud('');
    setCodigoPostal('');
    setUsarMismaDireccion(false);
    setCiudad('');
    setColonia('');
    setIdDomicilio(null);
    setCalle('');
    setInterior('');
    setExterior('');
  };

  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open]);

  useEffect(() => {
    const getCorreo = async () => {
      if (usarCorreoRegistrado && personId) {
        try {
          const correo = await getCorreoByPersonaDocumentoId(token, personId);
          setCorreo(correo);
        } catch (error) {
          console.error("Error al obtener el correo de la persona seleccionada:", error.message || error);
          showSnackbar(error.message || "Error al obtener el correo de la persona seleccionada.", "error");
        }
      }
    };
    getCorreo();
  }, [usarCorreoRegistrado, personId, token]);

  useEffect(() => {
    const fetchTipoNotificacion = async () => {
      try {
        const data = await getTipoNotificacion(token);
        setTiposNotificacion(data);
      } catch (error) {
        console.error('Error al obtener los tipos de notificación:', error);
      }
    };

    if (open) {
      fetchTipoNotificacion();
    }
  }, [open, token]);

  const getDomicilio = async () => {
    if (personId) {
      try {
        const domicilio = await getDomicilioByPersonaDocumentoId(token, personId);
        if (domicilio) {
          setDomicilio(domicilio);
          setSelectedEstado(domicilio.estadoRepublica);
          setSelectedMunicipio(domicilio.municipio);
          setCalle(domicilio.calle);
          setCodigoPostal(domicilio.codigoPostal);
          setColonia(domicilio.colonia);
          setExterior(domicilio.exterior);
          setInterior(domicilio.interior);
          setIdDomicilio(domicilio.id);
        } else {
          showSnackbar("El participante no tiene un domicilio, ingresa uno nuevo", "error");
        }
      } catch (error) {
        console.error("Error al obtener el domicilio de la persona seleccionada:", error);
        showSnackbar("El participante no tiene un domicilio, ingresa uno nuevo", "error");
      }
    }
  };

  useEffect(() => {
    if (usarMismaDireccion && personId) {
      getDomicilio();
    }
  }, [usarMismaDireccion, personId, token]);

  const loadEstados = async () => {
    try {
      const estadosData = await fetchEstados(token);
      setEstados(estadosData); 
    } catch (error) {
      console.error('Error al obtener los estados:', error);
    }
  };

  useEffect(() => {
    if (selectedEstado) {
      const loadMunicipios = async () => {
        try {
          const estadoSeleccionado = estados.find((estado) => estado.name === selectedEstado);
          if (estadoSeleccionado) {
            const municipiosData = await fetchMunicipiosByEstadoId(token, estadoSeleccionado.id);
            setMunicipios(municipiosData);
          }
        } catch (error) {
          console.error('Error al obtener los municipios:', error);
        }
      };
  
      loadMunicipios();
    } else {
      setMunicipios([]);
    }
  }, [selectedEstado, token, estados]);

useEffect(() => {
  if (!selectedEstado) {
    setSelectedEstado('');
    setSelectedMunicipio('');
  }
}, [selectedEstado]);
  
 
  return (
    <MuiModal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'white', borderRadius: '8px', boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
          p: 4,
          width: metodo === 2 ? '1000px' : '600px',
          maxWidth: '90%',
          overflow: 'auto',
        }}
      >
        {/* Encabezado */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" component="h2" sx={{ fontWeight: 'bold' }}>
            Notificación
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider sx={{ my: 2 }} />

        {/* Selección de método */}
        {metodo !== 2 && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="body1">Método:</Typography>
            <Select
              value={metodo}
              onChange={handleMethodChange}
              fullWidth
              sx={{ mt: 1 }}
              disabled={isDisabled}
            >
            {tiposNotificacion.map((tipo) => (
              <MenuItem key={tipo.ordinal} value={tipo.ordinal}>{tipo.descripcion}</MenuItem>
            ))}
            </Select>
          </Box>
        )}

        {/* Contenido específico por método */}
        {metodo === 1 && (
          <>
            <FormControlLabel 
              control={<Checkbox checked={usarCorreoRegistrado} onChange={handleCheckboxChange} />}
              label="Utilizar el correo registrado del actor"
              sx={{marginBottom: 2 }}
            />
            
            <Typography variant="body1" sx={{ mt: 2 }}>
              Ingresar correo:
            </Typography>
            <TextField
              value={correo}
              onChange={(e) => setCorreo(e.target.value)}
              fullWidth
              sx={{ mt: 1 }}
            />
          </>
        )}

        {/*Solo si se selecciona Domicilio */}
        {metodo === 2 && (
          <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2, width: '100%' }}>
            <Grid container spacing={2}>
              {/* Columna 1 */}
              <Grid item xs={12} md={4}>
                <Typography variant="body1">Método:</Typography>
                <Select
                  value={metodo}
                  onChange={handleMethodChange}
                  fullWidth
                  sx={{ mt: 1 }}
                >
                {tiposNotificacion.map((tipo) => (
                  <MenuItem key={tipo.ordinal} value={tipo.ordinal}>{tipo.descripcion}</MenuItem>
                ))} 
                </Select>

                <FormControl fullWidth sx={{ mt:2, mb: 2 }}>
                  <InputLabel>Estado</InputLabel>
                  <Select
                    value={selectedEstado}
                    onChange={(e) => {
                      const estadoSeleccionado = e.target.value;
                      setSelectedEstado(estadoSeleccionado);
                    }}
                    label="Estado"
                  >
                    {estados.map((estado) => (
                      <MenuItem key={estado.id} value={estado.name}>{estado.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <TextField value={ciudad}
                onChange={(e) => setCiudad(e.target.value)}
                label="Ciudad" fullWidth sx={{ mb: 2 }} />

                <TextField 
                value={calle}
                onChange={(e) => setCalle(e.target.value)}
                label="Calle" 
                fullWidth sx={{ mb: 2 }} />

                <TextField 
                value={latitud}
                onChange={(e) => setLatitud(e.target.value)}
                label="Latitud" 
                fullWidth sx={{ mb: 2 }} />
              </Grid>

              {/* Columna 2 */}
              <Grid item xs={12} md={4}>
                <FormControlLabel
                  control={<Checkbox checked={usarMismaDireccion} onChange={handleCheckboxChangeDireccion} />}
                  label="Misma dirección del participante"
                  sx={{ mb: 2 }}
                />
                <TextField 
                value={codigoPostal}
                onChange={(e) => setCodigoPostal(e.target.value)}
                label="Código Postal" 
                fullWidth sx={{ mb: 2 }} />
                
                <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
                  <InputLabel>Municipio</InputLabel>
                  <Select
                    value={selectedMunicipio || ''}
                    onChange={(e) => setSelectedMunicipio(e.target.value)}
                    label="Municipio"
                  >
                    {municipios.map((municipio) => (
                      <MenuItem key={municipio.id} value={municipio.name}>{municipio.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>


                <TextField 
                value={colonia}
                onChange={(e) => setColonia(e.target.value)}
                label="Colonia" 
                fullWidth sx={{ mb: 2 }} 
                />
                <Box display="flex" justifyContent="space-between" sx={{ mb: 2 }}>
                  <TextField 
                  value={exterior}
                  onChange={(e) => setExterior(e.target.value)}
                  label="No. Exterior" 
                  fullWidth sx={{ mr: 1 }} />

                  <TextField 
                  value={interior}
                  onChange={(e) => setInterior(e.target.value)}
                  label="No. Interior" 
                  fullWidth />
                </Box>
                <TextField 
                value={longitud}
                onChange={(e) => setLongitud(e.target.value)}
                label="Longitud" 
                fullWidth sx={{ mb: 2 }} />
              </Grid>

              {/* Columna 3 */}
              <Grid item xs={12} md={4}>
                <Button variant="contained" sx={{ mb: 2 }}>
                  Buscar
                </Button>
                <Box sx={{ border: '1px solid #ddd', height: '300px', mt: 2 }}>
                  <Typography align="center" sx={{ pt: 10 }}>Mapa</Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}

        {/* Botones de acción */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 'auto',
            pt: 3,
          }}
        >
          <Button
            type="button"
            variant="contained"
            className="button-save"
            onClick={handleSave}
            sx={{ marginRight: "10px" }}
            disabled={isDisabled}
          >
            Guardar
          </Button>

          <Button
            type="button"
            variant="contained"
            className="button-close"
            onClick={handleCancelClick}
          >
            Cancelar
          </Button>
        </Box>
      </Box>
    </MuiModal>
  );
};

export default NotificationModal;
