import axios from "../../api/axiosConfig";

export const fetchTipoAudienciasGenerales = async (token, page = 0, size = 20, sort = '', key = '') => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_TRIALS_API_URL}/api/core/tipoaudiencia`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page,
          size,
          sort,
          key,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error al obtener audiencias generales:', error);
    throw error;
  }
};

export const fetchTipoAudienciasAutocomplete = async (token, page = 0, size = 20, nombre = '') => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_TRIALS_API_URL}/api/core/tipoaudiencia/autocomplete`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page,
          size,
          nombre,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error al obtener el autocompletado de tipo audiencias:', error);
    throw error;
  }
};