import { Autocomplete, Box, Checkbox, Chip, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Paper, Select, styled, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { getDocumento } from "../../../../../BandejaOficios/BandejaOficiosService";
import keycloakConf from "../../../../../../api/keycloakConf";

const General = ({
    formData,
    setFormData,
    handleInputChange,
    errors,
    etapasProcesales,
    tiposAcuerdos,
    promociones,
    rubros,
    isSentencia,
    isDisabled }) => {

    const [inputValueAutoComplete, setInputValueAutoComplete] = useState('');
    const [rubroAux, setRubroAux] = useState();
    const { getToken } = useContext(keycloakConf);
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const handleAddRubro = (value) => {

        let rubroSelected = value;

        if (rubroSelected) {
            let existeRubro = formData.general.rubros.some(element => element.id === rubroSelected.id);

            if (!existeRubro) {

                setFormData((prevState) => ({
                    ...prevState,
                    general: {
                        ...prevState.general,
                        rubros: [...prevState.general.rubros, rubroSelected.nombre]
                    }
                }))
            }
        }

    }

    const handleEliminarRubro = (rubro) => {
        let rubrosTemp = formData.general.rubros.filter(item => item !== rubro);

        setFormData((prevState) => ({
            ...prevState,
            general: {
                ...prevState.general,
                rubros: rubrosTemp
            }
        }))

    }

    const handleCheckPromocion = (promocion, isChecked) => {

        setFormData(prevFormData => {
            //verificamos si el elemento seleccionado existe en el arreglo de promociones relacionadas:
            const existePromocion = prevFormData.general.promocionesRelacionadas.some(
                item => item.id === promocion.id
            );

            if (existePromocion) {
                // Eliminar la promoción si ya está seleccionada y se desmarca el checkbox
                return {
                    ...prevFormData,
                    general: {
                        ...prevFormData.general,
                        promocionesRelacionadas: prevFormData.general.promocionesRelacionadas.filter(
                            item => item.id !== promocion.id
                        )
                    }
                };
            } else {
                // Agregar la promoción si no existe en el array y se marca el checkbox
                return {
                    ...prevFormData,
                    general: {
                        ...prevFormData.general,
                        promocionesRelacionadas: [
                            ...prevFormData.general.promocionesRelacionadas,
                            promocion
                        ]
                    }
                };
            }
        });
    };

    const handleDownloadPromocion = async (element, item) => {
        let documentoId = element.id;
        
        try {
      
            let documento = await getDocumento(getToken(), documentoId)
            let blob = new Blob([documento], { type: 'application/pdf' });
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob)
            link.target = "promocion.pdf"
            link.click()

        } catch (error) {
            console.log("Error al obtener el oficio");
        }
    }


    return (<>
        <Grid container spacing={2} sx={{ mt: 2 }}>
            {!isSentencia && (
                <Grid item sm={4}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel id="select-tipoAcuerdo-label">Tipos de acuerdo:</InputLabel>
                        <Select
                            labelId="select-acuerdo-label"
                            name="general.tipoAcuerdo"
                            value={formData?.general.tipoAcuerdo && tiposAcuerdos.length > 0 ? formData.general.tipoAcuerdo : ''}
                            onChange={handleInputChange}
                            label="Tipo de acuerdo"
                            disabled={isDisabled}
                            error={!!errors["general.tipoAcuerdo"]}

                            required
                        >
                            {tiposAcuerdos.map((tipoAcuerdo) => (
                                <MenuItem key={tipoAcuerdo.id} value={tipoAcuerdo.nombreAcuerdo}>
                                    {tipoAcuerdo.nombreAcuerdo}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText >{errors["general.tipoAcuerdo"] || ''}</FormHelperText>
                    </FormControl>
                </Grid>
            )}


            {isSentencia && (
                <Grid item sm={4}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel id="select-tipoAcuerdo-label">Tipos de sentencia:</InputLabel>
                        <Select
                            labelId="select-acuerdo-label"
                            name="general.tipoSentencia"
                            value={formData?.general.tipoSentencia}
                            onChange={handleInputChange}
                            label="Tipo de sentencia"
                            error={!!errors["general.tipoSentencia"]}
                            disabled={isDisabled}
                            required
                        >

                            <MenuItem key={1} value="SENTENCIA_DEFINITIVA">
                                Definitiva
                            </MenuItem>
                            <MenuItem key={2} value="SENTENCIA_INTERLOCUTORIA">
                                Interlocutora
                            </MenuItem>

                        </Select>
                        <FormHelperText >{errors["general.tipoSentencia"] || ''}</FormHelperText>
                    </FormControl>
                </Grid>
            )}


            <Grid item sm={4}>
                <TextField
                    label="Fecha de resolución: "
                    name="general.fechaResolucion"
                    onChange={handleInputChange}
                    fullWidth
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    error={!!errors["general.fechaResolucion"]}
                    helperText={errors["general.fechaResolucion"]}
                    value={formData.general.fechaResolucion}
                    disabled={isDisabled}
                />
            </Grid>

            <Grid item sm={4}>
                <FormControl fullWidth variant="outlined">
                    <InputLabel id="select-etapa-procesal-label">Etapas procesales:</InputLabel>
                    <Select
                        labelId="select-etapa-procesa-label"
                        name="general.etapaProcesal"
                        value={formData?.general.etapaProcesal && etapasProcesales.length > 0 ? formData.general.etapaProcesal : ''}
                        onChange={handleInputChange}
                        error={!!errors["general.etapaProcesal"]}
                        label="Etapas procesales:"
                        required
                        disabled={isDisabled}
                    >
                        {etapasProcesales.map((etapaProcesal) => (
                            <MenuItem key={etapaProcesal.id} value={etapaProcesal.etapaProcesal}>
                                {etapaProcesal.etapaProcesal}
                            </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText >{errors["general.etapaProcesal"] || ''}</FormHelperText>
                </FormControl>
            </Grid>
        </Grid>

        {!isSentencia && (
            <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item sm={4}>
                    <Autocomplete
                        options={inputValueAutoComplete ? rubros : rubros.slice(0, 20)}
                        getOptionLabel={(option) => option.nombre}
                        value={rubroAux}
                        disabled={isDisabled}
                        onChange={(event, item) => {
                            handleAddRubro(item);
                        }}
                        onInputChange={(event, newInputValue) => {
                            setInputValueAutoComplete(newInputValue);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Rubros"
                                name="general.rubros"
                                variant="outlined"
                                error={!!errors["general.rubros"]}
                                helperText={errors["general.rubros"]}
                            />
                        )}
                    />
                </Grid>

                <Grid item sm={8}>

                    <Box paddingBottom={2}>
                        <Typography variant="body1">Rubros</Typography>
                        <Paper
                            elevation={1}
                            sx={{ padding: 2, flex: 1, overflowY: "auto" }}

                        >
                            {formData.general.rubros.map((rubro) => (
                                <Chip
                                    disabled={isDisabled}
                                    sx={{ mx: 1, mb: 1 }}
                                    key={rubro}
                                    id={rubro}
                                    label={rubro}
                                    onDelete={() =>
                                        handleEliminarRubro(rubro)
                                    }
                                />
                            ))}
                        </Paper>
                    </Box>

                </Grid>

            </Grid>
        )}

        {isSentencia && (
            <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item sm={4}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel id="select-tipo-resolucion-label">Tipo de Resolución:</InputLabel>
                        <Select
                            labelId="select-tipo-resolucion-label"
                            name="general.tipoResolucion"
                            value={formData?.general.tipoResolucion}
                            disabled={isDisabled}
                            onChange={handleInputChange}
                            error={!!errors["general.tipoResolucion"]}
                            label="Tipo Resolución:"
                            required
                        >

                            <MenuItem key={1} value="CONDENATORIA">
                                Condenatoria
                            </MenuItem>
                            <MenuItem key={2} value="OBSOLUTORIA">
                                Obsolutoria
                            </MenuItem>
                            <MenuItem key={3} value="DECLARATIVA">
                                Declarativa
                            </MenuItem>
                            <MenuItem key={4} value="IMPROCEDENTE">
                                Improcedente
                            </MenuItem>
                        </Select>
                        <FormHelperText >{errors["general.etapaProcesal"] || ''}</FormHelperText>
                    </FormControl>
                </Grid>

                <Grid item sm={8}>
                    <TextField
                        label="Extracto de Sentencia"
                        name="general.extractoSentencia"
                        value={formData?.general.extractoSentencia || ''}
                        onChange={handleInputChange}
                        multiline
                        rows={2}
                        variant="outlined"
                        fullWidth
                        error={!!errors["general.extractoSentencia"]}
                        helperText={errors["gemeral.extractoSentencia"]}
                        disabled={isDisabled}
                        inputProps={{
                            maxLength: 600
                        }}
                    />
                </Grid>
            </Grid>
        )}

        {!isSentencia && (
            <Grid container spacing={2} sx={{ mt: 2 }}>

                <Grid item sm={8}>
                    <TextField
                        label="Resumen"
                        fullWidth
                        name="general.resumen"
                        onChange={(e) => handleInputChange(e)}
                        value={formData.general.resumen}
                        variant="outlined"
                        error={!!errors["general.resumen"]}
                        helperText={errors["general.resumen"]}
                        disabled={isDisabled} />
                </Grid>

                <Grid item sm={4}>
                    <Typography variant="body1">Recomendaciones:</Typography>
                    <Paper elevation={1} sx={{ padding: 2, flex: 1, overflowY: "auto" }}>
                        {formData.general.promocionesRelacionadas.map((promocion) => (
                            <Typography variant="body1" key={promocion.id}>{promocion.recomendacion}</Typography>
                        ))}
                    </Paper>
                </Grid>


            </Grid>
        )}


        <Grid container spacing={2} sx={{ mt: 2 }}>

            <Grid item sm={8}>
                <Typography variant="body1">Promociones relacionadas</Typography>
                <TableContainer sx={{ maxHeight: 600, overflowY: 'auto' }}>
                    <Table stickyHeader sx={{ minWidth: 750 }}>

                        <TableBody>
                            {promociones.map((promocion) => (
                                <StyledTableRow
                                    key={promocion.id}
                                    hover
                                    style={{ cursor: 'pointer' }}>
                                    <TableCell> <FormControlLabel
                                        control={
                                            <Checkbox
                                                disabled={isDisabled}
                                                checked={formData.general.promocionesRelacionadas.some(
                                                    item => item.id === promocion.id
                                                )}
                                                onChange={(e) => {
                                                    handleCheckPromocion(promocion, e.target.checked)
                                                }}
                                            />
                                        }
                                    /></TableCell>
                                    <TableCell>{promocion.nombre}</TableCell>
                                    <TableCell>
                                        
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                color: "blue",
                                                textDecoration: "underline",
                                                cursor: "pointer",
                                                "&:hover": {
                                                    textDecoration: "none", // Estilo al pasar el mouse
                                                },
                                            }}
                                            onClick={ () => { handleDownloadPromocion(promocion) } }
                                        >
                                            {promocion.nombreArchivo}
                                        </Typography>
                                    </TableCell>

                                </StyledTableRow>
                            ))}
                            {promociones.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={6} align="center">
                                        No hay registros disponibles.
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>


        </Grid>



    </>)
}

export default General;