import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  Button,
  Stack,
  Paper
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchInput from "../../../components/Search/SearchInput";
import keycloakConf from "../../../api/keycloakConf";
import { fetchIndicadores } from '../../../shared/services/DocumentoService';
import { styled } from '@mui/material/styles';

// Estilo personalizado para los indicadores
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  borderRadius: '8px',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
  fontWeight: 'bold',
  fontSize: '14px',
  border: '2px solid transparent',
  "&:nth-of-type(3)": {
    color: '#8c92bc', // Color azul grisáceo
    borderColor: '#8c92bc',
  },
  "&:nth-of-type(2)": {
    color: '#8c92bc', // Color azul grisáceo
    borderColor: '#8c92bc',
  },
  "&:nth-of-type(1)": {
    color: '#8c92bc', // Color azul grisáceo
    borderColor: '#8c92bc',
  },
  "&:nth-of-type(4)": {
    color: '#8c92bc', // Color azul grisáceo
    borderColor: '#8c92bc',
  },
}));

const HeaderBandejaAsignados = ({
  loadBandejas,
  searchQuery,
  setSearchQuery
}) => {
  const navigate = useNavigate();
  const { getToken } = useContext(keycloakConf);
  const [indicadores, setIndicadores] = useState(null);

  const loadIndicadores = async () => {
    try {
      const token = getToken(); 
      const data = await fetchIndicadores(token, false);
      setIndicadores(data);
    } catch (error) {
      console.error('Error al cargar indicadores:', error);
    }
  };

  useEffect(() => {
    loadIndicadores();
  }, [getToken]);

  const handleCancel = () => {
    navigate("/");
  };

  if (!indicadores) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Grid container spacing={2} alignItems="center">
      {/* Botón de cerrar */}
      <Grid item xs={12} sm={12} md={2}>
        <Button
        className="button-close"
          variant="contained"
          color="error"
          onClick={handleCancel}
          sx={{ marginLeft: '10px' }}
        >
          Cerrar
        </Button>
      </Grid>

      {/* Indicadores */}
      <Grid item xs={12} sm={12} md={6}>
        <Stack direction="row" spacing={2}>
          <Item>
            <div style={{ color: "#636569" }}>Expedientes<br />asignados</div>
            <div style={{ fontSize: '24px' }}>{indicadores.indicador1}</div>
          </Item>
          <Item>
            <div style={{ color: "#636569" }}>Término<br /><strong>rebasado</strong></div>
            <div style={{ fontSize: '24px' }}>{indicadores.indicador2}</div>
          </Item>
          <Item>
            <div style={{ color: "#636569" }}>Término<br /><strong>24 hrs</strong></div>
            <div style={{ fontSize: '24px' }}>{indicadores.indicador3}</div>
          </Item>
          <Item>
            <div style={{ color: "#636569" }}>Término<br /><strong>3 días</strong></div>
            <div style={{ fontSize: '24px' }}>{indicadores.indicador4}</div>
          </Item>
        </Stack>
      </Grid>

      {/* Campo de búsqueda */}
      <Grid item xs={12} sm={12} md={4}>
        <SearchInput
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Buscar..."
          ariaLabel="Campo de búsqueda para bandeja de asignados"
          tooltipTitle="Escriba para buscar"
          onKeyDown={(e) => e.key === "Enter" && loadBandejas()}
        />
      </Grid>
    </Grid>
  );
};

export default HeaderBandejaAsignados;
