import axios from 'axios';

async function fetchNacionalidades(token) {
    try {
        const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + '/api/core/nacionalidades', {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error al obtener nacionalidades:', error);
    }
}

export default fetchNacionalidades;