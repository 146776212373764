import Concepto from "../pages/Conceptos/Concepto";

const Glosario = {
  Nombre: "Nombre",
  Mate: "Materia",
  Juzgado: "Juzgado",
  Acciones: "Acciones",
  Folio: "Folio",
  NoExpediente: "No. Expediente",
  TipoEntrada: "Tipo de entrada",
  Origen: "Origen",  
  Concepto: "Concepto",
  FechaHoraEnvio: "Fecha y hora de envío", 
  FechaHora: "Fecha y hora",
  FechaTurnado: "Fecha turnado",
  FechaTermino: "Fecha término", 
  Estatus: "Estado",
  Tipo: "Tipo",
  NumeroOficio: "Número de oficio",
  Dependencia: "Dependencia",
  FechaEmision: "Fecha de emisión", 
  FechaEntrega: "Fecha de entrega", 
  Destino: "Destino",
  HoraInicial: "Hora inicial", 
  HoraFinal: "Hora final",
  Id: "ID",
  Calle: "Calle",
  NInterior: "N. Interior",
  Colonia: "Colonia",
  Direccion: "Dirección",
  Telefono: "Teléfono",
  FechaAlta: "Fecha de alta",
  Centrotrabajo: "Centro de trabajo",
  CorreoElectronico: "Correo electrónico",
  Sala: "Sala",
  Juez: "Juez",
  Horario: "Horario",
  NumAcuerdo: "Número de acuerdo",
  FechaResolucion: "Fecha de resolución",
  FechaPublicacion: "Fecha de publicación",
  Resumen: "Resumen",
  AccionesRapidas: "Acciones rapidas",
  TipoAcuerdo: "Tipo",
  Metodo: "Método",
  numCarpeta: "Número de carpeta",
  lugar: "Lugar",
  fecha: "Fecha",
  organoJurisdiccional: "Órgano jurisdiccional",
  descripcion: "Descripción",
  actor: "Actor",
  demandado: "Demandado",
  FechaCreacion: "Fecha de creación",
  NoElementos: "No. Elementos",
  usuario: "Usuario",
  Notas: "Notas",
  rol: "Rol",
  asistencia: "Asistencia",
  documentoIdenficacion: "Documento con el que se identifica",
  selector:"Selector",
  domicilio:"Domicilio",
  fechaSalida: "Fecha salida",
  fechaNotificado: "Fecha notificado",
  nombreDestinatario: "Nombre del destinatario",
  metodoNotificacion: "Metódo de notificación",
  comentarios: "Comentarios",
  concepto: "Concepto",
  dias:"Días de termino",
  juicio: "Juicio"
};

export default Glosario;
