import React, { useEffect, useState, useContext } from "react";
import { Grid } from "@mui/material";
import { validateFields } from '../../../components/Alert/ValidationRequired/validationRequired';
import keycloakConf from "../../../api/keycloakConf";
import FormSentenciaPublica from "./Form/FormSentenciaPublica";
import FormExpedienteJuzgado from "../../../layouts/FormExpedienteJuzgado/FormExpedienteJuzgado";
import { useToastAlert } from '../../../components/Alert/ToastAlertSave/ToastAlertSave';
import { useToastAlertValidation } from '../../../components/Alert/ToastAlertValidation/ToastAlertValidation';
import { fetchCarpetaSentencia } from '../../../shared/services/CarpetaService';
import { createSentenciaPublica } from '../../../shared/services/DocumentoService';
import ConfirmationModal from "../../../components/Modal/ConfirmationModal";


export default function SentenciaPublica() {
  const { getToken } = useContext(keycloakConf);
  const { showSnackbar } = useToastAlert();
  const { showSnackbar: showAlertValidate } = useToastAlertValidation();
  const [errorMessages, setErrorMessages] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [bandValidate, setBandValidate] = useState(false);
  const [isButtonCuestionario, setIsButtonCuestionario] = useState(true); //cambiar a false para validar cuestionario y cambiar en el metodo clearFields()
  const [isButtonArchivo, setIsButtonArchivo] = useState(false);

  const [formData, setFormData] = useState({
    expediente: '',
    year: '',
    idCarpeta: "",
    actor: "",
    demandado: "",
    materia: "",
    juzgado: "",
    sentencia: "",
    resolucion: "",
    fechaResolucion: null
  });

  const clearFields = () => {
    setFormData(() => ({
      expediente: '',
      year: '',
      idCarpeta: "",
      actor: "",
      demandado: "",
      materia: "",
      juzgado: "",
      sentencia: "",
      resolucion: "",
      fechaResolucion: null
    }));
    setBandValidate(false);
    setIsButtonCuestionario(true); // cambiar a false para validar cuestionario
    setIsButtonArchivo(false);
  };
  
  const handleSubmitFEJ = async () => {
    if(bandValidate){
      if(!isButtonCuestionario){
        showSnackbar('Falta completar el cuestionario', 'error');
        
        return;
      }
      
      if(!isButtonArchivo){
        showSnackbar('Falta añadir archivo', 'error');

        return;
      }

      setErrorMessages({});
      setModalOpen(true)
    }else{
      const rules = {
        expediente: { required: true },
        year: { required: true },
      };
      const errors = validateFields(formData, rules, showAlertValidate);
      if (Object.keys(errors).length > 0) {
        setErrorMessages(errors);
        return;
      }
      setErrorMessages({});
      showSnackbar('Falta validar expediente, año', 'error');
    }  

  };
  
  const handleValidateFEJ = (e) => {
    const rules = {
      expediente: { required: true },
      year: { required: true },
    };
    const errors = validateFields(formData, rules, showAlertValidate);
    if (Object.keys(errors).length > 0) {
      setErrorMessages(errors);
      return;
    }
    searchCarpeta();
    setErrorMessages({});
  };


  const searchCarpeta = async () => {
    try {
      let token = getToken();
      const data= {
        "numExpediente": formData.expediente,
        "year": Number(formData.year),
      };
      const response = await fetchCarpetaSentencia(token, data);
      setFormData((prev) => ({
        ...prev,
        idCarpeta: response.idCarpeta,
        idDocumento: response.idDocumento,
        actor: response.actor,
        demandado: response.demandado,
        materia: response.materia,
        juzgado: response.juzgado,
        sentencia: response.sentencia,
        resolucion: response.resolucion,
        fechaResolucion: response.fechaResolucion
      }));

      setBandValidate(true);

    } catch (error) {
      setBandValidate(false);
      showSnackbar('No existe expediente relacionado con la información ingresada', 'error');
    }
  };

  const handleConfirm = async () => {
    try {
      let token = getToken();

      await createSentenciaPublica(token, formData.idDocumento, formData.archivo);

      clearFields();
      showSnackbar("Sentencia pública guardada exitosamente.", "success");
    } catch (error) {
      console.error("Error al guardar Sentencia publica.");
      showSnackbar("¡Algo salió mal!", "error");
    }
    setModalOpen(false);
  };

  return (
    <>
      <ConfirmationModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={handleConfirm}
        title="Guardar sentencia pública?"
        message="Se guardará una nueva sentencia pública, con la información proporcionada."
        confirmationButton="Sí, guardar"
        closeButton="No, cancelar"
      />

      <Grid item xs={12} sm={6} md={6}>
        <FormExpedienteJuzgado 
          handleSubmit={handleSubmitFEJ}
          handleValidate={handleValidateFEJ}
          formData={formData}
          setFormData={setFormData}
          errorMessages={errorMessages}
          irAruta={'/home'}
          showJuzgado={false}
        />
      </Grid>
      {bandValidate && (
        <FormSentenciaPublica 
          formData={formData}
          setFormData={setFormData}
          errors = {errorMessages}
          setErrorMessages = {setErrorMessages}
          setIsButtonCuestionario = {setIsButtonCuestionario}
          setIsButtonArchivo = {setIsButtonArchivo}
        />
      )}
    </>
  );
}
