import axios from 'axios';

export const fetchCatalogo = async (token, catalogo) => {
    try {
        let url;

        switch (catalogo) {
            case 'tipoDefensor':
                url = `/api/workflow/carpeta/enums/catalogoTipoDefensor`;
                break;
            case 'tipoDomicilio':
                url = `/api/workflow/carpeta/enums/catalogoTiposDomicilio`;
                break;
            case 'discapacidad':
                url = `/api/workflow/carpeta/enums/catalogoDiscapacidades`;
                break;
            case 'condicionMigratoria':
                url = `/api/workflow/carpeta/enums/catalogoCondicionMigratoria`;
                break;
            case 'grupoVulnerable':
                url = `/api/workflow/carpeta/enums/catalogoGrupoVulnerable`;
                break;
            case 'profesionOficio':
                url = `/api/workflow/carpeta/enums/catalogoProfesionOficio`;
                break;
            case 'catalogoIngresoMensualNeto':
                url = `/api/workflow/carpeta/enums/catalogoIngresoMensualNeto`;
                break;
            case 'catalogoFrecuenciaIngreso':
                url = `/api/workflow/carpeta/enums/catalogoFrecuenciaIngreso`;
                break;
            case 'estadoCivil':
                url = `/api/workflow/carpeta/enums/catalogoEstadoCivil`;
            break;
            default:
                throw new Error(`Catálogo desconocido: ${catalogo}`);
        }

        const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + url, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });

        return response.data;
    } catch (error) {
        console.error(`Error al listar ${catalogo}:`, error);
        throw error;
    }
};
