import React from 'react';
import {
    Box,
    Paper,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip
} from '@mui/material';
import PaginationGeneric from '../../../components/Pagination/PaginationGeneric';
import ApprovalIcon from '@mui/icons-material/Approval';
import PrintIcon from '@mui/icons-material/Print';
import { getCaratula, getSello } from '../../Demanda/DemandaService';
import { useToastAlert } from '../../../components/Alert/ToastAlertSave/ToastAlertSave';
import text from '../../../assets/Glosario';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const CarpetaTable = ({
    data,
    token,
    pageable,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage
}) => {
    const { showSnackbar } = useToastAlert();

    const handleDownloadSello = async (item) => {
        try {
            const sello = await getSello(token, item.id);
            let blob = new Blob([sello], { type: 'application/pdf' });
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob)
            link.target = item.ruta
            link.click()
        } catch (error) {
            console.error('Error al generar el documento:', error);
            showSnackbar('¡Algo salió mal!', 'error');
        }
    }

    const handleDownloadCaratula = async (item) => {
        try {
            const sello = await getCaratula(token, item.id);
            let blob = new Blob([sello], { type: 'application/pdf' });
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob)
            link.target = item.ruta
            link.click()
        } catch (error) {
            console.error('Error al generar el documento:', error);
            showSnackbar('¡Algo salió mal!', 'error');
        }
    }


    const sortedData = [...data].sort((a, b) => new Date(b.fechaRegistro) - new Date(a.fechaRegistro));

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <PaginationGeneric
                    pageable={pageable}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                />
                <TableContainer sx={{ maxHeight: 600, overflowY: 'auto' }}>
                    <Table stickyHeader sx={{ minWidth: 750 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell className='table-header'>{text.Folio}</TableCell>
                                <TableCell className='table-header'>{text.NoExpediente}</TableCell>
                                <TableCell className='table-header'>{text.Estatus}</TableCell>
                                <TableCell className='table-header'>{text.Mate}</TableCell>
                                <TableCell className='table-header'>{text.TipoEntrada}</TableCell>
                                <TableCell className='table-header'>{text.FechaHora}</TableCell>
                                <TableCell className='table-header'>{text.Acciones}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedData.map((item, index) => (
                                <StyledTableRow
                                    key={index}
                                    hover
                                >
                                    <TableCell>{item.folio}</TableCell>
                                    <TableCell>{item.expediente}</TableCell>
                                    <TableCell>
                                        {item.estatus === "EDICION" ? 'Edición' :
                                            item.estatus.charAt(0).toUpperCase() + item.estatus.slice(1).toLowerCase()}
                                    </TableCell>
                                    <TableCell>{item.materia}</TableCell>
                                    <TableCell>
                                    {item.tipoEntrada === "Promocion" ? 'Promoción' : item.tipoEntrada}
                                    </TableCell>
                                    <TableCell>{new Date(item.fechaRegistro).toLocaleString()}</TableCell>
                                    <TableCell align="left">
                                        <Tooltip title="Impresión de sello">
                                            <ApprovalIcon style={{ marginRight: '10px' }} className='icon-color' onClick={() => handleDownloadSello(item)} />
                                        </Tooltip>
                                        <Tooltip title="Impresión de carátula">
                                            <PrintIcon className='icon-color' onClick={() => handleDownloadCaratula(item)} />
                                        </Tooltip>
                                    </TableCell>
                                </StyledTableRow>
                            ))}
                            {sortedData.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={7} align="center">
                                        No hay registros disponibles.
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <PaginationGeneric
                    pageable={pageable}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                />
            </Paper>
        </Box>
    );
};

export default CarpetaTable;
