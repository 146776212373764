import React, { useState, useContext, useEffect } from 'react';
import {
  Box,
  Modal,
  IconButton,
  Divider,
  Typography,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import keycloakConf from '../../../api/keycloakConf';
import { getDetalleNotificacion } from '../../../shared/services/NotificacionService';

const ResumenNotificacionModal = ({
  isModalOpen,
  setModalOpen,
  expedienteData,
  formatDate
}) => {
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    borderRadius: '8px',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
    p: 2,
    maxWidth: '700px',
    width: '100%',  
  };

  const { getToken } = useContext(keycloakConf);
  const [loading, setLoading] = useState(false);
  const [nota, setNota] = useState('');
  const [detalle, setDetalle] = useState({
    parte: "",
    nombre: "",
    domicilio: "",
  });

  useEffect(() => {
    loadDetalleNotificacion();
  }, []);
  
 const loadDetalleNotificacion = async () => {
    try {
      setLoading(true);
      const token = getToken();
      const data = await getDetalleNotificacion(token, expedienteData.id);
      console.log("data", data);
      setDetalle((prevDetalle) => ({
        ...prevDetalle,
        ...data,
      }));
    } catch (error) {
      console.error('Error obtener detalle notificacion', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={isModalOpen}
      onClose={() => setModalOpen(false)}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={modalStyle}>
        {loading ? <p>Cargando...</p> : (
          <>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography id="modal-title" variant="h6" component="h2" sx={{ fontWeight: 'bold', color: '#333' }}>
                Expediente: <strong>{expedienteData.expediente || 'N/A'}</strong></Typography>
              <IconButton onClick={() => setModalOpen(false)}>
                <CloseIcon />
              </IconButton>
            </Box>

            <Divider sx={{ my: 1 }} />

            <Typography sx={{pl: 2}}>
              Concepto: <strong>{Array.isArray(expedienteData.concepto) && expedienteData.concepto.length > 0 ? expedienteData.concepto : 'N/A'}</strong>
            </Typography>
            <Typography sx={{pl: 2}}>
              Fecha de publicación: <strong>{expedienteData.documentoDetalleRecord?.fechaPublicacion ? formatDate(expedienteData.documentoDetalleRecord.fechaPublicacion) : '-'}</strong>
            </Typography>
            <Typography sx={{pl: 2}}>
              Fecha de resolución: <strong>{expedienteData.documentoDetalleRecord?.fechaResolucion ? formatDate(expedienteData.documentoDetalleRecord.fechaResolucion) : '-'}</strong>
            </Typography>
            <Typography sx={{pl: 2}}>
              Parte: <strong>{detalle.parte || '-'}</strong>
            </Typography>
            <Typography sx={{pl: 2}}>
              Nombre: <strong>{detalle.nombre || '-'}</strong>
            </Typography>
            <Typography sx={{pl: 2}}>
              Domicilio: <strong>{detalle.domicilio || '-'}</strong>
            </Typography>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default ResumenNotificacionModal;
