import { Box, Divider, Grid, Tab, Tabs } from "@mui/material";
import Acciones from "../../components/Acciones/Acciones";
import { useState, useRef } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import General from "./Tabs/General";
import Asistencia from "./Tabs/Asistencia";
import DetallesPruebas from "./Tabs/DetallesPruebas";
import { useItem } from "../../components/ItemProvider/ItemContext";

const VistaAudienciaGeneral = () => {
    const { item } = useItem();
    const location = useLocation();
    const itemFromLocation = location.state?.item || {};
    const itemToUse = item || itemFromLocation;
    
    const isConsulta = location.state.isConsulta || {};  //USAR item PARA MAPEAR DATOS DEL SELECCIONADO
    const tabnav = location.state.tabnav || 0;
    const isTab = location.state?.isTab || false;
    const generalRef = useRef();
    const [tabvalue, setTabValue] = useState(tabnav);
    const navigate = useNavigate();

    const isDetallesPruebasVisible = () => {
        const tipoJuicio = itemToUse.tipoJuicio?.toLowerCase() || "";
        return tipoJuicio.includes("mercantil") && tipoJuicio.includes("oral");
    };
    
    const handleActualizarAudiencia = async () => {
        if (generalRef.current) {
            await generalRef.current.handleActualizarAudiencia();
        }
    };
    
    const accion1 = () => {
        alert("Accion 1 lista");
    };

    const accion2 = () => {
        isTab
            ? navigate('/api/bandeja/expediente', {
                state: {
                    item: itemToUse,
                    tabnav: 3
                }
            })
            : navigate('/api/bandeja/audiencias');
    };

    // Cambio a tabs
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <>
            <Acciones accion1={handleActualizarAudiencia} accion2={accion2} isDisabled={isConsulta} />
            <Grid className="box-sombra">
                <Grid item xs={12} sx={{ marginBottom: 4 }}>
                    <Tabs value={tabvalue} onChange={handleTabChange} variant="fullWidth">
                        <Tab label="General" />
                        <Tab label="Asistencia" />
                        {/* Solo se muestra la pestaña de Detalles Pruebas si isDetallesPruebasVisible es verdadero */}
                        {isDetallesPruebasVisible() && <Tab label="Detalles pruebas" />}
                    </Tabs>
                </Grid>

                {tabvalue === 0 && (
                    <General
                        item={itemFromLocation}
                        ref={generalRef}
                        isConsulta={isConsulta}
                        isTabF={isTab}
                        itemToUse={item}
                    />
                )}

                {tabvalue === 1 && (
                    <Asistencia item={itemFromLocation} isConsulta={isConsulta} />
                )}

                {tabvalue === 2 && (
                    <DetallesPruebas
                        item={itemFromLocation}
                        isConsulta={isConsulta}
                        ref={generalRef}
                    />
                )}
            </Grid>
        </>
    );
};

export default VistaAudienciaGeneral;
