import React, { useEffect, useState, useContext } from 'react';

import {
    Typography
} from "@mui/material";
import KeycloakConf from '../../api/keycloakConf';
import FormTransferencia from './Form/FormTransferencia';
import Acciones from '../../components/Acciones/Acciones';
import ConfirmationModal from '../../components/Modal/ConfirmationModal';

import { useToastAlert } from '../../components/Alert/ToastAlertSave/ToastAlertSave';
import { useToastAlertValidation } from '../../components/Alert/ToastAlertValidation/ToastAlertValidation';
import { validateFields } from '../../components/Alert/ValidationRequired/validationRequired';
import { fetchPersonalTurnado } from '../../shared/services/PersonasService';
import { fetchTransferencia } from './TransferenciaService';
import { updateTransferencia } from './TransferenciaService';
import { downloadTransferencia } from './TransferenciaService';
import { Navigate, useLocation, useNavigate } from "react-router-dom";


const Transferencia = () =>{
    const { getToken } = useContext(KeycloakConf);
    const [loading, setLoading] = useState(true);
    const [personalTurnadoOptions, setPersonalTurnadoOptions] = useState([]);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const { showSnackbar } = useToastAlert();
    const { showSnackbar : showAlertValidate } = useToastAlertValidation();
    const [modalOpen, setModalOpen] = useState([]);
    const [transferencia, setTransferencia] = useState({
        id:'',
        personaEntregaId:'',
        personaEntrega:'',
        personaEntregaPuesto:'',
        personaRecibeId:'',
        personaRecibe: '',
        personaRecibePuesto:'',
        fechaTransferencia: null,
        totalExpedientes: 0,
        expedientes: [],
        uuid: '',
        estatus: '',
        observaciones: null
    });

    const [transferenciaData, setTransferenciaData] = useState({
        personaRecibeId: '',
        personaEntregaId: '',
        juzgadoId: '',
        observaciones: ''
    })

    const loadRegistroTransferencia = async () => {
        const token = getToken();

        try{
            const response = await fetchTransferencia(token);
            setTransferencia(response);
        }catch(error){
            console.error(error);
        }
    }

    const fetchUpdateJuzgado = async (data) => {
        const token = getToken();

        try{
            const response = await updateTransferencia(token, data, transferencia.id);
            setTransferencia(response);
            getReporte(response.uuid);
        }catch(error){
            console.error(error);
        }
    }

    const getReporte = async (uuid) =>{
        const token = getToken();

        try{
            const response = downloadTransferencia(token, uuid); 

            var blob = new Blob([response], { type: "application/pdf" });
            let link = document.createElement("a");
            
            link.href = window.URL.createObjectURL(blob);
            link.target = "ListaTransferencia.pdf";
            link.click();
        }catch (error) {
            showSnackbar("No se pudo descargar la lista de transferencia", "error");
        }
    }


    const handleCancel = () =>{
        navigate('/api/bandeja/asignados');
    };

    const handleOk = () => {
        const rules = {
            personaRecibeId: {required: true},
            observaciones: {required: true}
        }

        const errors = validateFields(transferencia, rules, showAlertValidate);

        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
        }

        setErrors({});

        setModalOpen(true);
    }

    const handleConfirm = () =>{
        const data = {
            personaEntregaId: transferencia.personaEntregaId,
            personaRecibeId: transferencia.personaRecibeId,
            juzgadoId: null,
            observaciones: transferencia.observaciones
        };

        fetchUpdateJuzgado(data);
    }

 
    useEffect(()=>{
        const loadPersonalJuzgado = async () => {
            const token = getToken();
    
            try{
                const response = await fetchPersonalTurnado(token);
                setPersonalTurnadoOptions(response);
            }catch(error){
                setErrors((prevData)=>({
                    ...prevData,
                    personal: 'Error al obtener el personal del Juzgado'
                }));
            }
        };

        setModalOpen(false);

        loadPersonalJuzgado();
        loadRegistroTransferencia();
    },[getToken])

    return(
        <div>
            <ConfirmationModal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                onConfirm={handleConfirm}
                title={"¿Transferir los expedientes?"}
                message={"Se  transferiran los expedientes a la persona seleccionada"}
                confirmationButton={"Sí, transferir"}
                closeButton="No, cancelar"
            />

            {/* Botón de cerrar */}
            <Acciones accion1={handleOk}  accion2={handleCancel} isDisabled={transferencia.id===null || transferencia.id ===''}/>

            {transferencia.id!==null && transferencia.id !=='' && 
                <FormTransferencia 
                personalTurnadoOptions = {personalTurnadoOptions}  
                transferencia = {transferencia}
                setTransferencia = {setTransferencia}
                errors={errors}
                isDisabled={transferencia.id===null || transferencia.id ===''}
                />
            }
            {transferencia.id===null || transferencia.id ==='' &&
                <Typography>No hay transferencias pendientes </Typography>
            }
        </div>
        
        
    );

}

export default Transferencia;