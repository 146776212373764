import axios from "../../api/axiosConfig";

// Función para obtener todos los juzgados
export const fetchJuzgados = async (token, page = 0, size = 10, sort = '', key = '') => {
    try {
        //defaults 
        const params = new URLSearchParams();
        params.append("sort", encodeURI('estado'));
        params.append("sort", encodeURI('materia.nombre'));
        params.append("sort", encodeURI('nombre'));
        params.append("page", page);
        params.append("size", size);
        params.append("key", key);
        const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/core/juzgados`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: 
                params
            ,
        });
        return response.data; // Devuelve el contenido de los juzgados
    } catch (error) {
        console.error('Error al listar juzgados:', error);
        throw error;
    }
};

export const addJuzgado = async (token, nuevoJuzgado) => {
    try {

        const response = await axios.post(process.env.REACT_APP_TRIALS_API_URL + `/api/core/juzgados`, nuevoJuzgado, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return response.data; // Devuelve la respuesta al agregar un juzgado
    } catch (error) {
        console.error('Error al agregar juzgado:', error);
        throw error;
    }
};


export const updateJuzgado = async (token, juzgado) => {
    try {
        const response = await axios.put(process.env.REACT_APP_TRIALS_API_URL + `/api/core/juzgados`, juzgado, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },

        });
        return response.data; // Devuelve la respuesta de la API
    } catch (error) {
        console.error('Error al actualizar juzgado:', error);
        throw error;
    }
};

// Función para eliminar un juzgado
export const deleteJuzgado = async (token, id) => {
    try {
        const response = await axios.delete(process.env.REACT_APP_TRIALS_API_URL + `/api/core/juzgados/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });

        return response.data; // Devuelve información sobre la eliminación
    } catch (error) {
        console.error('Error al eliminar juzgado:', error);
        throw error;
    }
};


export const updateStatus = async (token, id, status) => {
    try {
        const response = await axios.patch(process.env.REACT_APP_TRIALS_API_URL + `/api/core/juzgados/${id}/status/${status}`,
         {},
         {
           headers: {
             Authorization: `Bearer ${token}`,
           }
         }
        );
   
        return response.data;
     } catch (error) {
       console.log('Error al actualizar el estado del documento: ', error);
       
     }
};

export const fetchJuzgadoById = async (token, id) => {
    try {
        const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/core/juzgados/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        if (!response.data) {
            throw new Error('No se encontró el juzgado');
        }
        return response.data;
    } catch (error) {
        console.error('Error al obtener el juzgado:', error);
        if (error.response) {
            console.error('Respuesta del servidor:', error.response.data);
            console.error('Código de estado:', error.response.status);
        }
        throw new Error('No se pudo obtener el juzgado. Por favor, verifica la conexión e intenta de nuevo.');
    }
};

export const fetchJuzgadosAutocomplete = async (token, page = 0, size = 10, sort = '', key = '') => {
    try {
        const params = new URLSearchParams();
        params.append("sort", encodeURI('materia.nombre'));
        params.append("sort", encodeURI('nombre'));
        params.append("key", key);
        params.append("size", size);
        
        const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/core/juzgados/autocomplete`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: params,
        });
    
        return response.data;
    } catch (error) {
        console.error('Error al buscar juzgados:', error);
        throw error;
    }
};


export const fetchJuzgadoByOficialiaId = async (token, id) => {
    try {
        const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/core/juzgados/oficialias/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        if (!response.data) {
            throw new Error('No se encontró el juzgado relacionado a la oficialia');
        }
        return response.data;
    } catch (error) {
        console.error('Error al obtener el juzgado relacionado a la oficialia:', error);
        if (error.response) {
            console.error('Respuesta del servidor:', error.response.data);
            console.error('Código de estado:', error.response.status);
        }
        throw new Error('No se pudo obtener el juzgado relacionado a la oficialia. Por favor, verifica la conexión e intenta de nuevo.');
    }
};

export const fetchSalas = async (token) => {
    try {
        const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/core/juzgados/salas`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        if (!response.data) {
            throw new Error('No hay salas disponibles');
        }
        return response.data;
    } catch (error) {
        console.error('Error al obtener las salas:', error);
        if (error.response) {
            console.error('Respuesta del servidor:', error.response.data);
            console.error('Código de estado:', error.response.status);
        }
        throw new Error('No se pudo obtener las salas. Por favor, verifica la conexión e intenta de nuevo.');
    }
};

export const fetchJuzgadoActual = async (token) => {
    try {
        const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/core/juzgados/actual`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        if (!response.data) {
            throw new Error('No se puede obtener el juzgado actual');
        }
        return response.data;
    } catch (error) {
        console.error('Error al obtener el juzgado actual:', error);
        if (error.response) {
            console.error('Respuesta del servidor:', error.response.data);
            console.error('Código de estado:', error.response.status);
        }
        throw new Error('No se pudo obtener el juzgado actual. Por favor, verifica la conexión e intenta de nuevo.');
    }
};