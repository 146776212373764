import { Accordion, AccordionDetails, AccordionSummary, Box, IconButton, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ModalCreacionParticipantesPenal from "../../../../../components/Modal/ModalCreacionParticipantesPenal";
import glosario from "../../../../../assets/Glosario";
import DeleteIcon from '@mui/icons-material/Delete';

export default function Participantes({ 
    formData,
    setFormdata,
    tipoParticipantes,
    rolesParticipantes
 }) {
    const [openModal, setOpenModal] = useState(false);
    const [participanteSelected, setParticipanteSelected] = useState({});
   

    const handleNewParticipante = () => {
        setParticipanteSelected({});
        setOpenModal(true);
    }

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
        },
        "&:last-child td, &:last-child th": {
            border: 0,
        },
    }));

    const handleShowRegister = (item, indexElement) => {
        let participante = item;
        participante.indexElement = indexElement;
        setParticipanteSelected(participante);
        setOpenModal(true);
    }

    const handleRemoveParticipante = (item, indexElement) => {
        const participante = item;
        
        // Encuentra la categoría correspondiente
        
        
        const categoria = tipoParticipantes.find(
            (tipo) => tipo.id === participante.tipo).nombre;

        setFormdata((prevState) => {
            const participantesCategoria = prevState.participantes[categoria];
            const updatedParticipantes = participantesCategoria.filter((p, index) => indexElement !== index);
            return {
                ...prevState,
                participantes: {
                    ...prevState.participantes,
                    [categoria]: updatedParticipantes,
                },
            };
        });
    }

    const handleSaveParticipante = (item) => {
        const participante = item;

        // Encuentra la categoría correspondiente
        const categoria = tipoParticipantes.find(
            (tipo) => tipo.id === participante.tipo
        ).nombre;

        const rol = participante.rol ? rolesParticipantes.find(
            (rol) => rol.id === participante.rol
        ).nombre : "";

        participante.categoria = categoria;
        participante.rolStr = rol;
        
        // Actualiza el estado respetando la estructura anidada
        setFormdata((prevState) => ({
            ...prevState,
            participantes: {
                ...prevState.participantes,
                [categoria]: [...(prevState.participantes[categoria] || []), participante],
            },
        }));
    };


    const handleUpdateParticipante = (item) => {
        const participante = item;
        // Encuentra la categoría correspondiente
        const categoria = tipoParticipantes.find(
            (tipo) => tipo.id === participante.tipo
        ).nombre;

         const rol = participante.rol ? rolesParticipantes.find(
            (rol) => rol.id === participante.rol
        ).nombre : "";


        participante.categoria = categoria;
        participante.rolStr = rol;

        setFormdata((prevState) => {

            const participantesCategoria = prevState.participantes[categoria];

            const updatedParticipantes = participantesCategoria.map((p, index) =>
                p.indexElement === index ? participante : p
            );
            

            return {
                ...prevState,
                participantes: {
                    ...prevState.participantes,
                    [categoria]: updatedParticipantes,
                },
            };
        });
    }

    return (<>
        <Box display="flex" alignItems="center" justifyContent="space-between">
            <Tooltip title="Agregar participante">
                <IconButton className="icon-color" onClick={handleNewParticipante} >
                    <PersonAddIcon />
                </IconButton>
            </Tooltip>
        </Box>

        {Object.entries(formData).map(([categoria, elementos], index) => (
            <Accordion key={`accordion-header-${categoria}-${index}`}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel-${index}-content`}
                    id={`panel-${index}-header`}
                >
                    <Typography  variant="body1">
                        { categoria }
                    </Typography>
                </AccordionSummary>

                <AccordionDetails>
                    <Box sx={{ width: "100%" }} key={index}>
                        <Paper sx={{ width: "100%", mb: 2 }}>
                            <TableContainer sx={{ maxHeight: 600, overflowY: "auto" }}>
                                <Table stickyHeader sx={{ minWidth: 750 }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="table-header">{glosario.Nombre}</TableCell>
                                            <TableCell className="table-header">{glosario.Tipo}</TableCell>
                                            <TableCell className="table-header">{glosario.Acciones}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {elementos.length > 0 ? (
                                            elementos.map((item, indexElement) => (
                                                <StyledTableRow
                                                    key={item.id}
                                                    hover
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => handleShowRegister(item, indexElement)}
                                                >
                                                    <TableCell>{item.nombre ? item.nombre + " " + item.apellidoPaterno + " " + item.apellidoMaterno : item.pseudonimo }</TableCell>
                                                    <TableCell>{item.rolStr}</TableCell>
                                                    <TableCell>
                                                        <IconButton
                                                            onClick={(event) => {
                                                                event.stopPropagation();
                                                                handleRemoveParticipante(item, indexElement);
                                                            }}
                                                        >
                                                            <DeleteIcon className="icon-color" />
                                                        </IconButton>
                                                    </TableCell>
                                                </StyledTableRow>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell colSpan={6} align="center">
                                                    No hay participantes.
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    </Box>
                </AccordionDetails>
            </Accordion>
        ))}




        <ModalCreacionParticipantesPenal
            open={openModal}
            onClose={() => { setOpenModal(false) }}
            tipoParticipantes={tipoParticipantes}
            rolesParticipantes={rolesParticipantes}
            handleSaveParticipante={handleSaveParticipante}
            participanteSelected={participanteSelected}
            handleUpdateParticipante={handleUpdateParticipante}
        />
    </>)
}