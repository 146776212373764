import React, { useState, useEffect } from 'react';
import { CssBaseline } from '@mui/material';
import { RouterProvider } from 'react-router-dom';

import Spinner from './components/Spinner/Spinner';
import router from './components/Routes';
import axiosInstance, { setSpinnerCallback } from './api/axiosConfig';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Button, Box, Checkbox, Tabs, Tab } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#636569', // Gris oficial
    },
    secondary: {
      main: '#636569', // Gris oficial
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#636569', // Gris oficial
            borderWidth: '2px',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#95b74b', // Color activo
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: '#95b74b', // Color de la etiqueta cuando está enfocado
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid rgba(100, 105, 99, 0.62)', // Borde inferior
          boxShadow: '0px 0.5px 1px rgba(134, 134, 134, 0.74)', // Sombra suave en la parte inferior
        },
      },
    },    
    MuiTab: {
      styleOverrides: {
        root: {
          border: '2px solid rgba(99, 101, 105, 0.62)',
          marginRight: '8px',
          fontWeight: 500, 
          color: 'rgba(99, 101, 105, 0.62)', 
          padding: '6px 12px',
          borderTopLeftRadius: '4px',
          borderTopRightRadius: '4px',
          '&.Mui-selected': {
            color: 'rgb(140 146 188)',
            fontWeight: 750, 
            border: '2px solid rgba(140, 146, 188, 1)', 
            backgroundColor: 'rgba(140, 146, 188, 0.001)',
            boxShadow: '0px 0px 10px rgba(134, 134, 134, 0.32)', 
          },
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            color: '#636569',
            borderColor: 'rgba(99, 101, 105, 0.85)',
          },
        },
      },
    },
    TableRow: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'rgb(99, 101, 105)', // Hover en filas de tabla
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderColor: '#636569', // Gris oficial
          borderWidth: '2px',
          backgroundColor: 'transparent',
          color: '#636569',
          '&:hover': {
            backgroundColor: '#4e5153',
            borderColor: '#4e5153',
            color: '#fff',
          },
          '&:focus': {
            backgroundColor: 'transparent',
            borderColor: '#95b74b',
            color: '#636569',
          },
        },
      },
    },
    MuiTransferList: {
      styleOverrides: {
        root: {
          boxShadow: '0px 8px 20px 0px hsl(0deg 0% 0% / 30%)',
        },
        button: {
          backgroundColor: '#636569',
          borderColor: '#636569',
          borderWidth: '2px',
          '&:hover': {
            backgroundColor: '#4e5153',
            borderColor: '#4e5153',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: '0px 0px 12px 0px hsl(0deg 0% 0% / 18%)',
        },
      },
    },
    MuiBox: {
      styleOverrides: {
        root: {
          boxShadow: '0px 0px 12px 0px hsl(0deg 0% 0% / 18%)',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: '#95b74b',
          },
          '&:hover': {
            backgroundColor: 'rgba(99, 101, 105, 0.1)',
          },
        },
      },
    },
  },
});

const App = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setSpinnerCallback(setLoading); 
  }, []);

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline>
          <Spinner open={loading} /> {/* Spinner global */}
          <RouterProvider router={router} />
        </CssBaseline>
      </ThemeProvider>
    </>
  );
};

export default App;
