import { Box, Button, Typography, Grid, Card, CardContent } from "@mui/material";
import { useState, useRef } from "react";
import { useToastAlert } from "../../components/Alert/ToastAlertSave/ToastAlertSave";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const DigitalizarDemanda = ({ token, onClose, onFileSelect }) => {
    const [file, setFile] = useState(null);

    const { showSnackbar } = useToastAlert();
    const fileInputRef = useRef(null);

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (!selectedFile) return;

        if (selectedFile.size > 50 * 1024 * 1024) {
            showSnackbar('El archivo debe ser menor a 50 MB', 'error');
            return;
        }

        // Validación de tipo de archivo (solo PDF)
        if (selectedFile.type !== "application/pdf") {
            showSnackbar("Solo se permiten archivos PDF.", "error");
            return;
        }

        setFile(selectedFile);
        onFileSelect(selectedFile);
    };


    const handleRemoveFile = () => {
        setFile(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = null;
        }
    };
    
    const handleDownloadPdf = (event) => {
        var blob = new Blob([file], { type: "application/pdf" });
        let link = document.createElement("a");

        link.href = window.URL.createObjectURL(blob);
        link.target = "digitalizacion.pdf";
        link.click();
    }

    return (

        <Grid item xs={10} mb={2} ml={7}>



            <Box display="flex" justifyContent="flex-start" mt={2}>

                <Grid>
                    <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                        De clic en el botón "Cargar archivo" para seleccionar un archivo PDF.
                    </Typography>
                </Grid>

                <Grid ml={2}>
                    <Button className='button-validation' variant="contained" component="label">
                        Cargar archivo
                        <input
                            type="file"
                            accept="application/pdf"
                            hidden
                            onChange={handleFileChange}
                            ref={fileInputRef}
                        />
                    </Button>
                </Grid>

            </Box>

            {file && (
                <Grid container spacing={3} sx={{ marginTop: 1 }}>
                    <Grid item xs={12} sm={6} md={2}>
                        <Card sx={{ maxWidth: 245, margin: "0 auto", position: "relative" }}>
                            <Typography
                                variant="body4"
                                sx={{
                                    position: "absolute",
                                    top: 10,
                                    right: 10,
                                    cursor: "pointer",
                                    fontWeight: "bold",
                                    color: "red",
                                    zIndex: 1,
                                }}
                                onClick={handleRemoveFile}
                            >
                                X
                            </Typography>

                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: 100 }}>
                                <PictureAsPdfIcon sx={{ fontSize: 80, color: "black" }} />
                            </Box>

                            <CardContent>
                                <Typography 
                                    variant="body1"
                                    sx={{
                                    color: 'blue',
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                    maxWidth: '200px',
                                    }}
                                    onClick={handleDownloadPdf}
                                >
                                    {file.name}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default DigitalizarDemanda;
