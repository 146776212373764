import React, { useState } from "react";
import {
  TextField,
  Divider,
  Select,
  Typography,
  Grid,
  Button,
  Box,
} from "@mui/material";
import DigitalizarDemanda from "../../../RegistrosAntiguos/DigitalizarDemanda";

export default function FormSentenciaPublica({
  formData,
  setFormData,
  errors,
  setErrorMessages,
  setIsButtonCuestionario,
  setIsButtonArchivo,
}) {
  const [isPenal, setIsPenal] = useState(false); //Usar para mostrar penal

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
    if(name == "fechaResolucion")
      setErrorMessages({});
  };

  const handleFileSelect = (selectedFile) => {
    setFormData((prev) => ({
      ...prev,
      archivo: selectedFile,
    }));
    setIsButtonArchivo(true);
  };

  const formatString = (input1) => {
    const input2 = input1.replace(/_/g, ' ');
    return input2.charAt(0).toUpperCase() + input2.slice(1).toLowerCase();
  };
  
  return (
    <div>
      <form>
        <Grid container spacing={1} sx={{ marginTop: 2 }}>
          <Grid item xs={12} sm={6} md={1}>
            <Typography>Actor: </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Typography>
              {formData.actor}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={1}>
            <Typography>Demandado: </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Typography>
              {formData.demandado || ""}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={1}>
            <Typography>Materia: </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Typography>
              {formData.materia || ""}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={1}>
            <Typography>Juzgado: </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Typography>
            {formData.juzgado || ""}
            </Typography>
          </Grid>
        </Grid>

        <Box sx={{ marginTop: 2, marginBottom: 2 }}>
          <Divider />
        </Box>

        <Grid container spacing={2} sx={{ marginTop: 2 }}>
          <Grid item xs={12} sm={6} md={3}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                <Typography>
                  Sentencia:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Typography align="center">
                  {formatString(formData.sentencia) || ""}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                <Typography>
                  Fecha de resolución:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Typography align="center">
                  {formData.fechaResolucion || ""}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                <Typography>
                  Tipo de resolución:
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Typography align="center">
                  {formatString(formData.resolucion) || ""}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {isPenal && (
            <>
              <Grid item xs={12} sm={6} md={3}>
                <Select
                  name="Delito"
                  labelId="select-delito-label"
                  value={formData?.delito || ''}
                  onChange={(e)=>{handleChange(e)}}
                  label="Delito"
                  required
                >
                  {/* {tipoDocumentoData.map((tipoDoc) => (
                    <MenuItem key={tipoDoc.id} value={tipoDoc.id}>
                      {tipoDoc.nombre}
                    </MenuItem>
                  ))} */}
                </Select>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Select
                  name="Sentencias definitivas"
                  labelId="select-sentencia-def-label"
                  value={formData?.sentenciaDefinitiva || ''}
                  onChange={(e)=>{handleChange(e)}}
                  label="Sentencias definitivas"
                  required
                >
                  {/* {tipoDocumentoData.map((tipoDoc) => (
                    <MenuItem key={tipoDoc.id} value={tipoDoc.id}>
                      {tipoDoc.nombre}
                    </MenuItem>
                  ))} */}
                </Select>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Select
                  name="Resolución recurrida"
                  labelId="select-resolucion-label"
                  value={formData?.resolucionRecurrida || ''}
                  onChange={(e)=>{handleChange(e)}}
                  label="Resolución recurrida"
                  required
                >
                  {/* {tipoDocumentoData.map((tipoDoc) => (
                    <MenuItem key={tipoDoc.id} value={tipoDoc.id}>
                      {tipoDoc.nombre}
                    </MenuItem>
                  ))} */}
                </Select>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  name="observaciones"
                  label="Observaciones"
                  variant="outlined"
                  fullWidth
                  multiline
                  value={formData.observaciones || ""}
                  onChange={handleChange}
                />
              </Grid>
            </>
          )}

          {/* <Grid item xs={12} sm={6} md={3}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {setIsButtonCuestionario(true)}}
            >
              Abrir cuestionario
            </Button>
          </Grid> */}

          <Grid item xs={12} sm={12} md={12} sx={{ marginTop: 4 }}>
            <Typography sx={{ fontWeight: "bold", mb: 1 }}>
              {"Archivo:"}
            </Typography>
            <DigitalizarDemanda onFileSelect={handleFileSelect} />
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
