import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Modal, Box, Typography, RadioGroup, FormControlLabel, Radio, Button } from "@mui/material";
import { diferirAudiencia } from '../../shared/AudienciasService';
import { useToastAlert } from '../Alert/ToastAlertSave/ToastAlertSave';

const ModalDiferirReprogramar = ({ open, onClose, id, item, handleRefresh, token, isTab, itemAsignado }) => {
    const [selectedOption, setSelectedOption] = useState("");
    const { showSnackbar } = useToastAlert();
    const navigate = useNavigate();

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleDiferir = async () => {
        if (selectedOption === "diferir") {
            try {
                await diferirAudiencia(token, id);
                showSnackbar('Audiencia diferida con éxito', 'success');
                handleRefresh();
                onClose();
            } catch (error) {
                showSnackbar('Error al diferir la audiencia', 'error');
            }
        } else if (selectedOption === "diferirProgramar") {
            navigate('/api/bandeja/reprogramarAudiencias', { state: { item, isTab, itemAsignado } });
        }
    };

    useEffect(() => {
        if (open) {
            setSelectedOption("");
        }
    }, [open]);

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    borderRadius: 2,
                    boxShadow: 24,
                    p: 4,
                    textAlign: 'center',
                }}
            >
                <Typography 
                    variant="h6" 
                    component="h2" 
                    sx={{ mb: 2, textAlign: 'left', fontWeight: 'bold' }}
                >
                    Diferir
                </Typography>
                <Typography sx={{ mb: 3 }}>
                    Seleccione qué hacer con la audiencia
                </Typography>
                <RadioGroup
                    value={selectedOption}
                    onChange={handleOptionChange}
                    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', mb: 3 }}
                >
                    <FormControlLabel value="diferir" control={<Radio />} label="Diferir" />
                    <FormControlLabel value="diferirProgramar" control={<Radio />} label="Diferir o reagendar" />
                </RadioGroup>
                <Box display="flex" justifyContent="flex-end" mt={3} gap={2}>
                    <Button variant="outlined" className="button-close" onClick={onClose}>
                        Cerrar
                    </Button>
                    <Button variant="contained" className="button-save" onClick={handleDiferir}>
                        Guardar
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ModalDiferirReprogramar;
