import { Autocomplete, Box, Button, FormControl, FormHelperText, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useContext, useEffect, useRef, useState } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import { getJuecesByJuzgado } from "../../../DemandaService";
import keycloakConf from "../../../../../api/keycloakConf";

export default function General({
    formData,
    handleInputChange,
    setFormData,
    nombreJuicio,
    errors,
    tiposAudiencias,
    materiaId,
    materia
}) {
    const { getToken } = useContext(keycloakConf);
    const token = getToken();

    const [anexosErrors, setAnexosErrors] = useState([]);
    const anexoRefs = useRef([]);
    const [jueces, setJueces] = useState([]);

    useEffect(() => { obtenerJueces(); }, []);

    const handleAddAnexo = () => {

        setFormData(prevState => ({
            ...prevState,
            general: {
                ...prevState.general,
                anexos: [...prevState.general.anexos, '']
            }
        }));


        setTimeout(() => {
            const lastAnexoRef = anexoRefs.current[formData.general.anexos.length];
            if (lastAnexoRef) lastAnexoRef.focus();
        }, 0);
    }

    const handleAnexoChange = (index, value) => {
        const newValue = value
        const newAnexo = formData.general.anexos;

        newAnexo[index] = newValue;

        setFormData(prevState => ({
            ...prevState,
            general: {
                ...prevState.general,
                anexos: newAnexo
            }

        }));

        const newAnexosErrors = [...anexosErrors];
        newAnexosErrors[index] = value.length >= 3 ? "" : `El anexo ${index + 1} debe tener al menos 3 caracteres.`;
        setAnexosErrors(newAnexosErrors);

    }
    const handleRemoveAnexo = (index) => {
        const newAnexos = formData.general.anexos.filter((_, i) => i !== index);

        setFormData(prevState => ({
            ...prevState,
            general: {
                ...prevState.general,
                anexos: newAnexos
            }
        }));
    }

    const obtenerJueces = async () => {
        const response = await getJuecesByJuzgado(token, materiaId);
        setJueces(response);
    }

    return (<>
        { /* formulario principal */}
        <Grid container spacing={2} rowSpacing={4}>

            <Grid item xs={4}>
                <FormControl fullWidth>
                    <TextField
                        value={formData?.general?.nombrePromovente || ""}
                        name="general.nombrePromovente"
                        label="Nombre del promovente"
                        error={!!errors["general.nombrePromovente"]}
                        helperText={errors["general.nombrePromovente"] || ''}
                        onChange={handleInputChange}
                    />
                </FormControl>
            </Grid>

            <Grid item xs={4}>
                <FormControl fullWidth>
                    <TextField
                        value={formData?.general?.apellidoPaternoPromovente || ""}
                        label="Apellido paterno"
                        name="general.apellidoPaternoPromovente"
                        error={!!errors["general.apellidoPaternoPromovente"]}
                        helperText={errors["general.apellidoPaternoPromovente"] || ''}
                        onChange={handleInputChange}
                    />
                </FormControl>
            </Grid>

            <Grid item xs={4}>
                <FormControl fullWidth>
                    <TextField
                        value={formData?.general?.apellidoMaternoPromovente || ""}
                        label="Apellido materno"
                        name="general.apellidoMaternoPromovente"
                        error={!!errors["general.apellidoMaternoPromovente"]}
                        helperText={errors["general.apellidoMaternoPromovente"] || ''}
                        onChange={handleInputChange}
                    />
                </FormControl>
            </Grid>


            <Grid item xs={4}>
                <FormControl fullWidth error={!!errors["general.tipoJuzgado"]}>
                    <InputLabel id="demo-simple-select-label">Tipo Juzgado</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={formData?.general?.tipoJuzgado || ""}
                        name="general.tipoJuzgado"
                        label="Tipo Juzgado"
                        onChange={handleInputChange}
                    >
                        <MenuItem value="0">Control</MenuItem>
                        <MenuItem value="1">Ejecución</MenuItem>
                        <MenuItem value="2">Enjuiciamiento</MenuItem>
                    </Select>
                    <FormHelperText>{errors["general.tipoJuzgado"] || ""}</FormHelperText>
                </FormControl>
            </Grid>

            {formData.general.tipoJuzgado === "2" && (
                <Grid item xs={4}>
                    <FormControl fullWidth error={!!errors["general.juez"]}>
                        <InputLabel id="demo-simple-select-label">Jueces</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={formData?.general?.juez || ""}
                            name="general.juez"
                            label="Jueces"
                            onChange={handleInputChange}
                        >
                            {jueces.map(element => <MenuItem key={element.id} value={element.id}>{element.nombreCompleto}</MenuItem>)}

                        </Select>
                        <FormHelperText>{errors["general.juez"] || ""}</FormHelperText>
                    </FormControl>
                </Grid>
            )}


            <Grid item xs={4}>
                <FormControl fullWidth>
                    <TextField
                        value={formData?.general?.numOficio || ""}
                        name="general.numOficio"
                        label="Número de Oficio"
                        error={!!errors["general.numOficio"]}
                        helperText={errors["general.numOficio"] || ''}
                        onChange={handleInputChange}
                    />
                </FormControl>
            </Grid>

            <Grid item xs={4}>
                <FormControl fullWidth>
                    <TextField
                        value={formData?.general?.numCarpetaInv || ""}
                        label="Número de Carpeta de Investigación"
                        name="general.numCarpetaInv"
                        error={!!errors["general.numCarpetaInv"]}
                        helperText={errors["general.numCarpetaInv"] || ''}
                        onChange={handleInputChange}
                    />
                </FormControl>
            </Grid>

            <Grid item xs={4}>
                <FormControl fullWidth>
                    <TextField
                        value={formData?.general?.lugarHecho || ""}
                        label="Lugar del hecho"
                        name="general.lugarHecho"
                        error={!!errors["general.lugarHecho"]}
                        helperText={errors["general.lugarHecho"] || ''}
                        onChange={handleInputChange}
                    />
                </FormControl>
            </Grid>

            <Grid item xs={  materia === "Penal" ? 2 : 4  }>
                <FormControl fullWidth>
                    <TextField
                        value={formData?.general?.fechaHecho || ""}
                        label="Fecha del hecho"
                        type="date"
                        name="general.fechaHecho"
                        InputLabelProps={{ shrink: true }}
                        error={!!errors["general.fechaHecho"]}
                        helperText={errors["general.fechaHecho"] || ''}
                        onChange={handleInputChange}
                    />
                </FormControl>
            </Grid>

            {materia === "Penal" && (
                <Grid item xs={2}>
                    <FormControl fullWidth>
                        <TextField
                            value={formData?.general?.fechaPresentacion || ""}
                            label="Fecha de presentación"
                            type="date"
                            name="general.fechaPresentacion"
                            InputLabelProps={{ shrink: true }}
                            error={!!errors["general.fechaPresentacion"]}
                            helperText={errors["general.fechaPresentacion"] || ''}
                            onChange={handleInputChange}
                        />
                    </FormControl>
                </Grid>
            )}

            { /* campos validos unicamente para tipo de juicio expedientillo de control judicial, expedientillo de control de actos de investigación */}
            {(nombreJuicio === "Expedientillo de Control Judicial" || nombreJuicio === "Expedientillo de Control De Actos De Investigación") && (
                <>
                    <Grid item xs={2}>
                        <FormControl fullWidth>
                            <TextField
                                value={formData?.general?.horaFormal || ""}
                                label="Hora formal"
                                name="general.horaFormal"
                                type="time"
                                InputLabelProps={{ shrink: true }}
                                error={!!errors["general.horaFormal"]}
                                helperText={errors["general.horaFormal"] || ''}
                                onChange={handleInputChange}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={2}>
                        <FormControl fullWidth>
                            <TextField
                                value={formData?.general?.horaMaterial || ""}
                                label="Hora material"
                                name="general.horaMaterial"
                                type="time"
                                InputLabelProps={{ shrink: true }}
                                error={!!errors["general.horaMaterial"]}
                                helperText={errors["general.horaMaterial"] || ''}
                                onChange={handleInputChange}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <TextField
                                value={formData?.general?.lugarDisposicion || ""}
                                name="general.lugarDisposicion"
                                label="Lugar del disposición"
                                error={!!errors["general.lugarDisposicion"]}
                                helperText={errors["general.lugarDisposicion"] || ''}
                                onChange={handleInputChange}
                            />
                        </FormControl>
                    </Grid>
                </>)}

            {nombreJuicio === "Causas Penales por Orden De Aprehensión" && (
                <Grid item xs={4}>
                    <FormControl fullWidth error={!!errors["general.tipoSolAudiencia"]} >
                        <InputLabel id="demo-simple-select-label">Tipo de solicitud de audiencia</InputLabel>
                        <Select
                            labelId="tipo-solicitud-select-label"
                            id="select-tipo-solicitud"
                            value={formData?.general?.tipoSolAudiencia || ""}
                            name="general.tipoSolAudiencia"
                            label="Tipo de solicitud de audiencia"
                            onChange={handleInputChange}
                        >
                            <MenuItem value="porEscrito">Por escrito</MenuItem>
                            <MenuItem value="privada">Privada</MenuItem>
                        </Select>
                        <FormHelperText>{errors["general.tipoSolAudiencia"] || ""}</FormHelperText>
                    </FormControl>
                </Grid>
            )}

            { /* CAMPO PARA SELECCIONAR EL TIPO DE AUDIENCIA */}
            <Grid item xs={4}>
                <Autocomplete
                    fullWidth
                    options={tiposAudiencias}
                    getOptionLabel={(option) => option.nombre || ""}
                    value={tiposAudiencias.find(element => element.id === formData?.general?.tipoAudiencia) || null}
                    onChange={(event, newValue) => {
                        handleInputChange({
                            target: {
                                name: "general.tipoAudiencia",
                                value: newValue?.id || ""
                            }
                        });
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Tipo de audiencias"
                            error={!!errors["general.tipoAudiencia"]}
                            helperText={errors["general.tipoAudiencia"] || ""}
                            variant="outlined"
                        />
                    )}
                    renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                            {option.nombre}
                        </li>
                    )}
                />
            </Grid>



            { /* ANEXOS */}
            <Grid item xs={12}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <Typography >Anexos</Typography>
                    <Box sx={{ flexGrow: 2 }}>
                        {formData.general.anexos.map((anexo, index) => (
                            <Grid
                                container
                                alignItems="center"
                                key={index}
                                sx={{ mb: 2 }}
                            >
                                <Grid item xs={11}>
                                    <TextField
                                        label={`Anexo ${index + 1}`}
                                        fullWidth
                                        value={anexo}
                                        onChange={(e) => handleAnexoChange(index, e.target.value)}
                                        inputRef={(el) => (anexoRefs.current[index] = el)}
                                        helperText={anexosErrors[index] && "El anexo debe tener al menos 3 caracteres"}
                                        FormHelperTextProps={{
                                            style: {
                                                color: anexosErrors[index] !== "" ? "#d32f2f" : "inherit",
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <IconButton
                                        onClick={() => handleRemoveAnexo(index)}
                                    >
                                        <DeleteIcon className='icon-color' />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        ))}
                    </Box>
                </Box>
            </Grid>

        </Grid>

        <Grid item xs={12}>
            <Box display="flex" justifyContent="flex-start" mt={2}>
                <Button
                    className='button-validation'
                    startIcon={<AddCircleOutlineIcon />}
                    variant="contained"
                    name="general.anexos"
                    onClick={handleAddAnexo}
                >
                    Agregar Anexo
                </Button>
            </Box>
        </Grid>

    </>);
}
