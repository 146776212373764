import React, { useEffect, useState, useContext } from 'react';
import { Modal, Box, Typography, TextField, Select, MenuItem, Autocomplete, FormControlLabel, Button, FormControl, FormHelperText, RadioGroup, Radio, FormLabel, ToggleButtonGroup, ToggleButton, Divider } from "@mui/material";
import { fetchPiezas } from '../../shared/services/CarpetaService';
import { fetchNumeroPieza } from '../../shared/services/CarpetaService';
import { createPiezas } from '../../shared/services/CarpetaService';
import { adjuntarPieza } from '../../shared/services/CarpetaService';
import { fetchCatalogos } from '../../shared/services/CarpetaService';
import { getCaratulaPieza } from '../../shared/services/DocumentoService';
import { adjuntarPromocion } from '../../shared/services/DocumentoService';
import keycloakConf from '../../api/keycloakConf';
import { useToastAlert } from '../Alert/ToastAlertSave/ToastAlertSave'

const ModalPieza = ({ open, onClose, data, handleRefresh }) => {
  const [selectedTipoPieza, setSelectedTipoPieza] = useState(null);
  const [selectedPieza, setSelectedPieza] = useState(null);
  const [numPieza, setNumPieza] = useState("----");
  const [catalogoTipoPiezas, setCatalogoTipoPiezas] = useState([]);
  const [listaPiezas, setListaPiezas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const { getToken } = useContext(keycloakConf);
  const { showSnackbar, showConfirmDialog } = useToastAlert();
  const [ tipoDocAdjuntar, setTipoDocAdjuntar ] =useState(null);
  const [disableTipoDoc, setDisableTipoDoc] = useState(false);

  const token = getToken();

  useEffect(() => {
    if (open) {
      const loadCatalogoTipoPiezas = async () => {
        setLoading(true);
        setSelectedPieza(null);
        setListaPiezas([]);
        try {
          const response = await fetchCatalogos(token, "catalogoTipoPiezas")
          setCatalogoTipoPiezas(response);
        } catch (err) {
          setError('Error al cargar el catálogo de Tipos de Pieza');
        } finally {
          setLoading(false);
        }
      };

      const loadListaPiezas = async () => {
        setLoading(true);
        setListaPiezas([{id:"NP", numeroPieza:"Pieza Nueva", tipoPieza:"Pieza Nueva"}]);
        try {
          const documento = data.documentos[0];
          const response = await fetchPiezas(token, {documentoId: documento.id});
          const list = listaPiezas.concat(response);
          setListaPiezas(list);
        } catch (err) {
          setError('Error al cargar el listado de Piezas de carpeta');
        } finally {
          setLoading(false);
        }
      };

      loadCatalogoTipoPiezas();
      loadListaPiezas();
      console.log(data);
      if (data.tipo && data.tipo === 'Promocion'){
        setTipoDocAdjuntar(0);
      }else{
        setTipoDocAdjuntar(1);
        setDisableTipoDoc(true);
      }

      if (!data.carpetaAsignada){
        setTipoDocAdjuntar(1);
      }

    } else {
      setListaPiezas([{id:"NP", numeroPieza:"Pieza nueva", tipoPieza:"Pieza nueva"}]);
      setCatalogoTipoPiezas([]);
      setSelectedTipoPieza("");
      setSelectedPieza("");
      setNumPieza("");
    }
  }, [open, token]); 

  const getNumeroPieza = async (clavePieza) => {
    setLoading(true);
    const request = {
      clavePieza: clavePieza,
      carpetaId: data.carpetaId
    }
    try{
      const response = await fetchNumeroPieza(token, request);
      setNumPieza(response.numPieza);
    } catch (err) {
      setError('Error al obtener el número de Pieza' + err);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeTipoPieza = (event, valor) => {
    const selectedTipoPiezaItem = valor;

    setSelectedTipoPieza(selectedTipoPiezaItem);

    if (valor){
      getNumeroPieza(selectedTipoPiezaItem.clave);
    }
    
  };

  const handleChangePieza = (event) => {
    const selectedPiezaId = event.target.value;

    setSelectedTipoPieza(null);
    setNumPieza("");
    setSelectedPieza(selectedPiezaId);
    
  };

  const handleChange = (event, value) =>{
    setTipoDocAdjuntar(value);

    if (value===0){
      setSelectedPieza(null);
    }
  }


  const handleAceptar = async (e) => {
    const payload = {
      carpetaId: data.carpetaId,
      piezaId: selectedPieza,
      pieza: {
        tipoPiezaId: null,
        clavePieza: selectedTipoPieza.clave,
        documentos: data.documentos.map((i)=>i.id)
      }
    };

    if (tipoDocAdjuntar === 0){
      try{
        const promocionId = data.documentos[0].id;
        const result = await adjuntarPromocion(token, promocionId);

        showSnackbar(`Promoción ${result.folio} integrada con exito`, 'success');
        handleRefresh();
        onClose(e, "pieza");
      }catch(error){
        setError(error);
        showSnackbar('No se pudo adjuntar la promoción al expediente','error');
      }

      return;
    }

    if (payload.piezaId==="NP"){
      try {
        await createPiezas(token, payload);

        const caratula = await getCaratulaPieza(token, data.documentos[0].id);

        var blob = new Blob([caratula], { type: "application/pdf" });
        let link = document.createElement("a");
        
        link.href = window.URL.createObjectURL(blob);
        link.target = "caratulaPieza.pdf";
        link.click();

        showSnackbar('Pieza creada con éxito', 'success');
        handleRefresh();
        onClose(e, "pieza");
      } catch (err) {
        console.error('Error al crear la pieza:', err);
        setError('Error crear la pieza');
      }
      
    } else {
      try {
        await adjuntarPieza(token, payload);
        showSnackbar('Documento adjuntado con éxito a la pieza', 'success');
        handleRefresh();
        onClose(e, "pieza");
      } catch (err) {
        console.error('Error al adjuntar  el documento:', err);
        setError('Error al adjuntar el documento');
      }
    }
  };

  return (
    <Modal open={open} onClose={(e)=>onClose(e, "pieza")}>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 2,
      }}>
        <Typography variant="h7" align="left" sx={{ fontWeight: 'bold', mb: 2 }}>Adjuntar</Typography>
        <Typography sx={{ minWidth: 70 }}>Relacionada al expediente {data.expediente}</Typography> 
        <Divider sx={{ my: 2 }} />
        <Typography sx={{ minWidth: 100 }}>Adjuntar a:</Typography> 
        <Box display="flex" alignItems="center" gap={2} mt={2}>
          { Object.getOwnPropertyNames(data).includes('carpetaAsignada') &&
            <ToggleButtonGroup
              value={tipoDocAdjuntar}
              color="primary"
              exclusive
              onChange={handleChange}
              fullWidth 
              disabled={disableTipoDoc}
              >
              <ToggleButton value={0} disabled={!data.carpetaAsignada}>Expediente</ToggleButton>
              <ToggleButton value={1}>Pieza</ToggleButton>
            </ToggleButtonGroup>
}
        </Box>
        {tipoDocAdjuntar === 0 && 
          <Box display="flex" alignItems="center" gap={2} mt={2}>
            <TextField
                type="test"
                value={data.expediente}
                disabled
            />
          </Box>
        }
        {tipoDocAdjuntar===1 &&
          <Box display="flex" alignItems="center" gap={2} mt={2}>
            <FormControl fullWidth>
              <Select
                name="pieza"
                value={selectedPieza}
                onChange={handleChangePieza}
                displayEmpty
                sx={{ width: '100%' }}
                error={!!error.pieza}
                renderValue={(selected) => {
                  return selected ? listaPiezas.find(pieza => pieza.id === selected)?.numeroPieza : <span style={{ color: "gray" }}>Selecciona una opción</span>;
                }}
              >
                {loading ? (
                  <MenuItem disabled>Cargando...</MenuItem>
                ) : (

                  listaPiezas.map(pieza => (
                    <MenuItem key={pieza.id} value={pieza.id}>
                      {pieza.numeroPieza}
                    </MenuItem>
                  ))
                )}
              </Select>
              {error.pieza && <FormHelperText>{error.pieza}</FormHelperText>}
            </FormControl>
            </Box>
        }
        { selectedPieza==="NP" && 
          <Box display="flex" alignItems="center" gap={2} mt={2} fullWidth>
            <Autocomplete fullWidth
              options={catalogoTipoPiezas}
              getOptionLabel={(option) => option.etiqueta || ''}
              value={selectedTipoPieza || null}
              onChange={handleChangeTipoPieza}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Tipo de pieza"
                  variant="outlined"
                  error={!!error.tipoPieza}
                  fullWidth
                />
              )}
            />
            <TextField
              minWidth="70"
              type="test"
              label="Número de pieza"
              value={numPieza}
              disabled
            />
          </Box>        
        }

        <Typography mt={3}>Total de elementos seleccionados a adjuntar: {data.documentos?data.documentos.length:""} </Typography>

        <Box display="flex" justifyContent="flex-end" mt={3} gap={2}>
          <Button variant="outlined" className="button-close" onClick={(e)=>onClose(e, "pieza")}>
            Cancelar
          </Button>
          <Button variant="contained" className="button-save" onClick={(e)=>handleAceptar(e)}>
            Aceptar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalPieza;
