import React, { useState, useEffect } from 'react';
import { Box, Typography, FormControl, InputLabel, Select, MenuItem, TextField, Grid, FormControlLabel, Checkbox } from '@mui/material';
import { fetchEscolaridades } from '../../../../shared/services/EscolaridadesService';
import { fetchCatalogo } from '../../../../shared/services/CatalogoListService';
import { fetchEstados } from '../../../../shared/services/EstadosService';
import { fetchMunicipiosByEstadoId } from '../../../../shared/services/EstadosService';
import fetchLenguasIndigenas from '../../../../shared/services/LenguasIndigenasService';
import fetchPaises from '../../../../shared/services/PaisService';
import fetchIdentificaciones from '../../../../shared/services/IdentificacionService';
import validCombinations from './validCombinations';
import { useToastAlertValidation } from '../../../../components/Alert/ToastAlertValidation/ToastAlertValidation';


const DatosEstadisticos = ({ participante, onInputChange, token, nombreTipo, tipoPersona, isEdit, isDisabled}) => {
  const [hablaLenguaExtranjera, setHablaLenguaExtranjera] = useState(false);
  const [hablaLenguaIndigena, sethablaLenguaIndigena] = useState(false);
  const [tieneReligion, setTieneReligion] = useState(false);
  const [religion, setReligion] = useState('');
  const [escolaridades, setEscolaridades] = useState([]);
  const [discapacidades, setDiscapacidades] = useState([]);
  const [condicionesMigratorias, setCondicionMigratoria] = useState([]);
  const [gruposVulnerables, setGruposVulnerables] = useState([]);
  const [profesionesOficios, setProfecionesOficios] = useState([]);
  const [estados, setEstados] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [lenguasIndigenas, setLenguasIndigenas] = useState([]);
  const [ingresosMensuales, setIngresosMensuales] = useState([]);
  const [frecuenciaIngresos, setFrecuenciaIngresos] = useState([]);
  const [paises, setPaises] = useState([]);
  const [paisSeleccionado, setPaisSeleccionado] = useState('');
  const [paisMx, setPaisMx] = useState('');
  const [documento, setDocumento] = useState([]);
  const [paisMxId, setPaisMxId] = useState('');
  const { showSnackbar } = useToastAlertValidation(); 
  
  const camposVisibles = validCombinations[`${nombreTipo}_${tipoPersona}`] || [];  
  const isFieldVisible = (fieldName) => camposVisibles.includes(fieldName);

  const cantidadDependientes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const cantidadBienes = [1, 2, 3, 4, 5, 6, 7,8, 9, 10];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    onInputChange(name, value);
    
    if (name === "paisNacimiento") {
        setPaisSeleccionado(value);
        if (value !== paisMx?.id) {
          setEstados([]); 
          setMunicipios([]); 
          onInputChange("entidadNacimiento", ""); 
          onInputChange("municipioNacimiento", ""); 
           
        } else {
          loadEstados();
        }
    }
  };

  const handleCheckboxChange = (setter) => (e) => {
    setter(e.target.checked);
  };

  const handleLenguaExtranjeraChange = (e) => {
    setHablaLenguaExtranjera(e.target.checked);
    if (!e.target.checked) {
      onInputChange("hablaLenguaExtranjera", "");
    }
  };

  const handleHablaEspanolChange = (e) => {
    const checked = e.target.checked;
    onInputChange('hablaEspanol', checked);
  };


  const handleLenguaIndigenaChange = (e) => {
    sethablaLenguaIndigena(e.target.checked);
    if (!e.target.checked) {
      onInputChange("lenguaIndigena", "");
    }
  };

  const handleReligionCheckboxChange = (e) => {
    setTieneReligion(e.target.checked);
    if (!e.target.checked) {
      setReligion('');
    }
  };

  const loadEscolaridades = async () => {
    try {
      const escolaridadesData = await fetchEscolaridades(token);
      setEscolaridades(escolaridadesData);
    } catch (error) {
      console.error('Error al obtener escolaridades:', error);
    }
  };

  const loadDiscapacidades = async () => {
    try {
      const discapacidadesData = await fetchCatalogo(token, 'discapacidad');
      setDiscapacidades(discapacidadesData);
    } catch (error) {
      console.error('Error al obtener discapacidades:', error);
    }
  };

  const loadCondicionMigratoria = async () => {
    try {
      const condicionMigratoriaData = await fetchCatalogo(token, 'condicionMigratoria');
      setCondicionMigratoria(condicionMigratoriaData);
    } catch (error) {
      console.error('Error al obtener condición migratoria:', error);
    }
  };

  const loadGruposVulnerables = async () => {
    try {
      const gruposVulnerablesData = await fetchCatalogo(token, 'grupoVulnerable');
      setGruposVulnerables(gruposVulnerablesData);
    } catch (error) {
      console.error('Error al obtener grupos vulnerables:', error);
    }
  };

  const loadProfesionUOficio = async () => {
    try {
      const profesionUOficioData = await fetchCatalogo(token, 'profesionOficio');
      setProfecionesOficios(profesionUOficioData);
    } catch (error) {
      console.error('Error al obtener oficios:', error);
    }
  };

   const loadEstados = async () => {
      try {
        const estadosData = await fetchEstados(token);
        setEstados(estadosData); 
      } catch (error) {
        console.error('Error al obtener los estados:', error);
      }
    };
    
    useEffect(() => {
      if (estados.length > 0 && participante?.entidadNacimiento) {
        const nombreEstado = participante.entidadNacimiento;
        const currentEstado = estados.find((estado) => estado?.name === nombreEstado);
        if (currentEstado) {
          loadMunicipios(currentEstado.id);
        }
      }

    }, [estados, participante?.estado]);

    
  const handleEstadoChange = async (event) => {
    const selectedEstadoName = event.target.value; 
    handleInputChange(event); 
    const selectedEstado = estados.find(estado => estado.name === selectedEstadoName); 
    if (selectedEstado) {
      const selectedEstadoId = selectedEstado.id; 
      await loadMunicipios(selectedEstadoId); 
    }
  };

  const loadMunicipios = async (estadoId) => {
    try {
      const municipiosData = await fetchMunicipiosByEstadoId(token, estadoId);
      setMunicipios(municipiosData);
    } catch (error) {
      console.error('Error al obtener los municipios:', error);
    }
  };

  const loadIngresoMensual = async () => {
    try {
      const ingresosMensualesData = await fetchCatalogo(token, 'catalogoIngresoMensualNeto');
      setIngresosMensuales(ingresosMensualesData);
    } catch (error) {
      console.error('Error al obtener los ingresos mensuales:', error);
    }
  };

  const loadFrecuenciaIngreso = async () => {
    try {
      const frecuenciaIngresosData = await fetchCatalogo(token, 'catalogoFrecuenciaIngreso');
      setFrecuenciaIngresos(frecuenciaIngresosData);
    } catch (error) {
      console.error('Error al obtener la frecuencia del ingreso:', error);
    }
  };

  const loadLenguasIndigenas = async () => {
    try {
      const lenguasIndigenasData = await fetchLenguasIndigenas(token);
      setLenguasIndigenas(lenguasIndigenasData);
    } catch (error) {
      console.error('Error al obtener las lenguas indigenas:', error);
    }
  };

  const loadPaises = async () => {
    try {
      const paisesData = await fetchPaises(token);
      setPaises(paisesData);
      const paisMx = paisesData.find((pais)=> pais.codeAlpha2 == 'MX');

      if( !isEdit && paisMx ) {
        setPaisSeleccionado(paisMx.id);
        setPaisMxId(paisMx?.id);
        setPaisMx(paisMx);
        onInputChange('pais', paisMx.id); 
      } else if(isEdit && participante?.paisNacimiento?.id == null && paisMx?.id ){
        setPaisSeleccionado(paisMx?.id);
        setPaisMxId(paisMx?.id);
        onInputChange('pais', paisMx.id);
      } else if(isEdit && participante?.paisNacimiento?.id){
        setPaisSeleccionado(participante?.paisNacimiento.id);
        setPaisMxId(paisMx?.id);
        onInputChange('pais', participante?.paisNacimiento.id);
      }

    } catch (error) {
      console.error('Error al obtener paises:', error);
    }
  };

  const loadIdentificaciones = async () => {
    try {
      const documentosData = await fetchIdentificaciones(token);
      setDocumento(documentosData);
    } catch (error) {
      console.error('Error al obtener los documentos de identificación:', error);
    }
  };

  const loadData = () => {
    loadEscolaridades();
    loadDiscapacidades();
    loadCondicionMigratoria();
    loadGruposVulnerables();
    loadProfesionUOficio();
    loadEstados();
    loadLenguasIndigenas();
    loadIngresoMensual();
    loadFrecuenciaIngreso();
    loadPaises();
    loadIdentificaciones();
    
  };

  useEffect(() => {
    loadData();
  }, [token]);

  useEffect(() => {
    if (!nombreTipo || !tipoPersona) {
      showSnackbar("Debe seleccionar un tipo y un tipo de persona para visualizar los datos", "error");
    }
  }, [nombreTipo, tipoPersona, showSnackbar]);

  return (
    <Box p={4} sx={{ width: '100%' }}>
      <Grid container spacing={2}>
        {/* Fila para País, Estado y Municipio */}
        <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={4}>
                    {isFieldVisible('paisNacimiento') && (
                    <FormControl fullWidth sx={{ marginBottom: 2 }}>
                        <InputLabel>País de nacimiento</InputLabel>
                        <Select
                            name="paisNacimiento"
                            value={paisSeleccionado || ''}
                            onChange={handleInputChange}
                            label="Selecciona un país"
                            disabled={isDisabled}
                        >
                            {paises.map((pais) => (
                                <MenuItem key={pais.id} value={pais.id}>{pais.nombre}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    )}
                </Grid>

                <Grid item xs={4}>
                {isFieldVisible('entidadNacimiento') && (
                  <>
                    {paisSeleccionado === paisMxId ? ( 
                        <FormControl fullWidth sx={{ marginBottom: 2 }}>
                            <InputLabel>Entidad de nacimiento</InputLabel>
                            <Select
                                name="entidadNacimiento"
                                value={participante.entidadNacimiento || ''}
                                onChange={handleEstadoChange}
                                label="Entidad de nacimiento"
                                disabled={isDisabled}
                            >
                                {estados.map((estado) => (
                                    <MenuItem key={estado.id} value={estado.name}>{estado.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    ) : (
                        <TextField
                            fullWidth
                            name="entidadNacimiento"
                            value={participante.entidadNacimiento || ''}
                            onChange={handleInputChange}
                            label="Especifique el estado"
                            sx={{ marginBottom: 2 }}
                            disabled={isDisabled}
                        />
                    )}
                    </>
                  )}
                </Grid>

                <Grid item xs={4}>
                {isFieldVisible('municipioNacimiento') && (
                  <>
                    {paisSeleccionado === paisMxId ? ( // Si el país es México
                        <FormControl fullWidth sx={{ marginBottom: 2 }}>
                            <InputLabel>Municipio</InputLabel>
                            <Select
                                name="municipioNacimiento"
                                value={participante.municipioNacimiento || ''}
                                onChange={handleInputChange}
                                label="Municipio/Delegación"
                                disabled={isDisabled}
                            >
                                {municipios.length > 0 ? (
                                    municipios.map((municipio) => (
                                        <MenuItem key={municipio.id} value={municipio.name}>{municipio.name}</MenuItem>
                                    ))
                                ) : (
                                    <MenuItem value="">No hay municipios disponibles</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    ) : (
                        <TextField
                            fullWidth
                            name="municipioNacimiento"
                            value={participante.municipioNacimiento || ''}
                            onChange={handleInputChange}
                            label="Especifique el municipio"
                            sx={{ marginBottom: 2 }}
                            disabled={isDisabled}
                        />
                    )}
                  </>
                )}
                </Grid>
            </Grid>
        </Grid>

        {/* Columna 1 */}
        <Grid item xs={12} sm={6} md={4}>
        {isFieldVisible('condicionMigratoria') && (
          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel>Condición migratoria</InputLabel>
            <Select
              name="condicionMigratoria"
              value={participante.condicionMigratoria ?? ''}
              onChange={handleInputChange}
              label="Condición migratoria"
              disabled={isDisabled}
            >
              {condicionesMigratorias.map((condicionMigratoria) => (
                <MenuItem key={condicionMigratoria.clave} value={condicionMigratoria.clave}>{condicionMigratoria.etiqueta}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {isFieldVisible('profesion') && ( 
          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel>Profesión u oficio</InputLabel>
            <Select
              name="profesion"
              value={participante.profesion ?? ''}
              onChange={handleInputChange}
              label="Profesión u oficio"
              disabled={isDisabled}
            >
              {profesionesOficios.map((profesion) => (
                <MenuItem key={profesion.clave} value={profesion.clave}>{profesion.etiqueta}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {isFieldVisible('hablaLenguaExtranjera') && (
          <>
          <Typography sx={{ marginBottom: 1 }}>¿Habla lengua extranjera?</Typography>
          <FormControlLabel
            control={
              <Checkbox
                name="hablaLenguaExtranjera"
                checked={participante.hablaLenguaExtranjera}
                value={participante.hablaLenguaExtranjera || ''}
                onChange={(e) =>
                  onInputChange("hablaLenguaExtranjera", e.target.checked)
                }
                color="primary"
                disabled={isDisabled}
              />
            }
            label="Sí"
          />
          {participante.hablaLenguaExtranjera && (
            <TextField
              fullWidth
              name="lenguaExtranjera"
              value={participante.lenguaExtranjera || ''}
              onChange={handleInputChange}
              label="Especifique lengua extranjera"
              sx={{ marginBottom: 2 }}
              disabled={isDisabled}
            />
          )}
          </>
        )}

        {isFieldVisible('tieneDependientes') && (
          <>
          <Typography sx={{ marginBottom: 1 }}>¿Tiene dependientes económicos?</Typography>
          <FormControlLabel
            control={
              <Checkbox
                name="tieneDependientes"
                checked={participante.tieneDependientes}
                onChange={(e) =>
                  onInputChange("tieneDependientes", e.target.checked)
                }
                disabled={isDisabled}
              />
            }
            label="Sí"
            sx={{ marginBottom: 2 }}
          />
          {participante.tieneDependientes && (
            <>
              <FormControl fullWidth sx={{ marginBottom: 2 }}>
                <InputLabel>Cantidad de dependientes</InputLabel>
                <Select
                  name="cantidadDependientes"
                  value={participante.cantidadDependientes || ''}
                  onChange={handleInputChange}
                  label="Cantidad de dependientes"
                  disabled={isDisabled}
                >
                  {cantidadDependientes.map((cantidad, index) => (
                    <MenuItem key={index} value={cantidad}>{cantidad}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                fullWidth
                name="detallesDependientes"
                value={participante.detallesDependientes || ''}
                onChange={handleInputChange}
                label="Detalles de los dependientes económicos"
                sx={{ marginBottom: 2 }}
                disabled={isDisabled}
              />
            </>
          )}
        </>
        )}

        {isFieldVisible('tieneReligion') && (
        <>
          <Typography sx={{ marginBottom: 1 }}>¿Tiene alguna religión?</Typography>
          <FormControlLabel
            name="tieneReligion"
            control={
            <Checkbox checked={participante.tieneReligion} onChange={(e) =>
              onInputChange("tieneReligion", e.target.checked)
            } name="tieneReligion" />}
            label="Sí"
            disabled={isDisabled}
          />

          {participante.tieneReligion && (
            <TextField
              fullWidth
              name="religion"
              value={participante.religion || ''}
              onChange={handleInputChange}
              label="Especifique religión"
              sx={{ marginBottom: 2 }}
              disabled={isDisabled}
            />
          )}
          </>
        )}

        {isFieldVisible('grupoEtnico') && (
          <>
          <Typography sx={{ marginBottom: 1 }}>¿Pertenece a algún grupo étnico?</Typography>
          <FormControlLabel
            control={
              <Checkbox
                name="perteneceGrupoEtnico"
                checked={participante.perteneceGrupoEtnico}
                onChange={(e) =>
                  onInputChange("perteneceGrupoEtnico", e.target.checked)
                }
              />
            }
            label="Sí"
            sx={{ marginBottom: 2 }}
            disabled={isDisabled}
          />
          {participante.perteneceGrupoEtnico && (
            <TextField
              fullWidth
              name="grupoEtnico"
              value={participante.grupoEtnico || ''}
              onChange={handleInputChange}
              label="Especifique grupo étnico"
              sx={{ marginBottom: 2 }}
              disabled={isDisabled}
            />
          )}
          </>
        )}

        </Grid>

        {/* Columna 2 */}
        <Grid item xs={12} sm={6} md={4}>
        {isFieldVisible('escolaridad') && (
          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel>Escolaridad</InputLabel>
            <Select
              name="escolaridad"
              value={participante.escolaridad?.id || participante.escolaridad || ''}
              onChange={handleInputChange}
              label="Selecciona una opción"
              disabled={isDisabled}
            >
              {escolaridades.map((escolaridad) => (
                <MenuItem key={escolaridad.id} value={escolaridad.id}>{escolaridad.nombre}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {isFieldVisible('hablaEspanol') && (
          <>
          <Typography sx={{ marginBottom: 1 }}>¿Habla español?</Typography>
          <FormControlLabel
            control={
              <Checkbox
                name="hablaEspanol"
                checked={participante.hablaEspanol}
                onChange={handleHablaEspanolChange}
                color="primary"
                disabled={isDisabled}
              />
            }
            label="Sí"
          />
          </>
        )}

        {isFieldVisible('sabeLeer') && (
          <>
          <Typography sx={{ marginBottom: 1 }}>¿Sabe leer y escribir?</Typography>
          <FormControlLabel
            control={
              <Checkbox
                name="sabeLeer"
                checked={participante.sabeLeer}
                onChange={(e) =>
                  onInputChange("sabeLeer", e.target.checked)}
                color="primary"
                disabled={isDisabled}
              />
            }
            label="Sí"
          />
          </>
        )}

        {isFieldVisible('recibePercepciones') && (
          <>
          <Typography sx={{ marginBottom: 1 }}>¿Recibe percepciones?</Typography>
          <FormControlLabel
            control={
              <Checkbox
                name="recibePercepciones"
                checked={participante.recibePercepciones}
                onChange={(e) =>
                  onInputChange("recibePercepciones", e.target.checked)
                }
                disabled={isDisabled}
              />
            }
            label="Sí"
            sx={{ marginBottom: 2 }}
          />
          {participante.recibePercepciones && (
            <>
              <FormControl fullWidth sx={{ marginBottom: 2 }}>
                <InputLabel>Ingreso mensual neto</InputLabel>
                <Select
                  name="ingresosMensuales"
                  value={participante.ingresosMensuales ?? ''}
                  onChange={handleInputChange}
                  label="Ingreso mensual neto"
                  disabled={isDisabled}
                >
                  {ingresosMensuales.map((ingreso) => (
                    <MenuItem key={ingreso.clave} value={ingreso.clave}>{ingreso.etiqueta}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth sx={{ marginBottom: 2 }}>
                <InputLabel>Frecuencia del Ingreso</InputLabel>
                <Select
                  name="frecuenciaIngreso"
                  value={participante.frecuenciaIngreso ?? ''}
                  onChange={handleInputChange}
                  label="Frecuencia del ingreso"
                  disable={isDisabled}
                >
                  {frecuenciaIngresos.map((frecuencia) => (
                    <MenuItem key={frecuencia.clave} value={frecuencia.clave}>{frecuencia.etiqueta}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
          </>
        )}

        {isFieldVisible('tieneSeniasParticulares') && (
          <>
          <Typography sx={{ marginBottom: 1 }}>¿Tiene señas particulares?</Typography>
          <FormControlLabel
            control={
              <Checkbox
                name="tieneSeniasParticulares"
                checked={participante.tieneSeniasParticulares}
                onChange={(e) =>
                  onInputChange("tieneSeniasParticulares", e.target.checked)
                }
                disabled={isDisabled}
              />
            }
            label="Sí"
            sx={{ marginBottom: 2 }}
          />
          {participante.tieneSeniasParticulares && (
            <TextField
              fullWidth
              name="senias"
              value={participante.senias || ''}
              onChange={handleInputChange}
              label="Especifica las señas"
              sx={{ marginBottom: 2 }}
              disabled={isDisabled}
            />
          )}
          </>
        )}

        {isFieldVisible('datosPrivados') && (
          <>
          <Typography sx={{ marginBottom: 1 }}>¿Tratar los datos de forma privada?</Typography>
          <FormControlLabel
            control={
              <Checkbox
                name="datosPrivados"
                checked={participante.datosPrivados}
                onChange={(e) =>
                  onInputChange("datosPrivados", e.target.checked)}
                color="primary"
                disabled={isDisabled}
              />
            }
            label="Sí"
            sx={{ marginBottom: 2 }}
          />
          </>
        )}
        </Grid>

        {/* Columna 3 */}
        <Grid item xs={12} sm={6} md={4}>

        {isFieldVisible('discapacidad') && (
          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel>Discapacidad</InputLabel>
            <Select
              name="discapacidad"
              value={participante.discapacidad ?? ''}
              onChange={handleInputChange}
              label="Discapacidad"
              disabled={isDisabled}
            >
              {discapacidades.map((discapacidad) => (
                <MenuItem key={discapacidad.clave} value={discapacidad.clave}>
                  {discapacidad.etiqueta}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {isFieldVisible('lenguaIndigena') && (
          <>
          <Typography sx={{ marginBottom: 1 }}>¿Habla lengua indígena?</Typography>
          <FormControlLabel
            control={
              <Checkbox
              name="hablaLenguaIndigena"
              checked={participante.hablaLenguaIndigena}
              onChange={(e) =>
                onInputChange("hablaLenguaIndigena", e.target.checked)
              }
              disabled={isDisabled}
              />
            }
            label="Sí"
          />
          {participante.hablaLenguaIndigena && (
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel>Lengua indígena</InputLabel>
              <Select
                name="lenguaIndigena"
                value={participante.lenguaIndigena?.id || participante.lenguaIndigena || ''}
                onChange={handleInputChange}
                label="Lengua indígena"
                disabled={isDisabled}
              >
                {lenguasIndigenas.map((lenguaIndigena) => (
                  <MenuItem key={lenguaIndigena.id} value={lenguaIndigena.id}>{lenguaIndigena.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          </>
        )}

        {isFieldVisible('grupoVulnerable') && (
          <>
          <Typography sx={{ marginBottom: 1 }}>¿Pertenece a algún grupo vulnerable?</Typography>
          <FormControlLabel
            control={
              <Checkbox
                name="perteneceGrupoVulnerable"
                checked={participante.perteneceGrupoVulnerable}
                value={participante.perteneceGrupoVulnerable ?? ''}   //  participante.grupoVulnerable ?? ''
                onChange={(e) =>
                  onInputChange("perteneceGrupoVulnerable", e.target.checked)
                }
                disabled={isDisabled}
              />
            }
            label="Sí"
            sx={{ marginBottom: 2 }}
          />
          
          {participante.perteneceGrupoVulnerable && (
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel>Grupo Vulnerable</InputLabel>
              <Select
                name="grupoVulnerable"
                value={participante.grupoVulnerable ?? ''} //no se si esta bien
                onChange={handleInputChange}
                label="Selecciona un grupo"
                disabled={isDisabled}
              >
                {gruposVulnerables.map((grupoVulnerable) => (
                  <MenuItem key={grupoVulnerable.clave} value={grupoVulnerable.clave}>{grupoVulnerable.etiqueta}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          </>
        )}

        {isFieldVisible('bienes') && (
          <>
          <Typography sx={{ marginBottom: 1 }}>¿Tiene bienes de su propiedad?</Typography>
          <FormControlLabel
            control={
              <Checkbox
                name = "tieneBienes"
                checked={participante.tieneBienes}
                onChange={(e) =>
                  onInputChange("tieneBienes", e.target.checked)
                }
                disabled={isDisabled}
              />
            }
            label="Sí"
            sx={{ marginBottom: 2 }}
          />

          {participante.tieneBienes && (
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel>Cantidad de bienes o propiedades</InputLabel>
              <Select
                name="cantidadBienes"
                value={participante.cantidadBienes}
                onChange={handleInputChange}
                label="Cantidad de bienes o propiedades"
                disabled={isDisabled}
              >
                {cantidadBienes.map((cantidad, index) => (
                  <MenuItem key={index} value={cantidad}>{cantidad}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          </>
        )}

        {isFieldVisible('documentoIdentificacion') && (
          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel>Documento con el cuál se identifica</InputLabel>
            <Select
              name="documentoIdentificacion"
              value={participante.documentoIdentificacion?.id || participante.documentoIdentificacion || ''}
              onChange={handleInputChange}
              label="Documento con el cual se identifica"
              disabled={isDisabled}
            >
            {documento.map((documento) => (
                <MenuItem key={documento.id} value={documento.id}>{documento.identificacion}</MenuItem>
            ))}
            </Select>
          </FormControl>
        )}

        {isFieldVisible('lugarTrabajo') && (
          <TextField
              fullWidth
              name="lugarTrabajo"
                value={participante.lugarTrabajo || ''}
              onChange={handleInputChange}
              label="Lugar de trabajo"
              sx={{ marginBottom: 2 }}
              disabled={isDisabled}
            />
        )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default DatosEstadisticos;