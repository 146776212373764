import { Box, Button, Checkbox, Divider, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useToastAlert } from '../../../components/Alert/ToastAlertSave/ToastAlertSave';
import { useToastAlertUpdate } from '../../../components/Alert/ToastAlertUpdate/ToastAlertUpdate';
import { useToastAlertValidation } from '../../../components/Alert/ToastAlertValidation/ToastAlertValidation';
import { fetchEstados, fetchMunicipiosByEstadoId } from "../../../shared/services/EstadosService";
import { fetchDistritos } from "../../../shared/services/DistritosService";
import { validateFields } from "../../../components/Alert/ValidationRequired/validationRequired";
import { create, fetchById, update } from "../institucionService";
import AccionesOnlyButtons from "../../../components/Acciones/AccionesOnlyButtons";
import Breadcrumbs from "../../../components/Breadcrumb/breadcrumbs";

const FormInstitucion = forwardRef(({ token, onAdd, onEdit, onCancel, activeTitle = true }, ref) => {
    const navigate = useNavigate();
    const { showSnackbar, showConfirmDialog } = useToastAlert();
    const { showSnackbar: showUpdateSnackbar } = useToastAlertUpdate();
    const { showSnackbar: showAlertValidate } = useToastAlertValidation();
    const [estados, setEstados] = useState([]);
    const [municipios, setMunicipios] = useState([]);
    const [distritos, setDistritos] = useState([]);
    const [errorMessages, setErrorMessages] = useState({});

    const [formData, setFormData] = useState({
        id: '',
        nombre: '',
        estado: '',
        telefono: '',
        extension: '',
        tipoInstitucion: '',
        domicilio: {
            id: '',
            calle: '',
            exterior: '',
            interior: '',
            idEstado: '',
            municipio: '',
            localidad: '',
            colonia: '',
            codigoPostal: '',
            estadoRepublica: '',
            referencia: '',
        }
    });

    useEffect(() => {
        if (onEdit) {
            getById();
        } else {
            loadEstados();
            resetForm();
            setFormData((prevData) => ({
                ...prevData,
                estado: 'ACTIVE',
            }))
        }
    }, [onEdit, token]);

    const getById = function (id) {
        const loadbyId = async () => {
            try {
                const institucion = await fetchById(token, onEdit.id);
                institucion.domicilio = { ...institucion.domicilio, audit: {} }
                setFormData(institucion);
                try {
                    const estadosData = await fetchEstados(token);
                    setEstados(estadosData);
                    try {
                        const estadoData = estadosData.filter(estado => estado.name === institucion.domicilio.estadoRepublica);


                        const municipiosData = await fetchMunicipiosByEstadoId(token, estadoData[0].id);
                        setMunicipios(municipiosData);
                    } catch (error) {
                        console.error('Error al obtener municipios:', error);
                    }
                } catch (error) {
                    console.error('Error al obtener estados:', error);
                }
            } catch (error) {
                console.error('Error al obtener la institucion:', error);
            }
        };
        loadbyId();
    };

    const fetchMunicipios = function (event) {
        formData.domicilio.municipio = '';
        const loadMunicipios = async () => {
            try {
                const estadoData = estados.filter(estado => estado.name === event.target.value);
                const municipiosData = await fetchMunicipiosByEstadoId(token, estadoData[0].id);


                setMunicipios(municipiosData);
            } catch (error) {
                console.error('Error al obtener municipios:', error);
            }
        };
        loadMunicipios();
        handleChange(event);
    };

    const loadEstados = async () => {
        try {
            const estadosData = await fetchEstados(token);
            setEstados(estadosData);
        } catch (error) {
            console.error('Error al obtener estados:', error);
        }
    };

    const loadDistritos = async () => {
        try {
            const distritosData = await fetchDistritos(token);

            setDistritos(distritosData);
        } catch (error) {
            console.error('Error al obtener distritos:', error);
        }
    };

    const formatPhoneCelNumbers = (value) => {
        const numericValue = value.replace(/\D/g, '');

        if (numericValue.length === 0) {
            return '';
        } else if (numericValue.length <= 3) {
            return `(${numericValue}`;
        } else if (numericValue.length <= 6) {
            return `(${numericValue.slice(0, 3)}) ${numericValue.slice(3)}`;
        } else {
            return `(${numericValue.slice(0, 3)}) ${numericValue.slice(3, 6)} ${numericValue.slice(6, 10)}`;
        }
    };

    const handleSubmit = async (e) => {

        const rules = {
            nombre: { required: true },
            estado: { required: true },
            telefono: { pattern: /^\(\d{3}\) \d{3} \d{4}$/ },
            'domicilio.calle': { required: true },
            'domicilio.estadoRepublica': { required: true },
            'domicilio.municipio': { required: true },
            'tipoInstitucion': { required: true }
        }
        const errors = validateFields(formData, rules, showAlertValidate);

        if (formData.telefono.replace(/\D/g, '').length !== 10 && formData.telefono.replace(/\D/g, '').length > 0) {
            setErrorMessages((prevErrors) => ({
                ...prevErrors,
                telefono: 'El número de telefono debe tener 10 dígitos.'
            }));
            return;
        }

        if (Object.keys(errors).length > 0) {
            setErrorMessages(errors);
            return;
        }

        let formDataToSubmit = {
            ...formData,
            audit: {}
        };

        try {
            if (formData.id) {
                await update(token, formDataToSubmit);
                showUpdateSnackbar('Registro actualizado con éxito', 'success');
            } else {
                await create(token, formDataToSubmit);
                showSnackbar('Registro guardado con éxito', 'success');
            }

            if (onAdd) {
                onAdd();
            }
            
            resetForm();

            if(onCancel){
                onCancel();
            }


        } catch (error) {
            console.error('Error al guardar el registro:', error);
            if (error.response && error.response.status === 409) {
                showSnackbar("No pueden existir 2 instituciones con el mismo nombre", 'error');
                setErrorMessages({ ...errorMessages, nombre: 'Nombre de institucion ya existe' });
            } else {
                showSnackbar('¡Algo salió mal!', 'error');
            }
        }

    };

    const resetForm = () => {
        setFormData({

            nombre: '',
            estado: 'ACTIVE',
            telefono: '',
            extension: '',
            tipoInstitucion: '',
            domicilio: {
                calle: '',
                interior: '',
                exterior: '',
                colonia: '',
                localidad: '',
                codigoPostal: '',
                municipio: '',
                estadoRepublica: '',
                referencia: ''
            }

        });
        setErrorMessages({});
    };

    const handleCancel = () => {
        if (onCancel) {
            onCancel();
        } else {
            navigate('/api/core/instituciones');
        }

    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'telefono') {
            const formattedValue = formatPhoneCelNumbers(value);
            const numericValue = value.replace(/\D/g, '');

            setFormData((prevData) => ({
                ...prevData,
                telefono: numericValue
            }));
            e.target.value = formattedValue;
        } else if (name.startsWith('domicilio.')) {
            const fieldName = name.split('.')[1];
            setFormData((prevData) => ({
                ...prevData,
                domicilio: {
                    ...prevData.domicilio,
                    [fieldName]: value
                }
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value
            }));
        }

        setErrorMessages({
            ...errorMessages,
            [name]: '',
        });

    };
    
    useImperativeHandle(ref, () => ({
        guardar: handleSubmit,
        cancelar: handleCancel
    }));

    return (
        <form>
            {onCancel == null  && (
                <AccionesOnlyButtons accion1={handleSubmit} accion2={handleCancel}/>
            )}

            <Grid  className="box-sombra">
                {activeTitle && (<Breadcrumbs type="true" istitulo="true" className="title2"/>)}
                <Grid container spacing={2} sx={{ marginTop: 1 }} >
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        name="nombre"
                        inputProps={{ minLength: 3, maxLength: 100 }}
                        label="Nombre de la institución"
                        variant="outlined"
                        fullWidth
                        value={formData.nombre}
                        onChange={handleChange}
                        error={!!errorMessages.nombre}
                    />
                    {errorMessages.nombre && <FormHelperText error>{errorMessages.nombre}</FormHelperText>}
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        name="domicilio.calle"
                        inputProps={{ minLength: 3, maxLength: 100 }}
                        label="Calle"
                        variant="outlined"
                        fullWidth
                        value={formData.domicilio.calle}
                        onChange={handleChange}
                        error={!!errorMessages['domicilio.calle']}
                    />
                    {errorMessages['domicilio.calle'] &&
                        <FormHelperText error>{errorMessages['domicilio.calle']}</FormHelperText>}
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        name="domicilio.exterior"
                        inputProps={{ maxLength: 20 }}
                        label="Número exterior"
                        variant="outlined"
                        fullWidth
                        value={formData.domicilio.exterior}
                        onChange={handleChange}
                        error={!!errorMessages.exterior}
                    />
                    {errorMessages.exterior && <FormHelperText error>{errorMessages.exterior}</FormHelperText>}
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        name="domicilio.interior"
                        inputProps={{ maxLength: 20 }}
                        label="Número interior (Opcional)"
                        variant="outlined"
                        fullWidth
                        value={formData.domicilio.interior}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth variant="outlined" error={!!errorMessages['domicilio.estadoRepublica']}>
                        <InputLabel id="estado-label">Estado</InputLabel>
                        <Select
                            labelId="estado-label"
                            name="domicilio.estadoRepublica"
                            value={formData.domicilio.estadoRepublica}
                            onChange={fetchMunicipios}
                            label="Estado"
                            error={!!errorMessages['domicilio.estadoRepublica']}
                        >
                            {estados.map((estado) => (
                                <MenuItem key={estado.id} value={estado.name}>{estado.name}</MenuItem>
                            ))}
                        </Select>
                        {errorMessages['domicilio.estadoRepublica'] &&
                            <FormHelperText>{errorMessages['domicilio.estadoRepublica']}</FormHelperText>}
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth variant="outlined" error={!!errorMessages['domicilio.municipio']}>
                        <InputLabel id="municipio-label">Delegación o municipio</InputLabel>
                        <Select
                            labelId="municipio-label"
                            name="domicilio.municipio"
                            value={formData.domicilio.municipio}
                            onChange={handleChange}
                            label="Delegación o municipio"
                            error={!!errorMessages['domicilio.municipio']}
                        >
                            {municipios.map((municipio) => (
                                <MenuItem key={municipio.id} value={municipio.name}>{municipio.name}</MenuItem>
                            ))}
                        </Select>
                        {errorMessages['domicilio.municipio'] &&
                            <FormHelperText>{errorMessages['domicilio.municipio']}</FormHelperText>}
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        name="domicilio.localidad"
                        inputProps={{ maxLength: 100 }}
                        label="Localidad (Opcional)"
                        variant="outlined"
                        fullWidth
                        value={formData.domicilio.localidad}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        name="domicilio.colonia"
                        inputProps={{ maxLength: 100 }}
                        label="Colonia (Opcional)"
                        variant="outlined"
                        fullWidth
                        value={formData.domicilio.colonia}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        name="domicilio.codigoPostal"
                        inputProps={{ maxLength: 5 }}
                        label="Código postal (Opcional) "
                        variant="outlined"
                        fullWidth
                        value={formData.domicilio.codigoPostal}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        name="domicilio.referencia"
                        inputProps={{ maxLength: 100 }}
                        label="Referencia (Opcional)"
                        variant="outlined"
                        fullWidth
                        value={formData.domicilio.referencia}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        name="telefono"
                        inputProps={{ maxLength: 14 }}
                        label="Teléfono (Opcional)"
                        variant="outlined"
                        fullWidth
                        value={formatPhoneCelNumbers(formData.telefono)}
                        onChange={handleChange}
                        error={!!errorMessages.telefono}
                    />
                    {errorMessages.telefono && <FormHelperText error>{errorMessages.telefono}</FormHelperText>}
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        name="extension"
                        inputProps={{ maxLength: 6 }}
                        label="Extensión (Opcional)"
                        variant="outlined"
                        fullWidth
                        value={formData.extension}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth variant="outlined" error={!!errorMessages['tipoInstitucion']}>
                        <InputLabel id="tipoInstitucion-label">Tipo de institución</InputLabel>
                        <Select
                            labelId="tipoInstitución-label"
                            name="tipoInstitucion"
                            value={formData.tipoInstitucion}
                            onChange={handleChange}
                            label="Tipo de institución"
                            error={!!errorMessages.tipoInstitucion}
                        >
                              <MenuItem key="Externa" value="Externa">Externa</MenuItem>
                              <MenuItem key="Tribunal Federal" value="Tribunal Federal">Tribunal Federal</MenuItem>

                        </Select>
                        {errorMessages['tipoInstitucion'] &&
                            <FormHelperText>{errorMessages['tipoInstitucion']}</FormHelperText>}
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth variant="outlined">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formData.estado === "ACTIVE"}
                                    onChange={(event) => handleChange({
                                        target: {
                                            name: 'estado',
                                            value: event.target.checked ? 'ACTIVE' : 'INACTIVE'
                                        }
                                    })}
                                    color="primary"
                                />
                            }
                            label="Activar institución"
                        />
                        {errorMessages.estado && <FormHelperText>{errorMessages.estado}</FormHelperText>}
                    </FormControl>
                </Grid>
                </Grid>
            </Grid>
        </form>
    )
});

export default FormInstitucion;