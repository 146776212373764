import React, { useState } from 'react';
import {
  Box,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl
} from '@mui/material';
import PaginationGeneric from '../../../../components/Pagination/PaginationGeneric';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { fetchDocumentoPrueba, fetchDesistimientoAdmision } from '../../../../shared/AudienciasService';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const TablaDetallesPruebas = ({
  onEdit,
  setPage,
  token,
  rowsPerPage,
  rows,
  pageable,
  page,
  setRowsPerPage
}, ref) => {
  const [selectedValues, setSelectedValues] = useState({});

  const handleRadioChange = async (event, rowId) => {
    console.log(rows);
    const status = event.target.value;
    setSelectedValues((prev) => ({
      ...prev,
      [rowId]: status,
    }));
  
    try {
      const response = await fetchDesistimientoAdmision(token, rowId, status);  // Llama a la API con el id y estatus
    } catch (error) {
      console.error("Error al llamar a la API:", error);
    }
  };
  
  const handleDownload = async (item) => {
    try {
      const doc = await fetchDocumentoPrueba(token, item.idAudienciaPruebas);
      let blob = new Blob([doc], { type: "application/pdf" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.target = item.ruta;
      link.click();
    } catch (error) {
      console.error("Error al generar el documento:", error);
    }
  };


  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <PaginationGeneric
          pageable={pageable}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
        <TableContainer sx={{ maxHeight: 600, overflowY: 'auto' }}>
          <Table stickyHeader sx={{ minWidth: 750 }}>
            <TableHead>
              <TableRow>
                <TableCell className='table-header'>Asistente</TableCell>
                <TableCell className='table-header'>Tipo de pruebas</TableCell>
                <TableCell className='table-header'>Descripción</TableCell>
                <TableCell className='table-header'>Tipo de perito</TableCell>
                <TableCell className='table-header' >Desistimiento / Admisión</TableCell>
                <TableCell className='table-header'>Archivos</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((item) => (
                <StyledTableRow
                  key={item.idAudienciaPruebas}
                  onClick={() => onEdit(item)}
                  hover
                  style={{ cursor: 'pointer' }}
                >
                  <TableCell>{item.asistente}</TableCell>
                  <TableCell>{item.tipoPrueba}</TableCell>
                  <TableCell>{item.descripcion}</TableCell>
                  <TableCell>{item.tipoPerito}</TableCell>
                  <TableCell align='center'>
                    <Tooltip title="Selecciona Desistimiento o Admisión">
                      <FormControl component="fieldset">
                        <RadioGroup
                          row
                          sx={{ display: 'flex', justifyContent: 'center' }} // Centra los radios
                          name={`desistimientoAdmision-${item.idAudienciaPruebas}`}
                          value={selectedValues[item.idAudienciaPruebas] || item.desistimientoAdmision}
                          onChange={(e) => handleRadioChange(e, item.idAudienciaPruebas)}
                        >
                          <FormControlLabel
                            value="DESISTIMIENTO"
                            control={<Radio />}
                          />
                          <FormControlLabel
                            value="ADMISION"
                            control={<Radio />}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Impresión de Documento">
                      <PictureAsPdfIcon
                        style={{ marginRight: '10px' }}
                        className="icon-color"
                        onClick={(event) => {
                          event.stopPropagation();
                          handleDownload(item);
                        }}
                      />
                    </Tooltip>
                  </TableCell>
                </StyledTableRow>
              ))}
              {rows.length === 0 && (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No hay salas disponibles.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <PaginationGeneric
          pageable={pageable}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default TablaDetallesPruebas;
