import React, { useEffect, useState, useContext } from 'react';
import { Box, Grid } from "@mui/material";
import NotificacionTable from "./Grid/NotificacionTable";
import KeycloakConf from '../../api/keycloakConf';
import ConfirmationDialog from "../../components/Alert/ConfirmationDialog";
import ToastAlertDelete from "../../components/Alert/ToastAlertDelete/ToastAlertDelete";
import RefreshButton from "../../components/Button/RefreshButton";
import SearchInput from "../../components/Search/SearchInput";
import { findAll } from './NotificacionService';
import NotificacionFiltros from './Grid/NotificacionFiltros';
import ConfirmAndDownloadListaModal from "./ModalesGrid/ConfirmAndDownloadListaModal";
import Breadcrumbs from '../../components/Breadcrumb/breadcrumbs';
import { useLocation } from 'react-router-dom';

const Notificacion = () => {
    const [notificacionData, setNotificacionData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(true);
    const { getToken } = useContext(KeycloakConf);
    const [pageable, setPageable] = useState({});
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [toastOpen, setToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [toastType, setToastType] = useState('success');
    const [isDigitalizacion, setIsDigitalizacion] = useState(false);
    const [sendRutaModal, setSendRutaModal] = useState(false);
    const [isModalDescargarListaOpen, setIsModalDescargarListaOpen] = useState(false);
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);
    const [tipo, setTipo] = useState('ESTRADO');
    const [estado, setEstadoNotificacion] = useState('PENDIENTE_DE_ASIGNAR');

    const [selectedIds, setSelectedIds] = useState([]);
    const [data, setData] = useState([]);
    const location = useLocation();
    const handleSelectionChange = (selected) => {
        setSelectedIds(selected);
        setIsButtonEnabled(selected.length > 0);
    };

    useEffect(() => {
        loadNotificacionData();
        if(location.state?.tipo && location.state?.estado){
            setTipo(location.state.tipo);
            setEstadoNotificacion(location.state.estado);
            location.state = {};
        }
    }, [page, rowsPerPage, searchQuery, tipo, estado]);

    const loadNotificacionData = async () => {
        setLoading(true);
        const token = getToken();
        try {
            const data = await findAll(token, page, rowsPerPage, '', tipo, estado);
            setNotificacionData(data.content || []);
            setPageable(data.page || {});
            setData(data)
        } catch (error) {
            console.error('Error al cargar datos de notificaciones:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleRefresh = () => {
        setSearchQuery('');
        setPage(0);
        loadNotificacionData();
    };

    //Descarga lista
    const handleDescargarLista = async () => {
        try {
            //TODO Falta funcion para descargar lista
        } catch (error) {
            console.error('Error al Descargar Lista de registros en ruta', error);
        }
    };

    return (
        <>
        <Grid className="box-sombra">
            {!isDigitalizacion && (
                <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
                    <Box display="flex" alignItems="center">
                        <Grid sx={{marginRight:1}}>
                            <Breadcrumbs istitulo="true" className="title2"/>
                        </Grid>
                        <RefreshButton onClick={handleRefresh} />
                    </Box>
                    <Box flexBasis="300px" ml={2}>
                        <SearchInput
                            value={searchQuery}
                            onChange={handleSearchChange}
                            placeholder="Buscar..."
                            ariaLabel="Campo de búsqueda para notificaciones"
                            tooltipTitle="Escriba para buscar"
                        />
                    </Box>
                </Box>
            )}

            <Box mb={4} mt={4}>
                <NotificacionFiltros
                    tipo = {tipo}
                    setTipo={setTipo}
                    estado = {estado}
                    setEstadoNotificacion={setEstadoNotificacion}
                    isButtonEnabled={isButtonEnabled}
                    cantidadNotificaciones={selectedIds.length}
                    selectedIds={selectedIds}
                    loadNotificacionData={loadNotificacionData}
                    setSendRutaModal = {setSendRutaModal}
                    data={data}
                />
            </Box>

            {loading ? <p>Cargando...</p> : (
                <NotificacionTable
                    estado = {estado}
                    data={notificacionData}
                    token={getToken()}
                    setIsDigitalizacion={setIsDigitalizacion}
                    loadNotificacionData={loadNotificacionData}
                    pageable={pageable}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    onSelectionChange={handleSelectionChange}
                    fetchData={handleRefresh}
                    tipo={tipo}
                    sendRutaModal={sendRutaModal}
                    setSendRutaModal={setSendRutaModal}
                    setIsModalDescargarListaOpen={setIsModalDescargarListaOpen}
                />
            )}

            <ConfirmationDialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                message={dialogMessage}
            />

            <ToastAlertDelete
                open={toastOpen}
                onClose={() => setToastOpen(false)}
                message={toastMessage}
                type={toastType}
            />

            <ConfirmAndDownloadListaModal
                open = {isModalDescargarListaOpen}
                onClose = {() => setIsModalDescargarListaOpen(false)}
                onConfirm = {handleDescargarLista}
                numRegistros = {selectedIds.length}
            />
            </Grid>
        </>
    );
};

export default Notificacion;
