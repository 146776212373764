import React from 'react';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import { Tooltip } from '@mui/material';

const StyledButton = styled(IconButton)(({ theme }) => ({
    backgroundColor: '#636569', // Color de fondo del botón
    color: 'white', // Color del ícono
    borderRadius: '50%', // Hace que sea circular
    '&:hover': {
        backgroundColor: '#a6a6a6', // Color al pasar el mouse
    },
}));

const ButtonAdd = ({ onClick, ariaLabel, isDisabled=false }) => {
    return (
        <Tooltip title="Agregar">
            <StyledButton aria-label={ariaLabel} onClick={onClick} disabled={isDisabled}>
                <AddIcon />
            </StyledButton>
        </Tooltip>
    );
};

export default ButtonAdd;
