import React from 'react';
import {
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  Typography
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { Margin } from '@mui/icons-material';

const ConfirmAndDownloadListaModal = ({ open, onClose, onConfirm, numRegistros }) => {
  const dialogContentStyle = { textAlign: 'left', wordWrap: 'break-word', overflowWrap: 'break-word'};
  const dialogActionsStyle = { justifyContent: 'center' };
  const buttonStyle = { textTransform: 'none' };

  return (
    <Dialog 
    open={open} 
    onClose={onClose}
    PaperProps={{
      style: {
        width: '700px',
        maxWidth: '90vw',
        padding: '5px',
        position: 'fixed',
        top: '20px',
        left: '50%',
        transform: 'translateX(-50%)',
      },
    }}
      disableEscapeKeyDown
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography id="modal-title" variant="h6" component="h2" sx={{ fontWeight: 'bold', color: '#333', mt:3, ml:3 }}>
          <strong>Éxito</strong></Typography>
        <IconButton onClick={onClose} sx={{ mt:3, mr:3 }}>
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent style={dialogContentStyle}>
        {"Se han marcado "+ numRegistros + " expedientes en ruta."}
      </DialogContent>
      <DialogActions style={dialogActionsStyle}>
        <Button
          variant="outlined"
          onClick={() => {
            onConfirm();
            onClose();
          }}
          style={{ ...buttonStyle, borderColor: '#8c92bc', color: '#fff' }}
          sx= {{mb:1}}
          className="button-save"
        >
          Descargar lista
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmAndDownloadListaModal;