import React, { useState, useEffect } from "react";
import {
  TextField,
  Divider,
  FormHelperText,
  FormControl,
  Typography,
  Grid,
  Select,
  MenuItem,
  Box,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { TranscribeSharp } from "@mui/icons-material";

export default function FormTransferencia({personalTurnadoOptions, transferencia, setTransferencia, errors, isDisabled}) {
  const location = useLocation();

  const [personaRecibe, setPersonaRecibe] = useState('');
  const [observaciones, setObservaciones] = useState();

  const handleChange = (event) =>{

    const { name, value } = event.target;

    setPersonaRecibe(value);

    setTransferencia((prevData)=>({
      ...prevData,
      personaRecibeId: value
    }));

  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setTransferencia((prevData) => ({
      ...prevData,
      observaciones: value
    }));
  }

  useEffect(()=>{
    setPersonaRecibe(transferencia.personaRecibeId??'');
  });

  return (
    <div>
      <form>
        <Grid container spacing={1} sx={{ marginTop: 2 }}>
          <Grid item xs={12} sm={4} md={2}>
            <Typography>Entrega: </Typography>
          </Grid>
          <Grid item xs={12} sm={8} md={10}>
            <TextField value={transferencia.personaEntrega + ", " +transferencia.personaEntregaPuesto} fullWidth disabled/>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Typography>Recibe: </Typography>
          </Grid>
          <Grid item xs={12} sm={8} md={10}>
            <FormControl fullWidth variant="outlined">
                <Select
                    name="personaRecibeId"
                    error={!!errors.personaRecibeId}
                    value={personaRecibe}
                    required
                    onChange={handleChange}
                    disabled={isDisabled}
                >
                    { personalTurnadoOptions.map((persona) => (
                        <MenuItem key={persona.id} value={persona.id}>
                            {persona.nombre + ", " + persona.puesto}
                        </MenuItem>
                    ))}
                </Select>
                <FormHelperText >{errors.personaRecibeId || ''}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        <Box sx={{ marginTop: 2, marginBottom: 2 }}>
          <Divider />
        </Box>

        <Typography variant="h7" align="left" sx={{ mb: 2 }}>Expedientes / Piezas</Typography>
        <TableContainer sx={{ maxHeight: 600, overflowY: "auto" }}>
          <Table stickyHeader sx={{ minWidth: 750 }}>
            <TableHead>
              <TableRow>
                <TableCell className="table-header" align="center">
                  No.
                </TableCell>
                <TableCell className="table-header" align="center">
                  Expediente
                </TableCell>
                <TableCell className="table-header" align="center">
                  Concepto
                </TableCell>
                <TableCell className="table-header" align="center">
                  Fecha de término
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transferencia.expedientes.map((item, index) => {
                return (
                  <TableRow
                    key={item.id}
                    hover
                  >
                    <TableCell>
                      {index+1}
                    </TableCell>   
                    <TableCell align="center">
                      {item.expediente}
                    </TableCell>
                    <TableCell align="center">{item.concepto}</TableCell>
                    <TableCell align="center">
                      {item.fechaTermino != null ? new Date(item.fechaTermino).toLocaleString() : "-"}
                    </TableCell>
                  </TableRow>
                );
              })}
              {transferencia.expedientes.length === 0 && (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    No hay registros disponibles.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid container spacing={1} sx={{ marginTop: 2 }}>
          <Grid item xs={12}>
            <TextField
              label="Observaciones generales"
              name="observaciones"
              value={transferencia.observaciones}
              fullWidth  
              error={!!errors.observaciones}
              InputLabelProps={{ shrink: true }}
              onChange={(e) => handleInputChange(e)}
              disabled={isDisabled} />
            {errors.observaciones && (
              <FormHelperText>{errors.observaciones}</FormHelperText>
            )}
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
