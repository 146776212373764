import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Box, Grid, Tabs, Tab } from '@mui/material';
import DatosGenerales from './DatosGenerales';
import DatosContacto from './DatosContacto';
import DatosEstadistica from './DatosEstadisticos';
import KeycloakConf from '../../../../api/keycloakConf';
import { useNavigate } from 'react-router-dom'; 
import Acciones from '../../../../components/Acciones/Acciones';
import ConfirmationModal from '../../../../components/Modal/ConfirmationModal';
import { useToastAlertValidation } from '../../../../components/Alert/ToastAlertValidation/ToastAlertValidation';
import { addNewParticipant } from './Service/ParticipanteService';
import { useLocation } from 'react-router-dom';
import { getParticipanteById, updateParticipante} from './Service/ParticipanteService';
import NotificationModal from '../../../../components/Modal/TipoNotificacion';

const AgregarParticipante = () => {
    const [activeTab, setActiveTab] = useState(0);
    const navigate = useNavigate();  

    const { showSnackbar } = useToastAlertValidation(); 
    const [showValidations, setShowValidations] = useState(false);
    
    const initialParticipante = {
        datosGenerales: {
            tipo: [''],
            nombre: '',
            pseudonimo: '',
            nacionalidad: '',
            rfc: '',
            apellidoPaterno: '',
            sexo: '',
            edad: '',
            curp: '',
            tipoPersona: '',
            apellidoMaterno: '',
            fechaNacimiento: '',
            estadoCivil: null,
            razonSocial: '',
            cedula: '',
            tipoDefensor: null,
            adscripcion: '',
            enRepresentacion: '',
            idCarpeta: ''
        },

        datosContacto: {
            pais: null,
            estado: '',
            municipio: '',
            codigoPostal: '',
            colonia: '',
            calle: '',
            numeroExterior: '',
            numeroInterior: '',
            tipoDomicilio: null,
            correoElectronico: '',
            telefono: '',
        },
        datosEstadistica: {
            escolaridad: '',
            paisNacimiento: null,
            condicionMigratoria: null,
            lenguaExtranjera: '',
            profesion: null,
            entidadNacimiento: '',
            lenguaIndigena: '',
            grupoVulnerable: null,
            recibePercepciones: '',
            dicapacidad: '',
            municipioNacimiento: '',
            sabeLeer: false,
            ingresosMensuales: null,
            frecuenciaIngreso: null,
            lugarTrabajo: '',
            hablaEspanol: false,
            senias: '',
            grupoEtnico: '',
            datosPrivados: false,
            detallesDependientes: '',
            tieneDependientes: '',
            documentoIdentificacion: '',
            hablaLenguaIndigena: false,
            perteneceGrupoVulnerable: false,
            tieneBienes: false,
            tieneSeniasParticulares: false,
            perteneceGrupoEtnico: false,
            tieneReligion: false, 
            tieneDependientes: false,
            hablaLenguaExtranjera: false,
            religion: ''
        }
    };

    const [participante, setParticipante] = useState(initialParticipante);    
    
    const [modalOpen, setModalOpen] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const { getToken } = useContext(KeycloakConf);
    const token = getToken(); 

    const [modalNotificacionOpen, setModalNotificacionOpen] = useState(false);
    const [newParticipantId, setNewParticipantId] = useState(null);
    const [participantCreated, setParticipantCreated] = useState(false);

    const location = useLocation();
    const { participanteId, isEdit, isDisabled } = location.state || {}; 
    const { item } = location.state || {};
    const [nombreTipo, setNombreTipo] = useState('');
    const [errors, setErrors] = useState({});
    
    const [currentDateTime, setCurrentDateTime] = useState({
        date: new Date().toLocaleDateString(),
        time: new Date().toLocaleTimeString(),
    });

    const handleChange = (event, newValue) => {
        if (newValue === 1 && !validateErrors()) {
            showSnackbar("Complete la información faltante", "error");
            return;
        }
        setActiveTab(newValue);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false); 
    };

    const handleTipoChange = (newTipo) => {
        setParticipante({
            ...initialParticipante,
            datosGenerales: {
                ...initialParticipante.datosGenerales,
                tipo: newTipo, 
            }
        });
    };

    const handleCancelClick = () => {
        setOpenDialog(true); 
    };

    const handleClose = () => {
        navigate("/api/bandeja/expediente/", { state: { item } });
    };

        const onInputChange = (section, name, value) => {
        setParticipante((prevData) => {
            const updatedData = {
                ...prevData,
                [section]: {
                    ...prevData[section],
                    [name]: value === null || value === '' ? null : value,
                }
            };

            const newErrors = validateErrors(section);
            setErrors((prevErrors) => ({
                ...prevErrors,
                ...newErrors
            }));

            return updatedData;
        });
    };

    const handleFechaNacimientoChange = (e) => {
        const fechaNacimiento = e.target.value;
        const edad = calculateAge(fechaNacimiento);
        if (edad < 18) {
            showSnackbar("La persona debe ser mayor de edad, ingrese una fecha de nacimiento diferente", "error");
        } else {
            setParticipante(prevData => ({
                ...prevData,
                datosGenerales: {
                    ...prevData.datosGenerales,
                    fechaNacimiento,
                    edad
                }
            }));
        } 
    };

    const calculateAge = (fechaNacimiento) => {
        const birthDate = new Date(fechaNacimiento);
        const currentDate = new Date();
        let age = currentDate.getFullYear() - birthDate.getFullYear();
        const month = currentDate.getMonth();
        if (month < birthDate.getMonth() || (month === birthDate.getMonth() && currentDate.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    };

    const transformToOnlyIds = (data) => {
        const transformSection = (section) => {
            return Object.keys(section).reduce((acc, key) => {
                const value = section[key];
                if (value && typeof value === 'object' && value.id) {
                    acc[key] = value.id; 
                } else if (Array.isArray(value)) {
                    console.log(item);
                    acc[key] = value.map(item => item && typeof item === 'object' && item.id ? item.id : item);
                } else {
                    acc[key] = value;
                }
                return acc;
            }, {});
        };
    
        return {
            datosGenerales: transformSection(data.datosGenerales),
            datosContacto: transformSection(data.datosContacto),
            datosEstadistica: transformSection(data.datosEstadistica)
        };
    };

    const validateErrors = (section) => {
        const errors = {};
        if (section === 'datosContacto') {
            const { datosContacto } = participante;
            const camposObligatorios = [
                "pais", 
                "estado", 
                "municipio", 
                "codigoPostal", 
                "colonia", 
                "calle", 
                "numeroExterior",
                "numeroInterior",  
                "tipoDomicilio", 
                "correoElectronico", 
                "telefono"
            ];
            camposObligatorios.forEach(campo => {
                if (!datosContacto[campo]) {
                    errors[campo] = 'Este campo es obligatorio';
                }
            });
        }
        return errors;
    };

      const handleSubmit = () => {
        const newErrors = validateErrors('datosContacto');
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            showSnackbar("Complete la información faltante", "error");
            setActiveTab(1);
            setShowValidations(true);

            return;
        }
        setModalOpen(true);
        const participanteTransformado = transformToOnlyIds(participante);
        setParticipante(participanteTransformado);
    };

    const handleConfirm = useCallback(async () => {
            try {
                if (isEdit && participanteId) {
                    await updateParticipante(token, participanteId, participante);
                   
                    showSnackbar("Participante actualizado con éxito", "success");
                    navigate("/api/bandeja/expediente/", { state: { item } });
                } else {
                    participante.datosGenerales.idCarpeta = item.carpetaId;
                    const createdParticipant = await addNewParticipant(token, participante);
        
                    if (createdParticipant && createdParticipant.personaDocumentoId) {
                        setNewParticipantId(createdParticipant.personaDocumentoId);
                        setParticipantCreated(true);
                    } else {
                        console.error("El ID del participante no se asignó correctamente.");
                    }
        
                    setModalOpen(false);
                    showSnackbar("Participante generado con éxito", "success");
                }            
            } catch (error) {
                console.error("Error al guardar el participante:", error);
                showSnackbar("Hubo un error al generar o actualizar el participante", "error");
            }
        
        
    }, [isEdit, token, participante, participanteId, navigate]);
    


    useEffect(() => {
        if (participantCreated && newParticipantId) {
            setModalNotificacionOpen(true);
            setParticipantCreated(false); 
        }
    }, [participantCreated, newParticipantId]);
       

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentDateTime({
                date: new Date().toLocaleDateString(),
                time: new Date().toLocaleTimeString(),
            });
        }, 1000);
        return () => clearInterval(interval);
    }, []);    

    //EDITAR
    useEffect(() => {
        if (isEdit && participanteId) {
            const fetchParticipant = async () => {
                try {
                    const response = await getParticipanteById(token, participanteId);
                    setParticipante(response);
                } catch (error) {
                    console.error("Error al cargar los datos del participante:", error);
                }
            };
    
            fetchParticipant();
        }
    }, [isEdit, participanteId, token]);

    return (
        <Grid container spacing={2} alignItems="center" sx={{ width: '100%' }}>
            <Acciones accion1={handleSubmit} accion2={handleCancelClick}/>         
                      
            <Box sx={{ mt: 2, width: '100%' }}>
                <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)}>
                    <Tab label="General" />
                    <Tab label="Datos de contacto" />
                    <Tab label="Datos de estadística" />
                </Tabs>
                {activeTab === 0 && (
                    <DatosGenerales token={token}
                        participante={participante.datosGenerales}
                        onInputChange={(name, value) => {
                            onInputChange("datosGenerales", name, value);
                            if (name === 'tipo') {
                                setParticipante(prevData => ({
                                    ...prevData,
                                    datosGenerales: {
                                        ...prevData.datosGenerales,
                                        tipo: value,
                                    }
                                }));
                            }
                        }}
                        handleFechaNacimientoChange={handleFechaNacimientoChange}
                        onNombreTipoChange={setNombreTipo}
                        handleTipoChange={handleTipoChange} 
                        isDisabled={isDisabled}                        
                    />
                )}
                {activeTab === 1 && (
                    <DatosContacto token={token}
                        participante={participante.datosContacto}
                        onInputChange={(name, value) => onInputChange("datosContacto", name, value)}
                        tipoSeleccionado={participante.datosGenerales.tipoNombre}
                        tipoPersona={participante.datosGenerales.tipoPersona}
                        isEdit={isEdit}
                        validateErrors={validateErrors}
                        errors={errors} 
                        showValidations={showValidations}
                        isDisabled={isDisabled}
                    />
                )}
                {activeTab === 2 && (
                    <DatosEstadistica token={token}
                        participante={participante.datosEstadistica}
                        onInputChange={(name, value) => onInputChange("datosEstadistica", name, value)}
                        nombreTipo={nombreTipo} 
                        tipoPersona={participante.datosGenerales.tipoPersona}
                        isEdit={isEdit}
                        isDisabled={isDisabled}
                    />
                )}
            </Box>

            {modalOpen && (
                <ConfirmationModal
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                    onConfirm={handleConfirm}
                    title={isEdit ? "¿Actualizar participante?" : "¿Guardar participante?"}
                    message={
                        isEdit
                            ? "Se actualizarán los datos del participante existente."
                            : "Se generará un nuevo registro."
                    }
                    confirmationButton="Sí, generar"
                    closeButton="No, cancelar"
                />
            )}

            {openDialog && (
            <ConfirmationModal
                open={openDialog}
                onClose={handleCloseDialog} 
                onConfirm={handleClose} 
                title={isEdit ? "¿Salir sin actualizar participante?" : "¿Salir sin generar participante?"}
                message={isEdit ? "Se perderán los datos actualizdos" : "Se perderán los datos del participante"}
                closeButton={"No, cancelar"}
                confirmationButton={"Sí, salir"}
            />
            )}

            <NotificationModal
                open={modalNotificacionOpen}
                handleClose={() => setModalNotificacionOpen(false)}
                personId={newParticipantId}
                item={item}
            />

        </Grid>
    );
};

export default AgregarParticipante;
