import React, { useEffect, useState, useContext } from "react";
import FormDemanda from "./FormDemanda";
import { useLocation } from "react-router-dom";
import { getDemandaById, updateAnexos } from "../DemandaService";
import keycloakConf from "../../../api/keycloakConf";
import { useToastAlert } from "../../../components/Alert/ToastAlertSave/ToastAlertSave";
import { useToastAlertUpdate } from '../../../components/Alert/ToastAlertUpdate/ToastAlertUpdate';
import FormDemandaFamiliar from "./FormFamiliar/FormDemandaFamiliar";
import { getSello } from "../DemandaService";

export default function EditFormDemanda() {
  const location = useLocation();
  const { state } = location;
  const { documentoId } = state || {};
  const { getToken } = useContext(keycloakConf);
  const [demandaData, setDemandaData] = useState(null);
  const { showSnackbar, showConfirmDialog } = useToastAlert();
  const { showSnackbar: showUpdateSnackbar } = useToastAlertUpdate();

  const isEditable = location.pathname === "/api/workflow/demanda/add";

  useEffect(() => {
    const fetchDemanda = async () => {
      const token = getToken();
      if (documentoId && token) {
        try {
          const data = await getDemandaById(token, documentoId);
          setDemandaData(data);
        } catch (error) {
          console.error("Error al obtener la demanda:", error);
        }
      }
    };
    if (!demandaData && documentoId) {
      fetchDemanda();
    }
  }, [documentoId, getToken, demandaData]);

  const handleEditDemanda = async (anexos, razonEdicion) => {
    const token = getToken();
    if (documentoId && token) {
      try {
        await updateAnexos(token, documentoId, anexos, razonEdicion);
        showUpdateSnackbar('Registro actualizado con éxito', 'success');
        const sello = await getSello(token, documentoId);
        var blob = new Blob([sello], { type: "application/pdf" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.target = "sello.pdf";
        link.click();

      } catch (error) {
        showSnackbar('¡Algo salió mal!', 'error');
      }
    }
  };

  if (!demandaData) {
    return <div>Cargando...</div>; 
  }


  return demandaData.tipoJuicio.includes("Familiar Oralidad") ? (
    <FormDemandaFamiliar data={demandaData} handleEditDemanda={ handleEditDemanda } />
  ) : (
    <FormDemanda
      isEditable={!isEditable}
      handleEditDemanda={handleEditDemanda}
      demandaData={demandaData}
    />
  );
}
