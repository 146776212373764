// Importa el archivo JSON
import TipoPartesData from './TipoPartesData.json';
import axios from 'axios';



// Función para obtener todas las materias desde el JSON
export const fetchTipoPartes = (page = 0, size = 10, sort = 'snombre,desc') => {
  try {
    // Simular paginación, ordenamiento, etc.
    const { tipoPartes } = TipoPartesData;

    // Aplica el ordenamiento (esto es un ejemplo simple, debes adaptarlo si necesitas un ordenamiento más complejo)
    const sortedTipoPartes = tipoPartes.sort((a, b) => {
      const [field, direction] = sort.split(',');
      if (direction === 'asc') {
        return a[field].localeCompare(b[field]);
      } else {
        return b[field].localeCompare(a[field]);
      }
    });

    // Simular paginación
    const start = page * size;
    const end = start + size;
    const paginatedTipoPartes = sortedTipoPartes.slice(start, end);

    return paginatedTipoPartes; // Devuelve el contenido paginado y ordenado
  } catch (error) {
    console.error('Error al listar materias:', error);
    throw error;
  }
};

// Función para agregar una nueva materia al JSON (esto es solo una simulación local)
export const addTipoPartes = (snombre, susuarioalta) => {
  try {
    const newTipoPartes = {
      psid: generateUUID(), // Genera un nuevo UUID
      nversion: 1, // Asigna la versión inicial
      snombre,
      sestado: 'A', // Estado inicial
      tfechaalta: new Date().toISOString(), // Fecha actual
      tfechaedita: new Date().toISOString(), // Fecha de edición actual
      susuarioalta,
      susuarioedita: susuarioalta // Inicialmente el mismo usuario
    };

    // Agrega la nueva materia al contenido
    TipoPartesData.tipoPartes.push(newTipoPartes);

    return newTipoPartes; // Devuelve la nueva materia
  } catch (error) {
    console.error('Error al agregar materia:', error);
    throw error;
  }
};

// Función para eliminar una materia del JSON (esto es solo una simulación local)
export const deleteTipoPartes = (psid) => {
  try {
    // Encuentra el índice de la materia a eliminar
    const index = TipoPartesData.tipoPartes.findIndex(tipoPartes => tipoPartes.psid === psid);

    if (index === -1) {
      throw new Error('Materia no encontrada');
    }

    // Elimina la materia del contenido
    const [deletedTipoPartes] = TipoPartesData.tipoPartes.splice(index, 1);

    return deletedTipoPartes; // Devuelve la materia eliminada
  } catch (error) {
    console.error('Error al eliminar materia:', error);
    throw error;
  }
};

// Función para generar un UUID (solo para uso local)
function generateUUID() {
  // Usa una implementación simple de UUID v4 para generar un nuevo UUID
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}


async function fetchListTipoPartes(token, carpetaId) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_TRIALS_API_URL}/api/core/tipopartes/${carpetaId}/listar`, 
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error al obtener tipos parte:', error);
  }
}


export default fetchListTipoPartes;