export const crearActor = (actorTab, actorFisica, actorMoral) => {
  return actorTab === 0
    ? {
      nombre: actorFisica.nombreActor,
      apellidoPaterno: actorFisica.apellidoPaternoActor,
      apellidoMaterno: actorFisica.apellidoMaternoActor,
      pseudonimo: actorFisica.pseudonimoActor,
      tipoPersona: "fisica",
      tipoParte: 1,
    }
    : {
      nombre: actorMoral.nombreActorMoral,
      tipoPersona: "moral",
      tipoParte: 1,
    };
};

export const crearDemandado = (demandadoTab, demandadoFisica, demandadoMoral) => {
  return demandadoTab === 0
    ? {
      nombre: demandadoFisica.nombreDemandado,
      apellidoPaterno: demandadoFisica.apellidoPaternoDemandado,
      apellidoMaterno: demandadoFisica.apellidoMaternoDemandado,
      pseudonimo: demandadoFisica.pseudonimoDemandado,
      tipoPersona: "fisica",
      tipoParte: 2,
    }
    : {
      nombre: demandadoMoral.nombreDemandadoMoral,
      tipoPersona: "moral",
      tipoParte: 2,
    };
};
