import React, { useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "moment/locale/es";
import { Button } from "@mui/material";

// Asegurarse de que Moment esté configurado en español
moment.locale('es');  // Esto configura los días y meses en español

// Usar el localizador de moment
const localizer = momentLocalizer(moment);

const CalendarComponent = ({ events }) => {
  // Estado para controlar la vista activa del calendario
  const [view, setView] = useState("week"); // Estado inicial en vista "week"

  // Estilo de contenedor flexible para el calendario
  const calendarStyles = {
    height: "100vh",
    padding: "10px",
    borderRadius: "10px",
    boxShadow: "0 4px 10px rgba(2, 2, 2, 0.1)",
    backgroundColor: "#fff",
  };

  // Barra de navegación personalizada
  const CustomToolbar = (toolbar) => {
    const goToNext = () => {
      toolbar.onNavigate('NEXT'); // Avanza al siguiente mes/semana/día
    };

    const goToToday = () => {
      toolbar.onNavigate('TODAY'); // Vuelve a la fecha actual
    };

    // Funciones para cambiar las vistas
    const filterWithDay = () => {
      setView("day"); // Cambia la vista a día
    };

    const filterWithWeek = () => {
      setView("week"); // Cambia la vista a semana
    };

    const filterWithAgenda = () => {
      setView("agenda"); // Cambia la vista a agenda
    };

    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
          <div>
            <Button  type="button" variant="contained" className="button-save" onClick={goToToday} >
              Hoy
            </Button>
          </div>
          <span style={{ fontSize: '18px' }}>{toolbar.label}</span>
          <div>
            <Button  type="button" variant="contained" className="button-save" onClick={goToNext}>Siguiente </Button>
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px' }}>
          <div>
            <Button type="button" variant="contained" className="button-save" onClick={filterWithDay} variant="contained" className="button-save">Día</Button>
          </div>
          <div>
            <Button type="button" variant="contained" className="button-save" onClick={filterWithWeek}>Semana</Button>
          </div>
          <div>
            <Button type="button" variant="contained" className="button-save" onClick={filterWithAgenda}>Agenda</Button>
          </div>
        </div>
      </>
    );
  };

  return (
    <div style={calendarStyles}>
      <Calendar
        localizer={localizer}
        events={events}
        view={view} // Aquí vinculamos la vista activa con el estado
        defaultView="week" // Vista por defecto
        step={30} // Intervalos de 30 minutos
        timeslots={2} // Espaciado de los horarios
        style={{
          height: "100vh",
        }}
        views={["week", "day", "agenda"]} // Vistas disponibles
        popup={true}
        eventPropGetter={(event) => ({
          style: {
            backgroundColor: event.color || "#34a853", // Color dinámico
            color: "white",
          },
        })}
        messages={{
          today: 'Hoy',
          previous: 'Anterior',
          next: 'Siguiente',
          month: 'Mes',
          week: 'Semana',
          day: 'Día',
          agenda: 'Agenda',
          time: 'Horario',
          event:"Audiencia",
          date: 'Fecha',
          noEventsInRange: 'No hay eventos en este rango',
          showMore: (total) => `+ Ver más (${total})`,
        }}
        components={{
          toolbar: CustomToolbar, // Usamos la barra de navegación personalizada
        }}
        startAccessor="start"
        endAccessor="end"
      />
    </div>
  );
};

export default CalendarComponent;
