import React, { useEffect, useState, useContext } from 'react';
import { fetchIndicadores } from '../../shared/services/DocumentoService';
import keycloakConf from '../../api/keycloakConf';
import { Box, Paper, CircularProgress, Grid, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column', 
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    borderRadius: '8px',
    boxShadow: 'none',
    fontWeight: 'bold',
    fontSize: '13px',
    border: '3px solid transparent',
    height: '80px',
    color: '#636569',
    "&:nth-of-type(1)": {
        borderColor: '#d30000',
    },
    "&:nth-of-type(2)": {
        borderColor: '#95b74b',
    },
    "&:nth-of-type(3)": {
        borderColor: '#8c92bc',
    },
    "&:nth-of-type(4)": {
        borderColor: '#666666',
    },
}));


const IndicadoresComponent = ({ isRecepcion }) => {
    const [indicadores, setIndicadores] = useState(null);
    const { getToken } = useContext(keycloakConf);

    const loadIndicadores = async () => {
        try {
            const token = getToken(); 
            const data = await fetchIndicadores(token, isRecepcion);
            setIndicadores(data);
        } catch (error) {
            console.error('Error al cargar indicadores:', error);
        }
    };

    useEffect(() => {
        loadIndicadores();
    }, []);

    if (!indicadores) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
                <Stack direction="row" spacing={2} justifyContent="center">
                    <Item>
                        <div>Pendientes de<br />RECEPCIÓN</div>
                        <div style={{ fontSize: '18px', color:"#d30000" }}>{indicadores.indicador1}</div>
                    </Item>
                    <Item>
                        <div>Enviados<br />HOY</div>
                        <div style={{ fontSize: '18px', color:"#95b74b" }}>{indicadores.indicador2}</div>
                    </Item>
                    <Item>
                        <div>Enviados<br />AYER</div>
                        <div style={{ fontSize: '18px', color:"#8c92bc" }}>{indicadores.indicador3}</div>
                    </Item>
                    <Item>
                        <div>Enviados<br />HACE 3 DÍAS</div>
                        <div style={{ fontSize: '18px', color:"#666666" }}>{indicadores.indicador4}</div>
                    </Item>
                </Stack>
            </Grid>
        </Grid>
    );
};

export default IndicadoresComponent;
