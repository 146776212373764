import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, TextField, Button, MenuItem, Grid, Select, FormControl, InputLabel, FormHelperText } from '@mui/material';
import { useToastAlert } from '../Alert/ToastAlertSave/ToastAlertSave';
import { useToastAlertValidation } from '../Alert/ToastAlertValidation/ToastAlertValidation';
import { validateFields } from '../Alert/ValidationRequired/validationRequired';

const ModalCreacionParticipantesPenal = ({ open, onClose, onSave, tipoParticipantes, rolesParticipantes, handleSaveParticipante, participanteSelected, handleUpdateParticipante }) => {
    const [type, setType] = useState('');
    const [role, setRole] = useState('');
    const [personType, setPersonType] = useState('');
    const [firstName, setFirstName] = useState('');
    const [paternalSurname, setPaternalSurname] = useState('');
    const [maternalSurname, setMaternalSurname] = useState('');
    const [pseudonym, setPseudonym] = useState('');
    const [errors, setErrors] = useState({});
    const { showSnackbar, showConfirmDialog } = useToastAlert();
    const { showSnackbar: showAlertValidate } = useToastAlertValidation();
    
    useEffect(() => {
        setType(participanteSelected?.tipo || '');
        setRole(participanteSelected?.rol || '');
        setPersonType(participanteSelected?.tipoPersona || '');
        setFirstName(participanteSelected?.nombre || '');
        setPaternalSurname(participanteSelected?.apellidoPaterno || '');
        setMaternalSurname(participanteSelected?.apellidoMaterno || '');
        setPseudonym(participanteSelected?.pseudonimo || '');
      }, [participanteSelected]);
    
      const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "600",
        bgcolor: 'white',
        borderRadius: '8px',
        boxShadow: 24,
        p: 4,
    };

    const handleSave = () => {
        // Lógica para guardar la información
        const participante = {
            tipo: type,
            rol: role,
            tipoPersona: personType,
            nombre: firstName,
            apellidoPaterno: paternalSurname,
            apellidoMaterno: maternalSurname,
            pseudonimo: pseudonym
        }

        const rules = {
            'tipo': { required: true },
            'tipoPersona': { required: true },
            'nombre': { required: pseudonym === '' },
            'apellidoPaterno': { required: pseudonym === '' },
            'apellidoMaterno': { required:  pseudonym === '' },
            'pseudonimo': { required: firstName === '' && paternalSurname === '' }
        }

        let validationErrors = validateFields(participante, rules, showAlertValidate);
        

        if (Object.keys(validationErrors).length === 0) {
            
            if(Object.keys(participanteSelected).length > 0){
                handleUpdateParticipante(participante);
            }else{
                handleSaveParticipante(participante);
            }
            
            onClose();
            limpiarCampos();

        } else {
            setErrors(validationErrors)
        }

    };

    const limpiarCampos = () => {
        setType("");
        setRole("");
        setPersonType("");
        setFirstName("");
        setPaternalSurname("");
        setMaternalSurname("");
        setPseudonym("");
        setErrors({})
    }


    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={style}>
                <Typography variant="h6" component="h2" mb={4}>Añadir Participante</Typography>
                <Grid container spacing={2} rowSpacing={2}>

                    <Grid item xs={4}>
                        <FormControl fullWidth error={!!errors["tipo"]} variant="outlined">
                            <InputLabel id="select-type-label">Tipo</InputLabel>
                            <Select
                                labelId="select-type-label"
                                label="Tipo"
                                name="participante.tipo"
                                value={type}
                                onChange={(e) => setType(e.target.value)}

                            >
                                {tipoParticipantes.map(participante => {
                                    return <MenuItem key={participante.id} value={participante.id}>{participante.nombre}</MenuItem>
                                })}

                            </Select>
                            <FormHelperText sx={{ color: !!errors["tipo"] ? "red" : "inherit" }}>{errors["tipo"] || ""}</FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                        <FormControl fullWidth error={!!errors["rol"]} variant="outlined">
                            <InputLabel id="select-role-label">Rol</InputLabel>
                            <Select
                                labelId="select-role-label"
                                label="Rol"
                                name="participante.rol"
                                value={role}
                                onChange={(e) => setRole(e.target.value)}
                            >
                                {rolesParticipantes.map(rol => {
                                    return <MenuItem key={rol.id} value={rol.id}>{rol.nombre}</MenuItem>
                                })}
                            </Select>
                            <FormHelperText sx={{ color: !!errors["rol"] ? "red" : "inherit" }}>{errors["rol"] || ""}</FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                        <FormControl fullWidth error={!!errors["tipoPersona"]}>
                            <InputLabel id="select-person-type-label">Tipo de persona</InputLabel>
                            <Select
                                labelId="select-person-type-label"
                                label="Tipo de persona"
                                name="participante.tipoPersona"
                                value={personType}
                                onChange={(e) => setPersonType(e.target.value)}
                                fullWidth
                                margin="normal"
                            >
                                <MenuItem value="Física">Física</MenuItem>
                                <MenuItem value="Moral">Moral</MenuItem>
                            </Select>
                            <FormHelperText sx={{ color: !!errors["tipoPersona"] ? "red" : "inherit" }}>{errors["tipoPersona"] || ""}</FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <TextField
                                label="Nombre(s)"
                                name="participante.nombre"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                margin="normal"
                                error={!!errors["nombre"]}
                                helperText={errors["nombre"] || ''}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <TextField
                                name="participante.apellidoPaterno"
                                label="Apellido paterno"
                                value={paternalSurname}
                                onChange={(e) => setPaternalSurname(e.target.value)}
                                margin="normal"
                                error={!!errors["apellidoPaterno"]}
                                helperText={errors["apellidoPaterno"] || ''}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <TextField
                                label="Apellido materno"
                                name="participante.apellidoMaterno"
                                value={maternalSurname}
                                onChange={(e) => setMaternalSurname(e.target.value)}
                                margin="normal"
                                error={!!errors["apellidoMaterno"]}
                                helperText={errors["apellidoMaterno"] || ''}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <TextField
                                label="Pseudónimo"
                                name="participante.pseudonimo"
                                value={pseudonym}
                                onChange={(e) => setPseudonym(e.target.value)}
                                margin="normal"
                                error={!!errors["pseudonimo"]}
                                helperText={errors["pseudonimo"] || ''}
                            />
                        </FormControl>
                    </Grid>
                </Grid>

                <Button onClick={handleSave} variant="contained" className="button-save" sx={{ mt: 2, float: "left" }}>Guardar</Button>
            </Box>
        </Modal>
    );
};

export default ModalCreacionParticipantesPenal;
