import {
    Box,
    Divider,
    Modal,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Grid,
  } from "@mui/material";
  
  import { useContext, useEffect, useState } from "react";
  import keycloakConf from "../../api/keycloakConf";
  import TableBandejaAsignados from "./Grid/TableBandejaAsignados";
  import ConfirmationDialog from "../../components/Alert/ConfirmationDialog/ConfirmationDialogGeneric";
  import ToastAlertInfo from "../../components/Alert/ToastAlertInfo/ToastAlertInfo";
  import { fetchAsignados } from "./BandejaAsignadosService";
  import { useNavigate } from "react-router-dom";
  import HeaderBandejaAsignados from "./Grid/HeaderBandejaAsignados";
  import CloseIcon from "@mui/icons-material/Close";
  
  const Bandeja = () => {
    const [bandeja, setData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const { getToken } = useContext(keycloakConf);
    const [loading, setLoading] = useState(true);
    const [pageable, setPageable] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState("");
    const [toastOpen, setToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState(
      "Ningun registro seleccionado."
    );
    const [toastTitle, setToastTitle] = useState("Seleccione Registros");
    const [errorMessages, setErrorMessages] = useState({});
    const [cartItems, setCartItems] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const navigate = useNavigate();
  
    const modalStyle = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "35%",
      bgcolor: "background.paper",
      boxShadow: 24,
      p: 4,
      maxHeight: "80vh",
      overflowY: "auto",
    };
  
    useEffect(() => {
      loadBandejaAsignados();
    }, [getToken, page, rowsPerPage]);
  
    const loadBandejaAsignados = async () => {
      setLoading(true);
      const token = getToken();
      try {
        const bandejaData = await fetchAsignados(
          token,
          page,
          rowsPerPage,
          "",
          searchQuery
        );
        setData(bandejaData.content);
        setPageable(bandejaData.page);
      } catch (error) {
        console.error("Error cargando registros:", error);
      } finally {
        setLoading(false);
      }
    };
  
    const handleCloseDialog = () => {
      setDialogOpen(false);
    };
  
    const handleConfirmSalida = async () => {
      const token = getToken();
    };
  
    const handleCloseToast = () => {
      setToastOpen(false);
    };
  
    const handleRemoveFromCart = (movid) => {
      const updatedCartItems = cartItems.filter((item) => item.movid !== movid);
      setCartItems(updatedCartItems);
      const removedItem = cartItems.find((item) => item.movid === movid);
      if (
        removedItem &&
        !bandeja.some((item) => item.movid === removedItem.movid)
      ) {
        setData((prevBandejas) => [...prevBandejas, removedItem]);
      }
    };
  
    return (
      <>
        <Modal
          open={isModalOpen}
          onClose={() => setModalOpen(false)}
          aria-labelledby="modal-carrito-title"
          aria-describedby="modal-carrito-description"
        >
          <Box sx={modalStyle}>
            <h3 id="modal-carrito-title">Carrito</h3>
            {cartItems.length === 0 ? (
              <p id="modal-carrito-description">No hay items en el carrito.</p>
            ) : (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Folio</TableCell>
                      <TableCell align="center">No. Expediente</TableCell>
                      <TableCell align="center">Acciones</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {cartItems.map((item) => (
                      <TableRow key={item.movid}>
                        <TableCell align="center">{item.folio}</TableCell>
                        <TableCell align="center">{item.expediente}</TableCell>
                        <TableCell align="center">
                          <IconButton
                            onClick={() => handleRemoveFromCart(item.movid)}
                          >
                            <CloseIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {cartItems.length === 0 ? (
              <></>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 2,
                  marginBottom: -4,
                }}
              >
                <p>Total de registros: {cartItems.length}</p>
              </Box>
            )}
          </Box>
        </Modal>
  
        <HeaderBandejaAsignados
          errorMessages={errorMessages}
          loadBandejas={loadBandejaAsignados}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          setDrawerOpen={setModalOpen}
          setDialogOpen={setDialogOpen}
        />
  
        <Box sx={{ marginTop: 2, marginBottom: 3 }}>
          <Divider />
        </Box>
  
        <Grid className="box-sombra">
        <TableBandejaAsignados
          bandeja={bandeja}
          pageable={pageable}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          loadBandejaAsignados={loadBandejaAsignados}
          toastOpen={toastOpen}
          setToastOpen={setToastOpen}
          loading={loading}
          cartItems={cartItems}
          setCartItems={setCartItems}
          setSearchQuery={setSearchQuery}
          setToastMessage={setToastMessage}
          setToastTitle={setToastTitle}
        />
        </Grid>
        <ConfirmationDialog
          open={dialogOpen}
          onClose={handleCloseDialog}
          onConfirm={handleConfirmSalida}
          message={dialogMessage}
          title={"Confirmar salida"}
          btnLeft={"No, cancelar"}
          btnRight={"Sí, dar salida"}
        />
          
        <ToastAlertInfo
          open={toastOpen}
          onClose={handleCloseToast}
          title={toastTitle}
          message={toastMessage}
          type={"info"}
        />
      </>
    );
  };
  
  export default Bandeja;
  