import React, { useContext, useEffect, useState } from "react";
import { TextField, Button, MenuItem, Select, FormControl, InputLabel, Grid, Typography, Divider, FormHelperText, Autocomplete, InputLabelProps} from "@mui/material";
import { validateFields } from "../../../components/Alert/ValidationRequired/validationRequired";
import { useToastAlertValidation } from "../../../components/Alert/ToastAlertValidation/ToastAlertValidation";
import { fetchCarpetaActorDemandado } from "../../../shared/services/CarpetaService";
import keycloakConf from "../../../api/keycloakConf";
import { useToastAlert } from "../../../components/Alert/ToastAlertSave/ToastAlertSave";
import { fetchJuzgadosAutocomplete } from "../../../shared/services/JuzgadosService";
import Acciones from "../../../components/Acciones/Acciones";
import { createAudiencia, getAgendaSala, getSalasAll, getTipoAudiencias } from "../BandejaAudienciasService";
import { useLocation, useNavigate } from "react-router-dom";
import { validateDisponibilidadSala } from "../BandejaAudienciasService";
import CalendarComponent from "../../../components/Calendario/calendario";
import { checkDiaInhabil } from "../BandejaAudienciasService";
import moment from "moment";
import Breadcrumbs from "../../../components/Breadcrumb/breadcrumbs";

const FormularioAudiencia = () => {
    const { showSnackbar } = useToastAlert();
    const { showSnackbar: showAlertValidate } = useToastAlertValidation();
    const [errorMessages, setErrorMessages] = useState({});
    const [juzgados, setJuzgados] = useState([]);
    const [salas, setSalas] = useState([]);
    const [audiencias, setTipoAudiencias] = useState([]);
    const { getToken } = useContext(keycloakConf);
    const location = useLocation();
    const navigate = useNavigate();
    const { item } = location.state || {};
    const [conflict, setConflict] = useState(false);

    const [events, setEvents] = useState([]);

    const [formData, setFormData] = useState({
        expediente: "",
        year: "",
        juzgado: "",
        tipo: "",
        sala: "",
        fecha: null,
        hora: null,
        duracion: "",
        descripcion: "",
    });

    const duraciones = [
        { id: 15, label: "15 minutos" },
        { id: 30, label: "30 minutos" },
        { id: 45, label: "45 minutos" },
        { id: 60, label: "1 hora" },
        { id: 75, label: "1 hora y 15 minutos" },
        { id: 90, label: "1 hora y 30 minutos" },
        { id: 105, label: "1 hora y 45 minutos" },
        { id: 120, label: "2 horas" },
        { id: 135, label: "2 horas y 15 minutos" },
        { id: 150, label: "2 horas y 30 minutos" },
        { id: 165, label: "2 horas y 45 minutos" },
        { id: 180, label: "3 horas" }
    ];


    const [carpeta, setCarpeta] = useState({
        idCarpeta: 0,
        actor: '',
        demandado: ''
    });

    useEffect(() => {
        loadData(() => fetchJuzgadosAutocomplete(getToken(), 0, 10, '', ''), setJuzgados, "Juzgados");
        loadData(() => getTipoAudiencias(getToken()), setTipoAudiencias, "Audiencias");
        loadData(() => getSalasAll(getToken()), setSalas, "Salas");
    }, [])

    useEffect(() => {
        if (juzgados.length === 1 && item && !formData.juzgado) { asignarData(); }
        if (item && formData.juzgado && formData.expediente && formData.year) { handleValidate(false); }
    }, [juzgados, formData.juzgado]);

    const getAgenda = async (salaId) => {
        const response = await getAgendaSala(getToken(), salaId); // Suponiendo que `getAgendaSala` retorna un array
        const events = response.map((item) => ({
            start: new Date(item.horaInicio),
            end: new Date(item.horaFin),
            title: item.titulo
        }));

        setEvents(events);
    };

    const asignarData = () => {
        if (item) {
            let arrayExpediente = item.expediente.split("/");

            setFormData(prevData => ({
                ...prevData,
                expediente: arrayExpediente[0],
                year: arrayExpediente[1],
                juzgado: juzgados[0]
            }));
        }
    }

    const loadData = async (fetchFunction, setFieldState, typeData, funfinally = false) => {
        try {
            const response = await fetchFunction();

            await setFieldState(response || []);

        } catch (error) {
            console.error(`Error al obtener los ${typeData}`, error);
        }
        finally {
            if (funfinally) { funfinally() }
        }

    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));

        if (name === "sala") {
            getAgenda(value);
        }
    };

    const handleJuzgadoChange = (event, newValue) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            juzgado: newValue
        }));
    };

    const handleValidate = async (imprimirMensaje = true) => {
        const token = getToken();

        let rulesExpediente = {
            expediente: { required: true },
            year: { required: true },
            juzgado: { required: true }
        }

        const errores = validateFields(formData, rulesExpediente, showAlertValidate);

        setErrorMessages(errores);

        if (Object.keys(errores).length === 0) {
            const data = {
                "numExpediente": formData.expediente,
                "year": formData.year,
                "idJuzgado": formData.juzgado.id
            }

            try {
                const response = await fetchCarpetaActorDemandado(token, data)
                setCarpeta({
                    idCarpeta: response.idCarpeta,
                    actor: response.actor,
                    demandado: response.demandado
                });

                if (imprimirMensaje) {
                    showAlertValidate("Expediente encontrado", "success", "Validación exitosa");
                }

            } catch (error) {
                setCarpeta({});
                showAlertValidate('Expediente inexistente.', 'error', 'Error en la validación');
            }
        }
    }

    const handleCancel = () => {
        item ? navigate('/api/bandeja/expediente', {
            state: {
                item: item,
                tabnav: 3
            }
        }) : navigate('/api/bandeja/audiencias');
    }

    const validarDiaInhabil = async (fecha, juzgadoId) => {
        const token = getToken();
        try {
            const response = await checkDiaInhabil(token, fecha, juzgadoId);
            if (response) {
                showSnackbar("El día seleccionado es un día inhabil, por favor elija otra fecha.", "error");
                return true;
            }
            return false;
        } catch (error) {
            console.error("Error al verificar el día inhabil:", error);
        }
    };

    useEffect(() => {
        const isDiaInhabil = validarDiaInhabil(formData.fecha, formData.juzgado.id);
        
        if (formData.sala && formData.fecha && formData.hora && formData.duracion) {
            const token = getToken();
            
            validateDisponibilidadSala(token, formData.sala, formData.fecha, formData.hora, formData.duracion)
                .then((response) => {
                    if (response.error || response.conflict) {
                        setConflict(true);
                        showSnackbar("Audiencia en conflicto: ajuste la fecha/hora de la audiencia", "error");
                    } else {
                        setConflict(false);
                    }
                })
                .catch((error) => {
                    console.error("Error al validar disponibilidad de la sala:", error);
                    showSnackbar("Hubo un problema al validar la disponibilidad de la sala", "error");
                    setConflict(true);
                });
        }
    }, [formData.sala, formData.fecha, formData.hora, formData.duracion, getToken]);

    const handleCreateAudiencia = async () => {
        let rules = {
            expediente: { required: true },
            year: { required: true },
            juzgado: { required: true },
            tipo: { required: true },
            sala: { required: true },
            fecha: { required: true },
            hora: { required: true },
            duracion: { required: true },
            descripcion: { required: true }
        }

        const errores = validateFields(formData, rules, showAlertValidate);
        const isDiaInhabil = await validarDiaInhabil(formData.fecha, formData.juzgado.id);
        
        if (isDiaInhabil) {
            showAlertValidate("El día seleccionado es inhabil. Por favor elija otro día.", "error");
            return;
        }

        if (conflict) {
            showAlertValidate("No se puede guardar la audiencia debido a un conflicto en la fecha y hora. Horarios traslapados.", "error");
            return;
        }

        setErrorMessages(errores);
        if (Object.keys(errores).length === 0) {
            const dataSend = {
                carpetaId: carpeta.idCarpeta,
                tipoAudiencia: formData.tipo,
                salaId: formData.sala,
                fecha: formData.fecha,
                hora: formData.hora,
                duracion: formData.duracion,
                descripcion: formData.descripcion
            }

            try {
                const response = await createAudiencia(getToken(), dataSend);
                if (response.audienciaId) {
                    showAlertValidate("Audiencia creada exitosamente.", "success", "Audiencia creada");
                    handleCancel();
                }

            } catch (error) {
                showAlertValidate('Ocurrio un error.', 'error', 'Error en la creación de la audiencia.');
                console.log("Ocurrio un error", error);

            }

        }
    }

    const handleSalaClick = () => {
        if (salas.length === 0) {
            showSnackbar("Debe existir una sala asociada al juzgado de la persona logueada", "error");
        }
    };

    useEffect(() => {
        const today = new Date().toISOString().split("T")[0];
        setFormData(prevFormData => ({
            ...prevFormData,
            fecha: today,
        }));
    }, []);

    useEffect(() => {
        const defaultTime = "08:00:00";
        setFormData(prevFormData => ({
            ...prevFormData,
            hora: defaultTime,
        }));
    }, []);

    return (
        <>
            <Acciones accion1={handleCreateAudiencia} accion2={handleCancel} textoBoton1="Guardar" />
            <Grid className="box-sombra">
                <Breadcrumbs istitulo="true" className="title2"/>
            <Grid container spacing={2} sx={{ marginTop: 1, marginBottom: 2 }}>
                {/* Primera mitad */}
                <Grid item xs={12} sm={6} md={6} p={4}>
                    { /*  PARTE DE EXPEDIENTE, AÑO, JUZGAOD  */}
                    <Grid container spacing={2}>

                        <Grid item xs={4}>
                            <FormControl fullWidth variant="outlined">
                                <TextField
                                    name="expediente"
                                    label="Expediente"
                                    variant="outlined"
                                    fullWidth
                                    value={formData?.expediente || ''}
                                    disabled={item != null}
                                    onChange={handleChange}
                                    error={!!errorMessages.expediente}
                                    helperText={errorMessages.expediente}
                                    onInput={(e) => {
                                        e.target.value = e.target.value.replace(/[^0-9a-zA-Z]/g, '');
                                    }}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={4}>
                            <FormControl fullWidth variant="outlined">
                                <TextField
                                    name="year"
                                    label="Año"
                                    variant="outlined"
                                    disabled={item != null}
                                    fullWidth
                                    value={formData?.year || ''}
                                    onChange={handleChange}
                                    error={!!errorMessages.year}
                                    helperText={errorMessages.year}
                                    onInput={(e) => {
                                        e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                    }}
                                    inputProps={{ maxLength: 4 }}

                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={4}>
                            <Autocomplete
                                options={juzgados.sort((a, b) => a.materia.localeCompare(b.materia))}
                                getOptionLabel={(option) => option.nombre || ''}
                                groupBy={(option) => option.materia}
                                disabled={item != null}
                                value={formData?.juzgado || null}
                                onChange={handleJuzgadoChange}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Juzgado"
                                        variant="outlined"
                                        error={!!errorMessages.juzgado}
                                        helperText={errorMessages.juzgado}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>

                    { /*  boton de validación. */}
                    <Grid container spacing={2}>
                        <Grid item xs={3} mt={2}>
                            <Button
                                variant="contained"
                                className='button-validation'
                                disabled={item}
                                onClick={handleValidate}
                            >
                                Validar
                            </Button>
                        </Grid>
                    </Grid>

                    { /* Resto del formulario */}
                    {carpeta.idCarpeta !== 0 && (
                        <>
                            { /* ACTOR Y DEMANDADO */}
                            <Grid container spacing={2} mt={4}>

                                <Grid item xs={12} sm={6} md={4}>
                                    <Typography>Actor: </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={8}>
                                    <Typography style={{ color: "gray" }}>
                                        {carpeta.actor}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={6} md={4}>
                                    <Typography>Demandado: </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} md={8}>
                                    <Typography style={{ color: "gray" }}>
                                        {carpeta.demandado }
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12}>
                                    <Divider sx={{ mb: 3 }} />
                                </Grid>

                            </Grid>

                            { /* Tipo de audiencia y sala */}
                            <Grid container spacing={2} mt={4}>
                                <Grid item xs={12} sm={2} md={5}>
                                    <Autocomplete
                                        options={audiencias}
                                        getOptionLabel={(option) => option.nombre || ""}
                                        value={audiencias.find((a) => a.id === formData.tipo) || null}
                                        onChange={(event, newValue) => {
                                            setFormData((prevData) => ({
                                                ...prevData,
                                                tipo: newValue ? newValue.id : "",
                                            }));
                                        }}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Tipo"
                                                variant="outlined"
                                                error={!!errorMessages.tipo}
                                                helperText={errorMessages.tipo}
                                            />
                                        )}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={2} md={5}>
                                    <FormControl fullWidth>
                                        <InputLabel id="select-sala-label">Sala</InputLabel>
                                        <Select
                                            name="sala"
                                            labelId="select-sala-label"
                                            value={formData.sala || ""}
                                            onChange={handleChange}
                                            label="Sala"
                                            error={!!errorMessages.sala}
                                            onClick={handleSalaClick}
                                        >
                                            {salas.map(item => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    {item.nombre}
                                                </MenuItem>
                                            ))}

                                        </Select>
                                        <FormHelperText sx={{ color: 'error.main' }}>{errorMessages["sala"] || ''}</FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            { /* Fecha, hora y duración de audiencia */}
                            <Grid container spacing={2} mt={4}>
                                <Grid item xs={12} sm={2} md={4}>
                                    <FormControl fullWidth>
                                        <TextField
                                            name="fecha"
                                            type="date"
                                            label="Fecha"
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            value={formData.fecha || ""}
                                            error={!!errorMessages.fecha}
                                            onChange={handleChange}
                                        />
                                        <FormHelperText sx={{ color: 'error.main' }}>{errorMessages["fecha"] || ''}</FormHelperText>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={2} md={4}>
                                    <FormControl fullWidth>
                                        <TextField
                                            name="hora"
                                            type="time"
                                            label="Hora"
                                            variant="outlined"
                                            fullWidth
                                            inputProps={{ step: 1 }}
                                            InputLabelProps={{ shrink: true }}
                                            error={!!errorMessages.hora}
                                            value={formData.hora || ""}
                                            onChange={handleChange}
                                        />
                                        <FormHelperText sx={{ color: 'error.main' }}>{errorMessages["hora"] || ''}</FormHelperText>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} sm={2} md={4}>
                                    <FormControl fullWidth>
                                        <InputLabel id="select-sala-label">Duración estimada</InputLabel>
                                        <Select
                                            name="duracion"
                                            labelId="select-duracion-label"
                                            value={formData.duracion || ""}
                                            onChange={handleChange}
                                            label="Duracion estimada"
                                            error={!!errorMessages.duracion}
                                        >
                                            {duraciones.map(item => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    {item.label}
                                                </MenuItem>
                                            ))}


                                        </Select>
                                        <FormHelperText sx={{ color: 'error.main' }} >{errorMessages["duracion"] || ''}</FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>

                            { /* DESCRIPCION (NOTAS) AUDIENCIA */}
                            <Grid container spacing={2} mt={4}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        name="descripcion"

                                        multiline
                                        rows={3}
                                        FormHelperTextProps={{
                                            style: { textAlign: 'right' }
                                        }}
                                        label="Descripción (Notas)"
                                        variant="outlined"
                                        fullWidth
                                        error={!!errorMessages.descripcion}
                                        helperText={errorMessages.descripcion || ""}
                                        value={formData.descripcion || ""}
                                        onChange={handleChange}
                                    />
                                </Grid>

                            </Grid>
                        </>
                    )}

                </Grid>

                {/* Segunda mitad */}
                <Grid item xs={12} sm={6} md={6}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            {formData.sala && (
                                <CalendarComponent events={events} />
                            )}
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
            </Grid>
        </>);
};

export default FormularioAudiencia;
