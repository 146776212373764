
import React from 'react';
import {
  Box,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from '@mui/material';
import PaginationGeneric from '../../../components/Pagination/PaginationGeneric';
import text from '../../../assets/Glosario';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';


const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const formatFecha = (fecha) => {
  const opciones = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
  return new Date(fecha).toLocaleString('es-ES', opciones);
};


const ListaEstradoTabla = ({
  listaEstrado,
  onListaEstrado,
  pageable,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage
}) => {


  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <PaginationGeneric
          pageable={pageable}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
        <TableContainer sx={{ maxHeight: 600, overflowY: 'auto' }}>
          <Table stickyHeader sx={{ minWidth: 750 }}>
            <TableHead>
              <TableRow>
                <TableCell className='table-header'>{text.FechaCreacion}</TableCell>
                <TableCell className='table-header'>{text.NoElementos}</TableCell>
                <TableCell className='table-header'>{text.usuario}</TableCell>
                <TableCell className='table-header'>{text.Acciones}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listaEstrado
                .map((item) => (
                  <StyledTableRow
                    key={item.id}
                    //onClick={() => onEdit(item)}
                    hover
                    style={{ cursor: 'pointer' }}>
                    <TableCell>{formatFecha(item.fechaAlta)}</TableCell>
                    <TableCell>{item.noNotificaciones}</TableCell>
                    <TableCell>{item.usuarioAlta}</TableCell>
                    <TableCell >
                      <Tooltip title="">
                        <PictureAsPdfIcon
                           style={{ marginRight: '10px' }}
                          className='icon-color' onClick={(event) => {
                            event.stopPropagation();
                            onListaEstrado(item.id, item.nombre);
                          }}
                        >
                        </PictureAsPdfIcon>
                      </Tooltip>
                    </TableCell>
                  </StyledTableRow>
                ))}
              {listaEstrado.length === 0 && (
                <TableRow>
                  <TableCell colSpan={18} align="center">
                    No hay registros disponibles.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <PaginationGeneric
          pageable={pageable}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default ListaEstradoTabla;
