import axios from 'axios';

const url = process.env.REACT_APP_TRIALS_API_URL + `/api/core/rubro`;

export const fetchEtapasProcesales = async(token, idTipoJuicio, idProcedimiento=0) =>{
    try {
      const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/core/etapaprocesal?IdTipoJuicio=${idTipoJuicio}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.log('Error al obtener las etapas procesales: ', error);
    }
};
