import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Box, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import PaginationGeneric from "../../components/Pagination/PaginationGeneric";
import text from '../../assets/Glosario';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ModalDiferirReprogramar from '../../components/Acciones/ModalDiferirReprogramar';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const BandejaAudienciaTable = ({
    bandejas,
    pageable,
    onDelete,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    handleRefresh,
    token,
    isTab,
    isPieza = false,
    isConsulta,
    hasNotEtapaProcesal = false,
    itemAsignado
}) => {

    const [modalOpen, setModalOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const navigate = useNavigate();

    const handleOpenModal = (id, item) => {
        setSelectedId(id);
        setSelectedItem(item);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setSelectedId(null);
    };

    const handleRowClick = (item) => {
        if (!(isPieza || hasNotEtapaProcesal)) {
            navigate(`/api/bandeja/audiencias/vista`, { state: { item, isTab }, isConsulta });
        }
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
            <PaginationGeneric
                    pageable={pageable}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                />
                <TableContainer sx={{ maxHeight: 600, overflowY: 'auto' }}>
                    <Table stickyHeader sx={{ minWidth: 750 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell className='table-header'>{text.Tipo}</TableCell>
                                <TableCell className='table-header'>{text.Juez}</TableCell>
                                {!isTab && (
                                    <TableCell className='table-header'>{text.numCarpeta}</TableCell>
                                )}
                                <TableCell className='table-header'>{text.lugar}</TableCell>
                                {isTab ? (
                                    <>
                                        <TableCell className='table-header'>{text.fecha}</TableCell>
                                        <TableCell className='table-header'>{text.Horario}</TableCell>
                                    </>
                                ) : (
                                    <TableCell className='table-header'>{text.FechaHora}</TableCell>
                                )}
                                <TableCell className='table-header'>{text.Estatus}</TableCell>
                                {!(isPieza || hasNotEtapaProcesal) && <TableCell className='table-header'>{text.Acciones}</TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {bandejas.map((item) => {
                                const fechaHora = item.fechaHora ? new Date(item.fechaHora) : null;
                                const fecha = fechaHora ? fechaHora.toLocaleDateString() : null;
                                const hora = fechaHora ? fechaHora.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' }) : null;

                                return (
                                    <StyledTableRow
                                        key={item.id}
                                        hover
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => handleRowClick(item)}>
                                        <TableCell>{item.tipoAudiencia.charAt(0).toUpperCase() + item.tipoAudiencia.slice(1).toLowerCase()}</TableCell>
                                        <TableCell>{item.juez}</TableCell>
                                        {!isTab && (
                                            <TableCell>{item.numCarpeta}</TableCell>
                                        )}
                                        <TableCell>{item.lugar}</TableCell>
                                        {isTab ? (
                                            <>
                                                <TableCell>{fecha}</TableCell>
                                                <TableCell>{hora}</TableCell>
                                            </>
                                        ) : (
                                            <TableCell>
                                                {fechaHora ? fechaHora.toLocaleString() : null}
                                            </TableCell>
                                        )}
                                        <TableCell>{item.estatus.charAt(0).toUpperCase() + item.estatus.slice(1).toLowerCase()}</TableCell>
                                        {!(isPieza || hasNotEtapaProcesal) && (
                                        <TableCell>
                                            {item.estatus === "PROGRAMADA" && (
                                                <Box display="flex" justifyContent="center" alignItems="center" gap={1}>
                                                    <Tooltip title="Diferir">
                                                        <CalendarTodayIcon 
                                                            className='icon-color' 
                                                            style={{ marginRight: '10px' }}
                                                            onClick={(event) => {
                                                                event.stopPropagation();
                                                                handleOpenModal(item.id, item); 
                                                            }}
                                                            disabled={isConsulta}
                                                        />
                                                    </Tooltip>
                                                    <Tooltip title="Cancelar">
                                                        <DoDisturbAltIcon 
                                                            className='icon-color' 
                                                            style={{ marginRight: '10px' }}
                                                            onClick={(event) => {
                                                                event.stopPropagation();
                                                                onDelete(item.id);
                                                            }} 
                                                            disabled={isConsulta}
                                                        />
                                                    </Tooltip>
                                                </Box>
                                            )}
                                            {item.estatus === "DIFERIDA" && (
                                                <Box display="flex" justifyContent="center" alignItems="center" gap={1}>
                                                    <Tooltip title="Reprogramar">
                                                        <CalendarTodayIcon 
                                                            className='icon-color' 
                                                            style={{ marginRight: '10px' }}
                                                            onClick={(event) => {
                                                                event.stopPropagation();
                                                                navigate('/api/bandeja/reprogramarAudiencias', { state: { item, isTab, itemAsignado  } });
                                                            }}
                                                            disabled={isConsulta}
                                                        />
                                                    </Tooltip>
                                                    <Tooltip title="Cancelar">
                                                        <DoDisturbAltIcon 
                                                            className='icon-color' 
                                                            style={{ marginRight: '10px' }}
                                                            onClick={(event) => {
                                                                event.stopPropagation();
                                                                onDelete(item.id);
                                                            }} 
                                                            disabled={isConsulta}
                                                        />
                                                    </Tooltip>
                                                </Box>
                                            )}
                                        </TableCell>
                                        )}
                                    </StyledTableRow>
                                );
                            })}
                            {bandejas.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={6} align="center">
                                        No hay registros disponibles.
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <PaginationGeneric
                    pageable={pageable}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                />
            </Paper>

            <ModalDiferirReprogramar
                open={modalOpen}
                onClose={handleCloseModal}
                id={selectedId}
                item={selectedItem}
                handleRefresh={handleRefresh}
                token={token}
                isTab={isTab}
                itemAsignado={itemAsignado}
            />
        </Box>
    )
};

export default BandejaAudienciaTable;
