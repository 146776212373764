import axios from 'axios';

const url = process.env.REACT_APP_TRIALS_API_URL + `/api/core/procedimiento`;

  export const fetchProcedimientosByTipoJuicio = async (token, tipoJuicioId) => {
    try {
        const response = await axios.get(
            `${url}/${tipoJuicioId}`, 
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error al obtener los tipos de procedimiento:', error, "id:", tipoJuicioId);
        throw error;
    }
  };
