import React, { useState, useContext, useEffect } from 'react';
import {
  Box, Grid, Button, Select, MenuItem, Typography, Checkbox, FormControlLabel, Table, TableBody, TableCell,
  TableHead, TableRow, IconButton, TextField, Divider, Chip, FormControl, Popover, Modal, InputLabel
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AccionesOnlyButtons from '../../components/Acciones/AccionesOnlyButtons';
import keycloakConf from "../../api/keycloakConf";
import { createPeriodos, getEventosGenerales, getEventosOficialiaComun, deleteEventoById, editarEventoPeriodo } from '../../shared/services/EventoService';
import { useToastAlert } from "../../components/Alert/ToastAlertSave/ToastAlertSave";
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

const Calendarios = () => {
  const navigate = useNavigate();
  const { getToken } = useContext(keycloakConf);
  const token = getToken();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedView, setSelectedView] = useState('Mensual');
  const today = new Date();
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedCalendarios, setSelectedCalendarios] = useState([]);
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const { showSnackbar } = useToastAlert();
  const [eventosGenerales, setEventosGenerales] = useState([]);
  const [eventosOficialiaComun, setEventosOficialiaComun] = useState([]);
  const [isGeneralChecked, setIsGeneralChecked] = useState(false);
  const [isOficialiaChecked, setIsOficialiaChecked] = useState(false);
  const [editingEvento, setEditingEvento] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverData, setPopoverData] = useState({ description: '', filter: '', date: '' });

  const handlePopoverOpen = (event, description, filter, date, id) => {
    setAnchorEl(event.currentTarget);
    setPopoverData({ description, filter, date, id });
  };

  const handleClosePopover = () => { setAnchorEl(null); };

  function formatDate(date) {
    const [day, month, year] = date.split('/');
    if (!day || !month || !year) { return ''; }
    const formattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;

    const currentDate = new Date(formattedDate);
    currentDate.setDate(currentDate.getDate() + 1);
    const options = { weekday: 'long', day: 'numeric', month: 'long' };
    return new Intl.DateTimeFormat('es-ES', options).format(currentDate);
  }

  const daysOfWeek = ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'];

  const handleViewChange = (event) => {
    setSelectedView(event.target.value);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleOptionCalendarioChange = (event) => {
    const value = event.target.value;
    setSelectedCalendarios(typeof value === 'string' ? value.split('-') : value);
    setOpen(false);
  };

  const handleDeleteChip = (chipToDelete) => {
    setSelectedCalendarios((prev) =>
      prev.filter((chip) => chip !== chipToDelete)
    );
  };

  const handleGeneralCheckboxChange = (event) => {
    setIsGeneralChecked(event.target.checked);
  };

  const handleOficialiaCheckboxChange = (event) => {
    setIsOficialiaChecked(event.target.checked);
  };

  const handlePrev = () => {
    const newDate = new Date(currentDate);
    if (selectedView === 'Mensual') {
      newDate.setMonth(currentDate.getMonth() - 1);
    } else {
      newDate.setFullYear(currentDate.getFullYear() - 1);
    }
    setCurrentDate(newDate);
  };

  const handleNext = () => {
    const newDate = new Date(currentDate);
    if (selectedView === 'Mensual') {
      newDate.setMonth(currentDate.getMonth() + 1);
    } else {
      newDate.setFullYear(currentDate.getFullYear() + 1);
    }
    setCurrentDate(newDate);
  };

  const handleToday = () => { setCurrentDate(new Date()); };

  const generateCalendar = (month, year) => {
    const startOfMonth = new Date(year, month, 1);
    const endOfMonth = new Date(year, month + 1, 0);
    const startDay = startOfMonth.getDay();
    const daysInMonth = endOfMonth.getDate();

    const calendarDays = [];
    for (let i = 0; i < startDay; i++) {
      calendarDays.push(null);
    }
    for (let i = 1; i <= daysInMonth; i++) {
      calendarDays.push(i);
    }

    const weeks = [];
    for (let i = 0; i < calendarDays.length; i += 7) {
      weeks.push(calendarDays.slice(i, i + 7));
    }
    return weeks;
  };

  const handleStartDateChange = (event) => {
    const newStartDate = event.target.value;
    setStartDate(newStartDate);
    if (endDate && newStartDate > endDate) {
      setEndDate(null);
    }
  };

  const handleEndDateChange = (event) => {
    const newEndDate = event.target.value;
    setEndDate(newEndDate);
  };

  const handleCancel = () => { navigate('/home'); };

  const handleClose = () => { setOpen(false); };

  const handleSubmit = async () => {
    try {
      const record = {
        descripcion: selectedOption,
        diaInicio: startDate,
        diaFin: endDate,
        calendarios: selectedCalendarios.join('-'),
      };

      await createPeriodos(token, record);
      showSnackbar(`Periodo registrado con exito`, 'success');
      setStartDate(null);
      setEndDate(null);
      setSelectedOption('');
      setSelectedCalendarios([]);
      fetchEventosGenerales();
      fetchEventosOficialiaComun();
    } catch (error) {
      showSnackbar(`Error al registrar el periodo`, 'error');
    }
  };

  const fetchEventosGenerales = async () => {
    try {
      const pageable = { page: 0, size: 10 };
      const data = await getEventosGenerales(token, pageable);
      setEventosGenerales(data.content);
    } catch (error) {
      console.error("Error al cargar eventos generales:", error);
    }
  };

  const fetchEventosOficialiaComun = async () => {
    try {
      const pageable = { page: 0, size: 10 };
      const data = await getEventosOficialiaComun(token, pageable);
      setEventosOficialiaComun(data.content);
    } catch (error) {
      console.error("Error al cargar eventos de oficialia comun:", error);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteEventoById(token, popoverData.id);
      showSnackbar(`Periodo eliminado con exito`, 'success');
      handleClosePopover();
      fetchEventosGenerales();
      fetchEventosOficialiaComun();
    } catch (error) {
      showSnackbar(`Error al elimar el periodo`, 'error');
    }
  };

  useEffect(() => {
    fetchEventosGenerales();
    fetchEventosOficialiaComun();
  }, [token]);

  const getMarkedDays = () => {
    const markedDays = [];

    if (isGeneralChecked) {
      eventosGenerales.forEach((evento) => {
        const start = new Date(evento.diaInicio);
        start.setDate(start.getDate() + 1);
        const end = new Date(evento.diaFin);
        end.setDate(end.getDate() + 1);

        for (let date = new Date(start); date <= end; date.setDate(date.getDate() + 1)) {
          markedDays.push({
            day: date.getDate(),
            month: date.getMonth(),
            year: date.getFullYear(),
            eventoId: evento.id,
            descripcion: evento.descripcion,
            fecha: date.toLocaleDateString(),
            filtro: 'General',
            color: '#ba68c8',
          });
        }
      });
    }
    if (isOficialiaChecked) {
      eventosOficialiaComun.forEach((evento) => {
        const start = new Date(evento.diaInicio);
        start.setDate(start.getDate() + 1);
        const end = new Date(evento.diaFin);
        end.setDate(end.getDate() + 1);

        for (let date = new Date(start); date <= end; date.setDate(date.getDate() + 1)) {
          markedDays.push({
            day: date.getDate(),
            month: date.getMonth(),
            year: date.getFullYear(),
            eventoId: evento.id,
            descripcion: evento.descripcion,
            fecha: date.toLocaleDateString(),
            filtro: 'Oficialía Común',
            color: '#4caf50',
          });
        }
      });
    }
    return markedDays;
  };

  const handleEditClick = (id) => {
    const evento = [...eventosGenerales, ...eventosOficialiaComun].find(event => event.id === id);
    if (evento) {
      setEditingEvento({
        id: evento.id,
        descripcion: evento.descripcion,
        diaInicio: evento.diaInicio,
        diaFin: evento.diaFin,
      });
      setIsEditing(true);
      handleClosePopover();
    }
  };

  const handleEditEventoPeriodo = async (e) => {
    e.preventDefault();

    try {
      const updatedData = {
        id: editingEvento.id,
        descripcion: editingEvento.descripcion,
        diaInicio: editingEvento.diaInicio,
        diaFin: editingEvento.diaFin,
      };

      await editarEventoPeriodo(token, updatedData);
      showSnackbar('Periodo editado con éxito', 'success');
      fetchEventosGenerales();
      fetchEventosOficialiaComun();
      setIsEditing(false);
    } catch (error) {
      showSnackbar('Error al editar el periodo', 'error');
    }
  };

  const markedDays = getMarkedDays();

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    borderRadius: '8px',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
    p: 2,
    maxWidth: '300px',
    width: '100%',
  };

  const EventPopover = ({ open, anchorEl, onClose, eventData, onDelete, onEdit }) => (
    <>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <Box sx={{ position: 'relative' }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton onClick={() => onEdit(eventData.id)} className='icon-color'>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => onDelete(eventData.id)} className='icon-color'>
              <DeleteIcon />
            </IconButton>
            <IconButton onClick={onClose} className='icon-color'>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Box sx={{ p: 2 }}>
          <Typography>{eventData.description}</Typography>
          <Typography sx={{ mt: 1, fontWeight: 'bold' }}>{formatDate(eventData.date)}</Typography>
          <Typography sx={{ mt: 1 }}>{eventData.filter}</Typography>
        </Box>
      </Popover>
    </>
  );

  const renderMonthlyCalendar = () => {
    const calendar = generateCalendar(currentDate.getMonth(), currentDate.getFullYear());

    return (
      <>
        <Table>
          <TableHead style={{ border: '1px solid black' }}>
            <TableRow>
              {daysOfWeek.map((day) => (<TableCell key={day} align="center" className='table-header'>{day}</TableCell>))}
            </TableRow>
          </TableHead>
          <TableBody>
            {calendar.map((week, index) => (
              <TableRow key={index}>
                {week.map((day, idx) => {

                  const isToday = day === today.getDate() && currentDate.getMonth() === today.getMonth() && currentDate.getFullYear() === today.getFullYear();
                  const dayEvents = markedDays.filter((marked) => marked.day === day && marked.month === currentDate.getMonth() && marked.year === currentDate.getFullYear());

                  return (
                    <TableCell
                      key={idx}
                      align="center"
                      style={{ color: isToday ? '#007bff' : 'inherit', border: '1px solid black', verticalAlign: 'top', position: 'relative', textAlign: 'center' }}
                    >
                      {day ? (
                        <span
                          style={{
                            backgroundColor: isToday ? 'rgba(0, 123, 255, 0.3)' : 'transparent',
                            borderRadius: '50%',
                            padding: '0.2rem 0.5rem',
                            marginTop: '2px',
                          }}
                        >
                          {day}
                        </span>
                      ) : (
                        ''
                      )}
                      {day && dayEvents.length > 0 && (
                        <div style={{ position: 'absolute', top: '2px', right: '2px', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: '4px' }} >
                          {dayEvents.map((event, index) => (
                            <span
                              key={index}
                              style={{ width: '12px', height: '12px', borderRadius: '50%', backgroundColor: event.color, cursor: 'pointer' }}
                              onClick={(e) => handlePopoverOpen(e, event.descripcion, event.filtro, event.fecha, event.eventoId)}
                            />
                          ))}
                        </div>
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <EventPopover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClosePopover}
          eventData={popoverData}
          onDelete={handleDelete}
          onEdit={handleEditClick}
        />
      </>
    );
  };

  const renderYearlyCalendar = () => {
    return (
      <Grid container spacing={2} justifyContent="center">
        {Array.from({ length: 12 }, (_, month) => (
          <Grid item xs={12} sm={10} key={month}>
            <Typography align="center" variant="subtitle1" gutterBottom>
              {new Date(currentDate.getFullYear(), month).toLocaleString('default', { month: 'long' })}
            </Typography>
            <Box style={{ border: '1px solid black' }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    {daysOfWeek.map((day) => (<TableCell key={day} align="center" className='table-header'>{day}</TableCell>))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {generateCalendar(month, currentDate.getFullYear()).map((week, index) => (
                    <TableRow key={index}>
                      {week.map((day, idx) => {

                        const isToday = day === today.getDate() && month === today.getMonth() && currentDate.getFullYear() === today.getFullYear();
                        const dayEvents = markedDays.filter((marked) => marked.day === day && marked.month === month && marked.year === currentDate.getFullYear());

                        return (
                          <TableCell
                            key={idx}
                            align="center"
                            style={{ color: isToday ? '#007bff' : 'inherit', verticalAlign: 'top', position: 'relative', textAlign: 'center', height: idx === 0 || idx === 6 ? '60px' : 'auto' }}
                          >
                            {day ? (
                              <span
                                style={{
                                  backgroundColor: isToday ? 'rgba(0, 123, 255, 0.3)' : 'transparent',
                                  borderRadius: '50%',
                                  padding: '0.2rem 0.5rem',
                                  marginTop: '2px',
                                }}
                              >
                                {day}
                              </span>
                            ) : (
                              ''
                            )}
                            {day && dayEvents.length > 0 && (
                              <div style={{ position: 'absolute', top: '2px', right: '2px', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: '4px' }}
                              >
                                {dayEvents.map((event, index) => (
                                  <span
                                    key={index}
                                    style={{ width: '12px', height: '12px', borderRadius: '50%', backgroundColor: event.color, cursor: 'pointer' }}
                                    onClick={(e) => handlePopoverOpen(e, event.descripcion, event.filtro, event.fecha, event.eventoId)}
                                  />
                                ))}
                              </div>
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <EventPopover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                eventData={popoverData}
                onDelete={handleDelete}
                onEdit={handleEditClick}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <Box>
      <Box mb={3}>
        <AccionesOnlyButtons accion1={handleSubmit} accion2={handleCancel} />
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={3}>
          {selectedOption && (
            <><Typography variant="subtitle1" fontWeight="bold">Detalles</Typography><Divider sx={{ my: 1 }} /></>
          )}
          <Select
            value={selectedOption}
            onChange={handleOptionChange}
            fullWidth
            size="small"
            displayEmpty
            renderValue={(selected) => {
              if (!selected) { return (<Box display="flex" alignItems="center"><AddIcon sx={{ mr: 1 }} />Agregar</Box>); }
              return selected === 'DIA INHABIL' ? 'Día inhábil' : 'Periodo vacacional';
            }}
          >
            <MenuItem value="DIA INHABIL">Día inhábil</MenuItem>
            <MenuItem value="PERIODO VACACIONAL">Periodo vacacional</MenuItem>
          </Select>

          {selectedOption === '' && (
            <Box mt={2}>
              <Typography variant="body1" sx={{ mb: 1 }}>Calendarios</Typography>
              <FormControlLabel
                sx={{
                  backgroundColor: isGeneralChecked ? 'rgba(186, 104, 200, 0.3)' : 'transparent',
                  transition: 'background-color 0.3s ease', pr: 2, mb: 1
                }}
                control={<Checkbox checked={isGeneralChecked} onChange={handleGeneralCheckboxChange} />}
                label="General"
              />
              <FormControlLabel
                sx={{
                  backgroundColor: isOficialiaChecked ? 'rgba(76, 175, 80, 0.3)' : 'transparent',
                  transition: 'background-color 0.3s ease', pr: 2
                }}
                control={<Checkbox checked={isOficialiaChecked} onChange={handleOficialiaCheckboxChange} />}
                label="Oficialía común" />
            </Box>
          )}

          {selectedOption && (
            <Box mt={2}>
              <Typography variant="subtitle1" fontWeight="bold">Fechas</Typography>
              <Divider sx={{ my: 1 }} />
              <Box mt={2}>
                <TextField
                  label="Fecha inicio"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  size="small"
                  value={startDate}
                  onChange={handleStartDateChange}
                />
                <TextField
                  label="Fecha fin"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  size="small"
                  sx={{ mt: 2 }}
                  value={endDate}
                  onChange={handleEndDateChange}
                  InputProps={{ inputProps: { min: startDate } }}
                  disabled={!startDate}
                />
              </Box >
              <Box mt={2}>
                <Typography variant="subtitle1" fontWeight="bold">Calendarios</Typography>
                <Divider sx={{ my: 1 }} />
                <FormControl fullWidth>
                  <Select
                    value={selectedCalendarios}
                    onChange={handleOptionCalendarioChange}
                    fullWidth
                    size="small"
                    displayEmpty
                    multiple
                    open={open}
                    onClose={handleClose}
                    onOpen={() => setOpen(true)}
                    renderValue={(selected) => 'Seleccionar calendario'}
                  >
                    <MenuItem value="General">General</MenuItem>
                    <MenuItem value="Oficialía Común">Oficialía Común</MenuItem>
                  </Select>
                </FormControl>
                <Box mt={2} display="flex" flexWrap="wrap" gap={1}>
                  {selectedCalendarios.map((calendario) => (
                    <Chip
                      key={calendario}
                      label={calendario}
                      onDelete={() => handleDeleteChip(calendario)}
                    />
                  ))}
                </Box>
              </Box>
            </Box>
          )}
        </Grid>

        <Grid item xs={9}>
          {selectedOption === '' && (
            <Grid container justifyContent="space-between" alignItems="center" mb={2}>
              <Box>
                <IconButton onClick={handlePrev}>
                  <ArrowBackIosIcon />
                </IconButton>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={handleToday}
                  style={{ marginRight: 16, height: 36 }}
                >
                  Hoy
                </Button>
              </Box>
              <Typography variant="h6">
                {selectedView === 'Mensual' ? currentDate.toLocaleString('default', { month: 'long', year: 'numeric' }) : currentDate.getFullYear()}
              </Typography>
              <Box display="flex" alignItems="center">
                <Select value={selectedView} onChange={handleViewChange} size="small">
                  <MenuItem value="Mensual">Mensual</MenuItem>
                  <MenuItem value="Anual">Anual</MenuItem>
                </Select>
                <IconButton onClick={handleNext}>
                  <ArrowForwardIosIcon />
                </IconButton>
              </Box>
            </Grid>
          )}
          {selectedOption === '' && (selectedView === 'Mensual' ? renderMonthlyCalendar() : renderYearlyCalendar())}
        </Grid>
      </Grid>
      {isEditing && editingEvento && (
        <Modal open={isEditing} onClose={() => setIsEditing(false)}>
          <Box sx={style}>
            <Typography variant="h6" sx={{ mb: 3 }}>Editar Evento</Typography>
            <form onSubmit={handleEditEventoPeriodo}>
              <FormControl fullWidth size="small" sx={{ mb: 2 }}>
                <InputLabel id="descripcion-label">Detalle</InputLabel>
                <Select
                  labelId="descripcion-label"
                  id="descripcion"
                  value={editingEvento.descripcion}
                  onChange={(e) => setEditingEvento({ ...editingEvento, descripcion: e.target.value })}
                  label="Detalle"
                >
                  <MenuItem value="DIA INHABIL">Día inhábil</MenuItem>
                  <MenuItem value="PERIODO VACACIONAL">Periodo vacacional</MenuItem>
                </Select>
              </FormControl>
              <TextField
                label="Fecha Inicio"
                type="date"
                value={editingEvento.diaInicio}
                onChange={(e) => setEditingEvento({ ...editingEvento, diaInicio: e.target.value })}
                fullWidth
                size="small"
                sx={{ mb: 2 }}
              />
              <TextField
                label="Fecha Fin"
                type="date"
                value={editingEvento.diaFin}
                onChange={(e) => setEditingEvento({ ...editingEvento, diaFin: e.target.value })}
                fullWidth
                size="small"
                sx={{ mb: 2 }}
                InputProps={{ inputProps: { min: editingEvento.diaInicio } }}
              />
              <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
                <Button type="submit" className='button-save'>Guardar</Button>
                <Button type="button" className='button-close' style={{ marginLeft: "10px" }} onClick={() => setIsEditing(false)} >Cancelar</Button>
              </Box>
            </form>
          </Box>
        </Modal>
      )}
    </Box>
  );
};

export default Calendarios;