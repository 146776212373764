import React, { useEffect, useState, useContext } from "react";
import { Grid } from "@mui/material";
import { validateFields } from '../../components/Alert/ValidationRequired/validationRequired';
import keycloakConf from "../../api/keycloakConf";
import FormAmparo from "./Form/FormAmparo";
import FormExpedienteJuzgado from "../../layouts/FormExpedienteJuzgado/FormExpedienteJuzgado";
import { useToastAlert } from '../../components/Alert/ToastAlertSave/ToastAlertSave';
import { useToastAlertValidation } from '../../components/Alert/ToastAlertValidation/ToastAlertValidation';
import { fetchCarpetaActorDemandado } from '../../shared/services/CarpetaService';
import { fetchSalas } from '../../shared/services/JuzgadosService';
import { fetchTribunales } from '../../pages/Instituciones/institucionService';
import { fetchCatalogos } from "../../shared/services/CarpetaService";
import { fetchNumeroPieza } from "../../shared/services/CarpetaService";
import { fetchPersonaById } from "../Personas/PersonasService";
import { addAmparo, getCaratulaPieza } from '../../shared/services/DocumentoService';
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { getAmparoById } from "../../shared/services/DocumentoService";
import { updateAmparo } from "../../shared/services/DocumentoService";

export default function Amparo() {
  const { getToken } = useContext(keycloakConf);
  const [token, setToken] = useState({});
  const { showSnackbar } = useToastAlert();
  const { showSnackbar: showAlertValidate } = useToastAlertValidation();
  const [errorMessages, setErrorMessages] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [bandValidate, setBandValidate] = useState(false);
  const [tribunales, setTribunales] = useState([]);
  const [salas, setSalas] = useState([]);
  const [sentidoAmparo, setSentidoAmparo] = useState([]);
  const [sentidoImpugnacion, setSentidoImpugnacion] = useState([]);
  const location = useLocation();
  const { item } = location.state || {};
  const formDisabled = item && item.expediente ? true:false;

  const irAruta = '/api/bandeja/asignados';
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    expediente: item?.expediente ? item.expediente.split("/")[0] : '',
    year: item?.expediente ? item.expediente.split("/")[1] : '',
    juzgado: null
  });

  const [carpeta, setCarpeta] = useState({
    idCarpeta: item?.carpetaId ? item.carpetaId:0,
    actor: '',
    demandado: ''
  });

  const { documentoId, isEdit, expediente} = location.state || {};
  const [expedienteNumero, year] = expediente ? expediente.split("/") : ["", ""];

  useEffect(() => {
    if (isEdit && expediente) {
      setFormData({
        expediente: expedienteNumero, 
        year: year, 
        juzgado: formData.juzgado 
      });
    }
  }, [isEdit, expediente, expedienteNumero, year]);

  const [formAmparoData, setFormAmparoData] = useState({
    carpetaId: '',
    tipoAmparo: '',
    fechaPresentacion: '',
    fechaTermino: '',
    impugnacion: null,
    sentidoAmparo: '',
    sentidoImpugnacion: '',
    quejoso: '',
    tribunalId: null,
    salaId: null,
  })

  useEffect(() => {
    if (documentoId) {
      getAmparoById(token, documentoId)
        .then((data) => {
          setFormAmparoData((prevData) => ({
            ...prevData,
            tipoAmparo: data.tipoAmparo || '',
            fechaPresentacion: data.fechaPresentacion || '',
            fechaTermino: data.fechaTermino || '',
            impugnacion: data.impugnacion || '',
            sentidoImpugnacion: data.impugnacionAmparo || '',
            sentidoAmparo: data.sentidoAmparo || '',
            quejoso: data.quejoso || '',
            tribunalId: data.tribunalId || '',
            salaId: !data.tribunalId ? data.salaId : '',
            numeroAmparo: data.numeroAmparo || '',
          }));
        })
        
        .catch((error) => {
          console.error("Error al obtener la información de amparo:", error); 
        });
    }
  }, [documentoId, token, setFormAmparoData]);


  useEffect(() => {
    const obtenerDatos = async () => {
      try {
        setToken(getToken());
      } catch (error) {
        console.error("Error al obtener el token:", error);
      }
    };
    
    obtenerDatos();
    loadTribunales();
    loadSalas();
    loadCatalogos();
  }, [getToken]);

  useEffect(()=>{
    if(item && formData.expediente && formData.year && formData.juzgado && formData.juzgado.id && !carpeta.actor){
      searchCarpeta();
    }
  });

  const loadTribunales = async () => {
    const token = getToken();

    try {
      let tribunales = await fetchTribunales(token);
      setTribunales(tribunales);
    } catch (error) {
      console.log(error);
    }
  };

  const loadSalas = async () => {
    const token = getToken();

    try {
      let salas = await fetchSalas(token);
      setSalas(salas);
    } catch (error) {
      console.log(error);
    }
  }

  const loadCatalogos = async () => {
    const token = getToken();
    
    try {
      let sentidoAmparo = await fetchCatalogos(token, 'catalogoSentidoAmparo');
      setSentidoAmparo(sentidoAmparo);
    } catch (error) {
      console.log(error);
    }

    try {
      let sentidoImpugnacion = await fetchCatalogos(token, 'catalogoImpugnacionAmparo');
      setSentidoImpugnacion(sentidoImpugnacion);
    } catch (error) {
      console.log(error);
    }
  }

  const handleSubmitFEJ = async () => {
    if(bandValidate){
      const rules = {
        tipoAmparo: { required: true },
        fechaPresentacion: { required: true },
        fechaTermino: '',
        impugnacion: { required: true },
        sentidoAmparo: { required: true },
        sentidoImpugnacion: {required: formAmparoData.impugnacion==='1'},
        tribunalId: {required: formAmparoData.tipoAmparo==='AD' },
        salaId: {required: formAmparoData.tipoAmparo==='AM' },
        quejoso: { required: true },
      };

      const errors = validateFields(formAmparoData, rules, showAlertValidate);
      if (Object.keys(errors).length > 0) {
        setErrorMessages(errors);
        return;
      }

      setErrorMessages({});

      setModalOpen(true)

    }else{
      const rules = {
        expediente: { required: true },
        year: { required: true },
        juzgado: { required: true },
      };
      const errors = validateFields(formData, rules, showAlertValidate);
      if (Object.keys(errors).length > 0) {
        setErrorMessages(errors);
        return;
      }
      setErrorMessages({});
      showSnackbar('Falta validar expediente, año y juzgado', 'error');
    }  

  };
  
  const handleValidateFEJ = (e) => {
    const rules = {
      expediente: { required: true },
      year: { required: true },
      juzgado: { required: true },
    };
    const errors = validateFields(formData, rules, showAlertValidate);
    if (Object.keys(errors).length > 0) {
      setErrorMessages(errors);
      return;
    }
    searchCarpeta();
    setErrorMessages({});
  };

  const searchCarpeta = async () => {
    try {
      const data= {
        "numExpediente": formData.expediente,
        "year": Number(formData.year),
        "idJuzgado": formData.juzgado.id
      };
      const response = await fetchCarpetaActorDemandado(token, data);
      setCarpeta({
        idCarpeta: response.idCarpeta,
        actor: response.actor,
        demandado: response.demandado
      });

      setFormAmparoData(prevFormData => ({
        ...prevFormData,
        carpetaId: response.idCarpeta 
      }));

      setBandValidate(true);

    } catch (error) {
      setBandValidate(false);
      showSnackbar('No existe expediente relacionado con la información ingresada', 'error');
    }
  };

  const handleRadioTipoAmparo = async (data, setNumeroPieza) => {
    const token = getToken();

    try{
      let response = await fetchNumeroPieza(token, data);
      setNumeroPieza(response.numPieza);
    } catch(error){
      console.log(error);
    }
  }

  const clearFields = () => {
    setFormData({
      expediente: '',
      year: '',
      juzgado: null
    });

    setCarpeta({
      idCarpeta: 0,
      actor: '',
      demandado: ''
    });
    setBandValidate(false);
  }; 

  const handleConfirm = async () => {
    const token = await getToken();
    
    try {
      
      if (isEdit && documentoId) {
        
        const updatedAmparo = await updateAmparo(token, documentoId, formAmparoData);
        showSnackbar("Amparo actualizado con éxito", "success");
        navigate('/api/bandeja/asignados')
       
      } else {
        let documento = await addAmparo(token, formAmparoData);

        showSnackbar("Amparo generado con éxito", "success");

        const caratula = await getCaratulaPieza(token, documento.documentoId);

        var blob = new Blob([caratula], { type: "application/pdf" });
        let link = document.createElement("a");
        
        link.href = window.URL.createObjectURL(blob);
        link.target = "caratulaPieza.pdf";
        link.click();
        
        clearFields();
      }
    } catch (error) {
      console.error("Error al guardar el Amparo.");
      showSnackbar("¡Algo salió mal!", "error");
    }

    setModalOpen(false);
  };

  return (
    <>
      <ConfirmationModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={handleConfirm}
        title={isEdit ? "¿Actualizar amparo?" : "¿Generar amparo?"}
        message={isEdit 
          ? "Se actualizarán los datos del amparo en el sistema." 
          : "Se generará un nuevo amparo en el sistema con la información proporcionada."}
        confirmationButton={"Sí, generar"}
        closeButton="No, cancelar"
      />

      <Grid item xs={12} sm={6} md={6}>
        <FormExpedienteJuzgado 
          isEditAmparo={isEdit}
          handleSubmit={handleSubmitFEJ}
          handleValidate={handleValidateFEJ}
          formData={formData}
          setFormData={setFormData}
          errorMessages={errorMessages}
          irAruta={irAruta}
          isDisabled={bandValidate}
        />
      </Grid>
      {bandValidate && (
        <FormAmparo 
          carpeta={carpeta}
          formAmparoData = {formAmparoData}
          setFormAmparoData = {setFormAmparoData}
          tribunales = {tribunales}
          salas = {salas}
          sentidoAmparo = {sentidoAmparo}
          sentidoImpugnacion = {sentidoImpugnacion}
          handleRadioTipoAmparo = {handleRadioTipoAmparo}
          errorMessages = {errorMessages}
        />
      )}
    </>
  
  );
}
