import {
    Box,
    Paper,
    Stack,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
  } from "@mui/material";
  import PaginationGeneric from "../../../components/Pagination/PaginationGeneric";
  import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
  import text from "../../../assets/Glosario";
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  
  const TableLibroGobierno = ({
    bandejas,
    token,
    loadBandejas,
    pageable,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    verExpediente
  }) => {
  
  
    return (
      <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <PaginationGeneric
              pageable={pageable}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
            />
            <TableContainer sx={{ maxHeight: 600, overflowY: "auto" }}>
              <Table stickyHeader sx={{ minWidth: 750 }}>
                <TableHead>
                  <TableRow>
                    <TableCell className="table-header">
                      {text.NoExpediente}
                    </TableCell>
                    <TableCell className="table-header">
                      {text.FechaHora}
                    </TableCell>
                    <TableCell className="table-header">
                      {text.descripcion}
                    </TableCell>
                    <TableCell className="table-header">
                      {text.actor}
                    </TableCell>
                    <TableCell className="table-header">
                      {text.demandado}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bandejas.map((item) => (
                    <StyledTableRow key={item.id} hover onClick={(e)=>{
                      verExpediente(item);
                    }}>
                      <TableCell>{item.numExpediente}</TableCell>
                      <TableCell>
                        {item.fechaHora ? new Date(item.fechaHora).toLocaleString() : null}
                      </TableCell>
                      <TableCell>{item.tipoJuicio}</TableCell>
                      <TableCell>{item.actor}</TableCell>
                      <TableCell>{item.demandado}</TableCell>
                    </StyledTableRow>
                  ))}
                  {bandejas.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        No hay registros disponibles.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <PaginationGeneric
              pageable={pageable}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
            />
          </Paper>
      </Box>
    );
  };
  
  export default TableLibroGobierno;
  