import React, { useEffect, useState, useContext, useRef } from 'react';
import { Modal, Box, Typography, TextField, MenuItem, Select, InputLabel, FormControl, IconButton, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { fetchTipoPruebas } from '../../shared/services/tipoPruebaService';
import keycloakConf from '../../api/keycloakConf';
import { useToastAlert } from "../Alert/ToastAlertSave/ToastAlertSave";
import { fetchMateriaPericial } from '../../shared/services/MateriaPericialService';
import ConfirmationModal from '../Modal/ConfirmationModal';
import { createAudienciaPrueba } from '../../shared/services/tipoPruebaService';

export const ModalRegistroPrueba = ({ open: modalOpen, handleClose, personId, item }) => {
    const [tiposPrueba, setTiposPrueba] = useState([]);
    const [materiasPericiales, setMateriasPericiales] = useState([]);
    const { getToken } = useContext(keycloakConf);  
    const [error, setError] = useState(null); 
    const token = getToken();

    const fileInputRef = useRef(null);
    const [file, setFile] = useState(null);
    const { showSnackbar } = useToastAlert();
    const [openModal, setOpenModal] = useState(false);
    const [formData, setFormData] = useState([]);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  
    useEffect(() => {
      if (modalOpen) {
        setFormData({
          tipoPruebaId: '',
          nombreDeclarante: '',
          materiaPericialId: null,
          descripcionInstrumento: '',
          absolvente: '',
          objeto: '',
          descripcionDocumento: ''
        });
      }
    }, [modalOpen]); 
  
    const handleChange = (event) => {
      const { name, value } = event.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    };
    
    //ARCHIVO
    const handleSave = async () => {

      if (!formData.tipoPruebaId) {
          showSnackbar('Por favor elija un tipo de prueba', 'error');
          return;
      }
  
      const formDataToSend = new FormData();
      formDataToSend.append('audienciaPruebaRequest', JSON.stringify(formData));
  
      if (file) {
          formDataToSend.append('file', file);
      }

      setOpenModal(true);
  };
  
    
    const handleCancelClick = () => {
      handleClose();
    };

    const handleFileChange = (event) => {
      const selectedFile = event.target.files[0];
    
      if (selectedFile) {
        if (selectedFile.size > 50 * 1024 * 1024) {
          showSnackbar('El archivo debe ser menor a 50 MB', 'error');
          return;
        }
    
        if (selectedFile.type !== "application/pdf") {
          showSnackbar('Solo se permiten archivos PDF.', 'error');
          return;
        }
        
        setFile(selectedFile);
      } else {
        setFile(null);
      }
    };
  
    useEffect(() => {
      const getTipoPruebas = async () => {
        try {
          const data = await fetchTipoPruebas(token, item.carpetaId );
          setTiposPrueba(data);
        } catch (err) {
          setError('Error al obtener los tipos de prueba');
        } 
      };
  
      getTipoPruebas();
    }, [token]);

    useEffect(() => {
      const getMateriasPericiales = async () => {
        try {
          const data = await fetchMateriaPericial(token);
          setMateriasPericiales(data);
        } catch (err) {
          setError('Error al obtener la materia pericial');
        } 
      };
  
      getMateriasPericiales();
    }, [token]);

    const handleConfirmSave = async () => {
      try {
        const response = await createAudienciaPrueba(token, formData, file);
        showSnackbar('Prueba registrada correctamente', 'success');
        setShowConfirmationModal(false);
        handleClose();
      } catch (error) {
        console.error("Error al guardar la prueba", error);
        showSnackbar('Error al registrar la prueba', 'error');
      }
    };
    
    
    const selectedTipoPrueba = tiposPrueba.find(tipoPruebaId => tipoPruebaId.id === formData.tipoPruebaId);
    const tipoNombre = selectedTipoPrueba ? selectedTipoPrueba.nombre : '';

    return (
      <Modal open={modalOpen} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            p: 3,
          }}
        >
          <Typography variant="h6" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            Registrar prueba
            <IconButton onClick={handleClose} sx={{ color: 'gray' }}>
              <CloseIcon />
            </IconButton>
          </Typography>
  
          <hr />
  
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Tipo</InputLabel>
            <Select 
              name="tipoPruebaId"
              value={formData.tipoPruebaId} 
              onChange={handleChange} 
              label="Tipo">
              {tiposPrueba.map((tipoPrueba) => (
                <MenuItem key={tipoPrueba.id} value={tipoPrueba.id}>
                  {tipoPrueba.nombre}
                </MenuItem>
              ))}
            </Select>
            {tiposPrueba.length === 0 && (
            <Typography color="error" sx={{ mt: 1 }}>
              Este expediente no tiene ningún tipo de prueba asociado.
            </Typography>
          )}
          </FormControl>

          {(tipoNombre === 'Confesional' || tipoNombre === 'Pericial') && (
          <TextField
            name="nombreDeclarante"
            label="Nombre del declarante"
            value={formData.nombreDeclarante}
            onChange={handleChange}
            fullWidth
            sx={{ mt: 2 }}
          />
          )}
           
            {tipoNombre === 'Pericial' && (
           <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Materia pericial</InputLabel>
            <Select 
              name="materiaPericialId"
              value={formData.materiaPericialId || ''} 
              onChange={handleChange} 
              label="Materia pericial">
              {materiasPericiales.map((materiaPericial) => (
                <MenuItem key={materiaPericial.id} value={materiaPericial.id}>
                  {materiaPericial.nombre}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
            )}

        {tipoNombre === 'Instrumento Público' && (
          <TextField
            name="descripcionInstrumento"
            label="Descripción del instrumento"
            value={formData.descripcionInstrumento}
            onChange={handleChange}
            fullWidth
            sx={{ mt: 2 }}
          />
          )}

        {tipoNombre === 'Reconocimiento de contenido y firma' && (
          <TextField
            name="absolvente"
            label="Absolvente"
            value={formData.absolvente}
            onChange={handleChange}
            fullWidth
            sx={{ mt: 2 }}
          />
          )}

          {tipoNombre === 'Documento Privado' && (
            <TextField
              name="descripcionDocumento"
              label="Descripción del documento"
              value={formData.descripcionDocumento}
              onChange={handleChange}
              fullWidth
              sx={{ mt: 2 }}
            />
          )}

          {tipoNombre === 'Inspección judicial' && (
            <TextField
              name="objeto"
              label="Objeto"
              value={formData.objeto}
              onChange={handleChange}
              fullWidth
              sx={{ mt: 2 }}
            />
          )}

          <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
              <Button
                variant="contained"
                component="label"
                sx={{ mr: 2 }}
              >
                Cargar archivo
                <input type="file" accept="application/pdf" hidden onChange={handleFileChange} ref={fileInputRef} />
              </Button>
              {file && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    backgroundColor: "#f5f5f5",
                    padding: 2,
                    borderRadius: 1,
                    boxShadow: 1,
                    maxWidth: "300px", // Tamaño máximo para la carta
                    overflow: "hidden",
                    flexGrow: 1,
                  }}
                >
                  <Typography variant="body2" sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "80%" }}>
                    {file.name}
                  </Typography>
                  <Button
                    variant="text"
                    color="error"
                    onClick={() => {
                      setFile(null);
                      if (fileInputRef.current) fileInputRef.current.value = null;
                    }}
                    sx={{
                      minWidth: "auto",
                      padding: 0,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "1.5rem",
                        lineHeight: "1",
                        color: "red",
                      }}
                    >
                      ✖
                    </Typography>
                  </Button>
                </Box>
              )}
              </Box>
              
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 'auto',
              pt: 3,
            }}
          >
            <Button
              type="button"
              variant="contained"
              className="button-save"
              onClick={handleSave}
              sx={{ marginRight: "10px" }}
            >
              Guardar
            </Button>
  
            <Button
              type="button"
              variant="contained"
              className="button-close"
              onClick={handleCancelClick}
            >
              Cancelar
            </Button>
          </Box>
          
          <ConfirmationModal
            open={openModal}
            title="Confirmación"
            message="¿Está seguro de que desea guardar esta prueba?"
            onConfirm={handleConfirmSave}
            confirmationButton="Si, guardar"
            closeButton="No cancelar"
            onClose={() => setOpenModal(false)}
          />

        </Box>
      </Modal>
    );
  };
  