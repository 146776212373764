import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import RefreshButton from "../../../../components/Button/RefreshButton";
import ButtonAdd from "../../../../components/Button/ButtonAdd";
import { useNavigate } from "react-router-dom";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import TaskIcon from '@mui/icons-material/Task';
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useContext, useEffect, useState } from "react";
import PaginationGeneric from "../../../../components/Pagination/PaginationGeneric";
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { fetchGetAcuerdo, fetchGetAcuerdos } from "./Acuerdo/AcuerdoService";
import keycloakConf from "../../../../api/keycloakConf";
import texto from '../../../../assets/Glosario';
import { fetchPromocionesAcuerdo } from "./Acuerdo/AcuerdoService";
import { getDocumento, obtenerAcuerdoCargado } from "../../BandejaService";
import ApprovalIcon from "@mui/icons-material/Approval";
import { useItem } from "../../../../components/ItemProvider/ItemContext";
import { useNumAcuerdoContext } from "../../../../components/Breadcrumb/NumAcuerdoContext";

const Acuerdos = ({ general, isConsulta }) => {
    const [pageable, setPageable] = useState([]);
    const [page, setPage] = useState(0);
    const { getToken } = useContext(keycloakConf);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [acuerdos, setAcuerdos] = useState([]);
    const [promociones, setPromociones] = useState([]);
    const [tipoDocumento, setTipoDocumento] = useState("");
    const { item } = useItem();
    const navigate = useNavigate();

    const { numAcuerdo, setNumAcuerdoValue } = useNumAcuerdoContext();

    const [open, setOpen] = useState(false);
    const [openPromociones, setOpenPromociones] = useState(false);
    useEffect(() => { loadAcuerdos(); }, []);

    const loadAcuerdos = async () => {
        const token = getToken();
        try {
            const acuerdosData = await fetchGetAcuerdos(token, 0, 10, item.carpetaId);
            setAcuerdos(acuerdosData.content);
            setPageable(acuerdosData.page);
        } catch (error) {
            console.log("ha ocurrido un error", error);
        }
    }

    // Función para abrir el modal
    const handleOpen = (modal, data) => {

        if (modal && modal === "promociones") {
            setTipoDocumento(data.tipoDocumento);
            getPromocionesAcuerdos(data.numeroAcuerdo);
            setOpenPromociones(true);
            return;
        }
        setOpen(true);
    };



    // Función para cerrar el modal
    const handleClose = (modal) => {
        if (modal && modal === "promociones") {
            setOpenPromociones(false);
            return;
        }
        setOpen(false);
    };

    //funcion para dirigir a crear un acuerdo.
    const handleAddAcuerdos = () => {


        navigate('/api/bandeja/expediente/agregarAcuerdo', {
            state: { item: item, isSentencia: false }
        });
    }

    const handleShowRegistro = async (elemento) => {
        let isDisabled = elemento.estatus === "PUBLICADO";
        let isSentencia = elemento.tipoDocumento === "SENTENCIA";

        let data = await fetchGetAcuerdo(getToken(), elemento.numeroAcuerdo);

        navigate('/api/bandeja/expediente/agregarAcuerdo', {
            state: { item: item, data: data, isSentencia: isSentencia, isDisabled: isDisabled }
        });



    };

    const handleShowNotificacioines = async (elemento) => {

        if (elemento.estatus === "PUBLICADO") {
            let isSentencia = elemento.tipoDocumento === "SENTENCIA";
            let data = await fetchGetAcuerdo(getToken(), elemento.numeroAcuerdo);
            data.id = item.id;
            navigate('/api/workflow/acuerdo/acuerdoNotificacion', {
                state: { item: item, data: data, isSentencia: isSentencia }
            });
        }
    }

    const handleNotificacionesAcuerdos = async (elemento) => {
        navigate('/api/bandeja/expediente/notificacionesAcuerdo', {
            state: { item: item, acuerdo: elemento }
        }); 
    }

    const handlePDF = async (data) => {
        const token = getToken();

        try {
            const documento = await (tipoDocumento === "SENTENCIA" ? obtenerAcuerdoCargado(token, data.id)
                                                                   : getDocumento(token, data.id));
            let blob = new Blob([documento], { type: "application/pdf" });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.target = item.ruta;
            link.click();
        } catch (error) {
            console.error("Error al generar el documento:", error);
        }
    };

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    //Metodo para mostrar acuerdo digitalizado
    const handleShowAcuerdo = async (item) => {
        try {
            const token = getToken();
            let documento = await obtenerAcuerdoCargado(token, item, item.tamanioPapel);

            let blob = new Blob([documento], { type: "application/pdf" });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.target = "acuerdo.pdf";
            link.click();

        } catch (error) {
            console.error("Error al generar el documento:", error);
        }
    };


    const getPromocionesAcuerdos = async (acuerdoId) => {
        const token = getToken();

        try {
            const response = await fetchPromocionesAcuerdo(token, acuerdoId);
            setPromociones(response);
        } catch (error) {
            console.log(error);
        }
    }

    const handleCrearSentencia = () => {
        navigate('/api/bandeja/expediente/agregarAcuerdo', {
            state: { item: item, isSentencia: true }
        });
    }

    return <>

        <Box mb={2} mt={2} display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" alignItems="center">


                {item.etapaProcesal && (
                    <ButtonAdd onClick={handleAddAcuerdos} isDisabled={isConsulta} />
                )}

                &nbsp;
                <RefreshButton onClick={loadAcuerdos} />
            </Box>

            <Box display="flex" alignItems="end">
                {item.etapaProcesal && (
                    <Button
                        type="button"
                        variant="contained"
                        className="button-save"
                        onClick={handleCrearSentencia}
                        disabled={isConsulta}
                    >
                        Crear Sentencia
                    </Button>
                )}
            </Box>
        </Box>

        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer sx={{ maxHeight: 600, overflowY: 'auto' }}>
                    <Table stickyHeader sx={{ minWidth: 750 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell className='table-header'>{texto.NumAcuerdo}</TableCell>
                                <TableCell className='table-header'>{texto.FechaPublicacion}</TableCell>
                                <TableCell className='table-header'>{texto.Resumen}</TableCell>
                                <TableCell className='table-header'>{texto.Estatus}</TableCell>
                                {item.etapaProcesal && (
                                    <TableCell className='table-header'>{texto.AccionesRapidas}</TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(acuerdos ?? [])
                                .map((acuerdo) => (
                                    <StyledTableRow key={acuerdo.numeroAcuerdo} hover style={{ cursor: 'pointer' }}
                                        onClick={(e) => {
                                            if (!item.etapaProcesal) {
                                                e.preventDefault();
                                                return;
                                            }
                                            handleShowRegistro(acuerdo);
                                        }}>
                                        <TableCell>{acuerdo.numeroAcuerdo}</TableCell>
                                        <TableCell>{acuerdo.fechaPublicacion || "Sin publicar"}</TableCell>
                                        <TableCell>{acuerdo.resumen || acuerdo.extractoSentencia}</TableCell>
                                        <TableCell>{acuerdo.estatus}</TableCell>
                                        {item.etapaProcesal && (
                                            <TableCell>
                                                <IconButton>
                                                    <TaskIcon />
                                                </IconButton>
                                                <IconButton onClick={(e) => {
                                                    e.stopPropagation();
                                                    setNumAcuerdoValue(acuerdo.numeroAcuerdo);
                                                    handleNotificacionesAcuerdos(acuerdo);
                                                }}>
                                                    <NotificationsNoneIcon />
                                                </IconButton>
                                                <IconButton className="icon-color" onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleOpen("promociones", acuerdo)
                                                }}>
                                                    <LibraryBooksIcon />
                                                </IconButton>
                                                <IconButton onClick={(event) => {
                                                    event.stopPropagation();
                                                    handleShowAcuerdo(acuerdo.numeroAcuerdo);

                                                }}>
                                                    <ApprovalIcon />
                                                </IconButton>
                                            </TableCell>
                                        )}
                                    </StyledTableRow>
                                ))}
                            {acuerdos.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={6} align="center">
                                        No hay registros disponibles.
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {pageable && pageable.totalElements !== undefined && (
                    <PaginationGeneric
                        pageable={pageable}
                        page={page}
                        setPage={setPage}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                    />
                )}
            </Paper>

            <Dialog open={openPromociones} onClose={() => handleClose("promociones")}>
                <DialogTitle>
                    {tipoDocumento === "SENTENCIA" ? "Promociones y acuerdos ligados a la sentencia" : "Promociones ligadas al acuerdo"
                    }

                </DialogTitle>
                <DialogContent>
                    <TableContainer sx={{ maxHeight: 600, overflowY: 'auto' }}>
                        <Table stickyHeader sx={{ minWidth: 400 }}>
                            <TableBody>
                                {promociones.map((promocion) => (
                                    <StyledTableRow
                                        key={promocion.id}
                                        hover>
                                        <TableCell>{promocion.nombre}</TableCell>
                                        <TableCell>
                                            <IconButton onClick={(e) => handlePDF(promocion)}>
                                                <PictureAsPdfIcon className="icon-color" />
                                            </IconButton></TableCell>
                                    </StyledTableRow>
                                ))}
                                {promociones.length === 0 && (
                                    <TableRow>
                                        <TableCell colSpan={6} align="center">
                                            {tipoDocumento === "SENTENCIA" ? "No hay promociones ni acuerdos ligados a esta sentencia." : "No hay registros disponibles." }
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose("promociones")} color="primary">Cerrar</Button>
                </DialogActions>
            </Dialog>

        </Box>
    </>
}

export default Acuerdos;