import React, { useEffect, useState, useContext } from 'react';
import OficialiasTable from './CRUD/OficialiasTable';
import { fetchOficialias, deleteOficialia, fetchOficialiasMaterias, updateOficialia } from './OficialiasService';
import { useToastAlertUpdate } from '../../components/Alert/ToastAlertUpdate/ToastAlertUpdate';
import KeycloakConf from '../../api/keycloakConf';
import { Box, Grid } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import ToastAlertDelete from '../../components/Alert/ToastAlertDelete/ToastAlertDelete';
import ConfirmationDialog from '../../components/Alert/ConfirmationDialog';
import ButtonAdd from '../../components/Button/ButtonAdd';
import RefreshButton from '../../components/Button/RefreshButton';
import SearchInput from "../../components/Search/SearchInput";
import Breadcrumbs from '../../components/Breadcrumb/breadcrumbs';

const Oficialias = () => {

  const [oficialias, setOficialias] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageable, setPageable] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [itemToDelete, setItemToDelete] = useState(null);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [toastType, setToastType] = useState('success');
  const { getToken } = useContext(KeycloakConf);
  const navigate = useNavigate();
  const { showSnackbar: showUpdateSnackbar } = useToastAlertUpdate();

  const loadOficialias = async (searchQuery="") => {
    setLoading(true);
    const token = getToken();
    try {
      const [oficialiasData] = await Promise.all([
        fetchOficialias(token, page, rowsPerPage,"",searchQuery),
      ]);

      // Agrupar materias por oficialía
      const groupedOficialias = oficialiasData.content.reduce((acc, oficialia) => {
        const { id, nombre, estado, tipoOficialiaNombre, tipoId, materiaNombresList, juzgadoNombre, materiaNombres, materiaId, sedeId, juzgadoId } = oficialia;

        if (!acc[id]) {
          acc[id] = {
            id,
            nombre,
            estado,
            materiaNombres: [],
            materiaNombresList,
            materiaId: [],
            sedeId,
            tipoOficialiaNombre,
            tipoId,
            juzgadoNombre,
            juzgadoId
          };
        }

        if (!acc[id].materiaNombres.includes(materiaNombres)) {
          acc[id].materiaNombres.push(materiaNombres);
        }

        acc[id].materiaId = materiaId;
        return acc;
      }, {});

      // Convertir el objeto agrupado en un array
      const result = Object.values(groupedOficialias).map(oficialia => ({
        ...oficialia,
        materiaNombresList: oficialia.materiaNombres.join(', ')
      }));

      setOficialias(result);
      setPageable(oficialiasData.page);
    } catch (error) {
      console.error('Error cargando oficialias:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadOficialias();
  }, [getToken, page, rowsPerPage]);

  const handleDelete = async (oficialia) => {
    const token = getToken();
    const ids = oficialia.materiaId
      .filter(materiaId => materiaId !== null)
      .map(materiaId => ({ id: materiaId }));

  const nuevoEstado = oficialia.estado === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';
  const mensajeAccion = nuevoEstado === 'ACTIVE' ? 'activado' : 'desactivado';


    if (ids.length > 0 || oficialia.juzgadoId !== null) {
      
      setDialogMessage(`El registro será ${mensajeAccion} en el sistema.`)
      oficialia.estado = nuevoEstado;
    
      try {
        let formDataToSubmit = {
          id: oficialia.id,
          estado: oficialia.estado,
          nombre: oficialia.nombre,
          responsable: oficialia.responsable,
          tipoOficialia: { id: oficialia.tipoId },
          sede: { id: oficialia.sedeId },
          juzgado: { id: oficialia.juzgadoId },
          audit: {},
          "materia": ids
        };
        await updateOficialia(token, formDataToSubmit);
    
        const toastMessage = `Registro ${mensajeAccion} con éxito`;
        showUpdateSnackbar(toastMessage, 'success');
        loadOficialias();
      } catch (error) {
     
        setToastMessage(`Error al ${mensajeAccion} el registro`);
        setToastType('error');
        setToastOpen(true);
      }
    } else {
      setDialogMessage(`El registro seleccionado será permanentemente eliminado del sistema.`);
      setItemToDelete(oficialia.id);
      setDialogOpen(true);
    }
  };


  const handleConfirmDelete = async () => {
    const token = getToken();
    try {
      await deleteOficialia(token, itemToDelete);
      setOficialias(oficialias.filter(oficialia => oficialia.id !== itemToDelete));
      setToastMessage('Registro eliminado con éxito');
      setToastType('success');
    } catch (error) {
      setToastMessage('El registro no fue eliminado');
      setToastType('error');
    }
    setToastOpen(true);
    setDialogOpen(false);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleCloseToast = () => {
    setToastOpen(false);
  };

  const handleRefresh = (item) => {
    loadOficialias();
  };

  const handleEditOficialia = (oficialia) => {
    navigate('/api/core/oficialias/editar_oficialia', { state: { oficialia } });
  };

  return (
    <div>
      <Box mb={2} display="flex" justifyContent="space-between" alignItems="center" >
      <Box ml={2}>
        <Link to="/api/core/oficialias/crear_oficialia">
          <ButtonAdd ariaLabel="añadir nueva oficialia" />
        </Link>
        &nbsp;
        <RefreshButton onClick={handleRefresh} />
        </Box>
        <Box flexBasis="300px" ml={2}>
                <SearchInput
                    value={searchQuery}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                    }}
                    placeholder="Buscar..."
                    ariaLabel="Campo de busqueda para instituciones"
                    tooltipTitle="Escriba para buscar"
                    onKeyDown={(e) => e.key === 'Enter' && loadOficialias(searchQuery)}
                />
        </Box>
      </Box>
      <Grid className="box-sombra">
        <Breadcrumbs istitulo="true" className="title2"/>
      <Box>
        {loading ? <p>Cargando...</p> : (
          <OficialiasTable
            oficialias={oficialias.map(oficialia => ({
              ...oficialia,
            }))}
            onDelete={handleDelete}
            onEdit={handleEditOficialia}
            pageable={pageable}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        )}
      </Box>
      </Grid>

      <ConfirmationDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDelete}
        message={dialogMessage}
      />

      <ToastAlertDelete
        open={toastOpen}
        onClose={handleCloseToast}
        message={toastMessage}
        type={toastType}
      />
    </div>
  );
};

export default Oficialias;