import axios from 'axios';

export const fetchTipoPruebas = async (token, carpetaId) => {
  try {
    const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/core/tipoPruebas/by-tipo-juicio/${carpetaId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });

    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error al listar los tipos de prueba', error);
    throw error;
  }
};

export const createAudienciaPrueba = async (token, audienciaPruebaRequest, file) => {
  try {
    const formData = new FormData();
    formData.append('audienciaPruebaRequest', JSON.stringify(audienciaPruebaRequest));

    if (file) {
      formData.append('file', file);
    } else {
      formData.append('file', null);
    }

    const response = await axios.post(
      `${process.env.REACT_APP_TRIALS_API_URL}/api/workflow/audiencias-pruebas`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error al crear la audiencia prueba', error);
    throw error;
  }
};
