import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Box,
  Grid,
  Divider,
  Typography,
} from "@mui/material";
import { fetchExpedienteDetalle, updateDetalleExpediente } from "../../../../../shared/services/CarpetaService";
import { fetchTipoJuicioByTipoJuicioId } from "../../../../../shared/services/TipoJuicioService";
import { useNavigate, useLocation } from "react-router-dom";
import { useToastAlert } from "../../../../../components/Alert/ToastAlertSave/ToastAlertSave";
import { useToastAlertUpdate } from "../../../../../components/Alert/ToastAlertUpdate/ToastAlertUpdate";
import { useToastAlertValidation } from "../../../../../components/Alert/ToastAlertValidation/ToastAlertValidation";
import KeycloakConf from '../../../../../api/keycloakConf';
import DetalleLaboral from './Detalle/Laboral';
import DetalleMercantilCivil from './Detalle/MercantilCivil';
import DetalleFamiliar from './Detalle/Familiar';
import DetalleFamiliarOralidad from './Detalle/FamiliarOralidad';
import DetallePenal from './Detalle/Penal';
import DetallePenalOralidad from './Detalle/PenalOralidad';
import DetalleExhorto from './Detalle/Exhorto';

const DetalleExpediente = ({  }) => {
  const navigate = useNavigate();
  const { getToken } = useContext(KeycloakConf);
  const token = getToken(); 
  const location = useLocation();
  const { item, general, isConsulta } = location.state || {}; //item es la info de bandejaAsignado y General es la info de tabGeneral
  const { showSnackbar, showConfirmDialog } = useToastAlert();
  const { showSnackbar: showAlertValidate } = useToastAlertValidation();
  const { showSnackbar: showUpdateSnackbar } = useToastAlertUpdate();

  const [formData, setFormData] = useState({
    determinacion: "",
    fechaAdmision: "",
    fechaDesechado: "",
    ubicacion: "",
    asunto: "",
    fase: "",
    observaciones: "",
    sentencia: "",
    promovente: "",
    numeroCarpetaInvestigacion: "",
    numeroOficio: "",
    lugarHecho: "",
    fechaHecho: "",
    cantidadPrincipal: 0,
    moneda: "",
    numeroHijos: 0,
    numeroHijosMenoresEdad: 0,
    actaMatrimonio: "",
    lugarRegistroMatrimonio: "",
    entidad: "",
    municipio: "",
    localidad: "",
    fechaRegistro: "",
    horaFormal: "",
    horaMaterial: "",
    lugarDisposicion: "",
    presentacionImputado: "",
    solicitudAudiencia: "",
    fechaPresentacionImputado: "",
    ultimoDomicilioFamiliar: "",
    domicilioAcreedor: "",
    domicilioFamiliar: "",
    domicilioDemandado: "",
    domicilioMenorEdad: ""
  });

  const [tiposJuicio, setTiposJuicio] = useState([]);
  const [tipoMateria, setTipoMateria] = useState("default");
  const [tipoSistema, setTipoSistema] = useState("");
  const [fechaHoraActual, setFechaHoraActual] = useState({ fecha: '', hora: '' });

  const renderFields = () => {
    switch (tipoMateria) {
      case 'penal':
        if (tipoSistema === 'oral') 
          return <DetallePenalOralidad formData={formData} setFormData={setFormData} general={general} tiposJuicio={tiposJuicio} isConsulta={isConsulta}/>;
        return <DetallePenal formData={formData} setFormData={setFormData} general={general} tiposJuicio={tiposJuicio} isConsulta={isConsulta}/>;
      case 'laboral':
        return <DetalleLaboral formData={formData} setFormData={setFormData} general={general} tiposJuicio={tiposJuicio} isConsulta={isConsulta}/>;
      case 'mercantil':
        return <DetalleMercantilCivil formData={formData} setFormData={setFormData} general={general} tiposJuicio={tiposJuicio} isConsulta={isConsulta}/>;
      case 'familiar':
        if (tipoSistema === 'oral') 
          return <DetalleFamiliarOralidad formData={formData} setFormData={setFormData} general={general} tiposJuicio={tiposJuicio} isConsulta={isConsulta}/>;
        return <DetalleFamiliar formData={formData} setFormData={setFormData} general={general} tiposJuicio={tiposJuicio} isConsulta={isConsulta}/>;
      case 'civil':
        return <DetalleMercantilCivil formData={formData} setFormData={setFormData} general={general} tiposJuicio={tiposJuicio} isConsulta={isConsulta}/>;
      case 'exhorto':
        return <DetalleExhorto formData={formData} setFormData={setFormData} general={general} isConsulta={isConsulta}/>;
      case 'default':
        return <></>;
      default:
        console.log("Revisar tipo de Materia asignada, no coincide con las materias establecidas.");
        return <></>;
    }
  };

  useEffect(() => {
    loadDetalle();
    loadTipoJuicio();
    setTipoMateria(general.materia.toLowerCase());
    setTipoSistema(general.tipoSistema.toLowerCase());
  }, [token]);
    
  useEffect(() => {
      const interval = setInterval(() => {
          const now = new Date();
          setFechaHoraActual({ fecha: now.toLocaleDateString(), hora: now.toLocaleTimeString() });
      }, 1000);
      return () => clearInterval(interval);
  }, []);
  
  const loadDetalle = async () => {
    try {
      const response = await fetchExpedienteDetalle(token, item.carpetaId);
      setFormData(() => ({
        ...general,
        ...response,
      }));
    } catch (error) {
      console.error("Error al obtener el detalle del expediente.");
    }
  };

  const loadTipoJuicio = async () => {
    try {
      const response = await fetchTipoJuicioByTipoJuicioId(token, general.tipoJuicioId);
      setTiposJuicio(response);
    } catch (error) {
      console.error("Error al obtener los tipos de oficialia:", error);
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.tipoJuicioHijoId && typeof formData.tipoJuicioHijoId === "object") {
      formData.tipoJuicioHijoId = formData.tipoJuicioHijoId.id;
    }

    try {
      await updateDetalleExpediente(token, formData, item.carpetaId);
      showUpdateSnackbar("Registro actualizado con éxito", "success");
      navigate("/api/bandeja/expediente/", { state: { item } });
    } catch (error) {
      console.error("Error al guardar detalle:", error);
      showSnackbar("¡Algo salió mal!", "error");
    }
  };

  const handleCancel = () => {
    navigate("/api/bandeja/expediente/", { state: { item } });
  };


  return (
    <form>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={6} md={4}>
          <Button 
            type="button" 
            variant="contained" 
            onClick={handleSubmit} 
            className="button-save"
            disabled={isConsulta}
          >
            Guardar
          </Button>
          <Button
            type="button"
            variant="contained"
            className="button-close"
            onClick={handleCancel}
            style={{ marginLeft: '10px' }}
          >
            Cerrar
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={8}>
            <Typography variant="body1" textAlign="right">
                Fecha: {fechaHoraActual.fecha} | Hora: {fechaHoraActual.hora}
            </Typography>
        </Grid>
      </Grid>
      <Box sx={{ marginTop: 2 }}>
        <Divider />
      </Box>
      {renderFields()}
    </form>
  );
};

export default DetalleExpediente;
