import React, { useState, useContext } from 'react';
import { Modal as MuiModal, Box, Typography, Button, IconButton, Divider, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { addNota } from '../NotificacionService';
import keycloakConf from '../../../api/keycloakConf';
import { useToastAlert } from '../../../components/Alert/ToastAlertSave/ToastAlertSave';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'white',
  borderRadius: '8px',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  p: 2,
  maxWidth: '700px',
  width: '100%',
};

const AgregarNota = ({ isOpen, handleClose, expediente, concepto, handleSave, id }) => {
  const [nota, setNota] = useState('');
  const { getToken } = useContext(keycloakConf);
  const { showSnackbar } = useToastAlert();

  const handleConfirm = async () => {
    if (nota.trim().length > 0) {
      try {

        const token = getToken();
        const notaResponse = {
          id: id,
          notas: nota,
        };

        await addNota(token, notaResponse);

        handleSave();
        setNota('');
        handleClose();
        showSnackbar('Nota guardada con éxito', 'success');

      } catch (error) {
        showSnackbar('Error al guardar la nota', 'error');
      }
    } else {
      showSnackbar('La nota no puede estar vacía', 'error');
    }
  };

  return (
    <MuiModal
      open={isOpen}
      onClose={() => {
        handleClose();
        setNota('');
      }}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography id="modal-title" variant="h6" component="h2" sx={{ fontWeight: 'bold', color: '#333' }}>
            Agregar nota
          </Typography>
          <IconButton onClick={() => {
            handleClose();
            setNota('');
          }}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Divider sx={{ my: 1 }} />

        <Typography sx={{ mt: 2 }}>
          Expediente: <strong>{expediente || 'N/A'}</strong>
        </Typography>


        <Typography sx={{ mt: 2 }}>
          Concepto: <strong>{Array.isArray(concepto) && concepto.length > 0 ? concepto : 'N/A'}</strong>
        </Typography>


        <Typography sx={{ mt: 2 }}>Nota:</Typography>
        <TextField
          multiline
          rows={4}
          value={nota}
          onChange={(e) => setNota(e.target.value.slice(0, 500))} // Límite de 500 caracteres
          fullWidth
          placeholder="Escribe una nota (máximo 500 caracteres)"
          sx={{ mt: 1 }}
        />

        <Box display="flex" justifyContent="flex-end" sx={{ mt: 4 }}>
          <Button
            variant="outlined"
            className="button-close"
            onClick={() => {
              handleClose();
              setNota('');
            }}
            sx={{ mr: 2 }}
          >
            Cancelar
          </Button>
          <Button
            className="button-save"
            variant="contained"
            onClick={handleConfirm}
            sx={{ mr: 2 }}
          >
            Agregar
          </Button>
        </Box>
      </Box>
    </MuiModal>
  );
};

export default AgregarNota;
