import axios from 'axios';

export const getListaEstrado = async (token, id) => {
    try {
        const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/listaestrado/${id}`, {
          responseType: 'arraybuffer',
          headers: {
              Authorization: `Bearer ${token}`,
              Accept: "application/pdf",
          },
        });
        return response.data;
    } catch (error) {
        console.error('Error en Obtener lista de estrado: ', error);
        throw error;
    }
};
