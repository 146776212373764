import React, { useState, useContext, useEffect } from 'react';
import { Modal as MuiModal, Box, Typography, Button, IconButton, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { createLista } from '../NotificacionService';
import keycloakConf from '../../../api/keycloakConf';
import { useToastAlert } from '../../../components/Alert/ToastAlertSave/ToastAlertSave';
import ModalTurnado from '../../../components/ModalTurnarPersonalJuzgado/ModalTurnar';
import { getNotificacionesTurnado } from '../NotificacionService';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'white',
  borderRadius: '8px',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  p: 2,
  maxWidth: '500px',
  width: '100%',
};


const CrearLista = ({ isOpen, handleClose, cantidadNotificaciones, handleSave, selectedIds, loadNotificacionData, data }) => {
  const [selectedDate, setSelectedDate] = useState('');
  const { getToken } = useContext(keycloakConf);
  const { showSnackbar } = useToastAlert();

  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState([]);


  const handleRefresh = () => {
    loadNotificacionData();
  };

  const handleCloseModal = (event, modal = "") => {
    setOpenModal(false);
  };


  const handleOpenModal = (filteredData, modal = ""  ) => {
    setModalData(filteredData);
    setOpenModal(true);
  };

  const handleConfirm = async () => {

    if (!selectedDate) {
      showSnackbar('Seleccione una fecha de vencimiento.', 'error');
      return;
    }

    const token = getToken();

    const listaResponse = {
      notificacionIds: selectedIds,
      fechaVencimiento: selectedDate,
    };

    try {

      await createLista(token, listaResponse);
      loadNotificacionData();
      handleSave();
      handleClose();
      showSnackbar('Lista creada exitosamente.', 'success');

      const filteredData = data.content.reduce((acc, item) => { 
        if (selectedIds.includes(item.id)) { acc.push(item); } return acc; 
      }, []);
      
      try {
         const notificaciones = await getNotificacionesTurnado(token, filteredData.map(item => item.carpetaId));
          const filteredByNotificaciones = filteredData.filter(item => notificaciones.includes(item.carpetaId)); 
        
          // turnado de notificaciones
          if (filteredByNotificaciones.length > 0) { 
            handleOpenModal(filteredByNotificaciones); 
          }
  
        } catch (error) { 
          console.error('Error al obtener las notificaciones:', error); 
        }

    } catch (error) {
      showSnackbar('Error al crear la lista', 'error');
    }
  };

  return (
    <>
    <MuiModal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography id="modal-title" variant="h6" component="h2" sx={{ fontWeight: 'bold', color: '#333' }}>
            Crear Lista
          </Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Divider sx={{ my: 1 }} />

        <Typography sx={{ mt: 2 }}>
          Se seleccionaron <strong>{cantidadNotificaciones}</strong> notificaciones para crear la lista del estado.
        </Typography>


        <Box sx={{ mt: 3 }}>
          <Typography sx={{ mb: 1 }}>Fecha de vencimiento:</Typography>
          <input
            type="date"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
            style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ccc' }}
          />
        </Box>

        <Box display="flex" justifyContent="flex-end" sx={{ mt: 4 }}>
          <Button
            variant="outlined"
            className="button-close"
            onClick={handleClose}
            sx={{ mr: 2 }}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            className="button-save"
            onClick={handleConfirm}
            sx={{ mr: 2 }}
          >
            Crear lista oficial
          </Button>
        </Box>
      </Box>
    </MuiModal>

    <ModalTurnado 
    open={openModal}
    onClose={handleCloseModal}
    data = {modalData}
    handleRefresh={handleRefresh}
    subTitle="Notificaciones completadas, Turnar ¿Expediente?"
    /> 

    </> 

    
  );
};

export default CrearLista;
