
import KeycloakConf from "../../../api/keycloakConf";
import FormConcepto from "./FormConcepto";
import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; 

export default function EditConcepto() {
    const location = useLocation();
    const { concepto } = location.state || {};
    const { getToken } = useContext(KeycloakConf);

    const navigate = useNavigate();
    return (
        <div>
          {concepto ? (
            <FormConcepto
              token={getToken()}
              onAdd={async () => {
                navigate('/api/core/conceptos'); 
              }}
              onEdit={concepto}
            />
          ) : (
            <p>No se encontró el registro.</p>
          )}
        </div>
      );
}