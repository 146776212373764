  import { Box, FormControl, InputLabel, Select, MenuItem, TextField, Grid } from '@mui/material';
  import { useEffect, useState } from 'react';
  import { fetchCatalogo } from '../../../../shared/services/CatalogoListService';
  import fetchNacionalidades from '../../../../shared/services/NacionalidadesService';
  import validCombinations from './validCombinations';
  import { useLocation } from 'react-router-dom';
  import fetchListTipoPartes from '../../../TipoPartes/TipoPartesService';
  import { fetchPersonasDocumento } from '../../../Personas/PersonasService';
 
  const DatosGenerales = ({
    participante, 
    onInputChange, 
    handleFechaNacimientoChange, 
    token,
    onNombreTipoChange,
    isDisabled
  }) => {
    
    const location = useLocation();
    const { item: locationItem } = location.state || {};
    const sexos = ["Masculino", "Femenino"];

    const [tiposDefensores, setTiposDefensores] = useState([]);
    const [estadosCiviles, setEstadoCivil] = useState([]);
    const [nacionalidades, setNacionalidades] = useState([]); 
    const [enRepresentacion, setEnRepresentacion] = useState([]); 
    const [tipos, setTipos] = useState([]); 
    const [errors, setErrors] = useState("");
    
    const curpRegex =
  /^[A-Z]{1}[AEIOU]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])[HM]{1}[A-Z]{2}[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9]{1}$/;

  const rfcRegex =
  /^([A-ZÑ&]{3,4})[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])([A-Z0-9]{3})?$/;


    const [item, setItem] = useState(() => {
      const storedItem = localStorage.getItem('currentItem');
      return locationItem || (storedItem ? JSON.parse(storedItem) : null);
    });

    useEffect(() => {
      if (locationItem) {
        localStorage.setItem('currentItem', JSON.stringify(locationItem));
      }
    }, [locationItem]);

    const tipoPersona = participante.tipoPersona;
    const tipo = participante.tipo;

    const getNombreTipoById = (id) => {
      const tipoEncontrado = tipos.find(tipo => tipo.id === id);
      return tipoEncontrado ? tipoEncontrado.nombre : 'Tipo no encontrado';
  };

  const nombreTipo = getNombreTipoById(tipo);
  useEffect(() => {
    onNombreTipoChange(nombreTipo);
}, [nombreTipo, onNombreTipoChange]);

    const camposVisibles = validCombinations[`${nombreTipo}_${tipoPersona}`] || [];  
  
    const isFieldVisible = (fieldName) => camposVisibles.includes(fieldName);

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      onInputChange(name, value);  
    };

     const loadTipos = async () => {
      if (item) {
        try {
          const tipos = await fetchListTipoPartes(token, item.carpetaId);
          setTipos(tipos);
        } catch (error) {
          console.error('Error al cargar los tipos de partes:', error);
        }
      }
    };

  useEffect(() => {
    if (item?.id) {
      onInputChange('idCarpeta', item.id);
    }
  }, [item, onInputChange]);

  const validateCURP = (curp) => {
    if (!curpRegex.test(curp)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        curp: "La CURP no tiene un formato válido.",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        curp: null,
      }));
    }
  };

  const validateRFC = (rfc) => {
    if (!rfcRegex.test(rfc)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        rfc: "El RFC no tiene un formato válido.",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        rfc: null,
      }));
    }
  };
  
  const loadEstadosCivil = async () => {
    try {
      const estadoCivilData = await fetchCatalogo(token, 'estadoCivil');
      setEstadoCivil(estadoCivilData);
    } catch (error) {
      console.error('Error al obtener registros:', error);
    }
  };


  const getTipoParte = (tipo) => {
    const nombreObtenido = getNombreTipoById(tipo)
    let newTipo;   

    const tipoStr = nombreObtenido.toLowerCase(); 

    if (tipoStr.includes("actor")) {
      newTipo = "Actor";  
    }
    
    if (tipoStr.includes("demandado")) {
      newTipo = "Demandado";  
    }

    return newTipo;
  };

    const loadEnRepresentacion = async () => {
      if (!participante.tipo) {
        setEnRepresentacion([]); 
        return;
      }

      try {
       const data = await fetchPersonasDocumento(token, item.carpetaId, getTipoParte(participante.tipo));
        setEnRepresentacion(data);
      } catch (error) {
        console.error('Error al cargar enRepresentacion:', error);
      }
    };

    const loadTiposDefensores = async () => {
      try {
        const tipoDefensorData = await fetchCatalogo(token, "tipoDefensor");
        setTiposDefensores(tipoDefensorData); 
      } catch (error) {
        console.error('Error al obtener registros:', error);
      }
    };

    const loadNacionalidades = async () => {
      try {
        const nacionalidadData = await fetchNacionalidades(token);
        setNacionalidades(nacionalidadData);
      } catch (error) {
        console.error('Error al obtener nacionalidades:', error);
      }
    };
    
    useEffect(() => {
      loadEstadosCivil();
      loadTiposDefensores();
      loadNacionalidades();
      loadEnRepresentacion();
      loadTipos();
    },[item]);
  
    useEffect(() => {
      loadEnRepresentacion();
    }, [participante.tipo, participante.tipoPersona]);

    return (
      <Box p={4}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel>Tipo</InputLabel>
              <Select
                name="tipo"
                value={participante.tipo ?? ''}
                onChange={(e) => onInputChange('tipo', e.target.value)}
                label="Tipo"
                disabled={isDisabled}
              >
                {tipos.map((tipo) => (
                  <MenuItem key={tipo.id} value={tipo.id}>
                    {tipo.nombre}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            
            {isFieldVisible('nombre') && (
            <TextField
              fullWidth
              name="nombre"
              value={participante.nombre}
              onChange={handleInputChange}
              label="Nombre"
              sx={{ marginBottom: 2 }}
              disabled={isDisabled}
            />
            )}

            {isFieldVisible('pseudonimo') && (
            <TextField
              fullWidth
              name="pseudonimo"
              value={participante.pseudonimo}
              onChange={handleInputChange}
              label="Pseudónimo"
              sx={{ marginBottom: 2 }}
              disabled={isDisabled}
            />
            )}

            {isFieldVisible('nacionalidad') && (
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel>Nacionalidad</InputLabel>
              <Select
                name="nacionalidad"
                value={participante.nacionalidad?.id || participante.nacionalidad || ''}

                onChange={handleInputChange}
                label="Nacionalidad"
                disabled={isDisabled}
              >
                {nacionalidades.map((nacionalidad) => (
                <MenuItem key={nacionalidad.id} value={nacionalidad.id}>
                  {nacionalidad.name} {/* Usa la propiedad adecuada */}
                </MenuItem>
              ))}
              </Select>
            </FormControl>
            )}
            
            {isFieldVisible('rfc') && (
             <TextField
             fullWidth
             name="rfc"
             value={participante.rfc || ""}
             onChange={handleInputChange}
             onBlur={(e) => validateRFC(e.target.value)}
             label="RFC"
             error={!!errors.rfc}
             helperText={errors.rfc || ""}
             sx={{ marginBottom: 2 }}
             disabled={isDisabled}
           />
            )}

            {isFieldVisible('cedula') && (
            <TextField
              fullWidth
              name="cedula"
              value={participante.cedula}
              onChange={handleInputChange}
              label="Cédula profesional"
              sx={{ marginBottom: 2 }}
              disabled={isDisabled}
            />
            )}
          </Grid>

          <Grid item xs={12} md={4}>
            
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel>Tipo de persona</InputLabel>
              <Select
                name="tipoPersona"
                value={participante.tipoPersona || ''}
                onChange={handleInputChange}
                label="Tipo de persona"
                disabled={isDisabled}
              >
                <MenuItem value="fisica">Física</MenuItem>
                <MenuItem value="moral">Moral</MenuItem>
              </Select>
            </FormControl>
            
            {isFieldVisible('apellidoPaterno') && (
            <TextField
              fullWidth
              name="apellidoPaterno"
              value={participante.apellidoPaterno || ''}
              onChange={handleInputChange}
              label="Apellido paterno"
              sx={{ marginBottom: 2 }}
              disabled={isDisabled}
            />
          )}
              {isFieldVisible('sexo') && (
              <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel>Sexo</InputLabel>
              <Select
                name="sexo"
                value={participante.sexo || ''}
                onChange={handleInputChange}
                label="Sexo"
                disabled={isDisabled}
              >
                {sexos.map((sexo, index) => (
                  <MenuItem key={index} value={sexo}>
                    {sexo === "Masculino" ? "Masculino" : "Femenino"}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
            {isFieldVisible('edad') && (
            <TextField
              fullWidth
              name="edad"
              value={participante.edad || ''}
              label="Edad"
              disabled
              sx={{ marginBottom: 2 }}

            />
          )}
            {isFieldVisible('curp') && (
             <TextField
             fullWidth
             name="curp"
             value={participante.curp || ''}
             onChange={handleInputChange}
             onBlur={(e) => validateCURP(e.target.value)}
             label="CURP"
             error={!!errors.curp}
             helperText={errors.curp || ""}
             sx={{ marginBottom: 2 }}
             disabled={isDisabled}
           />
          )}
            {isFieldVisible('tipoDefensor') && (
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel>Tipo de defensor</InputLabel>
              <Select
                name="tipoDefensor"
                value={participante.tipoDefensor ?? ''}
                onChange={handleInputChange}
                label="Tipo de defensor"
                disabled={isDisabled}
              >
              {tiposDefensores.map((tipoDefensor) => (
                <MenuItem key={tipoDefensor.clave} value={tipoDefensor.clave}>
                  {tipoDefensor.etiqueta} {/* Usa la propiedad adecuada */}
                </MenuItem>
              ))}
              </Select>
            </FormControl>
          )}

          </Grid>

          {/* Columna Tipo Persona */}
          <Grid item xs={12} md={4}>
            {isFieldVisible('razonSocial') && (
            <TextField
              fullWidth
              name="razonSocial"
              value={participante.razonSocial || ''}
              onChange={handleInputChange}
              label="Razón social"
              sx={{ marginBottom: 2 }}
              disabled={isDisabled}
            />
          )}
            {isFieldVisible('fechaNacimiento') && (
            <TextField
            fullWidth
            type="date"
            name="fechaNacimiento"
            value={participante.fechaNacimiento || ''}
            onChange={handleFechaNacimientoChange}
            label="Fecha de nacimiento"
            sx={{ marginBottom: 2 }}
            disabled={isDisabled}
            inputProps={{
              max: new Date().toISOString().split('T')[0]
            }}
            InputLabelProps={{
              shrink: true 
            }}
          />
          )}

          {isFieldVisible('apellidoMaterno') && (
            <TextField
              fullWidth
              name="apellidoMaterno"
              value={participante.apellidoMaterno || ''}
              onChange={handleInputChange}
              label="Apellido materno"
              sx={{ marginBottom: 2 }}
              disabled={isDisabled}
            />
          )}

            {isFieldVisible('estadoCivil') && (
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
              <InputLabel>Estado civil</InputLabel>
              <Select
                name="estadoCivil"
                value={participante.estadoCivil ?? ''}
                onChange={handleInputChange}
                label="Estado civil"
                disabled={isDisabled}
              >
                {estadosCiviles.map((estado) => (
                <MenuItem key={estado.clave} value={estado.clave}>
                  {estado.etiqueta}
                </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
            {isFieldVisible('enRepresentacion') && (
            <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel>En representación de</InputLabel>
            <Select
              name="enRepresentacion"
              value={participante.enRepresentacion || ''}
              onChange={handleInputChange}
              label="En representación de"
              disabled={isDisabled}
            >
               {(enRepresentacion ?? []).map((representacion) => (
                <MenuItem key={representacion.id} value={representacion.id}>
                  {representacion.nombreCompleto}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          )}
            {isFieldVisible('adscripcion') && (
            <TextField
              fullWidth
              name="adscripcion"
              value={participante.adscripcion || ''}
              onChange={handleInputChange}
              label="Adscripción"
              sx={{ marginBottom: 2 }}
              disabled={isDisabled}
            />
          )}
          </Grid>
        </Grid>
      </Box>
    );
  };

  export default DatosGenerales;