import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import { fetchInstitucionesAutoComplete } from "../../../Instituciones/institucionService"; 
import FormInstitucion from "../../../Instituciones/Form/FormInstitucion"; 
import ConfirmationDialog from "../../../../components/Alert/ConfirmationDialog/ConfirmationDialogGeneric";
import { useContext, useEffect, useRef, useState } from "react";
import keycloakConf from "../../../../api/keycloakConf";
const General = ({ formData, handleInputChange, setModalOpen, modalOpenInstitucion, setModalOpenInstitucion }) => {
    const [instituciones, setInstituciones] = useState([]);
    const references = useRef();
    const { getToken } = useContext(keycloakConf);

    useEffect(() => {
        loadInstituciones();
    }, []);

    const loadInstituciones = async () => {
        try {
            const token = getToken();
            let instituciones = await fetchInstitucionesAutoComplete(token, 0, 20);
            setInstituciones(instituciones.content);

        } catch (error) {
            console.log("Error al obtener las instituciones");
        }

    };

    return(
        <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={4}>
                <TextField
                    label="Número de oficio: "
                    name="general.numeroOficio"
                    disabled={true}
                    fullWidth
                    value={formData.general.numeroOficio}
                />

            </Grid>

            <Grid item xs={4}>
                <TextField
                    label="Expediente "
                    name="general.expediente"
                    disabled={true}
                    fullWidth
                    value={formData.general.expediente}
                />
            </Grid>

            <Grid item xs={4}>
                <TextField
                    label="Fecha de emisión: "
                    name="general.fechaEmision"
                    disabled={true}
                    fullWidth
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    value={formData.general.fechaEmision}
                />
            </Grid>

            <Grid item xs={4}>
                <TextField
                    label="ID oficio: "
                    name="general.idOficio"
                    disabled={true}
                    fullWidth
                    value={formData.general.idOficio}
                />
            </Grid>

            <Grid item xs={4}>
                <Autocomplete
                    disabled={formData.general.estatus === "CANCELADO" || formData.oficio.estatusAcuse !== "Creado" }
                    options={
                        [...instituciones, { id: 'otra', nombre: 'Registrar nueva' }].filter(
                            (option, index, self) =>
                                index === self.findIndex((o) => o.id === option.id) // Eliminar duplicados
                        )
                    }
                    getOptionLabel={(option) => option.nombre}
                    value={
                        instituciones.find((institucion) => institucion.id === formData.general.dependencia) || null
                    }
                    onChange={(event, newValue) => {
                        handleInputChange({
                            target: {
                                name: 'general.dependencia',
                                value: newValue ? newValue.id : ''
                            }
                        });
                    }}
                    filterOptions={(options, state) => {
                        const filtered = options.filter((option) =>
                            option.nombre.toLowerCase().includes(state.inputValue.toLowerCase())
                        );
                        // Asegurarse de que "Registrar nueva" siempre esté visible
                        if (!filtered.some((option) => option.id === 'otra')) {
                            filtered.unshift({ id: 'otra', nombre: 'Registrar nueva' });
                        }
                        return filtered;
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Seleccione una dependencia"
                            variant="outlined"
                        />
                    )}
                />
            </Grid>

            <Grid item xs={4}>
                <TextField
                    label="Fecha de entrega: "
                    name="general.fechaEntrega"
                    disabled={true}
                    fullWidth
                    type="date"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={formData.general.fechaEntrega}
                />
            </Grid>

            <Grid item xs={4}>
                <TextField
                    label="Estatus: "
                    name="general.estatus"
                    disabled={true}
                    fullWidth
                    value={formData.general.estatus}
                />
            </Grid>

            <Grid item xs={4}>
                <TextField
                    label="Asunto"
                    name="general.asunto"
                    multiline
                    disabled={formData.general.estatus === "CANCELADO" || formData.oficio.estatusAcuse !== "Creado" }
                    variant="outlined"
                    value={formData.general.asunto}
                    onChange={handleInputChange}
                    fullWidth
                />
            </Grid>

            <Grid item xs={4}>
                <Button
                    disabled={formData.general.estatus === "CANCELADO" || formData.oficio.estatusAcuse !== "Creado"}
                    type="button"
                    variant="contained"
                    className="button-close"
                    onClick={() => setModalOpen(true)}
                >
                    Cancelar oficio
                </Button>
            </Grid>

            <ConfirmationDialog
                open={modalOpenInstitucion}
                onClose={() => setModalOpenInstitucion(false)}

                onConfirm={() => { references.current?.guardar() }}
                title="Agregar institución"
                message={
                    <FormInstitucion
                        token={getToken()}
                        onCancel={async () => {
                            await loadInstituciones();
                            setModalOpenInstitucion(false);

                        }
                        }
                        activeTitle = {false}
                        ref={(el) => { references.current = el; }}
                    />
                }
                btnLeft="Cerrar"
                btnRight="Guardar"
                widthGeneric="90%"
                autoClose={false}
            ></ConfirmationDialog>

        </Grid>)
}



export default General;