import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Typography, Box, Collapse, ListItemButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

export default function DemandaTable({ datos, openMaterias, setOpenMaterias, onJuicioSeleccionado }) {
  const navigate = useNavigate();

  const handleCellClick = (nombreJuicio, materia, juicioId) => {
    const formDemanda = '/api/workflow/demanda/add';
    const formFamiliar = '/api/workflow/demanda/familiar';
    const formPenal = '/api/workflow/demanda/penal';
  
    const juicioNombreMap = {
      Penal: "Penal",
      "Justicia para adolescentes": "Justicia para adolescentes (ORAL)"
    };
   
    if (juicioNombreMap[materia]) {
      // Obtener datos del juicio padre basado en la materia
      const dataPenal = datos
        .map(element => element.juicios) // Mapear los juicios
        .flat() // Aplanar el arreglo de arreglos
        .find(juicio => juicio.nombre.trim() === juicioNombreMap[materia]); // Buscar el juicio correspondiente

      if (dataPenal) {
        const juicioPadreId = dataPenal.id;
        const materiaId = dataPenal.materiaRecord.id;
  
        navigate(formPenal, {
          state: { juicioId, nombreJuicio, materia, juicioPadreId, materiaId }
        });
        return; // Salir después de navegar
      }
    }
  
    // Verificar si es materia familiar (tradicional o no)
    const materiaFamiliar =
      materia.toUpperCase() === "FAMILIAR" && nombreJuicio === "Familiar (Tradicional)";
    const navigateForm =
      materiaFamiliar
        ? formDemanda
        : materia.toUpperCase() === "FAMILIAR"
        ? formFamiliar
        : formDemanda;
  
    // Seleccionar juicio y navegar al formulario correspondiente
    onJuicioSeleccionado({ id: juicioId, nombre: nombreJuicio, materia });
    navigate(navigateForm, {
      state: { juicioId, nombreJuicio, materia }
    });
  };
  

  const toggleMateria = (materia) => {
    setOpenMaterias(prevState => ({
      ...prevState,
      [materia]: !prevState[materia]
    }));
  };

  return (
    <Box mb={3} >
      <TableContainer component={Paper} style={{ boxShadow: ' 0px 0px 12px 0px hsl(0deg 0% 0% / 18%)', border: '2px solid #636569', }}>
        <Table>
          <TableBody>
            {datos.map(({ materia, juicios }) => (
              <React.Fragment key={materia}>
                <TableRow>
                  <TableCell colSpan={2} align="left">
                    <ListItemButton onClick={() => toggleMateria(materia)}>
                      <Typography component="p" sx={{ flexGrow: 1, fontSize: '1.1rem' }}>
                        {materia}
                      </Typography>
                      {openMaterias[materia] ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell colSpan={2} style={{ padding: 0 }}>
                    <Collapse in={openMaterias[materia]} timeout="auto" unmountOnExit>
                      <Table>
                        <TableBody>
                          {juicios.map((juicio) => (
                            <TableRow
                              key={juicio.id}
                              sx={{
                                '&:hover': {
                                  backgroundColor: 'rgba(0, 0, 0, 0.100)',
                                  cursor: 'pointer',
                                },
                              }}
                              onClick={() => handleCellClick(juicio.nombre, materia, juicio.id)}
                            >
                              <TableCell>{juicio.nombre}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
