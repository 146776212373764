import axios from 'axios';

async function fetchPaises(token) {
    try {
        const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + '/api/core/paises', {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error al obtener paises:', error);
    }
}

export default fetchPaises;