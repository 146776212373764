import React, { useState, useEffect, useContext } from "react";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  Button,
  FormHelperText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import SearchInput from "../../../components/Search/SearchInput";
import keycloakConf from "../../../api/keycloakConf";
import { fetchEncargadosCarrito } from "../../../shared/services/PersonasService";
import { validateFields } from '../../../components/Alert/ValidationRequired/validationRequired';
import { useToastAlert } from '../../../components/Alert/ToastAlertSave/ToastAlertSave';
import { useToastAlertValidation } from '../../../components/Alert/ToastAlertValidation/ToastAlertValidation';

const HeaderBandejaSalida = ({
  errorMessages,
  setErrorMessages,
  loadBandejas,
  searchQuery,
  setSearchQuery,
  encargadosCarrito,
  setEncargadosCarrito,
  setDrawerOpen,
  setDialogOpen,
  formData,
  setFormData
}) => {
  const navigate = useNavigate();
  const { getToken } = useContext(keycloakConf);
  const { showSnackbar } = useToastAlert();
  const { showSnackbar: showAlertValidate } = useToastAlertValidation();

  useEffect(() => {
    loadEncargadosCarrito();
  }, [getToken]);

  const loadEncargadosCarrito = async () => {
    try {
      const token = getToken();
      const encargados = await fetchEncargadosCarrito(token);
      setEncargadosCarrito(encargados || []);
    } catch (error) {
      console.log("Error al obtener los Encargados Carrito");
    }
  };

  const handleSubmitSalida = async (e) => {
    const rules = {
      respEntrega: { required: true },
    };
    const errors = validateFields(formData, rules, showAlertValidate);
    if (Object.keys(errors).length > 0) {
      setErrorMessages(errors);
      return;
    }
    setDialogOpen(true);
    setErrorMessages({});
  };

  const handleCancel = () => {
    navigate("/");
  };

  const handleVerCarrito = () => {setDrawerOpen(true);};

  const handleResponsableChange = (event) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      respEntrega:  encargadosCarrito.find((item) => item.id === event.target.value)
    }));
  };

  return (
    <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={12} md={5}>
          <Button
            type="button"
            onClick={handleSubmitSalida}
            variant="contained"
            className="button-save"
            style={{ textTransform : "none" }}
          >
            Salida
          </Button>
          <Button
            variant="contained"
            className="button-close"
            onClick={handleCancel}
            style={{ marginLeft: "10px", textTransform : "none" }}
          >
            Cerrar 
          </Button>
          <Button
            className='button-validation'
            variant="contained"
            onClick={handleVerCarrito}
            style={{ marginLeft: "10px", textTransform : "none" }}
          >
            <AddShoppingCartIcon sx={{ mr: 1 }} /> Ver Carrito
          </Button>
        </Grid>

        <Grid item xs={12} sm={12} md={4} sx={{ paddingRight: { md: 4, xs: 0 } }}>
          <FormControl
            fullWidth
            variant="outlined"
            error={!!errorMessages.respEntrega}
          >
            <InputLabel id="select-responsable-entrega-label">Responsable de la entrega</InputLabel>
            <Select
              labelId="select-responsable-entrega-label"
              value={formData?.respEntrega?.id || ""}
              onChange={handleResponsableChange}
              label="Responsable de la entrega"
              required
            >
              {encargadosCarrito.map((encargadoCarrito) => (
                <MenuItem key={encargadoCarrito.id} value={encargadoCarrito.id}>
                  {encargadoCarrito.nombreCompleto}
                </MenuItem>
              ))}
            </Select>
            {errorMessages.respEntrega && (
              <FormHelperText>{errorMessages.respEntrega}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        
        <Grid item xs={12} sm={12} md={3}>
          <SearchInput
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Buscar..."
            ariaLabel="Campo de busqueda para bandeja de salida"
            tooltipTitle="Escriba para buscar"
            onKeyDown={(e) => e.key === "Enter" && loadBandejas()}
          />
        </Grid>
      </Grid>
  );
};

export default HeaderBandejaSalida;
