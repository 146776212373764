import React, { useState, useContext } from "react";
import {
  Box,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider,
  Typography,
  Grid,
  Paper,
  styled,
} from "@mui/material";
import SearchInput from "../../components/Search/SearchInput";
import { fetchCarpetaActorDemandado } from "../../shared/services/CarpetaService";
import keycloakConf from "../../api/keycloakConf";
import { useToastAlert } from "../../components/Alert/ToastAlertSave/ToastAlertSave";
import { changeEstadoCarpeta } from "../../shared/services/CarpetaService";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import { fetchCarpetaByExpedienteAndEstado } from "../../shared/services/CarpetaService";
import { devolverArchivoJudicial } from "../../shared/services/CarpetaService";
import { useNavigate } from "react-router-dom";

const ArchivoJudicial = () => {

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    cursor: "pointer", 
  }));

  const [searchData, setSearchData] = useState({ numExpediente: "", year: "" });
  const { getToken } = useContext(keycloakConf);
  const token = getToken ? getToken() : null; 
  const [expedientes, setExpedientes] = useState([]);
  const [selectedRadio, setSelectedRadio] = useState("enviar");
  const { showSnackbar } = useToastAlert();
  const [selectedIds, setSelectedIds] = useState([]);
  
  const navigate = useNavigate(); 

  const [modalOpen, setModalOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const handleSave = () => {
    if (selectedIds.length === 0) {
      showSnackbar("Debe seleccionar al menos un expediente.", "error");
      return;
    }

    setModalOpen(true);
  };

  const handleRadioChange = (e) => {
    setSelectedRadio(e.target.value);
    setExpedientes([]);
    setSelectedIds([]);
  };

  const handleConfirm = async () => {
    if (selectedRadio === "enviar") {
      try {
        await changeEstadoCarpeta(token, selectedIds);
        showSnackbar("Expedientes enviados.");
      } catch (error) {
        console.error("Error al actualizar el estado:", error);
        showSnackbar("Error al enviar los expedientes al archivo judicial.", "error");
      }
    } else if (selectedRadio === "devolver") {
      try {
        await devolverArchivoJudicial(token, selectedIds);
        showSnackbar("Expedientes devueltos.");
      } catch (error){
        console.error("Error al devolver los expedientes.", error);
        showSnackbar("Error al devolver los expedientes.", "error");
      }
    }
    
    setExpedientes([]);
    setSelectedIds([]);

    setModalOpen(false);
  };


  const handleCancelClick = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleNavigateHome = () => {
    setOpenDialog(false);
    navigate("/"); 
  };

  const handleBuscar = async () => {
    if (selectedRadio === "enviar") {
      try {
        const data = {
          numExpediente: searchData.numExpediente,
          year: searchData.year,
        };
        const result = await fetchCarpetaActorDemandado(token, data);
        const resultArray = Array.isArray(result) ? result : [result];
         
        const expedientesConBusqueda = resultArray.map((expediente) => ({
          ...expediente,
          numExpediente: searchData.numExpediente,
          year: searchData.year,
        }));
  
        setExpedientes((prevExpedientes) => [
          ...prevExpedientes,
          ...expedientesConBusqueda,
        ]);
  
        const idsCarpeta = expedientesConBusqueda.map((expediente) => expediente.idCarpeta);
        setSelectedIds((prevIds) => {
          const updatedIds = [...prevIds, ...idsCarpeta];
          return updatedIds;
        });
  
      } catch (error) {
        console.error("Error al obtener los expedientes:", error);
      }
    } else if (selectedRadio === "devolver") {
      try {
        const result = await fetchCarpetaByExpedienteAndEstado(
          token,
          searchData.numExpediente,
          searchData.year,
          "ARCHIVO_JUDICIAL",
          null
        );

        const expedienteConBusqueda = {
          ...result,
          numExpediente: searchData.numExpediente,
          year: searchData.year,
        };

        setExpedientes((prevExpedientes) => [
          ...prevExpedientes,
          expedienteConBusqueda,
        ]);
  
        setSelectedIds((prevIds) => {
          const updatedIds = [...prevIds, result.idCarpeta];
          return updatedIds;
        });
  
      } catch (error) {
        console.error("Error al obtener la carpeta:", error);
        showSnackbar("El expediente no se encuentra en el archivo judicial, por favor verifique el número", error);
      }
    }
  };
  
  return (
    <Box sx={{ width: "100%" }}>
      <Grid className="box-sombra">
        <Paper sx={{ width: "100%", mb: 2, padding: 2 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
            <Box sx={{ display: "flex", gap: 2 }}>
              <Button variant="contained" className="button-save" onClick={handleSave}>
                Enviar
              </Button>
              <Button variant="contained" className="button-close" onClick={handleCancelClick}>
                Cancelar
              </Button>
            </Box>
            <Box flexBasis="300px">
              <SearchInput placeholder="Buscar..." tooltipTitle="Escriba para buscar" />
            </Box>
          </Box>

          <Divider sx={{ marginY: 2 }} />

          <Box mb={2}>
          <RadioGroup
              row
              value={selectedRadio}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                value="enviar"
                control={<Radio />}
                label="Enviar"
              />
              <FormControlLabel
                value="devolver"
                control={<Radio />}
                label="Devolver"
              />
            </RadioGroup>
          </Box>

          <Box mb={2} display="flex" alignItems="center" gap={2}>
            <TextField label="Número de expediente" variant="outlined" size="small" value={searchData.numExpediente} onChange={(e) => setSearchData({ ...searchData, numExpediente: e.target.value })} />
            <TextField label="Año" variant="outlined" size="small" value={searchData.year} onChange={(e) => setSearchData({ ...searchData, year: e.target.value })}/>
            <Button variant="contained" onClick={handleBuscar}>
              Buscar
            </Button>
          </Box>

          <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
            Lista a enviar:
          </Typography>
          <Typography variant="subtitle1" color="primary">
            Total seleccionados: {expedientes.length}
          </Typography>

          <TableContainer sx={{ maxHeight: 600, overflowY: "auto" }}>
            <Table stickyHeader sx={{ minWidth: 750 }}>
              <TableHead>
                <TableRow>
                  <TableCell className="table-header">No Expediente</TableCell>
                  <TableCell className="table-header">Actor</TableCell>
                  <TableCell className="table-header">Demandado</TableCell>
                  <TableCell className="table-header">Tipo de Juicio</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {expedientes.length > 0 ? (
                  expedientes.map((expediente) => (
                    <StyledTableRow key={expediente.id} hover>
                      <TableCell>{`${expediente.numExpediente}/${expediente.year}`}</TableCell>
                      <TableCell>{expediente.actor}</TableCell>
                      <TableCell>{expediente.demandado}</TableCell>
                      <TableCell>{expediente.tipoJuicio}</TableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      No hay registros disponibles.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        <ConfirmationModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          onConfirm={handleConfirm}
          title={selectedRadio === "enviar" ? "¿Desea enviar los expedientes al archivo judicial?" : "¿Desea devolver los expedientes?" }
          message={selectedRadio === "enviar" ? "Se enviarán a archivo judicial los expedientes seleccionados" : "Se devolveran los expedientes seleccionados" }
          confirmationButton={selectedRadio === "enviar" ? "Sí, enviar" : "Sí, devolver" }
          closeButton="No, cancelar"
        />

        <ConfirmationModal
          open={openDialog}
          onClose={handleCloseDialog}
          onConfirm={handleNavigateHome}
          title="¿Seguro de que desea cancelar?"
          message="Se perderán los cambios"
          closeButton="No, cancelar"
          confirmationButton="Sí, salir"
        />
      </Grid>
    </Box>
  );
};

export default ArchivoJudicial;
