import { Box, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import RefreshButton from "../../../../components/Button/RefreshButton";
import keycloakConf from "../../../../api/keycloakConf";
import { getTurnados } from "../../../../shared/services/MovimientoService";
import { useItem } from "../../../../components/ItemProvider/ItemContext";

const Historico = () => {
    const { getToken } = useContext(keycloakConf);
    const [historico, setHistorico] = useState([]);
    const [loading, setLoading] = useState(true);
    const { item } = useItem();
    useEffect(() => {
        loadHistorico();
    }, []);

    const loadHistorico = async () => {
        setLoading(true);
        const token = getToken();

        try {
            const historicoData = await getTurnados(token, item.carpetaId);
            setHistorico(historicoData);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));
    return (

        <Box sx={{ width: '100%' }}>
            <Box mb={2} mt={2} display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" alignItems="center">
                    <RefreshButton  onClick={loadHistorico} />
                </Box>
            </Box>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer sx={{ maxHeight: 600, overflowY: 'auto' }}>
                    <Table stickyHeader sx={{ minWidth: 750 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell className='table-header'>Origen</TableCell>
                                <TableCell className='table-header'>Destino</TableCell>
                                <TableCell className='table-header'>Fecha de envío</TableCell>
                                <TableCell className='table-header'>Fecha de recepción</TableCell>
                                <TableCell className='table-header'>Concepto de envío</TableCell>
                                <TableCell className='table-header'>Concepto de recepción</TableCell>
                                <TableCell className='table-header'>Días término</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {historico.map((historico) => (
                                <StyledTableRow hover>
                                    <TableCell>{historico.origen}</TableCell>
                                    <TableCell>{historico.destino}</TableCell>
                                    <TableCell>{historico.envio}</TableCell>
                                    <TableCell>{historico.recepcion}</TableCell>
                                    <TableCell>{historico.conceptoEnvio}</TableCell>
                                    <TableCell>{historico.conceptoRecepcion}</TableCell>
                                    <TableCell>{historico.duration}</TableCell>

                                </StyledTableRow>
                            ))}
                            {historico.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={6} align="center">
                                        No hay turnados.
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Box>
    );
}

export default Historico;