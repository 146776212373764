import axios from "../../api/axiosConfig";

export const findAll = async (token, page = 0, size = 25, sort = 'estado,nombre') => {
    try {
        const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/core/sedes`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                page,
                size,
                sort,
            },
        });

        return response.data;
    } catch (error) {
        console.error('Error al listar los registros:', error);
        throw error;
    }
};

export const create = async (token, data) => {
    try {
        const response = await axios.post(process.env.REACT_APP_TRIALS_API_URL + `/api/core/sedes`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return response.data;
    } catch (error) {
        console.error('Error al agregar el registro:', error);
        throw error;
    }
};


export const update = async (token, data) => {
    try {
        const response = await axios.put(process.env.REACT_APP_TRIALS_API_URL + `/api/core/sedes`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },

        });
        return response.data;
    } catch (error) {
        console.error('Error al actualizar el registro:', error);
        throw error;
    }
};

export const remove = async (token, id) => {
    try {
        const response = await axios.delete(process.env.REACT_APP_TRIALS_API_URL + `/api/core/sedes/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return response.data;
    } catch (error) {
        console.error('Error al eliminar el registro:', error);
        throw error;
    }
};

export const fetchById = async (token, id) => {
    try {
        const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/core/sedes/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error al obtener el registro:', error);
        throw error;
    }
};

export const fetchSedesDomicilio = async (token, page = 0, size = 25, sort = '') => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_TRIALS_API_URL}/api/core/sedes/domicilios`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: {
                page,
                size,
                sort, 
            },
        });

        return response.data;
    } catch (error) {
        console.error('Error al listar los Domicilios de las Sedes:', error.response?.status, error.message);
        throw error;
    }
};