import React from "react";
import {
    Grid,
    Box,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
} from "@mui/material";

const CamposExhorto = ({
    formData,
    handleTipoExhortoChange,
    handleChange,
    tiposExhortos,
    errors,
    touched,
    isDisabled
}) => (
    <Grid container spacing={2}>
        {/* Campo Tipo */}
        <Grid item xs={12}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Typography sx={{ minWidth: "150px" }}>Tipo</Typography>

                <FormControl fullWidth  disabled={isDisabled}>
                    <InputLabel>Tipo</InputLabel>
                    <Select
                        value={formData.tipoExhorto}
                        label="Tipo"
                        onChange={handleTipoExhortoChange}
                    >
                        {tiposExhortos.map((tipo) => (
                            <MenuItem
                                key={tipo}
                                value={tipo}
                                disabled={tipo !== "Exhorto"}
                            >
                                {tipo}
                            </MenuItem>
                        ))}
                    </Select>
                    <Typography
                        color="red"
                        variant="caption"
                        sx={{
                            display: errors.tipoExhorto && touched.tipoExhorto ? "block" : "none",
                        }}
                    >
                        *Campo requerido
                    </Typography>
                </FormControl>
              
            </Box>
        </Grid>

        {/* Campo Procedencia */}
        <Grid item xs={12}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Typography sx={{ minWidth: "150px" }}>Procedencia</Typography>
                <TextField
                    fullWidth
                    label="Procedencia"
                    name="procedencia"
                    value={formData.procedencia}
                    onChange={handleChange}
                    placeholder="Procedencia"
                    error={errors.procedencia && touched.procedencia}
                    helperText={
                        errors.procedencia && touched.procedencia ? "*Campo requerido" : ""
                    }
                    InputProps={{
                        style: {
                            color:
                                errors.procedencia && touched.procedencia
                                    ? "red"
                                    : "black",
                        },
                    }}
                />
            </Box>
        </Grid>

        {/* Campo Observaciones */}
        <Grid item xs={12}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Typography sx={{ minWidth: "150px" }}>Observaciones</Typography>
                <TextField
                    fullWidth
                    label="Observaciones"
                    name="observaciones"
                    value={formData.observaciones}
                    onChange={handleChange}
                    disabled={isDisabled}
                    placeholder="Observaciones"
                />
            </Box>
        </Grid>
    </Grid>
);

export default CamposExhorto;
