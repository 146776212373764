import axios from "../../api/axiosConfig";

export const fetchComentariosByPersonaDocumento = async (token, personaDocumentoId, page = 0, size = 20) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_TRIALS_API_URL}/api/workflow/comentariosasistentes/comentariopersona/${personaDocumentoId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page,
          size,
        },
      }
    );
    const sortedComentarios = response.data.content.sort((a, b) => a.id - b.id);

    return { ...response.data, content: sortedComentarios };
  } catch (error) {
    console.error("Error al obtener los comentarios de la persona:", error);
    throw error;
  }
};

export const createComentario = async (token, comentarioData) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_TRIALS_API_URL}/api/workflow/comentariosasistentes`,
        comentarioData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error al crear el comentario:", error);
      throw error;
    }
  };

export const updateComentario = async (token, comentarioId, comentarioData) => {
    try {
      const response = await axios.patch(`${process.env.REACT_APP_TRIALS_API_URL}/api/workflow/comentariosasistentes/${comentarioId}`,
        comentarioData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error al actualizar el comentario:", error);
      throw error;
    }
  };
  
