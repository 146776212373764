import React from "react";
import {
  TextField,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Typography,
} from "@mui/material";

const DetalleExhorto = ({ 
  formData, 
  setFormData, 
  general,
  isConsulta
}) => {
const handleChange = (e) => {
  const { name, value } = e.target;
  setFormData((prevData) => ({
    ...prevData,
    [name]: value,
  }));
};

  return (
<Grid container spacing={2} sx={{ marginTop: 1 }}>
        <Grid item xs={12} sm={6} md={4} sx={{ mb: 2 }} key={"Fecha de presentación"}>
          <Typography sx={{ fontWeight: "bold", mb: 1 }}>
            {"Fecha de presentación"}:
          </Typography>
          <Typography>{general.fechaPresentacion || ""}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} sx={{ mb: 2 }} key={"Ubicación"}>
          <Typography sx={{ fontWeight: "bold", mb: 1 }}>
            {"Ubicación"}:
          </Typography>
          <Typography>{formData.ubicacion || ""}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl
            fullWidth
            variant="outlined"
          >
            <InputLabel id="determinacion-label">Determinación</InputLabel>
            <Select
              labelId="determinacion-label"
              name="determinacion"
              value={formData.determinacion || "PRESENTACION"}
              onChange={handleChange}
              label="Determinación"
              disabled={isConsulta}
            >
                <MenuItem key={"PRESENTACION"} value={"PRESENTACION"}>{"Presentación"}</MenuItem>
                <MenuItem key={"ADMITIDA"} value={"ADMITIDA"}>{"Admitida"}</MenuItem>
                <MenuItem key={"ADMITIDA_CON_PREVENCION"} value={"ADMITIDA_CON_PREVENCION"}>{"Admitida con prevención"}</MenuItem>
                <MenuItem key={"DESECHADA"} value={"DESECHADA"}>{"Desechada"}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4} sx={{ mb: 2 }} key={"fechaAdmision"}>
          <Typography sx={{ fontWeight: "bold", mb: 1 }}>
            {"Fecha admisión"}:
          </Typography>
          <Typography>{formData.fechaAdmision || "-"}</Typography>
        </Grid>
        {formData.sentencia?
          <Grid item xs={12} sm={6} md={4}>
            <Typography sx={{ fontWeight: 'bold', mb: 1 }}>
                {"Sentencia"}:
            </Typography>
            <Typography>{general.sentencia}</Typography>
          </Grid>
          :<></>
        }
        {formData.fechaDesechado? 
          <Grid item xs={12} sm={6} md={4} sx={{ mb: 2 }} key={"Fecha desechada"}>
            <Typography sx={{ fontWeight: "bold", mb: 1 }}>
              {"Fecha de desechada"}:
            </Typography>
            <Typography>{formData.fechaDesechado || ""}</Typography>
          </Grid>
          :
          <></>
        }
      <Grid item xs={12} sm={12} md={4}>
        <TextField
          name="asunto"
          label="Asunto"
          variant="outlined"
          fullWidth
          value={formData.asunto || ""}
          onChange={handleChange}
          disabled={isConsulta}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={8}>
        <TextField
          name="observaciones"
          label="Observaciones"
          variant="outlined"
          fullWidth
          value={formData.observaciones || ""}
          onChange={handleChange}
          disabled={isConsulta}
        />
      </Grid>
    </Grid>
  );
};

export default DetalleExhorto;
