import axios from 'axios';

export const getAllConceptos = async (token, carpetaId) => {
    try {
        const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/core/conceptos/carpetaId/${carpetaId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return response.data;
    } catch (error) {
        console.error('Error al listar los conceptos:', error);
        throw error;
    }
};
