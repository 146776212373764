import React, { useEffect, useState } from 'react';
import {
    Autocomplete,
    Box,
    Button,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    TextField,
    Checkbox
} from '@mui/material';
import { create, fetchById, update } from '../BloqueService';
import { validateFields } from '../../../components/Alert/ValidationRequired/validationRequired';
import { useNavigate } from 'react-router-dom';
import { useToastAlert } from '../../../components/Alert/ToastAlertSave/ToastAlertSave';
import { useToastAlertUpdate } from '../../../components/Alert/ToastAlertUpdate/ToastAlertUpdate';
import { useToastAlertValidation } from '../../../components/Alert/ToastAlertValidation/ToastAlertValidation';
import AccionesOnlyButtons from '../../../components/Acciones/AccionesOnlyButtons';
import Breadcrumbs from '../../../components/Breadcrumb/breadcrumbs';

const FormBloque = ({token, onAdd, onEdit}) => {
    const navigate = useNavigate();
    const { showSnackbar, showConfirmDialog } = useToastAlert(); 
    const { showSnackbar: showUpdateSnackbar } = useToastAlertUpdate();
    const { showSnackbar: showAlertValidate } = useToastAlertValidation();
    const [formData, setFormData] = useState({
        id: '',
        horaInicial: '',
        horaFinal: '',
        estado: ''
    });
    const [errorMessages, setErrorMessages] = useState({});

    useEffect(() => {
        if (onEdit) {
            getById();
        } else {
            resetForm();
            setFormData((prevData) => ({
                ...prevData,
                estado: 'ACTIVE',
            }))
        }
    }, [onEdit, token]);

    const resetForm = () => {
        setFormData({
            id: '',
            horaInicial: '08:00',
            horaFinal: '08:00',
            estado: 'ACTIVE'
        });
        setErrorMessages({});
    };

    const generateTimeOptions = (startHour, endHour) => {
        let options = [];
        let startTime = new Date(`2024-01-01T${startHour}:00`);
        let endTime = new Date(`2024-01-01T${endHour}:00`);

        while (startTime <= endTime) {
            let time = startTime.toTimeString().slice(0, 5); // Formato HH:mm
            options.push(time);
            startTime.setMinutes(startTime.getMinutes() + 15); // Intervalos de 15 minutos
        }

        return options;
    };

    const convertTo12HourFormat = (time24) => {
        const [hours, minutes] = time24.split(':');
        const hoursInt = parseInt(hours, 10);
        const isPM = hoursInt >= 12;
        const hours12 = hoursInt % 12 || 12; 
        const amPm = isPM ? 'PM' : 'AM';
        return `${hours12}:${minutes} ${amPm}`;
    };

    const getById = function (id) {
        const loadbyId = async () => {
            try {
                const bloque = await fetchById(token, onEdit.id);
                // Formatea las horas a HH:mm
                const formattedBloque = {
                    ...bloque,
                    horaInicial: bloque.horaInicial.slice(0, 5), // toma solo HH:mm
                    horaFinal: bloque.horaFinal.slice(0, 5)      // toma solo HH:mm
                };
                console.log(formattedBloque);
                setFormData(formattedBloque);
            } catch (error) {
                console.error('Error al obtener el bloque:', error);
            }
        };
        loadbyId();
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        setErrorMessages({
            ...errorMessages,
            [name]: '',
        });
    };

    const handleSubmit = async (e) => {
        const rules = {
            horaInicial: { required: true },
            horaFinal: { required: true },
            estado: { required: true }
        };

        const errors = validateFields(formData, rules, showAlertValidate);

        // Validar que las horas estén dentro del rango permitido
        const validTimeRange = generateTimeOptions('08:00', '18:00'); // Cambié el rango final a 18:00
        if (!validTimeRange.includes(formData.horaInicial)) {
            errors.horaInicial = 'La hora inicial debe estar entre 8:00 am y 6:00 pm';
        }
        if (!validTimeRange.includes(formData.horaFinal)) {
            errors.horaFinal = 'La hora final debe estar entre 8:00 am y 6:00 pm';
        }

        if (formData.horaFinal <= formData.horaInicial) {
            errors.horaFinal = 'La hora final debe ser mayor que la hora inicial';
        }

        if (Object.keys(errors).length > 0) {
            setErrorMessages(errors);
            return;
        }

        // Procesa el envío
        let formDataToSubmit = { ...formData, audit: {} };

        try {
            if (formData.id) {
                await update(token, formDataToSubmit);
                showUpdateSnackbar('Registro actualizado con éxito', 'success');
            } else {
                await create(token, formDataToSubmit);
                showSnackbar('Registro guardado con éxito', 'success');
            }

            if (onAdd) {
                onAdd();
            }

            resetForm();
        } catch (error) {
            console.error('Error al guardar el registro:', error);
            showSnackbar('¡Algo salió mal!', 'error');
        }
    };

    const handleCancel = () => {
        navigate('/api/core/bloques');
    };

    return (
        <form>
            <AccionesOnlyButtons accion1={handleSubmit} accion2={handleCancel} />
            <Grid className="box-sombra">
                <Breadcrumbs istitulo="true" className="box-sombra" />
                <Grid container spacing={2} sx={{ marginTop: 1 }}>
                    <Grid item xs={12} sm={6} md={4}>
                        <Autocomplete
                            name="horaInicial"
                            value={formData.horaInicial}
                            onChange={(e, newValue) => handleChange({ target: { name: 'horaInicial', value: newValue } })}
                            options={generateTimeOptions('08:00', '18:00')} // Cambié el rango final a 18:00
                            renderInput={(params) => <TextField {...params} label="Hora Inicial" />}
                            getOptionLabel={(option) => convertTo12HourFormat(option)}
                            disableClearable
                        />
                        {errorMessages.horaInicial && <FormHelperText error>{errorMessages.horaInicial}</FormHelperText>}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Autocomplete
                            name="horaFinal"
                            value={formData.horaFinal}
                            onChange={(e, newValue) => handleChange({ target: { name: 'horaFinal', value: newValue } })}
                            options={generateTimeOptions('08:00', '18:00')} // Cambié el rango final a 18:00
                            renderInput={(params) => <TextField {...params} label="Hora Final" />}
                            getOptionLabel={(option) => convertTo12HourFormat(option)}
                            disableClearable
                        />
                        {errorMessages.horaFinal && <FormHelperText error>{errorMessages.horaFinal}</FormHelperText>}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <FormControl fullWidth variant="outlined">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.estado === 'ACTIVE'}
                                        onChange={(event) => handleChange({
                                            target: {
                                                name: 'estado',
                                                value: event.target.checked ? 'ACTIVE' : 'INACTIVE'
                                            }
                                        })}
                                        color="primary"
                                    />
                                }
                                label="Activar bloque"
                            />
                            {errorMessages.estado && <FormHelperText>{errorMessages.estado}</FormHelperText>}
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

export default FormBloque;
