import React, { useState, useEffect, useContext } from "react";
import { Box, Grid, TextField, Typography, Autocomplete } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useToastAlert } from "../../../components/Alert/ToastAlertSave/ToastAlertSave";
import { useToastAlertValidation } from "../../../components/Alert/ToastAlertValidation/ToastAlertValidation.js";
import SweetAlertSave from "../../../components/Alert/SweetAlertSave/SweetAlertSave.js";
import KeycloakConf from "../../../api/keycloakConf.js";
import { getSello } from "../../Demanda/DemandaService.js";
import ConfirmationModal from "../../../components/Modal/ConfirmationModal.js";
import Acciones from "../../../components/Acciones/Acciones.js";
import DigitalizarDemanda from "../../RegistrosAntiguos/DigitalizarDemanda";
import { fetchInstitucionesAutoComplete } from "../../Instituciones/institucionService";
import { fetchJuzgadosAutocomplete } from "../../../shared/services/JuzgadosService";
import { createExhortoSalida } from "../../../shared/services/DocumentoService";
import { validateFields } from "../../../components/Alert/ValidationRequired/validationRequired";


function FormularioExhortoSalida() {
  const location = useLocation();
  const { item } = location.state || {}; //item es la info de bandejaAsignado y General es la info de tabGeneral
  const { getToken } = useContext(KeycloakConf);
  const { showSnackbar, showConfirmDialog } = useToastAlert();
  const { showSnackbar: showAlertValidate } = useToastAlertValidation();
  const navigate = useNavigate();
  const [destino, setDestino] = useState({});
  const [loading, setLoading] = useState(true);

  const [formData, setFormData] = useState({
    exhorto: "",
    numeroExpediente: "",
    year: "",
    juzgado: "",
    destino: "",
    tipoDestino: "",
    fechaEntrega: "",
    fechaDevolucion: "",
    tramite: "",
    observaciones: "",
    archivo: null,
  });

  const [errors, setErrors] = useState({
    destino: false,
    fechaEntrega: false,
    tramite: false,
  });

  const [modalOpen, setModalOpen] = useState(false);
  const [confirmClose, setConfirmClose] = useState(false);

  const [fechaHoraActual, setFechaHoraActual] = useState({
    fecha: "",
    hora: "",
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      setFechaHoraActual({
        fecha: now.toLocaleDateString(),
        hora: now.toLocaleTimeString(),
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    mapInfoExpediente();
    loadDestino();
  }, []);

  const loadDestino = async () => {
    const token = getToken();
    try {
      const [juzgados, instituciones] = await Promise.all([
        fetchJuzgadosAutocomplete(token),
        fetchInstitucionesAutoComplete(token, 0, 20),
      ]);

      const juzgadosConTipo = juzgados.map((juzgado) => ({
        ...juzgado,
        tipo: "juzgado",
      }));
  
      const institucionesConTipo = instituciones.content.map((institucion) => ({
        ...institucion,
        tipo: "institucion",
      }));

      const destino = [...juzgadosConTipo, ...institucionesConTipo];
      setDestino(destino);
      setLoading(false);
    } catch (error) {
      console.error("Error cargando Juzgados e Instituciones:", error);
    }
  };

  const mapInfoExpediente = async () => {
    const [expediente, year] = item.expediente.split("/");
    setFormData((prev) => ({
      ...prev,
      numeroExpediente: expediente,
      year: year,
      juzgado: item.juzgadoNombre,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (errors[name]) {
      setErrors((prev) => ({
        ...prev,
        [name]: value ? false : true,
      }));
    }
  };

  const handleSaveButton = async (e) => {
    e.preventDefault();
    let rulesExpediente = {
      destino: { required: true },
      fechaEntrega: { required: true },
      tramite: { required: true }
    }

    const errores = validateFields(formData, rulesExpediente, showAlertValidate);
    setErrors(errores);

    if (Object.keys(errores).length === 0) {
      setModalOpen(true);
    }
  };

  //Guardar exhorto de salida
  const handleConfirm = async () => {
    try {
      const token = getToken();
      const nuevoDocumento = {
        carpetaId: item.carpetaId,
        destino: formData.destino,
        tramite: formData.tramite,
        observaciones: formData.observaciones,
        fechaEntrega: formData.fechaEntrega,
        fechaDevolucion: formData.fechaDevolucion,
      };

      const response = await createExhortoSalida(token, nuevoDocumento, formData.archivo);

      showSnackbar("Exhorto de salida generado con éxito", "success");

      navigate("/api/bandeja/expediente/", { state: { item, tabnav:1 } });
    } catch (error) {
      if (error.response && error.response.data[0]) {
        showSnackbar(error.response.data[0].message, "error");
      } else {
        showSnackbar("¡Algo salió mal!", "error");
      }
    } finally {
      setModalOpen(false);
    }
  };

  const handleSubmit = async () => {
    SweetAlertSave.showSuccess();
  };

  const handleClose = async () => {
    //Campos a revisar si tienen algun campo lleno muestra modal
    const fieldsToCheck = [
      formData.exhorto,
      formData.destino,
      formData.fechaEntrega,
      formData.fechaDevolucion,
      formData.tramite,
      formData.observaciones,
      formData.archivo,
    ];
    //Revisa si tiene algun campo algo diferente de null
    const hasAnyField = fieldsToCheck.some((field) => field !== "" && field !== null);
    if (hasAnyField) {
      setConfirmClose(true);
    } else {
      navigate("/api/bandeja/expediente/", { state: { item, tabnav:1 } });
    }
  };

  const handleFileSelect = (selectedFile) => {
    setFormData((prev) => ({
      ...prev,
      archivo: selectedFile,
    }));
  };

  return (
    <form onSubmit={handleSubmit}>
    {loading ? <p>Cargando ... </p> : (
      <Grid container>
        <Acciones accion1={handleSaveButton} accion2={handleClose} />

        <Grid>
          <Box sx={{ p: 3, mx: "auto" }}>
            <Grid container spacing={8}>
              <Grid item xs={12} sm={6} md={4} sx={{ mb: 2 }} key={"Numero de expediente"} >
                <Typography sx={{ fontWeight: "bold", mb: 1 }}>
                  {"Número de expediente:"}
                </Typography>
                <Typography>{formData.numeroExpediente || ""}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} sx={{ mb: 2 }} key={"Anio"}>
                <Typography sx={{ fontWeight: "bold", mb: 1 }}>
                  {"Año:"}
                </Typography>
                <Typography>{formData.year || ""}</Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4} sx={{ mb: 2 }} key={"Juzgado"}>
                <Typography sx={{ fontWeight: "bold", mb: 1 }}>
                  {"Juzgado:"}
                </Typography>
                <Typography>{formData.juzgado || ""}</Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={8}>
              <Autocomplete
                options={[...destino].filter(
                  (option, index, self) =>
                    index === self.findIndex(
                      (o) => o.id === option.id && o.tipo === option.tipo
                    )
                )}
                getOptionLabel={(option) => `${option.nombre} (${option.tipo})`}
                value={
                  destino.find(
                    (dest) =>
                      dest.nombre === formData.destino &&
                      dest.tipo === formData.tipoDestino
                  ) || null
                }
                onChange={(event, newValue) => {
                  handleChange({
                    target: {
                      name: "destino",
                      value: newValue ? newValue.nombre : "",
                    },
                  });
                  handleChange({
                    target: {
                      name: "tipoDestino",
                      value: newValue ? newValue.tipo : "",
                    },
                  });
                }}
                filterOptions={(options, state) => {
                  const filtered = options.filter((option) =>
                    option.nombre.toLowerCase().includes(state.inputValue.toLowerCase())
                  );
                  return filtered;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Seleccione un destino"
                    variant="outlined"
                    error={!!errors.destino}
                    helperText={errors.destino}
                  />
                )}
              />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  name="fechaEntrega"
                  label="Fecha de entrega"
                  type="date"
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formData.fechaEntrega}
                  onChange={handleChange}
                  error={!!errors.fechaEntrega}
                  helperText={errors.fechaEntrega}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={8}>
                <TextField
                  name="tramite"
                  label="Trámite"
                  variant="outlined"
                  fullWidth
                  value={formData.tramite || ""}
                  onChange={handleChange}
                  error={!!errors.tramite}
                  helperText={errors.tramite}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <TextField
                  name="fechaDevolucion"
                  label="Fecha de devolución"
                  type="date"
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={formData.fechaDevolucion}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={8}>
                <TextField
                  name="observaciones"
                  label="Observaciones"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={3}
                  value={formData.observaciones || ""}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Typography sx={{ fontWeight: "bold", mb: 1 }}>
                  {"Archivo:"}
                </Typography>
                <DigitalizarDemanda onFileSelect={handleFileSelect} />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    )}
      <ConfirmationModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={handleConfirm}
        title="¿Generar exhorto de salida?"
        message="Se generará un nuevo exhorto de salida en el sistema."
        confirmationButton="Sí, generar"
        closeButton="No, cancelar"
      />

      <ConfirmationModal
        open={confirmClose}
        onClose={() => setConfirmClose(false)}
        onConfirm={() => navigate("/api/bandeja/expediente/", { state: { item, tabnav:1 } })}
        title="¿Salir sin generar exhorto de salida?"
        message="Se perderan los datos del exhorto de salida"
        confirmationButton="Sí, salir"
        closeButton="No, cancelar"
      />    
    
    </form>
  );
}

export default FormularioExhortoSalida;
