import axios from "../../api/axiosConfig";

export const createPeriodos = async (token, record) => {
	try {
		const response = await axios.post(`${process.env.REACT_APP_TRIALS_API_URL}/api/core/evento/periodos`,
			record,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error('Error al crear el periodo:', error);
		throw error;
	}
};

export const getEventosGenerales = async (token, page = 0, size = 10) => {
	try {
		const response = await axios.get(`${process.env.REACT_APP_TRIALS_API_URL}/api/core/evento/generales`, {
			params: {
				page,
				size,
			},
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		return response.data;
	} catch (error) {
		console.error("Error al obtener eventos generales:", error);
		throw error;
	}
};

export const getEventosOficialiaComun = async (token, page = 0, size = 10) => {
	try {
		const response = await axios.get(`${process.env.REACT_APP_TRIALS_API_URL}/api/core/evento/oficialiacomun`, {
			params: {
				page,
				size,
			},
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		return response.data;
	} catch (error) {
		console.error("Error al obtener eventos de oficialia común:", error);
		throw error;
	}
};

export const deleteEventoById = async (token, id) => {
	try {
		const response = await axios.delete(`${process.env.REACT_APP_TRIALS_API_URL}/api/core/evento/${id}`,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response.status;
	} catch (error) {
		console.error("Error al eliminar el evento:", error);
		throw error;
	}
};

export const editarEventoPeriodo = async (token, record) => {
	try {
		const response = await axios.put(`${process.env.REACT_APP_TRIALS_API_URL}/api/core/evento/editarperiodo`,
			record,
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error("Error al editar el periodo del evento:", error);
		throw error;
	}
};