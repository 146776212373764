import React, { createContext, useContext, useState } from 'react';

const NumAcuerdoContext = createContext();

export const NumAcuerdoProvider = ({ children }) => {
  const [numAcuerdo, setNumAcuerdo] = useState(null);

  const setNumAcuerdoValue = (value) => {
    setNumAcuerdo(value);
  };

  return (
    <NumAcuerdoContext.Provider value={{ numAcuerdo, setNumAcuerdoValue }}>
      {children}
    </NumAcuerdoContext.Provider>
  );
};

export const useNumAcuerdoContext = () => {
  return useContext(NumAcuerdoContext);
};
