import axios from "../../api/axiosConfig";

export const registrarAsistencia = async (token, asistenciaAudienciaRecord, file) => {
  try {
    const formData = new FormData();

    formData.append(
      "registrarAsistenciaAudienciaRecord",
      JSON.stringify(asistenciaAudienciaRecord)
    );

    formData.append("file", file);

    const response = await axios.post(
      `${process.env.REACT_APP_TRIALS_API_URL}/api/workflow/audienciaasistencia/registrarasistencia`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error al registrar asistencia:", error);
    throw error;
  }
};
