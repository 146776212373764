import axios from "axios";

export const fetchIdentificaciones = async (token) => {
    try {
        const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + '/api/core/documentoidentificacion', {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error al obtener los docuemntos de identificaión:', error);
    }
}

export default fetchIdentificaciones;