import { Box, Button, Checkbox, Chip, Divider, FormControl, FormControlLabel, FormHelperText, Grid, IconButton, MenuItem, Paper, Radio, RadioGroup, Select, Tab, Tabs, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useState, useRef } from "react";
import ActorDemandadoForm from "./ActorDemandadoForm";
import keycloakConf from "../../../../api/keycloakConf";
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useLocation, useNavigate } from 'react-router-dom';
import { validateFields } from '../../../../components/Alert/ValidationRequired/validationRequired';
import { useToastAlert } from '../../../../components/Alert/ToastAlertSave/ToastAlertSave';
import { useToastAlertValidation } from '../../../../components/Alert/ToastAlertValidation/ToastAlertValidation';
import { validateCurp } from "../../../../components/CURP/validationCurp";
import { isValidEmail, onlyAlphaNumeric, onlyNames, onlyNumbers } from "../../../../components/Caracteres/caracteresEspeciales";
import ConfirmationModal from "../../../../components/Modal/ConfirmationModal";
import { addDocumento } from "../../DemandaService";
import { getAllTipoJuicioDemandasHijos } from "../../../TipoJuicio/TipoJuicioService";
import { getSello } from "../../DemandaService";
import Acciones from "../../../../components/Acciones/Acciones";
import Glosario from "../../../../assets/Glosario";

export default function FormDemandaFamiliar({ data, handleEditDemanda }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [tabvalue, setTabValue] = useState(0);
  const [domicilioGeneralSeleccionado, setGeneralDomicilioSeleccionado] = useState('');
  const { getToken } = useContext(keycloakConf);
  const [juzgados, setJuzgados] = useState([]);
  const [juzgado, setjuzgado] = useState("");
  const { juicioId } = location.state || {};
  const [errors, setErrors] = useState({});
  const { showSnackbar, showConfirmDialog } = useToastAlert();
  const { showSnackbar: showAlertValidate } = useToastAlertValidation();
  const anexoRefs = useRef([]);
  const [anexosErrors, setAnexosErrors] = useState([]);
  const [motivoEdicion, setMotivoEdicion] = useState("");

  useEffect(() => {
    loadJuzgados();
  }, []);

  const loadJuzgados = async () => {
    try {
      const tiposJuiciosTemp = await getAllTipoJuicioDemandasHijos(getToken(), juicioId || data.tipoJuicioId);
      const tiposJuicios = tiposJuiciosTemp.filter(tipoJuicio => tipoJuicio.id !== juicioId);

      setJuzgados(tiposJuicios || []);
    } catch (error) {
      console.error("Error al obtener los Juzgados", error);
    }
  };


  const fieldsMap = {
    'actor.nombre': onlyNames,
    'actor.apellidoPaterno': onlyNames,
    'actor.apellidoMaterno': onlyNames,
    'actor.curp': onlyAlphaNumeric,
    'actpr.ine': onlyAlphaNumeric,
    'actor.celular': onlyNumbers,
    'demandado.nombre': onlyNames,
    'demandado.apellidoPaterno': onlyNames,
    'demandado.apellidoMaterno': onlyNames,
    'demandado.curp': onlyAlphaNumeric,
    'demandado.ine': onlyAlphaNumeric,
    'demandado.celular': onlyNumbers,
    'general.nombreAbogado': onlyNames,
    'general.cedulaAbogado': onlyAlphaNumeric,

  };

  const [nuevoDocumento, setNuevoDocumento] = useState({
    actor: {
      nombre: data?.actor.nombre || "",
      apellidoPaterno: data?.actor.apellidoPaterno || "",
      apellidoMaterno: data?.actor.apellidoMaterno || "",
      curp: data?.actor.curp || "",
      ine: data?.actor.ine || "",
      celular: data?.actor.celular || "",
      domicilio: data?.actor.domicilio || "",
      correoElectronico: data?.actor.correoElectronico || "",
      tipoParte: 1
    },
    demandado: {
      nombre: data?.demandado.nombre || "",
      apellidoPaterno: data?.demandado.apellidoPaterno || "",
      apellidoMaterno: data?.demandado.apellidoMaterno || "",
      curp: data?.demandado.curp || "",
      ine: data?.demandado.ine || "",
      domicilio: data?.demandado.domicilio || "",
      tipoParte: 2,
    },
    general: {
      domicilio: data?.general.domicilio || "",
      tieneAbogado: data?.general.tieneAbogado !== undefined ? data.general.tieneAbogado : 1,
      nombreAbogado: data?.general.nombreAbogado || "",
      cedulaAbogado: data?.general.cedulaAbogado || "",
      correoAbogado: data?.general.correoAbogado || "",
      tiposJuicios: data?.general.tiposJuicios || [],
      domicilioAcreedor: data?.general?.domicilioAcreedor || ""
    },
    anexos: data?.anexos || [],
    tipoJuicioId: juicioId
  });



  const validationRules = {
    actor: { // Tab "Actor"
      'actor.nombre': { required: true },
      'actor.apellidoPaterno': { required: true },
      'actor.curp': { validate: validateCurp },
      'actor.celular': { required: true },
      'actor.domicilio': { required: true },
      'actor.correoElectronico': { required: true, validate: isValidEmail }
    },
    demandado: { // Tab "Demandado"
      'demandado.nombre': { required: true },
      'demandado.apellidoPaterno': { required: true },
      'demandado.curp': { validate: validateCurp },
      'demandado.domicilio': { required: nuevoDocumento.general.tiposJuicios.some(item => item.nombre == 'Familiar Oralidad (Divorcio Incausado Unilateral)') }
    },
    general: { // Tab "General"
      'general.domicilio': { required: true },
      'general.cedulaAbogado': { required: nuevoDocumento.general.tieneAbogado },
      'general.correoAbogado': { required: nuevoDocumento.general.tieneAbogado, validate: isValidEmail },
      'general.domicilioAcreedor': { required: nuevoDocumento.general.tiposJuicios.some(item => item.nombre == 'Familiar Oralidad (Alimentos)') }
    }
  };

  const validacionCampos = async () => {
    const tabs = {
      "actor": 0,
      "demandado": 1,
      "general": 2
    };

    // Obtener las reglas de validación para todas las secciones
    const allValidationRules = {
      ...validationRules.actor,
      ...validationRules.demandado,
      ...validationRules.general
    };

    // Validar todos los campos con las reglas combinadas
    let validationErrors = validateFields(nuevoDocumento, allValidationRules, showAlertValidate);
    if (nuevoDocumento.general.tiposJuicios.length === 0) {
      validationErrors.tipoJucios = "Es necesario seleccionar al menos un tipo de juicio";
    }

    // Cambio de tab y seteo de errores.
    if (Object.keys(validationErrors).length > 0) {
      let inputName = Object.keys(validationErrors)[0];
      let [tab, campo] = inputName.split(".");

      if (campo === "cedulaAbogado" || campo === "correoAbogado") {
        tab = "actor";
      }

      if (validationErrors.tipoJucios) { tab = "general" }


      handleTabChange(this, tabs[tab]); // Cambiar a la tab correspondiente

      setErrors(validationErrors);
      return false;
    }

    setErrors({});
    return true;
  };



  const handleButtonSave = async () => {
    const token = await getToken();

    try {
      let documento = await addDocumento(token, nuevoDocumento);

      showSnackbar('Demanda generada con éxito', 'success');

      const sello = await getSello(token, documento.id);
      var blob = new Blob([sello], { type: 'application/pdf' });
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.target = 'sello.pdf';
      link.click();
      navigate('/api/workflow/demanda');
    } catch (error) {
      showSnackbar(error.response.data[0].message, 'error');
    }

    setModalOpen(false);

  }

  const handleClose = () => {
    if (data) {
      navigate('/api/bandeja/entrada');
    } else {
      navigate('/api/workflow/demanda');
    }

  }


  const handleTabChange = (event, newValue) => {

    setTabValue(newValue);

  };

  const handleConfirmSave = async (e) => {
    e.preventDefault();
    const validacion = await validacionCampos();
    if (validacion) {
      const newAnexosErrors = nuevoDocumento.anexos.map((anexo, index) => anexo.length < 3 ? index + 1 : "");
      setAnexosErrors(newAnexosErrors);

      const errors = newAnexosErrors.filter((error) => error !== "");
      if (errors.length === 1) {
        const errorMessage = `El anexo ${errors.join(", ")} debe tener al menos 3 caracteres.`;
        showSnackbar(errorMessage, "error");
        return;
      } else if (errors.length > 0) {
        const errorMessage = `Los anexos ${errors.join(", ")} deben tener al menos 3 caracteres.`;
        showSnackbar(errorMessage, "error");
        return;
      }

      setModalOpen(true);
    }

  }

  const formatPhoneCelNumbers = (value) => {
    const numericValue = value.replace(/\D/g, '');

    if (numericValue.length === 0) {
      return '';
    } else if (numericValue.length <= 3) {
      return `(${numericValue}`;
    } else if (numericValue.length <= 6) {
      return `(${numericValue.slice(0, 3)}) ${numericValue.slice(3)}`;
    } else {
      return `(${numericValue.slice(0, 3)}) ${numericValue.slice(3, 6)} ${numericValue.slice(6, 10)}`;
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    const keys = name.split('.');

    if ((name === "demandado.domicilio" || name === "actor.domicilio") && domicilioGeneralSeleccionado) {

      setNuevoDocumento(prevState => ({
        ...prevState,
        general: {
          ...prevState.general,
          domicilio: domicilioGeneralSeleccionado === '1' ? value : domicilioGeneralSeleccionado === '2' ? value : domicilioGeneralSeleccionado === '3' ? "" : ""
        }
      }));

    }


    setNuevoDocumento(prevState => {
      let updatedState = { ...prevState };

      let current = updatedState;
      for (let i = 0; i < keys.length - 1; i++) {
        current = current[keys[i]];
      }

      // Validación especial para el campo 'telefono'
      let formattedValue = value;
      if (name === 'actor.celular') {


        // Si el campo es 'telefono', se formatea el valor
        formattedValue = formatPhoneCelNumbers(value);
        const numericValue = value.replace(/\D/g, '');
        current[keys[keys.length - 1]] = numericValue; // Guardamos el valor numérico


        event.target.value = formattedValue; // Mostramos el valor formateado en el input

      } else {
        // Si no es 'telefono', simplemente guardamos el valor como está
        const caracterValue = (fieldsMap[name] || ((val) => val))(value);
        current[keys[keys.length - 1]] = caracterValue;
      }

      const updatedErrors = validateFields(updatedState, validationRules, showAlertValidate);

      setErrors(updatedErrors);

      return updatedState;
    }
    );
  };

  const handleChangeDomicilioGeneral = (e) => {
    let value = e.target.value;

    setGeneralDomicilioSeleccionado(value);
    setNuevoDocumento(prevState => ({
      ...prevState,
      general: {
        ...prevState.general,
        domicilio: value === '1' ? prevState.actor.domicilio : value === '2' ? prevState.demandado.domicilio : value === '3' ? "" : ""
      }
    }));

  }

  const handleJuzgadoChange = (e) => {
    let tiposJuiciosSelected = e.target.value;


    if (tiposJuiciosSelected) {

      let tipoJuicioObject = juzgados.find(element => element.id === tiposJuiciosSelected);


      let contieneUnilateral = false;
      let contieneBilateral = false;

      nuevoDocumento.general.tiposJuicios.forEach(element => {
        if (element.nombre === "Familiar Oralidad (Divorcio Incausado Unilateral)") {
          contieneUnilateral = true;
        }

        if (element.nombre === "Familiar Oralidad (Divorcio Incausado Bilateral)") {
          contieneBilateral = true;
        }
      });

      if ((contieneUnilateral && tipoJuicioObject.nombre === "Familiar Oralidad (Divorcio Incausado Bilateral)")
        || (contieneBilateral && tipoJuicioObject.nombre === "Familiar Oralidad (Divorcio Incausado Unilateral)")) {
        showSnackbar('No puede agregar dos tipos de juicios de divorcio Incausado', 'error');
        return;
      }


      setjuzgado(tiposJuiciosSelected);

      let existeJuzgado = nuevoDocumento.general.tiposJuicios.find(element => element.id === tiposJuiciosSelected);

      if (!existeJuzgado) {
        let juiciosTemp = nuevoDocumento.general.tiposJuicios;

        juiciosTemp.push(juzgados.filter(element => element.id === tiposJuiciosSelected)[0]);

        setNuevoDocumento(prevState => ({
          ...prevState,
          general: {
            ...prevState.general,
            tiposJuicios: juiciosTemp
          }
        }));
      }
    }

  }

  const handleDeleteTiposJuiciosGenerales = (juzgado) => {
    let tiposJuiciosSelected = juzgado;
    let juiciosTemp = nuevoDocumento.general.tiposJuicios.filter(element => element.id != tiposJuiciosSelected.id)

    setNuevoDocumento(prevState => ({
      ...prevState,
      general: {
        ...prevState.general,
        tiposJuicios: juiciosTemp
      }
    }));
  }

  const handleAddAnexo = () => {
    setNuevoDocumento(prevState => ({
      ...prevState,
      anexos: [...prevState.anexos, '']
    }));

    setTimeout(() => {
      const lastAnexoRef = anexoRefs.current[nuevoDocumento.anexos.length];
      if (lastAnexoRef) lastAnexoRef.focus();
    }, 0);
  }

  const handleAnexoChange = (index, value) => {
    const newValue = value
    const newAnexo = nuevoDocumento.anexos;

    newAnexo[index] = newValue;

    setNuevoDocumento(prevState => ({
      ...prevState,
      anexos: newAnexo
    }));

    const newAnexosErrors = [...anexosErrors];
    newAnexosErrors[index] = value.length >= 3 ? "" : `El anexo ${index + 1} debe tener al menos 3 caracteres.`;
    setAnexosErrors(newAnexosErrors);

  }

  const handleRemoveAnexo = (index) => {

    const newAnexos = nuevoDocumento.anexos.filter((_, i) => i !== index);

    setNuevoDocumento(prevState => ({
      ...prevState,
      anexos: newAnexos
    }));
  }

  const actualizarAnexos = () => {
    if (motivoEdicion) {
      handleEditDemanda(nuevoDocumento.anexos, motivoEdicion);
      navigate('/api/bandeja/entrada');
      showSnackbar('Anexos actualizados.', 'success');
    } else {
      showSnackbar('Por favor ingrese el motivo de edición.', 'error');
    }
  }

  return (
    <>

      <Acciones accion1={data ? actualizarAnexos : handleConfirmSave} accion2={handleClose} />

      <ConfirmationModal
        // open={modalOpen}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={handleButtonSave}
        title="¿Generar demanda?"
        message="Se generará una nueva demanda en el sistema."
        confirmationButton="Sí, generar"
        closeButton="No, cancelar"
      />
      <Grid className="box-sombra ">
        <h3 className="title1">{Glosario.oralidadFamiliar}</h3>
        <form>

          <Grid container spacing={2} sx={{ padding: 4, flex: 1, overflowY: "auto" }}>
            <Grid item xs={8}>
              <Tabs value={tabvalue} onChange={handleTabChange}>
                <Tab label="Actor" />
                <Tab label="Demandado" />
                <Tab label="General" />
              </Tabs>
            </Grid>
            <Grid item xs={4}></Grid>

            {tabvalue === 0 && (
              <>
                <ActorDemandadoForm
                  data={nuevoDocumento.actor}
                  handleInputChange={handleInputChange}
                  prefix="actor"
                  errors={errors}
                  formatPhoneCelNumbers={formatPhoneCelNumbers}
                  disabled={data ? true : false}
                />

                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={data ? true : false}
                        checked={nuevoDocumento.general.tieneAbogado === 1}
                        onChange={(e) =>
                          setNuevoDocumento((prevState) => ({
                            ...prevState,
                            general: {
                              ...prevState.general,
                              tieneAbogado: e.target.checked ? 1 : 0,
                            },
                          }))
                        }
                        color="primary"
                      />
                    }
                    label="Tiene abogado"
                  />
                </Grid>

                {nuevoDocumento.general.tieneAbogado === 1 && (
                  <>
                    <Grid item xs={4}>
                      <TextField
                        label="Nombre"
                        name="general.nombreAbogado"
                        disabled={!nuevoDocumento.general.tieneAbogado || data}
                        fullWidth
                        value={nuevoDocumento.general.nombreAbogado}
                        onChange={handleInputChange}
                        error={!!errors["general.nombreAbogado"]}
                        helperText={errors["general.nombreAbogado"] || ''}
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <TextField
                        label="Cédula"
                        name="general.cedulaAbogado"
                        disabled={!nuevoDocumento.general.tieneAbogado || data}
                        fullWidth
                        value={nuevoDocumento.general.cedulaAbogado}
                        onChange={handleInputChange}
                        error={!!errors["general.cedulaAbogado"]}
                        helperText={errors["general.cedulaAbogado"] || ''}
                      />
                    </Grid>

                    <Grid item xs={4}>
                      <TextField
                        label="Correo"
                        name="general.correoAbogado"
                        fullWidth
                        value={nuevoDocumento.general.correoAbogado}
                        disabled={!nuevoDocumento.general.tieneAbogado || data}
                        onChange={handleInputChange}
                        error={!!errors["general.correoAbogado"]}
                        helperText={errors["general.correoAbogado"] || ''}
                      />
                    </Grid>
                  </>
                )}
              </>
            )}

            {tabvalue === 1 && (
              <ActorDemandadoForm
                data={nuevoDocumento.demandado}
                handleInputChange={handleInputChange}
                prefix="demandado"
                disabled={data ? true : false}
                errors={errors}
                formatPhoneCelNumbers={formatPhoneCelNumbers}
              />
            )}

            {tabvalue === 2 && (
              <Grid container spacing={2} mt={2}>
                {/* Sección de Juicios */}
                <Grid item xs={12} md={6}>
                  <Box paddingBottom={2}>
                    <Typography>Juicios</Typography>
                    <Paper
                      elevation={1}
                      sx={{ padding: 2, flex: 1, overflowY: "auto" }}
                    >
                      {nuevoDocumento.general.tiposJuicios.map((juzgado) => (
                        <Chip
                          sx={{ mx: 1, mb: 1 }}
                          key={juzgado.id}
                          id={juzgado.id}
                          label={juzgado.nombre}
                          disabled={data}
                          onDelete={() =>
                            handleDeleteTiposJuiciosGenerales(juzgado)
                          }
                        />
                      ))}
                    </Paper>
                  </Box>

                  <Typography>Juicios a tramitar</Typography>

                  <FormControl
                    fullWidth
                    error={!!errors.tipoJucios} // Cambia el color del borde y texto si hay un error
                    required
                    variant="outlined" // Asegura que tenga el estilo "outlined"
                  >
                    <Select
                      labelId="select-juzgado-label"
                      value={juzgado}
                      disabled={data}
                      onChange={handleJuzgadoChange}
                      label="Juzgado"
                      fullWidth
                      required
                      displayEmpty
                      renderValue={
                        juzgado !== ""
                          ? undefined
                          : () => "Seleccionar juicio"
                      }
                    >
                      {/* Opciones de juzgados */}
                      {juzgados.map((juzgado) => (
                        <MenuItem key={juzgado.id} value={juzgado.id}>
                          {juzgado.nombre}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{errors?.tipoJucios || "Selecciona un juicio"}</FormHelperText>
                  </FormControl>

                </Grid>

                {/* Divider Vertical */}
                <Grid item xs={12} md={1}>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{
                      display: { xs: "none", md: "block" },
                      height: "100%",
                    }}
                  />
                </Grid>

                {/* Último domicilio familiar */}
                <Grid item xs={12} md={5}>
                  <Typography>
                    Último domicilio familiar
                  </Typography>
                  <RadioGroup onChange={handleChangeDomicilioGeneral} value={domicilioGeneralSeleccionado}>
                    <FormControlLabel
                      key={1}
                      value="1"
                      disabled={data}
                      control={<Radio />}
                      label={`Mismo que el domicilio del actor`}
                    />
                    <FormControlLabel
                      key={2}
                      value="2"
                      disabled={data}
                      control={<Radio />}
                      label={`Mismo que el domicilio del demandado`}
                    />
                    <FormControlLabel
                      key={3}
                      value="3"
                      disabled={data}
                      control={<Radio />}
                      label={`Otro domicilio`}
                    />
                  </RadioGroup>

                  <TextField
                    label="Domicilio"
                    name="general.domicilio"
                    onChange={handleInputChange}
                    fullWidth
                    value={nuevoDocumento.general.domicilio}
                    disabled={domicilioGeneralSeleccionado !== 3}
                    error={!!errors["general.domicilio"]}
                    helperText={errors["general.domicilio"] || ''}
                  />

                  {nuevoDocumento.general.tiposJuicios.some(item => item.nombre === "Familiar Oralidad (Alimentos)") && (
                    <TextField
                      sx={{ mt: 2 }}
                      label="Domicilio acreedor"
                      name="general.domicilioAcreedor"
                      onChange={handleInputChange}
                      fullWidth
                      value={nuevoDocumento.general.domicilioAcreedor}
                      disabled={data}
                      error={!!errors["general.domicilioAcreedor"]}
                      helperText={errors["general.domicilioAcreedor"] || ''}
                    />
                  )}
                </Grid>

                {/* Divider Horizontal */}
                <Grid item xs={12}>
                  <Divider
                    orientation="horizontal"
                    flexItem
                    sx={{ mt: 2, width: "100%" }}
                  />
                </Grid>

                { /*  MOTIVO ACTUALIZACIÓN ANEXOS  */}
                {data && (
                  <>
                    <Grid item xs={12}>
                      <TextField
                        label="Motivo de edición"
                        name="motivoEdicion"
                        onChange={(e) => {
                          setMotivoEdicion(e.target.value);
                        }}
                        fullWidth
                        value={motivoEdicion}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Divider
                        orientation="horizontal"
                        flexItem
                        sx={{ mb: 2, width: "100%" }}
                      />
                    </Grid>
                  </>
                )}


                {/* Sección de Anexos */}
                <Grid item xs={12}>
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <Typography >Anexos</Typography>
                    <Box sx={{ flexGrow: 2 }}>
                      {nuevoDocumento.anexos.map((anexo, index) => (
                        <Grid
                          container
                          alignItems="center"
                          key={index}
                          sx={{ mb: 2 }}
                        >
                          <Grid item xs={11}>
                            <TextField
                              label={`Anexo ${index + 1}`}
                              fullWidth
                              value={anexo}
                              onChange={(e) => handleAnexoChange(index, e.target.value)}
                              inputRef={(el) => (anexoRefs.current[index] = el)}
                              helperText={anexosErrors[index] && "El anexo debe tener al menos 3 caracteres"}
                              FormHelperTextProps={{
                                style: {
                                  color: anexosErrors[index] !== "" ? "#d32f2f" : "inherit",
                                },
                              }}
                            />
                          </Grid>
                          <Grid item>
                            <IconButton
                              onClick={() => handleRemoveAnexo(index)}
                            >
                              <DeleteIcon className='icon-color' />
                            </IconButton>
                          </Grid>
                        </Grid>
                      ))}
                      {/* Button anexos */}
                      <Grid item xs={12}>
                        <Button
                          className="button-validation"
                          startIcon={<AddCircleOutlineIcon />}
                          variant="contained"
                          onClick={handleAddAnexo}
                        >
                          Agregar Anexo
                        </Button>
                      </Grid>
                    </Box>
                  </Box>

                </Grid>
              </Grid>
            )}
          </Grid>
        </form>
      </Grid>

    </>
  );
}
