import axios from 'axios';

const url = process.env.REACT_APP_TRIALS_API_URL + `/api/core/rubro`;

  export const fetchRubrosByProcedimiento = async (token, procedimientoId) => {
    try {
        const response = await axios.get(
            `${url}/${procedimientoId}`, 
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error al obtener los rubros:', error, "con id:", procedimientoId);
        throw error;
    }
  };
