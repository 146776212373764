import axios from "../../api/axiosConfig";
const urlCarpeta = process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/carpeta`;

export const fetchCarpetaActorDemandado = async (token, data) => {
    try {
        const response = await axios.get(urlCarpeta, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                params: data
        });
        return response.data;
    } catch (error) {
        console.error('Error al encontrar la carpeta:', error);
        throw error;
    }
};

export const fetchPersonasByCarpetaId = async (token, carpetaId) => {
    try {
        const response = await axios.get(`${urlCarpeta}/personas/${carpetaId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error al obtener las personas:', error);
        throw error;
    }
};

export const fetchInfoExpediente = async (token, docId) => {
    try {
        const response = await axios.get(`${urlCarpeta}/recepcion/${docId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error al obtener la información del expediente:', error);
        throw error;
    }
};

export const fetchCatalogos = async (token, catalogo) => {
    const url = `${urlCarpeta}/enums/${catalogo}`;

    try{
        const response = await axios.get(url, {
            headers:{
                Authorization: `Bearer ${token}`
            }
        });

        return response.data;
    } catch (error) {
        console.error('Error al obtener el catálogo', error);
        throw error;
    }

};

export const fetchNumeroPieza = async (token, data) => {
    const url = `${urlCarpeta}/piezas/numPieza?clavePieza=${data.clavePieza}&carpetaId=${data.carpetaId}`;

    try{
        const response = await axios.get(url, {
            headers:{
                Authorization: `Bearer ${token}`
            }
        });

        return response.data;
    } catch (error) {
        console.error('Error al obtener el numero de pieza:', error);
        throw error;
    }

};

export const fetchExpedienteDetalle = async (token, docId) => {
    try {
        const response = await axios.get(`${urlCarpeta}/expediente/detalle/${docId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error al obtener la informacion del expediente:', error);
        throw error;
    }
};

export const updateDetalleExpediente = async (token, data, docId) => {
    try {
        const response = await axios.post(`${urlCarpeta}/expediente/detalle/${docId}`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error actualizar detalle expediente:', error);
        throw error;
    }
};

export const fetchPiezas = async (token, data) => {
    const url = `${process.env.REACT_APP_TRIALS_API_URL}/api/workflow/carpeta/piezas?documentoId=${data.documentoId}`;
  
    try{
        const response = await axios.get(url, {
            headers:{
                Authorization: `Bearer ${token}`
            }
        });
  
        return response.data;
    } catch (error) {
        console.error('Error al obtener el numero de pieza:', error);
        throw error;
    }
  
};

export const createPiezas = async (token, data) => {
    const url = `${process.env.REACT_APP_TRIALS_API_URL}/api/workflow/carpeta/piezas/adjuntar?carpetaId=${data.carpetaId}`;
  
    try{
        const response = await axios.post(url, data.pieza, {
            headers:{
                Authorization: `Bearer ${token}`
            }
        });
  
        return response.data;
    } catch (error) {
        console.error('Error al obtener el numero de pieza:', error);
        throw error;
    }
  
};

export const adjuntarPieza = async (token, data) => {
    const url = `${process.env.REACT_APP_TRIALS_API_URL}/api/workflow/carpeta/piezas/adjuntar?piezaId=${data.piezaId}`;
  
    try{
        const response = await axios.put(url, data.pieza, {
            headers:{
                Authorization: `Bearer ${token}`
            }
        });
  
        return response.data;
    } catch (error) {
        console.error('Error al obtener el numero de pieza:', error);
        throw error;
    }
  
};

export const fetchDocumentosAndPiezas = async (token, data, key) => {
    const url = `${process.env.REACT_APP_TRIALS_API_URL}/api/workflow/carpeta/documentos/${data.carpetaId}`;
  
    try{
        const response = await axios.get(url, {
            headers:{
                Authorization: `Bearer ${token}`
            },
            params:{
                key: key
            }
        });
  
        return response.data;
    } catch (error) {
        console.error('Error al obtener los documentos de la carpeta:', error);
        throw error;
    }
  
};

export const acoplarPieza = async (token, data) => {
    const url = `${process.env.REACT_APP_TRIALS_API_URL}/api/workflow/carpeta/piezas/acoplar?piezaId=${data.piezaId}&estatus=${data.estatus}`;

    try{
        const response = await axios.post(url, {}, {
            headers:{
                Authorization: `Bearer ${token}`
            }
        });

        return response.data;
    } catch (error) {
        console.error('Error al actualizar la pieza:', error);
        throw error;
    }

};

export const fetchLibroGobierno = async (token, page = 0, size = 20, key = '') => {
    try {
      const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/carpeta/librogobierno`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page,
          size,
          key,
          sort: 'id,asc',
        },
      });

      return response.data;
    } catch (error) {
      console.error('Error al obtener el libro de gobierno:', error);
      throw error;
    }
};

export const fetchCarpetaSentencia = async (token, data) => {
    const url = `${process.env.REACT_APP_TRIALS_API_URL}/api/workflow/carpeta/sentencia`;

    try{
        const response = await axios.get(url, {
            headers:{
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            params: data
        });

        return response.data;
    } catch (error) {
        console.error('Error al obtener los documentos de la carpeta:', error);
        throw error;
    }

};

export const changeEstadoCarpeta = async (token, data) => {
    const url = `${process.env.REACT_APP_TRIALS_API_URL}/api/workflow/carpeta/actualizar-estado`;

    try {
        const response = await axios.post(url, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });

        return response.data;
    } catch (error) {
        console.error('Error al actualizar el estado:', error);
        throw error;
    }
};

export const fetchCarpetaByExpedienteAndEstado = async (token, numExpediente, year, estado, juzgadoId) => {
    const url = `${process.env.REACT_APP_TRIALS_API_URL}/api/workflow/carpeta/expedientes`;
  
    try {
      const response = await axios.get(url, {
        params: {
          numExpediente,
          year,
          estado,
          idJuzgado: juzgadoId,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      return response.data;
    } catch (error) {
      console.error("Error al obtener los expedientes:", error);
      throw error;
    }
};

export const devolverArchivoJudicial = async (token, data) => {
    const url = `${process.env.REACT_APP_TRIALS_API_URL}/api/workflow/carpeta/devolver/archivo-judicial`;

    try {
        const response = await axios.post(url, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });

        return response.data;
    } catch (error) {
        console.error('Error al devolver los expedientes:', error);
        throw error;
    }
};