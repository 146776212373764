import React, { useState, useEffect, useContext } from 'react';
import {
    FormControl, TextField, Select, MenuItem, InputLabel, Grid, Checkbox, FormControlLabel, FormHelperText
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Breadcrumbs from '../../../components/Breadcrumb/breadcrumbs';
import AccionesOnlyButtons from '../../../components/Acciones/AccionesOnlyButtons';
import { useToastAlert } from '../../../components/Alert/ToastAlertSave/ToastAlertSave';
import { useToastAlertUpdate } from '../../../components/Alert/ToastAlertUpdate/ToastAlertUpdate';
import { useToastAlertValidation } from '../../../components/Alert/ToastAlertValidation/ToastAlertValidation';
import SelectAllTransferList from '../../../components/TransferList/TransferListTipoJuicio';
import { fetchMaterias } from '../../Materias/MateriasService';
import { fetchTipoJuicioByMateria } from '../../TipoJuicio/TipoJuicioService';
import { validateFields } from '../../../components/Alert/ValidationRequired/validationRequired';
import { createConceptos } from '../ConceptoService';
import { findByConceptoId } from '../ConceptoService';
import KeycloakConf from '../../../api/keycloakConf';
import { updateConceptos } from '../ConceptoService';

export default function FormConcepto({ onAdd, onEdit }) {
    const navigate = useNavigate();
    const { showSnackbar } = useToastAlert();
    const { showSnackbar: showUpdateSnackbar } = useToastAlertUpdate();
    const { showSnackbar: showAlertValidate } = useToastAlertValidation();

    const [formData, setFormData] = useState({
        concepto: '',
        diasTermino: '',
        estado: 'ACTIVE',
        materiaId: null
    });
    const { getToken } = useContext(KeycloakConf);
    const token = getToken();
    const [materias, setMaterias] = useState([]);
    const [tipoJuicio, setTipoJuicio] = useState([]);
    const [tipoJuicioSelected, setTipoJuicioSelected] = useState([]);
    const [errorMessages, setErrorMessages] = useState({});

    useEffect(() => {
        loadMaterias();
        if (onEdit) {
            getById();
        } else {
            resetForm();
        }
    }, [onEdit, token]);


    const loadMaterias = async () => {
        try {

            const materiasResponse = await fetchMaterias(token);
            setMaterias(materiasResponse);
        } catch (error) {
            console.error('Error al obtener materias:', error);
        }
    };

    const getById = async () => {
        const response = await findByConceptoId(token, onEdit.id)
        setFormData({
            id: response.conceptoId,
            concepto: response.concepto,
            diasTermino: response.dias,
            estado: response.estado,
            materiaId: response.materia,
        });

        if (response.nombreTipoJuicio) {
            setTipoJuicioSelected([{ id: response.juicioId, nombre: response.nombreTipoJuicio }]);
        }

        await getTiposJuicioByMateriaId(response.materia, response.nombreTipoJuicio , response.juicioId);


    }



    const getTiposJuicioByMateriaId = async (id, tiposJuicios, juicioId) => {
        try {
            const tipoJuiciosResponse = await fetchTipoJuicioByMateria(token, id);
            const nuevaLista = tipoJuiciosResponse.filter(tipoJuicio => tipoJuicio.id !== juicioId);
            setTipoJuicio(nuevaLista);
        } catch (error) {
            console.error('Error al obtener tipos de juicio:', error);
        }
    };



    const handleMateriaChange = async (event) => {
        const selectedMateriaId = event.target.value;
        setFormData(prev => ({ ...prev, materiaId: selectedMateriaId }));
        setTipoJuicioSelected([]);

        await getTiposJuicioByMateriaId(selectedMateriaId);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const rules = {
            concepto: { required: true },
            diasTermino: { required: true },
            estado: { required: true },
            materiaId: { required: true }
        };

        const errors = validateFields(formData, rules, showAlertValidate);
        if (Object.keys(errors).length > 0) {
            setErrorMessages(errors);
            return;
        }
        if (tipoJuicioSelected.length === 0) {
            errors.tipoJuicio = 'Debe seleccionar un tipo de juicio';
            showSnackbar('Debe seleccionar un tipo de juicio', 'error');
            setErrorMessages(errors);
            return;
        }
        if (tipoJuicioSelected.length > 1) {
            errors.tipoJuicio = 'Solo se permite enviar un tipo de juicio'; 
            showSnackbar('Solo se permite enviar un tipo de juicio', 'error'); 
            setErrorMessages(errors); return; 
        }

        const juiciosSeleccionados = tipoJuicioSelected
            .filter((tj) => {
                const seIncluyeElJuicioSeleccionadoEnLosTipos = !tipoJuicio.includes(tj.nombre);
                return seIncluyeElJuicioSeleccionadoEnLosTipos
            });

        const tipoJuiciosIds = juiciosSeleccionados.map((obj) => ({ id: obj.id }));
        const tipoJuicioSeleccionado = tipoJuiciosIds.length > 0 ? tipoJuiciosIds[0] : null;
        let formDataToSubmit = {
            id: formData.id,
            nombre: formData.concepto,
            dias: formData.diasTermino,
            estado: formData.estado,
            tipoJuicio: tipoJuicioSeleccionado
        };

        try {
    
            if (onEdit) { await updateConceptos(token, formDataToSubmit);
                 showSnackbar('Registro actualizado con éxito', 'success'); 
                } else { 
                    await createConceptos(token, formDataToSubmit);
                    showSnackbar('Registro guardado con éxito', 'success'); 
                } if (onAdd) onAdd(); 
                resetForm();
        } catch (error) {
            console.error('Error al guardar el registro:', error);
            showSnackbar('¡Algo salió mal!', 'error');
        }
    };

    const resetForm = () => {
        setFormData({
            concepto: '',
            diasTermino: '',
            estado: 'ACTIVE',
            materiaId: null
        });
        setTipoJuicio([]);
        setTipoJuicioSelected([]);
        setErrorMessages({});
    };

    return (
        <form onSubmit={handleSubmit}>
            <AccionesOnlyButtons accion1={handleSubmit} accion2={() => navigate('/api/core/conceptos')} />

            <Grid className='box-sombra'>
                <Breadcrumbs istitulo="true" className="title2" />

                <Grid container spacing={2} sx={{ marginTop: 1 }}>
                    <Grid item xs={12} sm={6} md={4}>
                        <FormControl fullWidth variant="outlined" error={!!errorMessages.concepto}>
                            <TextField
                                name="concepto"
                                label="Concepto"
                                variant="outlined"
                                fullWidth
                                value={formData.concepto}
                                onChange={handleChange}
                                error={!!errorMessages.concepto}
                                helperText={errorMessages.concepto}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <FormControl fullWidth variant="outlined" error={!!errorMessages.diasTermino}>
                            <TextField
                                name="diasTermino"
                                label="Días para término"
                                type="number"
                                min={0}
                                variant="outlined"
                                fullWidth
                                value={formData.diasTermino}
                                onChange={(e) => { const value = e.target.value; if (value >= 0) { handleChange(e); } }}
                                error={!!errorMessages.diasTermino}
                                helperText={errorMessages.diasTermino}
                              
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <FormControl fullWidth variant="outlined" error={!!errorMessages.materiaId}>
                            <InputLabel id="select-materia-label">Materia</InputLabel>
                            <Select
                                labelId="select-materia-label"
                                value={formData.materiaId || ''}
                                onChange={handleMateriaChange}
                                required
                                label="Materia"
                            >
                                {materias.map(materia => (
                                    <MenuItem key={materia.id} value={materia.id}>
                                        {materia.nombre}
                                    </MenuItem>
                                ))}
                            </Select>
                            {errorMessages.materiaId && <FormHelperText>{errorMessages.materiaId}</FormHelperText>}
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                        <FormControl fullWidth variant="outlined">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formData.estado === "ACTIVE"}
                                        onChange={(event) => handleChange({
                                            target: {
                                                name: 'estado',
                                                value: event.target.checked ? 'ACTIVE' : 'INACTIVE'
                                            }
                                        })}
                                        color="primary"
                                    />
                                }
                                label="Activar concepto"
                            />
                            {errorMessages.estado && <FormHelperText>{errorMessages.estado}</FormHelperText>}
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <p style={{ color: '#D32F2F', margin: '0 0 8px 0', fontSize: '0.875rem', textAlign: 'center' }}>
                            {errorMessages.tipoJuicio}
                        </p>
                        <SelectAllTransferList
                            itemList={tipoJuicio}
                            materiaId={formData.materiaId}
                            tipoJuicioSelected={tipoJuicioSelected}
                            setTipoJuicioSelected={setTipoJuicioSelected}
                            sx={{
                                boxShadow: '220px 220px 212px 220px hsl(0deg 0% 0% / 18%)',
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
}