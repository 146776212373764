import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Box,
  Grid,
  FormHelperText,
  FormControlLabel,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Divider,
  Checkbox
} from '@mui/material';
import { validateFields } from '../../../../components/Alert/ValidationRequired/validationRequired';
import { useNavigate } from 'react-router-dom';
import { fetchMaterias } from '../../../Materias/MateriasService';
import { fetchSedesDomicilio } from '../../../Sedes/SedeService';
import { addJuzgado, fetchJuzgadoById, updateJuzgado } from '../../../../shared/services/JuzgadosService';
import { useToastAlert } from '../../../../components/Alert/ToastAlertSave/ToastAlertSave';
import { useToastAlertUpdate } from '../../../../components/Alert/ToastAlertUpdate/ToastAlertUpdate';
import SelectAllTransferList from '../../../../components/TransferList/TransferListTipoJuicio';
import { useToastAlertValidation } from '../../../../components/Alert/ToastAlertValidation/ToastAlertValidation';
import { fetchTipoJuicioByMateria } from '../../../TipoJuicio/TipoJuicioService';
import AccionesOnlyButtons from '../../../../components/Acciones/AccionesOnlyButtons';
import Breadcrumbs from '../../../../components/Breadcrumb/breadcrumbs';

const FormJuzgado = ({ token, onAddJuzgado, juzgadoToEdit }) => {
  const navigate = useNavigate();
  const { showSnackbar, showConfirmDialog } = useToastAlert();
  const { showSnackbar: showAlertValidate } = useToastAlertValidation();
  const { showSnackbar: showUpdateSnackbar } = useToastAlertUpdate();

  const [juzgado, setJuzgado] = useState({
    id: '',
    nombre: '', 
    estado: '',
    materiaId: '',
    sedeId: '',
    contadorAsignaciones: '',
    maxAsignacionesRonda: '',
    instanciaJuzgado: '', 
  });

  const [materias, setMaterias] = useState([]);
  const [sedes, setSedes] = useState([]);
  const [tipoJuicio, setTipoJuicio] = useState([]); // todos los tipos juicios dado una materia id
  const [materiaId, setMateriaId] = useState(null);
  const [tipoJuicioSelected, setTipoJuicioSelected] = useState([]);
  const [errorMessages, setErrorMessages] = useState({});
  const [esExhorto, setEsExhorto] = useState(false);
  
  useEffect(() => {
    loadMaterias();
    loadSedesAndDomicilios();

    if (juzgadoToEdit) {
      getById();
    } else {
      resetForm();
      setJuzgado((prevData) => ({
        ...prevData,
        estado: 'ACTIVE',
      }));
      
    }
    
  }, [token, onAddJuzgado, juzgadoToEdit]);

  const getById = async () => {
      try {
        const response = await fetchJuzgadoById(token, juzgadoToEdit.id);
        const instancia = response.instanciaJuzgado;
        const mappedInstancia =
          instancia === 0 ? "0" : instancia === 1 ? "1" : "2";
       
        setJuzgado({
          ...response,
          instanciaJuzgado: mappedInstancia, 
        });
        const objMateriaId = response.materiaId;
        
        setMateriaId(objMateriaId);
        setTipoJuicioSelected(response.tipoJuicios);
        getTiposJuicioByMateriaId(objMateriaId, response.tipoJuicios);

        setEsExhorto(response.materiaId === 350);
        
      } catch (error) {
        console.error('Error al obtener la sede:', error);
      }
  };

  const loadMaterias = async () => {
    try {
      const materias = await fetchMaterias(token);
      setMaterias(materias);
    } catch (error) {
      console.error('Error al obtener materias:', error);
    }
  };

  const loadSedesAndDomicilios = async () => {
    try {
      const response = await fetchSedesDomicilio(token);
      if (response && Array.isArray(response.content)) {
        setSedes(response.content);
      } else {
        console.error('La respuesta no contiene un array en `content`:', response);
      }
    } catch (error) {
      console.error('Error al obtener las sedes:', error);
    } 
  };

  const getTiposJuicioByMateriaId = async (id, tiposJuicios = []) => {
    try {
      const tipoJuiciosResponse = await fetchTipoJuicioByMateria(token, id);

      const tiposJuicio = tipoJuiciosResponse
      .filter((tjr) => !tiposJuicios
      .find((tjs) => (tjs.id == tjr.id)));

      setTipoJuicio(tiposJuicio);
    } catch (error) {
      console.error('Error al obtener tipos de juicio:', error);
    }
  };

  const resetForm = () => {
    setMateriaId(null);
    setTipoJuicioSelected([]);
    setJuzgado({
      id: '',
      nombre: '',
      estado: 'ACTIVE',
      materiaId: '',
      sedeId: '',
      contadorAsignaciones: '',
      maxAsignacionesRonda: '',
      instanciaJuzgado: '',
    });
    setErrorMessages({});
    setEsExhorto(false);
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setJuzgado({
      ...juzgado,
      [name]: value,
    });
    setErrorMessages({
      ...errorMessages,
      [name]: '',
    });
    if (name === "materiaId") {
      setMateriaId(value); 
      setTipoJuicioSelected([]);

      const materiaSeleccionada = materias.find(
        (materia) => materia.id === value
      );
      try {
        const tiposJuicio = await fetchTipoJuicioByMateria(token, value);
        setTipoJuicio(tiposJuicio); 
      } catch (error) {
        console.error('Error al cargar tipos de juicio:', error);
      }
      if (materiaSeleccionada && materiaSeleccionada.nombre === "EXHORTO") {
        setEsExhorto(true);
      } else {
        setEsExhorto(false);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const rules = {
      nombre: { required: true },
      estado: { required: true },
      materiaId: { required: true },
      sedeId: { required: true },
      maxAsignacionesRonda: { required: true},
      instanciaJuzgado: { required: !esExhorto },
    };

    const errors = validateFields(juzgado, rules, showAlertValidate);
    if (Object.keys(errors).length > 0) {
      setErrorMessages(errors);
      return;
    }

    if (tipoJuicioSelected.length === 0) {
      errors.tipoJuicio = 'Debe seleccionar al menos un tipo de juicio';
      showSnackbar('Debe seleccionar al menos un tipo de juicio', 'error');
      setErrorMessages(errors);
      return;
    }

    console.log(juzgado.maxAsignacionesRonda);
    if (juzgado.maxAsignacionesRonda < 0) {
      errors.maxAsignacionesRonda = 'El máximo de asignaciones debe ser un número positivo';
      showSnackbar('El máximo de asignaciones debe ser un número positivo', 'error');
      setErrorMessages(errors);
      return;
    }

    const juiciosSeleccionados = tipoJuicioSelected
      .filter((tj) => {
        const seIncluyeElJuicioSeleccionadoEnLosTipos = !tipoJuicio.includes(tj.nombre);
        return seIncluyeElJuicioSeleccionadoEnLosTipos
      });

      const tipoJuiciosIds = juiciosSeleccionados.map((obj) => ({ id: obj.id }));

    let formData = {
        ...juzgado,
        materia: {id:materiaId},
        sede: { id: juzgado.sedeId },
        tipoJuicios: tipoJuiciosIds,
        instanciaJuzgado: esExhorto ? '2' : juzgado.instanciaJuzgado, 
    };

    try {
      if (juzgado.id) {
        if (!tipoJuiciosIds.length) {
          showSnackbar("Error al enviar tipos de juicio", 'error');
          return;
        }
        await updateJuzgado(token, formData);
        showUpdateSnackbar('Registro actualizado con éxito', 'success');
      } else {
        await addJuzgado(token, formData);
        showSnackbar('Registro guardado con éxito', 'success');
      }

      if (onAddJuzgado) {
        onAddJuzgado();
      }

      resetForm();
    } catch (error) {
      console.error('Error al guardar juzgado:', error);

      if (error.response && error.response.status === 409) {
        showSnackbar("No pueden existir 2 juzgados con el mismo nombre", 'error');
        setErrorMessages({ ...errorMessages, nombre: 'Nombre de juzgado ya existe' });
      } else {
        showSnackbar('¡Algo salió mal!', 'error');
      }
    }
  };

  const handleCancel = () => {
    navigate('/api/core/juzgados');
  };

  return (
    <form onSubmit={handleSubmit}>
      <AccionesOnlyButtons accion1={handleSubmit} accion2={handleCancel}/>
      <Grid className='box-sombra'>
        <Breadcrumbs istitulo="true" className="title2"/>
        <Grid container spacing={2} sx={{ marginTop: 1 }}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            name="nombre"
            label="Nombre"
            variant="outlined"
            fullWidth
            value={juzgado.nombre}
            onChange={handleChange}
            error={!!errorMessages.nombre}
          />
          {errorMessages.nombre && <FormHelperText error>{errorMessages.nombre}</FormHelperText>}
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth variant="outlined" error={!!errorMessages.materiaId}>
            <InputLabel id="materia-label">Materia</InputLabel>
            <Select
              labelId="materia-label"
              name="materiaId"
              value={juzgado.materiaId}
              onChange={handleChange}
              label="Materia"
            >
              {materias.map((materia) => (
                <MenuItem key={materia.id} value={materia.id}>{materia.nombre}</MenuItem>
              ))}
            </Select>
            {errorMessages.materiaId && <FormHelperText>{errorMessages.materiaId}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth variant="outlined" error={!!errorMessages.sedeId}>
            <InputLabel id="sede-label">Domicilio</InputLabel>
            <Select
              labelId="sede-label"
              name="sedeId"
              value={juzgado.sedeId}
              onChange={handleChange}
              label="Domicilio"
           
            >
              {sedes.map((sede) => (
                <MenuItem key={sede.sedeId} value={sede.sedeId}>{sede.sedeNombre}, C.{sede.calle}, No.{sede.exterior}, Col:{sede.colonia}, {sede.estadoRepublica}, CP:{sede.codigoPostal}</MenuItem>
              ))}
            </Select>
            {errorMessages.sedeId && <FormHelperText>{errorMessages.sedeId}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            name="contadorAsignaciones"
            label="Contador de asignaciones"
            variant="outlined"
            disabled
            fullWidth
            value={juzgado.contadorAsignaciones}
            onChange={handleChange}
            error={!!errorMessages.contadorAsignaciones}
          />
          {errorMessages.contadorAsignaciones && <FormHelperText error>{errorMessages.contadorAsignaciones}</FormHelperText>}
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            name="maxAsignacionesRonda"
            label="Máximo de asignaciones"
            type="number"
            min={0}
            variant="outlined"
            fullWidth
            value={juzgado.maxAsignacionesRonda}
            onChange={handleChange}
            error={!!errorMessages.maxAsignacionesRonda}
          />
          {errorMessages.maxAsignacionesRonda && <FormHelperText error>{errorMessages.maxAsignacionesRonda}</FormHelperText>}
        </Grid>
        {!esExhorto && (
        <Grid item xs={12} sm={6} md={4}>
         
          <FormControl fullWidth variant="outlined" error={!!errorMessages.instanciaJuzgado}>
            <InputLabel id="instancia-label">Instancia</InputLabel>
            <Select
              labelId="instancia-label"
              name="instanciaJuzgado"
              value={juzgado.instanciaJuzgado}
              onChange={handleChange}
              label="Instancia"
            >
              <MenuItem value="0">1era Instancia</MenuItem>
              <MenuItem value="1">2da Instancia</MenuItem>
            </Select>
            {errorMessages.instanciaJuzgado && (<FormHelperText>{errorMessages.instanciaJuzgado}</FormHelperText>)}</FormControl>
        </Grid>
  )}
        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth variant="outlined">
            <FormControlLabel
              control={
                <Checkbox
                  checked={juzgado.estado === "ACTIVE"}
                  onChange={(event) => handleChange({
                  target: {
                      name: 'estado',
                      value: event.target.checked ? 'ACTIVE' : 'INACTIVE'
                    }
                  })}
                  color="primary"
                />
              }
              label="Activar órgano jurisdiccional"
            />
            {errorMessages.estado && <FormHelperText>{errorMessages.estado}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <p style={{ color: '#D32F2F', margin: '0 0 8px 0', fontSize: '0.875rem', textAlign: 'center' }}>
            {errorMessages.tipoJuicio}
          </p>
          <SelectAllTransferList
              itemList = {tipoJuicio}
              materiaId = {materiaId}
              tipoJuicioSelected = {tipoJuicioSelected}
              setTipoJuicioSelected = {setTipoJuicioSelected}
              sx={{
                boxShadow: '220px 220px 212px 220px hsl(0deg 0% 0% / 18%)', // Sombra aplicada al contenedor
              }}
          />
        </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default FormJuzgado;
