export const initialActorFisica = {
  nombreActor: "",
  apellidoPaternoActor: "",
  apellidoMaternoActor: "",
  pseudonimoActor: "",
};

export const initialActorMoral = {
  nombreActorMoral: "",
};

export const initialDemandadoFisica = {
  nombreDemandado: "",
  apellidoPaternoDemandado: "",
  apellidoMaternoDemandado: "",
  pseudonimoDemandado: "",
};

export const initialDemandadoMoral = {
  nombreDemandadoMoral: "",
};
