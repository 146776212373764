import { useState } from "react";
import { IconButton, Typography, Tooltip, Checkbox, FormControlLabel, Box } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import TablaActores from './Tables/TablaActoresAudiencias';
import InfoIcon from "@mui/icons-material/Info";

const Asistencia = ({ item, isConsulta }) => {

  const [filtrarAsistencia, setFiltrarAsistencia] = useState(false);

  const groupedByType = (participantes) => {
    return participantes.reduce((acc, participante) => {
      if (!acc[participante.tipoParte]) {
        acc[participante.tipoParte] = [];
      }
      acc[participante.tipoParte].push(participante);
      return acc;
    }, {});
  }

  const participantesFiltrados = () => {
    if (!filtrarAsistencia) {
      return item?.asistenciaPersonaDocumento || [];
    }
    return item?.asistenciaPersonaDocumento || [];
  };

  const groupedParticipantes = groupedByType(participantesFiltrados());

  return <>
    <Box style={{ display: "flex", alignItems: "center", marginBottom: 8 }}>
      <Tooltip title="Participantes" arrow>
        <IconButton className="icon-color" sx={{ marginRight: 1 }}>
          <PersonAddAlt1Icon />
        </IconButton>
      </Tooltip>
      <FormControlLabel
        control={<Checkbox checked={filtrarAsistencia} onChange={(e) => setFiltrarAsistencia(e.target.checked)} />}
        label="Se presentó a la audiencia"
      />
      <Tooltip title="Filtrar y ver solo los que si se presentaron a la audiencia" arrow>
        <IconButton size="small" className="icon-color">
          <InfoIcon />
        </IconButton>
      </Tooltip>
    </Box>

    {Object.entries(groupedParticipantes).map(([tipoParte, participantes], index) => {
      return (
        <Accordion key={`accordion-header-${tipoParte}-${index}`}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography variant="body1">{tipoParte}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TablaActores
              key={`tabla-${tipoParte}`}
              data={participantes}
              tipo={tipoParte}
              item={item}
              filtrarAsistencia={filtrarAsistencia}
            />
          </AccordionDetails>
        </Accordion>
      );
    })}
  </>
};

export default Asistencia;