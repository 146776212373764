import axios from "../../api/axiosConfig";
const urlExhorto = process.env.REACT_APP_TRIALS_API_URL + "/api/workflow/exhorto";

export const sendExhorto = async (token, formData) => {
    
    try {
    const response = await axios.post(urlExhorto, formData,{
      headers: {
        Authorization: `Bearer ${token}`, 
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error al enviar los datos del exhorto:", error);
    throw error; 
  }
};


export const getExhortoById = async (token, id) => {
  try {
  
    const response = await axios.get(urlExhorto+`/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error al obtener el exhorto y editarla por ID:', error);
    throw error;
  }
};
