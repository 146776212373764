import { Box, Button, Grid, TextField } from "@mui/material";
import { useState } from "react";
import { validateFields } from "../../components/Alert/ValidationRequired/validationRequired";
import { onlyNumbers } from "../../components/Caracteres/caracteresEspeciales";
import { useToastAlertValidation } from "../../components/Alert/ToastAlertValidation/ToastAlertValidation";
import { fetchCarpetaActorDemandado } from "../../shared/services/CarpetaService";
import keycloakConf from "../../api/keycloakConf";
import { useContext } from "react";

const RegistrosAntiguosForm = ({ onError,  onSubmit }) => {
    const [formData, setFormData] = useState({
        numeroExpediente: "",
        anioExpediente: "",
    });
    const [errors, setErrors] = useState({});
    const { showSnackbar: showAlertValidate } = useToastAlertValidation();
    const { getToken } = useContext(keycloakConf);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const formattedValue = name === "numeroExpediente" || name === "anioExpediente" 
            ? onlyNumbers(value) 
            : value;

        setFormData((prevState) => ({
            ...prevState,
            [name]: formattedValue,
        }));
    };

    const handleValidate = async () => {
        const rules = {
            numeroExpediente: { required: true },
            anioExpediente: { required: true },
        };
        const validationErrors = validateFields(formData, rules, showAlertValidate);
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            try {
                const token = getToken();
                const response = await fetchCarpetaActorDemandado(token, {
                    numExpediente: formData.numeroExpediente,
                    year: formData.anioExpediente,
                });

                showAlertValidate("Expediente encontrado", "success", "Validación exitosa");
                if (onError) {
                    onError(true);
                }
                 
            } catch (error) {
                // habilitar
                if (onError) {
                    onError(false); 
                }
                if (onSubmit) {
                    onSubmit(formData); 
                }
            }
        }
    };

    return (
        <Box sx={{ marginTop: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Número de expediente"
                        variant="outlined"
                        name="numeroExpediente"
                        value={formData.numeroExpediente}
                        onChange={handleInputChange}
                        fullWidth
                        error={!!errors.numeroExpediente}
                        helperText={errors.numeroExpediente}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        label="Año de expediente"
                        variant="outlined"
                        name="anioExpediente"
                        inputProps={{ maxLength: 4 }}
                        value={formData.anioExpediente}
                        onChange={handleInputChange}
                        fullWidth
                        error={!!errors.anioExpediente}
                        helperText={errors.anioExpediente}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button className='button-validation' variant="contained" color="primary" onClick={handleValidate}>
                        Validar
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default RegistrosAntiguosForm;
