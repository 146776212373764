import React, { useEffect, useState } from 'react';
import { Box, Typography, FormControl, InputLabel, Select, MenuItem, TextField, Grid, FormHelperText } from '@mui/material';
import { fetchCatalogo } from '../../../../shared/services/CatalogoListService';
import fetchPaises from '../../../../shared/services/PaisService';
import { fetchEstados } from '../../../../shared/services/EstadosService';
import { fetchMunicipiosByEstadoId } from '../../../../shared/services/EstadosService';
import validCombinations from './validCombinations';

const DatosContacto = React.memo(({ participante, onInputChange, token, tipoSeleccionado, tipoPersona, isEdit, showValidations, isDisabled}) => {
  const [tiposDomicilios, setTipoDomicilio] = useState([]);
  const [paisesResidencia, setPaisesResidencia] = useState([]);
  const [estados, setEstados] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [paisSeleccionado, setPaisSeleccionado] = useState('');
  const [paisMxId, setPaisMxId] = useState('');
  const [paisMx, setPaisMx] = useState('');
  const [hideAllFields, setHideAllFields] = useState(false);
  const [errors, setErrors] = useState({});

  const validateFields = () => {
    const newErrors = {};
    if (!participante.pais) newErrors.pais = 'País de residencia es obligatorio';
    if (!participante.estado) newErrors.estado = 'Estado es obligatorio';
    if (!participante.municipio) newErrors.municipio = 'Ciudad es obligatorio';
    if (!participante.codigoPostal || participante.codigoPostal.length !== 5) newErrors.codigoPostal = 'Código postal es obligatorio';
    if (!participante.calle) newErrors.calle = 'Calle es obligatoria';
    if (!participante.colonia) newErrors.colonia = 'Colonia es obligatoria';
    if (!participante.numeroExterior) newErrors.numeroExterior = 'Número exterior es obligatorio';
    if (!participante.numeroInterior) newErrors.numeroInterior = 'Número interior es obligatorio';
    if (!participante.tipoDomicilio) newErrors.tipoDomicilio = 'Tipo de domicilio es obligatorio';
    if (!participante.correoElectronico) newErrors.correoElectronico = 'Correo electrónico es obligatorio';
    if (!participante.telefono) newErrors.telefono = 'Teléfono es obligatorio';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    if (showValidations) {
      validateFields();
    }
  }, [showValidations]);
  
  useEffect(() => {
    const combinationKey = `${tipoSeleccionado}_${tipoPersona}`;
    const selectedType = validCombinations[combinationKey];

    if (selectedType === 'disableAll') {
      setHideAllFields(true); 
    } else {
      setHideAllFields(false);
    }
  }, [tipoSeleccionado, tipoPersona, validCombinations]); 

  
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    onInputChange(name, value); 
  
    if (name === 'pais') {
      setPaisSeleccionado(value);
      if (value === paisMx?.id) {
        loadEstados();  
       
      } else {
        onInputChange('estado', '');
        onInputChange('municipio', '');
        setEstados([]);
        setMunicipios([]);
      }
    }  else if (name === 'estado') {

      if (paisSeleccionado === paisMx?.id && value) {
        const estadoSeleccionado = estados.find(estado => estado.name === value);
        if (estadoSeleccionado) {
          loadMunicipios(estadoSeleccionado.id);
        }
      } else {
        setMunicipios([]); 
      }
    }

    validateFields();
  };

  const loadTiposDomicilio = async () => {
    try {
      const tipoDomicilioData = await fetchCatalogo(token, 'tipoDomicilio');
      setTipoDomicilio(tipoDomicilioData);
    } catch (error) {
      console.error('Error al obtener registros:', error);
    }
  };

  const loadPaises = async () => {
    try {
      const paisesResidenciaData = await fetchPaises(token);
      setPaisesResidencia(paisesResidenciaData);
      const paisMx = paisesResidenciaData.find((pais)=> pais.codeAlpha2 === 'MX');
      
      if( !isEdit && paisMx ) {
        setPaisSeleccionado(paisMx.id);
        setPaisMxId(paisMx?.id);
        setPaisMx(paisMx);
        onInputChange('pais', paisMx.id); 
      } else if(isEdit && participante?.pais?.id == null && paisMx?.id ){
        setPaisSeleccionado(paisMx?.id);
        setPaisMxId(paisMx?.id);
        onInputChange('pais', paisMx.id);
      } else if(isEdit && participante?.pais?.id){
        setPaisSeleccionado(participante?.pais.id);
        setPaisMxId(paisMx?.id);
        onInputChange('pais', participante?.pais.id);
      }
      
    } catch (error) {
      console.error('Error al obtener paises:', error);
    }
  };

  const loadEstados = async () => {
    try {
      const estadosData = await fetchEstados(token);
      setEstados(estadosData);
    } catch (error) {
      console.error("Error al cargar estados:", error);
    }
  };
  
  useEffect(() => {
    if (paisSeleccionado === paisMx?.id) {
      loadEstados();
    }
  }, [paisSeleccionado, paisMx]);
  
  useEffect(() => {
    if (estados.length > 0 && participante?.estado) {
      const nombreEstado = participante.estado;
      const currentEstado = estados.find((estado) => estado?.name === nombreEstado);
      if (currentEstado) {
        loadMunicipios(currentEstado.id);
      }
    }
  }, [estados, participante?.estado]);

  const loadMunicipios = async (estadoId) => {
    try {
      const municipiosData = await fetchMunicipiosByEstadoId(token, estadoId);
      setMunicipios(municipiosData);
    } catch (error) {
      console.error('Error al obtener los municipios:', error);
    }
  };

  const loadData = () => {
    loadPaises();
    loadTiposDomicilio();
    loadEstados();
  }
  
  useEffect(() => {
    loadData();
  }, [token]);
  
  return (
    <Box p={4} sx={{ width: '100%' }}>
      <Grid container spacing={2} sx={{ width: '100%' }}>
        {/* Columna 1: País de residencia */}
        
        {!hideAllFields && (
        <>
        <Grid item xs={12} md={4}>
          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel>País de residencia</InputLabel>
            <Select
              name="pais"
              value={participante.pais?.id || participante.pais || ''}
              onChange={handleInputChange}
              label="País de residencia"
              disabled={isDisabled}
            >
               {paisesResidencia.map((pais) => (
                <MenuItem key={pais.id} value={pais.id}>{pais.nombre}</MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors.pais}</FormHelperText>
          </FormControl>
        
        </Grid>

        {/* Columna 2: Estado */}
        <Grid item xs={12} md={4}>
        <FormControl fullWidth sx={{ marginBottom: 2 }}  error={!!errors.estado}>
        
      {(paisSeleccionado === paisMxId) ? (
      <React.Fragment>
        <InputLabel>Estado</InputLabel>
        <Select
          name="estado"
          value={participante.estado || ''}
          onChange={handleInputChange}
          label="Estado"
          error={!!errors.estado}
          helperText={errors.estado} 
          disabled={isDisabled}
        >
          {estados.map((estado) => (
            <MenuItem key={estado.id} value={estado.name}>
              {estado.name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>{errors.estado}</FormHelperText>
      </React.Fragment>
    ) : (
      <TextField
        fullWidth
        name="estado"
        value={participante.estado || '' + paisSeleccionado  + "pais mx " + paisMx}
        onChange={handleInputChange}
        label="Estado" 
        error={!!errors.municipio}
        helperText={errors.municipio} 
        disabled={isDisabled}
      />
    )}
  </FormControl>
</Grid>

{/* Columna 3: Municipio/Ciudad */}
<Grid item xs={12} md={4}>
  <FormControl fullWidth sx={{ marginBottom: 2 }} error={!!errors.municipio}>
    {paisSeleccionado === paisMxId ? (
      <React.Fragment>
        <InputLabel>Ciudad</InputLabel>
        <Select
          name="municipio"
          value={participante.municipio || ''}
          onChange={handleInputChange}
          label="Ciudad"
          disabled={isDisabled}
        >
          {municipios.map((municipio) => (
            <MenuItem key={municipio.id} value={municipio.name}>
              {municipio.name}
            </MenuItem>
          ))} 
        </Select>
        <FormHelperText>{errors.municipio}</FormHelperText>
      </React.Fragment>
    ) : (
      <TextField
        fullWidth
        name="municipio"
        value={participante.municipio || ''}
        onChange={handleInputChange}
        label="Ciudad" 
        error={!!errors.municipio}
        helperText={errors.municipio} 
        disabled={isDisabled}
      />
    )}
  </FormControl>
        </Grid>

        {/* Columna 1: Código Postal */}
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            name="codigoPostal"
            value={participante.codigoPostal}
            onChange={handleInputChange}
            label="Código postal"
            sx={{ marginBottom: 2, '& .MuiInputBase-root': { borderColor: errors.codigoPostal ? 'red' : '' } }}
            error={!!errors.codigoPostal} 
            helperText={errors.codigoPostal} 
            disabled={isDisabled}
          />
        </Grid>

        {/* Columna 2: Colonia */}
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            name="colonia"
            value={participante.colonia}
            onChange={handleInputChange}
            label="Colonia"
            error={!!errors.colonia} 
            helperText={errors.colonia} 
            sx={{ marginBottom: 2 }}
            disabled={isDisabled}
          />
        </Grid>

        {/* Columna 3: Calle */}
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            name="calle"
            value={participante.calle}
            onChange={handleInputChange}
            label="Calle"
            error={!!errors.calle} 
            helperText={errors.calle} 
            sx={{ marginBottom: 2 }}
            disabled={isDisabled}
          />
        </Grid>

        {/* Columna 1: Número Exterior */}
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            name="numeroExterior"
            value={participante.numeroExterior}
            onChange={handleInputChange}
            label="Número exterior"
            error={!!errors.numeroExterior} 
            helperText={errors.numeroExterior} 
            sx={{ marginBottom: 2 }}
            disabled={isDisabled}
          />
        </Grid>

        {/* Columna 2: Número Interior */}
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            name="numeroInterior"
            value={participante.numeroInterior}
            onChange={handleInputChange}
            label="Número interior"
            error={!!errors.numeroInterior} 
            helperText={errors.numeroInterior}
            sx={{ marginBottom: 2 }}
            disabled={isDisabled}
          />
        </Grid>

        {/* Columna 1: Tipo de Domicilio */}
        <Grid item xs={12} md={4}>
          <FormControl fullWidth sx={{ marginBottom: 2 }} error={!!errors.estado}>
            <InputLabel>Tipo de domicilio</InputLabel>
            <Select
              name="tipoDomicilio"
              value={participante.tipoDomicilio ?? ''}
              onChange={handleInputChange}
              label="Tipo de domicilio"
              error={!!errors.tipoDomicilio} 
              helperText={errors.tipoDomicilio}
              disabled={isDisabled}
            >
              {tiposDomicilios.map((tipo) => (
                <MenuItem key={tipo.clave} value={tipo.clave}>
                  {tipo.etiqueta}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{errors.tipoDomicilio}</FormHelperText>
          </FormControl>
        </Grid>

        {/* Columna 1: Correo electrónico */}
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            name="correoElectronico"
            value={participante.correoElectronico}
            onChange={handleInputChange}
            label="Correo electrónico"
            error={!!errors.correoElectronico} // Mostrar error si hay un error en el campo
            helperText={errors.correoElectronico || ''}
            sx={{ marginBottom: 2 }}
            disabled={isDisabled}
          />
        </Grid>

        {/* Columna 2: Teléfono */}
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            name="telefono"
            value={participante.telefono}
            onChange={handleInputChange}
            label="Teléfono"
            error={!!errors.telefono} 
            helperText={errors.telefono}
            sx={{ marginBottom: 2 }}
            disabled={isDisabled}
          />
          
        </Grid>
        </>
        )}
      </Grid>
    </Box>
  );
});

export default DatosContacto;
