import axios from "../../api/axiosConfig";

export const getConceptos = async (token, page = 0, size = 25, sort = '', key = '') => {
    try {
        const params = new URLSearchParams();
        params.append("sort", 'estado');
        params.append("sort", 'nombre');
        params.append("page", page);
        params.append("size", size);
        params.append("key", key);
        const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/core/conceptos/registros`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            params: params,
        });

        return response.data;
    } catch (error) {
        console.error('Error al listar conceptos:', error);
        throw error;
    }
};


export const createConceptos = async (token, nuevoConcepto) => {
    console.log(nuevoConcepto)
    try {
  
      const response = await axios.post(process.env.REACT_APP_TRIALS_API_URL + `/api/core/conceptos`, nuevoConcepto, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      return response.data;
    } catch (error) {
      console.error('Error al agregar concepto:', error);
      throw error;
    }
  };


  export const updateStatusConcepto = async (token, id, status) => {
    try {
        const response = await axios.patch(process.env.REACT_APP_TRIALS_API_URL + `/api/core/conceptos/${id}/status/${status}`,
         {},
         {
           headers: {
             Authorization: `Bearer ${token}`,
           }
         }
        );
   
        return response.data;
     } catch (error) {
       console.log('Error al actualizar el estado del concepto: ', error);
       
     }
};


export const deleteConcepto = async (token, id) => {
    try {
        const response = await axios.delete(process.env.REACT_APP_TRIALS_API_URL + `/api/core/conceptos/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });

        return response.data;
    } catch (error) {
        console.error('Error al eliminar conceptos:', error);
        throw error;
    }
};


export const findByConceptoId = async (token, id) => {
    try {
        const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/core/conceptos/conceptoJuicio/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        });

        return response.data; 
    } catch (error) {
        console.error('Error al obtener concepto', error);
        throw error;
    }
};

export const updateConceptos = async (token, nuevoConcepto) => {
  
    try {
      const response = await axios.put(process.env.REACT_APP_TRIALS_API_URL + `/api/core/conceptos`, nuevoConcepto, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      return response.data;
    } catch (error) {
      console.error('Error al agregar concepto:', error);
      throw error;
    }
  };
