import React, { useState, useRef } from "react";
import { Modal, Box, Button, Typography, Grid, Card, CardContent } from "@mui/material";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { useToastAlert } from '../../../components/Alert/ToastAlertSave/ToastAlertSave';
import ConfirmationDialog from "../../../components/Alert/ConfirmationDialog";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const ActaMinimaModal = ({ isModalOpen, setModalOpen, handleAceptar, onClose, handleCargarArchivos }) => {
  const [formData, setFormData] = useState({ file: null });
  const { showSnackbar } = useToastAlert();
  const fileInputRef = useRef(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (file.size > 50 * 1024 * 1024) {
      showSnackbar('El archivo debe ser menor a 50 MB', 'error');
      return;
    }
    if (file.type !== "application/pdf") {
      showSnackbar("Solo se permiten archivos PDF.", "error");
      return;
    }

    setFormData({ ...formData, file });
    if (handleCargarArchivos) {
      handleCargarArchivos(file); // Llamar al callback cuando el archivo cambia
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!formData.file) {
      showSnackbar('Por favor, cargue un archivo PDF antes de guardar.', 'error');
      return;
    }

    let dataSend = new FormData();
    dataSend.append("file", formData.file);

    try {
      // Aquí puedes hacer la llamada a tu servicio para guardar el archivo
      // await createDocument(token, dataSend, formData.id);
      showSnackbar('Archivo guardado con éxito', 'success');

      // Llamar a handleAceptar para pasar el archivo al componente padre
      if (handleAceptar) {
        handleAceptar(formData.file);
      }

      // Cerrar el modal después de guardar
      setModalOpen(false);
    } catch (error) {
      console.error("Error al guardar el documento:", error);
      showSnackbar('¡Algo salió mal!', 'error');
    }
  };

  const handleDownloadPdf = () => {
    const blob = new Blob([formData.file], { type: "application/pdf" });
    let link = document.createElement("a");

    link.href = window.URL.createObjectURL(blob);
    link.target = "digitalizacion.pdf";
    link.click();
  };

  const handleConfirmDelete = () => {
    setFormData({ ...formData, file: null });
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'white',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal open={isModalOpen} onClose={onClose} aria-labelledby="modal-acta-minima-title">
      <Box sx={style}>
        <Typography variant="h6" component="h2" sx={{ fontWeight: 'bold', mb: 2 }}>
          Subir acta mínima
        </Typography>

        {/* Botón de cargar archivo */}
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12}>
            <Button   sx={{ width: '80%' }} variant="contained" component="label" className="button-save"
              startIcon={<CloudUploadIcon />}
              fullWidth>
              Cargar acta mínima PDF
              <input type="file" accept="application/pdf" hidden onChange={handleFileChange} ref={fileInputRef} />
            </Button>
          </Grid>

          {/* Mostrar el archivo cargado */}
          {formData.file && (
            <Card sx={{ maxWidth: 160, margin: "0 auto", position: "relative" , marginTop: 1}}>
  <Typography
    variant="body4"
    sx={{
      position: "absolute",
      top: 5,
      right: 5,
      cursor: "pointer",
      fontWeight: "bold",
      color: "red",
      zIndex: 1,
      fontSize: '16px',
    }}
    onClick={() => setDialogOpen(true)}
  >
    X
  </Typography>
  <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: 80 }}>
    <PictureAsPdfIcon sx={{ fontSize: 60, color: "black" }} />
  </Box>
  <CardContent sx={{ padding: 1 }}>
    <Typography
      variant="body2"
      sx={{
        color: 'blue',
        textDecoration: 'underline',
        cursor: 'pointer',
        maxWidth: '150px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
      onClick={handleDownloadPdf}
    >
      {formData.file.name}
    </Typography>
  </CardContent>
</Card>

          )}
        </Grid>

        {/* Botones de acción */}
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2, marginTop: 3 }}>
          <Button variant="outlined" onClick={onClose} className="button-close">
            Cancelar
          </Button>
          <Button variant="outlined" onClick={handleSubmit} className="button-save">
            Guardar
          </Button>
        </Box>

        <ConfirmationDialog
          open={dialogOpen}
          onClose={handleCloseDialog}
          onConfirm={handleConfirmDelete}
          message={`¿Está seguro de eliminar este archivo?`}
        />
      </Box>
    </Modal>
  );
};

export default ActaMinimaModal;
