import axios from 'axios';


export const getTipoAudiencias = async(token) =>{
 
    try {
      const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/core/tipoaudiencia`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      
      return response.data.content;
  
    } catch (error) {
      console.log('Error al obtener los tipos de audiencias del documento: ', error);

    }
  
  }

  export const getSalasAll = async(token) =>{
 
    try {
      const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/core/salas/allByJuzgado`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      
      return response.data;
  
    } catch (error) {
      console.log('Error al obtener las salas: ', error);

      
    }
  
  }

  export const createAudiencia = async(token, data) => {
    try {
      const response = await axios.post(process.env.REACT_APP_TRIALS_API_URL + "/api/workflow/audiencias/crearAudiencias", data, {
          headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
          }
      });
      return response.data;
  } catch (error) {
      console.error('Error al crear la audiencia:', error);
      throw error;
  }
  }

  export const reprogramarAudiencia = async (token, data) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_TRIALS_API_URL}/api/workflow/bandeja/audienciasgenerales/reprogramar`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
      });
      return response.data;
    } catch (error) {
      console.error('Error al reprogramar la audiencia:', error);
      throw error;
    }
  };

  export const getAgendaSala = async (token, salaId) => {
    
    try {
      const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/audiencias/getAgenda/${salaId}`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      
      return response.data;
  
    } catch (error) {
      console.log('Error al obtener la agenda: ', error);

      
    }
  };

  export const validateDisponibilidadSala = async (token, salaId, fecha, hora, duracion) => {
    
    try {
      const requestData = {
        salaId,
        fecha,
        hora,
        duracion,
      };

      const response = await axios.post(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/audiencias/validarDisponibilidad`,
        requestData,
        {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      return response.data;
  
    } catch (error) {
       if (error.response) {
        if (error.response.status === 400) {
            console.error('Error 400 - Audiencia en conflicto: ', error.response.data);
            return { error: 'Audiencia en conflicto', details: error.response.data };
        }
        console.error('Error al validar la disponibilidad: ', error.response);
        return { error: 'Hubo un problema al validar la disponibilidad de la sala' };
    } else if (error.request) {
        console.error('No se recibió respuesta del servidor: ', error.request);
        return { error: 'No se pudo contactar al servidor' };
    } else {
        console.error('Error en la configuración de la solicitud: ', error.message);
        return { error: 'Error en la configuración de la solicitud' };
    }     
    }
  };

  export const checkDiaInhabil = async (token, fecha, juzgadoId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_TRIALS_API_URL}/api/workflow/audiencias/getDiaInhabil`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            fecha: fecha,
            juzgadoId : juzgadoId,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error('Error al verificar si el día es inhabil:', error);
      throw error;  
    }
  };

  