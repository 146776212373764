import React from 'react';
import {
  Box,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  IconButton,
  Stack
} from '@mui/material';
import PaginationGeneric from '../../../components/Pagination/PaginationGeneric';
import text from '../../../assets/Glosario';
import CheckIcon from '@mui/icons-material/Check'; 
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const OficialiasTable = ({
  oficialias,
  onDelete,
  onEdit,
  pageable,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage
}) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
      <PaginationGeneric
          pageable={pageable}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
        <TableContainer sx={{ maxHeight: 600, overflowY: 'auto' }}>
          <Table stickyHeader sx={{ minWidth: 750 }}>
            <TableHead>
              <TableRow>
                <TableCell className='table-header'>{text.Nombre}</TableCell>
                <TableCell className='table-header'>{text.Mate}</TableCell>
                <TableCell className='table-header'>{text.Tipo}</TableCell>
                <TableCell className='table-header'>{text.Juzgado}</TableCell>
                <TableCell className='table-header'>{text.Acciones}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {oficialias
                .map((oficialia) => (
                  <StyledTableRow
                    key={oficialia.id}
                    onClick={() => onEdit(oficialia)} // Acción al hacer click en la fila
                    hover // Agrega un efecto de hover
                    style={{ cursor: 'pointer' }} // Cambio del cursor al pasar por la fila
                  >
                    <TableCell>{oficialia.nombre}</TableCell>
                    <TableCell>{oficialia.materiaNombresList}</TableCell>
                    <TableCell>{oficialia.tipoOficialiaNombre}</TableCell>
                    <TableCell>{oficialia.juzgadoNombre}</TableCell>

                    <TableCell align="center" >
                      <Stack direction="row">
                      <Tooltip title={oficialia.estado === 'ACTIVE' ? 'Desactivar' : 'Activar'}>
                      <IconButton className='icon-color'
                        style={{ marginRight: '10px' }}
                        onClick={(event) => {
                          event.stopPropagation();
                          
                          onDelete(oficialia);
                        }}
                      >
                        <CheckIcon />
                      </IconButton>
                    </Tooltip>
                      </Stack>
                    </TableCell>
                  </StyledTableRow>
                ))}
              {oficialias.length === 0 && (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No hay oficialías disponibles.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <PaginationGeneric
          pageable={pageable}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
      </Paper>
    </Box>
  );
};

export default OficialiasTable;
