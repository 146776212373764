
import React, { useState, useEffect } from 'react';
import { Button, MenuItem, Select, FormControl, InputLabel, Grid, Typography, Box } from '@mui/material';
import CrearLista from '../ModalesGrid/CrearLista';

export default function NotificacionFiltros({
  tipo,
  setTipo,
  estado,
  setEstadoNotificacion,
  isButtonEnabled,
  cantidadNotificaciones,
  selectedIds,
  loadNotificacionData,
  setSendRutaModal,
  data
}) {

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (tipo === "DOMICILIO") {
      setEstadoNotificacion("POR_NOTIFICAR");
    }
    if (estado === '' || (tipo !== "DOMICILIO" && (estado === "POR_NOTIFICAR" || estado === "EN_RUTA" || estado === "NOTIFICADOS"))) {
      setEstadoNotificacion("PENDIENTE_DE_ASIGNAR");
    }
  }, [tipo]);

  const handleCreateList = () => {
    setIsModalOpen(true); // Abre el modal
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Cierra el modal
  };

  const handleSaveList = (selectedIds) => {

    setIsModalOpen(false);
  };

  const handleDescargarListaButton = () => {
    // Colocar accion de boton Descargar Lista
  };

  const handleChangeTipo = (e) => {
    if (e.target.value !== "CORREO_ELECTRONICO") {
      setEstadoNotificacion("PENDIENTE_DE_ASIGNAR");
    } else {
      setEstadoNotificacion("POR_LEER")
    }
    setTipo(e.target.value)
  };

  return (
    <>
      <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
        <Grid item>
          <Box display="flex" alignItems="center">
            <Typography sx={{ marginRight: 1 }}>Tipo:</Typography>
            <FormControl sx={{ width: 300, marginRight: 5 }}>
              <InputLabel>Tipo</InputLabel>
              <Select
                defaultValue="ESTRADO"
                onChange={handleChangeTipo}
                label="TIPO"
              >
                <MenuItem value="ESTRADO">Estrado</MenuItem>
                <MenuItem value="CORREO_ELECTRONICO">Correo electronico</MenuItem>
                <MenuItem value="DOMICILIO">Domicilio</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
        {tipo === "DOMICILIO" ? (
          <>
            <Grid item>
              <Box display="flex" alignItems="center">
                <Typography sx={{ marginRight: 1 }}>Ver:</Typography>
                <FormControl sx={{ width: 300, marginRight: 5 }}>
                  <InputLabel>Ver</InputLabel>
                  <Select
                    value={estado}
                    onChange={(e) => setEstadoNotificacion(e.target.value)}
                    label="Ver"
                  >
                    <MenuItem value="POR_NOTIFICAR">Por notificar</MenuItem>
                    <MenuItem value="EN_RUTA">En ruta</MenuItem>
                    <MenuItem value="NOTIFICADOS">Notificados</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>

            {estado === "POR_NOTIFICAR" && (
              <Grid item>
                <Button
                  type="button"
                  variant="contained"
                  className="button-save"
                  onClick={() => setSendRutaModal(true)}
                  disabled={!isButtonEnabled}
                >
                  En ruta
                </Button>
              </Grid>
            )}

            {estado === "EN_RUTA" && (
              <Grid item>
                <Button
                  type="button"
                  variant="contained"
                  className="button-save"
                  onClick={handleDescargarListaButton}
                  disabled={!isButtonEnabled}
                >
                  Descargar lista
                </Button>
              </Grid>
            )}
          </>
        ) : (
          <>
            <Grid item>
              <Box display="flex" alignItems="center">
                <Typography sx={{ marginRight: 1 }}>Ver:</Typography>
                <FormControl sx={{ width: 300, marginRight: 5 }}>
                  <InputLabel>Ver</InputLabel>

                  {tipo !== 'CORREO_ELECTRONICO' ? (
                    <Select
                      value={estado || 'PENDIENTE_DE_ASIGNAR'}
                      onChange={(e) => setEstadoNotificacion(e.target.value)}
                      label="Ver"
                    >
                      <MenuItem value="PENDIENTE_DE_ASIGNAR">Pendiente de asignar</MenuItem>
                      <MenuItem value="ASIGNADO">Asignado</MenuItem>
                    </Select>
                  ) : (
                    <Select
                      value={estado || 'POR_LEER'} // Valor por defecto para evitar `undefined`
                      onChange={(e) => setEstadoNotificacion(e.target.value)}
                      label="Ver"
                    >
                      <MenuItem value="POR_LEER">Por leer</MenuItem>
                      <MenuItem value="LEIDOS">Leídos</MenuItem>
                    </Select>
                  )}

                </FormControl>
              </Box>
            </Grid>

            {tipo !== 'CORREO_ELECTRONICO' && (
              <Grid item>
                <Button
                  type="button"
                  variant="contained"
                  className="button-save"
                  onClick={handleCreateList}
                  disabled={!isButtonEnabled}
                >
                  Crear lista
                </Button>
              </Grid>
            )}
          </>
        )}
        <CrearLista
          isOpen={isModalOpen}
          handleClose={handleCloseModal}
          cantidadNotificaciones={cantidadNotificaciones}
          handleSave={handleSaveList}
          selectedIds={selectedIds}
          loadNotificacionData={loadNotificacionData}
          data={data}
        />
      </Grid>
    </>
  );
}
